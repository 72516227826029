// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  COMMENT_TYPE,
  COMMENTS_FETCH_TYPE,
  COMMENT_REMOVED_TYPE,
  COMMENT_ADD_STARTED_TYPE,
  COMMENT_ADD_COMPLETED_TYPE,
  COMMENT_ADD_FAILED_TYPE,
  COMMENT_UPDATE_STARTED_TYPE,
  COMMENT_UPDATE_COMPLETED_TYPE,
  COMMENT_UPDATE_FAILED_TYPE,
} from '../types/comments';
import * as types from '../types/comments';


export const fetchComments = (
  comments: { [ID_TYPE]: COMMENT_TYPE },
  order: Array<ID_TYPE>,
): COMMENTS_FETCH_TYPE => ({
  type: types.COMMENTS_FETCH,
  payload: {
    entities: comments,
    order,
  },
});

export const removeComment = (id: ID_TYPE): COMMENT_REMOVED_TYPE => ({
  type: types.COMMENT_REMOVED,
  payload: id,
});

export const startAddingComment = (comment: COMMENT_TYPE): COMMENT_ADD_STARTED_TYPE => ({
  type: types.COMMENT_ADD_STARTED,
  payload: comment,
});

export const completeAddingComment = (
  oldId: ID_TYPE,
  newId: ID_TYPE,
  extra: Object,
): COMMENT_ADD_COMPLETED_TYPE => ({
  type: types.COMMENT_ADD_COMPLETED,
  payload: {
    ...extra,
    oldId,
    newId,
  },
});

export const failAddingComment = (error: ERROR_TYPE): COMMENT_ADD_FAILED_TYPE => ({
  type: types.COMMENT_ADD_FAILED,
  payload: error,
});

export const startUpdateComment = (
  id: ID_TYPE,
  attributes: Object,
): COMMENT_UPDATE_STARTED_TYPE => ({
  type: types.COMMENT_UPDATE_STARTED,
  payload: {
    ...attributes,
    id,
    isConfirmed: false,
  },
});

export const completeUpdateComment = (
  oldId: ID_TYPE,
  newId: ID_TYPE,
  extra: Object,
): COMMENT_UPDATE_COMPLETED_TYPE => ({
  type: types.COMMENT_UPDATE_COMPLETED,
  payload: {
    ...extra,
    oldId,
    newId,
  },
});

export const failUpdateComment = (error: ERROR_TYPE): COMMENT_UPDATE_FAILED_TYPE => ({
  type: types.COMMENT_UPDATE_FAILED,
  payload: error,
});
