import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdClose, MdError, MdArrowBack } from 'react-icons/md';

import * as selectors from '../../../reducers';
import * as uploadingClientsActions from '../../../actions/uploadingClients';
import PopupHeader from '../../PopupHeader';
import PopupHeaderRightGroup from '../../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../../PopupHeaderLeftGroup';
import PopupCloseButton from '../../PopupCloseButton';
import PopupContent from '../../PopupContent';
import PopupFooter from '../../PopupFooter';
import Button from '../../Button';

import styles from './fileUploaderError.module.scss';


type FileUploaderErrorPropTypes = {
  error: ERROR_TYPE,
  onGoBack: Function,
}

const FileUploaderError = ({ error, onGoBack }: FileUploaderErrorPropTypes) => (
  <Fragment>
    <PopupHeader clearPadding isAlert>
      <PopupHeaderLeftGroup>
        <Button
          tint="mediumGray"
          clearMargins
          isTransparent
          isOutlined
          iconPosition="right"
          Icon={MdArrowBack}
          className={styles.popupBackButton}
          onClick={onGoBack}
        />
      </PopupHeaderLeftGroup>
      <PopupHeaderRightGroup>
        <PopupCloseButton
          tint="mediumGray"
          Icon={MdClose}
          clearMargins
          isTransparent
          isOutlined
        />
      </PopupHeaderRightGroup>
    </PopupHeader>
    <PopupContent isAlert className={styles.container}>
      <div className={styles.title}>
        {'¡Algo salió mal durante la lectura del archivo!'}
      </div>
      <div className={styles.divider} />
      <div className={styles.description}>
        <MdError />
        {error.message}
      </div>
      <div className={styles.divider} />
    </PopupContent>
    <PopupFooter isAlert align="center">
      <Button
        type="button"
        onClick={onGoBack}
        title="Volver"
        tint="secondary"
      />
    </PopupFooter>
  </Fragment>
);

export default connect(
  state => ({
    error: selectors.getUploadingClientsError(state),
  }),
  dispatch => ({
    onGoBack() {
      dispatch(uploadingClientsActions.updateUploadingClientsStep(1));
    },
  }),
)(FileUploaderError);
