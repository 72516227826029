// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdMoreVert,
  MdLocalPrintshop,
  MdEdit,
  MdInbox,
  MdDelete,
  MdSettings,
} from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
import * as closeableActions from '../../actions/closeable';
import * as uploadingClientsActions from '../../actions/uploadingClients';
import * as menuItemsActions from '../../actions/menuItems';
import DeletePhaseConfirmPopup, { DELETE_PHASE_POPUP_CONFIRM_DIALOG } from '../DeletePhaseConfirmPopup';
import { api } from '../../api';
import Button from '../Button';
import PopMenu from '../PopMenu';
import PhaseEditionPixel from '../PhaseEditionPixel';
import { BULK_UPLOADING_CLIENTS_POPUP_NAME } from '../BulkUploadingClientsPopup';
import { PHASE_SETTINGS_POPUP_ID } from '../PhaseSettingsPopup';
import { PHASE_SETTINGS_MENU_ID } from '../MenuItemsSettingsPhase';
import { TIER_TYPES } from '../../settings';

import styles from './phaseOptions.module.scss';


type PhaseOptionsPropTypes = {
  id: ID_TYPE,
  onDeleteClicked: Function,
  onLoadClicked: Function,
  onEditClicked: Function,
  onSettingsClicked: Function,
  reportURL: string
};

const PhaseOptions = ({
  id,
  reportURL,
  onDeleteClicked,
  onLoadClicked,
  onEditClicked,
  onSettingsClicked,
  ...props
} : PhaseOptionsPropTypes) => (
  <PopMenu
    id={`phaseMenu-${id}`}
    vertical="below"
    horizontal="left"
    tipPosition="center"
    offset={50}
    isRound
    options={[
      {
        Icon: MdLocalPrintshop,
        title: 'Generar reporte',
        href: reportURL,
        target: '_blank',
      },
      {
        Icon: MdEdit,
        title: 'Editar fase',
        onClick: onEditClicked,
      },
      // { Icon: MdInbox, title: 'Archivar todos los estudiantes' },
      {
        Icon: MdInbox,
        title: 'Actualizar o cargar Estudiantes en masa',
        onClick: onLoadClicked,
      },
      {
        Icon: MdSettings,
        title: 'Configurar',
        onClick: onSettingsClicked,
      },
      {
        Icon: MdDelete,
        title: 'Eliminar fase',
        tint: 'error',
        onClick: onDeleteClicked,
      }
    ]}
    {...props}
  >
    <div className={styles.clickInteractionContainer}>
      <Button
        size="medium"
        Icon={MdMoreVert}
        tint="mediumGray"
        clearMargins
        isTransparent
        isOutlined
      />
      <PhaseEditionPixel id={id} />
    </div>
    <DeletePhaseConfirmPopup id={id} />
  </PopMenu>
);

export default connect(
  (state, { id }) => {
    const phase = selectors.getPhase(state, id);
    const cycleFilter = selectors.getGlobalCycleIdFilter(state);
    const levelFilter = selectors.getGlobalLevelIdFilter(state);
    const { is_superuser = false } = (selectors.getCurrentLoggedUser(state) || {});
    
    const filters = { phase: id }
    if (cycleFilter) filters.cycle = cycleFilter;
    if (levelFilter) filters.level = levelFilter;
    
    return {
      reportURL: api.getURL(`board-reports/${phase.board}/student-report`, { ...filters }),
      schoolTier: ((selectors.getCurrentBoard(state) || {}).school || {}).tier || undefined,
      isSuperUser: is_superuser,
    };
  },
  (dispatch, { id }) => ({
    onDeleteClicked() {
      dispatch(closeableActions.open(`${DELETE_PHASE_POPUP_CONFIRM_DIALOG}_${id}`));
    },
    onDeleteConfirmed() {
      dispatch(actions.removePhase(id));
    },
    onLoadClicked() {
      dispatch(closeableActions.open(BULK_UPLOADING_CLIENTS_POPUP_NAME));
      dispatch(uploadingClientsActions.updateUploadingClientsStep(1));
      dispatch(uploadingClientsActions.clearUploadingClients());
      dispatch(uploadingClientsActions.updateUploadingClientsPhase(id));
    },
    onEditClicked() {
      dispatch(closeableActions.open(`phaseEditionPopover-${id}`));
    },
    onSettingsClicked(schoolTier, isSuperUser) {
      const LIMITED_TIERS = [
        TIER_TYPES.BASIC,
        TIER_TYPES.STANDARD,
      ];
      
      // Tiers
      let activeMenuItem = 'contacts';
      if (LIMITED_TIERS.includes(schoolTier) && !isSuperUser) {
        activeMenuItem = 'fileExport';
      }
      dispatch(actions.selectPhase(id));
      dispatch(menuItemsActions.updateMenuItems(PHASE_SETTINGS_MENU_ID, activeMenuItem));
      dispatch(closeableActions.open(PHASE_SETTINGS_POPUP_ID));
      dispatch(actions.startFetchPhase(id));
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSettingsClicked() {
      dispatchProps.onSettingsClicked(stateProps.schoolTier, stateProps.isSuperUser);
    },
  }),
)(PhaseOptions);
