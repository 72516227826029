// @flow
import isEmptyObject from 'is-empty-object';
import {
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
// import { normalize } from 'normalizr';
// import { delay } from 'redux-saga';

import { fibonacciBackoff } from '../lib/retry';
// import { throwTimeout } from '../lib/http';
// import { REQUEST_TIMEOUT } from '../settings';
import * as types from '../types/clientForm';
import * as selectors from '../reducers';
import * as actions from '../actions/clientForm';

import { Boards } from '../api';


function* fetchClientFormDescription(action) {
  const { payload } = action;
  const user = yield select(selectors.getUser);

  const mock = {
    delay: 0,
    response: {
      statusCode: 200,
      body: {
        client_form_description: {
          name: 'School 1 Client Form',
          slug: 'school-1-client-form',
          is_public: false,
          active_date_from: null,
          active_date_to: null,
          inactive_page_title: null,
          inactive_page_message: '',
          is_cloneable: false,
          success_page_title: null,
          success_page_message: '',
          action: null,
          form_elements: [
            {
              plugin_uid: 'text',
              position: 1,
              plugin_data: {
                label: 'Nombres',
                name: 'nombres',
                help_text: '',
                initial: '--',
                max_length: 50,
                required: true,
                placeholder: 'El placeholder',
              },
            },
            {
              plugin_uid: 'boolean',
              position: 2,
              plugin_data: {
                label: 'boolean field',
                name: 'boolean_field',
                help_text: 'test',
                initial: false,
                required: false,
              },
            },
            {
              plugin_uid: 'date',
              position: 3,
              plugin_data: {
                label: 'date',
                name: 'date',
                help_text: '',
                initial: null,
                input_formats: '',
                required: false,
              },
            },
            {
              plugin_uid: 'decimal',
              position: 4,
              plugin_data: {
                label: 'decimal field',
                name: 'decimal_field',
                help_text: '',
                initial: null,
                max_digits: null,
                decimal_places: null,
                min_value: null,
                max_value: null,
                required: false,
                placeholder: 'De de decimal!!',
              },
            },
            {
              plugin_uid: 'email',
              position: 5,
              plugin_data: {
                label: 'email',
                name: 'email',
                help_text: '',
                initial: '',
                max_length: 255,
                required: true,
                placeholder: '',
              },
            },
            {
              plugin_uid: 'float',
              position: 6,
              plugin_data: {
                label: 'float type',
                name: 'float_type',
                help_text: '',
                initial: null,
                min_value: null,
                max_value: null,
                required: false,
                placeholder: '',
              },
            },
            {
              plugin_uid: 'integer',
              position: 7,
              plugin_data: {
                label: 'integer field',
                name: 'integer_field',
                help_text: '',
                initial: null,
                min_value: null,
                max_value: null,
                required: false,
                placeholder: '',
              },
            },
            {
              plugin_uid: 'null_boolean',
              position: 8,
              plugin_data: {
                label: 'null boolean',
                name: 'null_boolean',
                help_text: '',
                initial: null,
                required: false,
              },
            },
            {
              plugin_uid: 'select',
              position: 9,
              plugin_data: {
                label: 'select',
                name: 'select',
                choices: [
                  {
                    value: 1,
                    name: 'Choice 1',
                  },
                  {
                    value: 2,
                    name: 'Choice 2',
                  },
                  {
                    value: 3,
                    name: 'Choice 3',
                  },
                  {
                    value: 4,
                    name: 'Choice 4',
                  },
                ],
                help_text: '',
                initial: '2',
                required: false,
              },
            },
            {
              plugin_uid: 'text',
              position: 10,
              plugin_data: {
                label: 'text',
                name: 'text',
                help_text: '',
                initial: 'asd',
                max_length: 255,
                required: false,
                placeholder: '',
              },
            },
            {
              plugin_uid: 'textarea',
              position: 11,
              plugin_data: {
                label: 'textarea',
                name: 'textarea',
                help_text: 'asdasdas1`23',
                initial: '',
                required: false,
                max_length: 50,
                placeholder: '',
              },
            },
          ],
          form_handlers: [],
        },
        contact_form_description: {
          name: 'School 1 Contact Form',
          slug: 'school-1-contact-form',
          is_public: false,
          active_date_from: null,
          active_date_to: null,
          inactive_page_title: null,
          inactive_page_message: '',
          is_cloneable: false,
          success_page_title: null,
          success_page_message: '',
          action: null,
          form_elements: [
            {
              plugin_uid: 'text',
              position: 1,
              plugin_data: {
                label: 'Nombre del contacto',
                name: 'nombre_del_contacto',
                help_text: '',
                initial: '',
                max_length: 50,
                required: true,
                placeholder: 'khe?',
              },
            },
            {
              plugin_uid: 'text',
              position: 2,
              plugin_data: {
                label: 'Telefono',
                name: 'telefono',
                help_text: '',
                initial: '',
                max_length: 255,
                required: false,
                placeholder: '',
              },
            },
          ],
          form_handlers: [],
        },
      },
    },
  };

  try {
    let response;
    if (user != null && !isEmptyObject(user)) {
      const token = yield select(selectors.getToken);
      response = yield call(
        fibonacciBackoff,
        [Boards.custom, 'adminClientForm'],
        { token, id: payload, mock },
      );
    } else {
      response = yield call(
        fibonacciBackoff,
        [Boards.custom, 'publicClientForm'],
        { id: payload, mock },
      );
    }

    const { client_form_description, contact_form_description } = response;

    yield put(actions.completeFetchingClientForm({
      clientFormDescription: client_form_description,
      contactFormDescription: contact_form_description,
    }));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingClientForm({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchClientFormFetch(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_FORM_FETCH_STARTED,
    fetchClientFormDescription,
  );
}
