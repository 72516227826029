// @flow
import React from 'react';
import { connect } from 'react-redux';
import { actions as jwtActions } from 'nozzmo-redux-jwt';
import {
  MdPersonAdd,
  MdSettings,
  MdSecurity,
} from 'react-icons/md';

import { SETTINGS_POPUP_ID } from '../SettingsPopup';
import { SETTINGS_MENU_ITEMS_ID } from '../MenuItems/MenuItemsSettings';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupContent from '../PopupContent';
import PopupFooter from '../PopupFooter';
import SettingsUser from './SettingsUser';
import SettingsItem from './SettingsItem';
import SettingsSchools from './SettingsSchools';

import * as closeableActions from '../../actions/closeable';
import * as menuItemsActions from '../../actions/menuItems';
import * as selectors from '../../reducers';


type SettingsPopoverPropTypes = {
  isAdmin: boolean,
  isSuper: boolean,
  onLogout: Function,
  openSettingsPopup: Function,
};

const SettingsPopover = ({
  isSuper,
  onLogout,
  openSettingsPopup,
}: SettingsPopoverPropTypes) => (
  <PopupContainer isPopover>
    <PopupHeader size="small">
      <SettingsUser />
    </PopupHeader>
    <PopupContent>
      <SettingsSchools />
    </PopupContent>
    <PopupContent>
      {
        // TODO: change to isAdmin & validate tiers.. for now, only supers can add new users
        isSuper && (
          <SettingsItem
            Icon={MdPersonAdd}
            text="Manejar usuarios"
            onClick={() => openSettingsPopup('usersMaintenance')}
          />
        )
      }
      <SettingsItem
        Icon={MdSettings}
        text="Configuración personal"
        onClick={() => openSettingsPopup('userSettings')}
      />
      <SettingsItem
        Icon={MdSecurity}
        text="Seguridad"
        onClick={() => openSettingsPopup('securitySettings')}
      />
      {/* <SettingsItem
        Icon={MdSettingsApplications}
        text="Configuración de colegio"
        onClick={() => openSettingsPopup('schoolSettings')}
      /> */}
    </PopupContent>
    <PopupFooter align='left'>
      <SettingsItem
        text="Cerrar sesión"
        theme='alert'
        onClick={() => onLogout()}
      />
    </PopupFooter>
  </PopupContainer>
);


export default connect(
  (state) => {
    const {
      // is_admin = false,
      is_superuser = false,
    } = selectors.getCurrentLoggedUser(state) || {};

    return ({
      isSuper: is_superuser,
    });
  },
  dispatch => ({
    onLogout() {
      dispatch(jwtActions.logout());
    },
    openSettingsPopup(settingItem) {
      dispatch(menuItemsActions.updateMenuItems(SETTINGS_MENU_ITEMS_ID, settingItem));
      dispatch(closeableActions.open(SETTINGS_POPUP_ID));
    },
  }),
)(SettingsPopover);
