// @flow
import { delay } from 'redux-saga';
import { call, race } from 'redux-saga/effects';

import { throwTimeout } from './http';
import HTTP_STATUS from './HTTPStatus';


export function* fibonacciBackoff(
  apiCall: Function | Array<any>,
  params: Object,
  maxAttempts: number = 7,
): Iterator<any> {
  let previousDelay = 1;
  let currentDelay = 1;

  // Max attempts
  for (let i = 1; i <= maxAttempts; i += 1) {
    try {
      const { apiResponse, timeout } = (
        yield race({
          apiResponse: call(apiCall, params),
          timeout: call(delay, 5000),
        })
      ) || {};

      if (timeout) {
        throwTimeout('');
      }

      return apiResponse;
    } catch (err) {
      // Don't call if it's anything other than 504 (synthetic timeout)
      if (err.statusCode === HTTP_STATUS.GATEWAY_TIMEOUT) {
        // Don't call delay if the last attempt fails
        if (i < maxAttempts) {
          yield call(delay, 1000 * currentDelay);

          // Fibonacci step
          const tmp = currentDelay;
          currentDelay += previousDelay;
          previousDelay = tmp;
        }
      } else {
        // Scalate error
        throw err;
      }
    }
  }

  // All attempts failed
  return throwTimeout('fibonacciBackoff');
}
