// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { COMMENT_TYPE } from '../types/comments';
import * as common from './common';
import * as types from '../types/comments';
import * as clientTypes from '../types/clients';


export type CommentsState = {
  byId: { [ID_TYPE]: COMMENT_TYPE },
  errors: { [ID_TYPE]: ERROR_TYPE }
};

const byId = common.byId({
  added: [types.COMMENT_ADD_STARTED],
  updated: [types.COMMENT_UPDATE_STARTED],
  fetched: [types.COMMENTS_FETCH],
  removed: [types.COMMENT_REMOVED],
  confirmed: [
    types.COMMENT_ADD_COMPLETED,
    types.COMMENT_UPDATE_COMPLETED,
  ],
  cascade: {
    [clientTypes.CLIENT_REMOVED]: 'client',
  },
  defaultAttributes: {
    isConfirmed: false,
  },
});

const errors = common.errors({
  clear: [
    types.COMMENT_ADD_STARTED,
    types.COMMENT_UPDATE_STARTED,
  ],
  populate: [
    types.COMMENT_ADD_FAILED,
    types.COMMENT_UPDATE_FAILED,
  ],
});

const comments = combineReducers({
  byId,
  errors,
});


export default comments;


// Selectors
export const getComment = (state: CommentsState, id: ID_TYPE): ?COMMENT_TYPE => state.byId[id];
export const getCommentError = (state: CommentsState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
