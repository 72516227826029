// @flow

export const REQUIRED = 'Este valor es requerido';

export const INVALID_TYPE = 'Tipo de dato no esperado';

export const maxDigits = (digits: number): string => `La cantidad máxima de dígitos es ${digits}`;

export const maxDecimalPlaces = (decimalPlaces: number): string => `La cantidad máxima de decimales es ${decimalPlaces}`;

export const minValue = (value: number): string => `El valor no puede ser menor que ${value}`;

export const maxValue = (value: number): string => `El valor no puede ser mayor que ${value}`;

export const EMAIL = 'Este email no está bien formateado';

export const maxLength = (length: number): string => `La longitud del campo ingresado no debe ser mayor a ${length}`;

export const minLength = (length: number): string => `La longitud del campo ingresado no debe ser menor a ${length}`;

export const integer = (value: any): string => `El valor ${value} no es un entero`;

export const boolean = (value: any, valueType: string): string => `El valor ${value} con tipo ${valueType} no es un booleano válido`;

export const choices = (): string => 'El valor seleccionado no es una opción disponible';
