// @flow
import React from 'react';

import styles from './dropFileZone.module.scss';
import pictureURL from '../../../../assets/images/drop_file_here.png';

type DropFileZonePropsType = {
  width: number | string,
  height: number | string, 
};

const DropFileZone = ({
  width,
  height,
  ...props
}: DropFileZonePropsType) => (
  <div {...props} style={{ width, height }} className={styles.dropFileZone}>
    <div {...props} className={styles.placeholder}>
      <img src={pictureURL} alt="alt" />
      {'Suelta el archivo acá...'}
    </div>
  </div>
);

export default DropFileZone;
