// @flow
import {
  eventChannel,
  END,
  Channel,
} from 'redux-saga';
import {
  call,
  cancelled,
  put,
  fork,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { formValueSelector, change } from 'redux-form';
import { contentType } from 'mime-types';
import axios from 'axios';

import { Files } from '../api';
import * as actions from '../actions/files';
import {
  FILE_UPLOAD_STARTED,
  FILE_UPDATE_UPLOAD_PROGRESS,
} from '../types/files';
import { getFileName } from '../utils';

function createUploaderChannel(signedUrl, fileToUpload, options) {
  return eventChannel((emit) => {
    const onProgress = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      emit(percentCompleted);
    };

    axios.put(signedUrl, fileToUpload, {
      ...options,
      onUploadProgress: onProgress,
    }).then(() => {
      emit(END);
    }).catch((err) => {
      emit(new Error(err.message));
      emit(END);
    });

    const unsubscribe = () => {};
    return unsubscribe;
  });
}

function* uploadProgressWatcher(UUID, fieldName, channel: Channel) {
  while (true) {
    try {
      const progress = yield take(channel);
      yield put(actions.updateFileUploadProgress(UUID, progress, fieldName));
    } catch (err) {
      /* yield put(actions.uploadFiles.progress(err)) */
    } finally {
      if (yield cancelled()) channel.close();
    }
  }
}

function* fetchSignFileUrl({ payload }: any) {
  // const token = yield select(selectors.getToken);

  try {
    for (let i = 0; i < payload.filesToUpload.length; i++) {
      const fileToUpload = payload.filesToUpload[i].file;
      const fileName = getFileName(payload.filesToUpload[i].name);
      const fileExtension = payload.filesToUpload[i].extension;
      const fileUUID = payload.filesToUpload[i].UUID;
      const mimeType = contentType(
        `${fileName}.${fileExtension}`,
      );
      const signedResponse = yield call(
        [Files.custom, 'sign'],
        {
          // token,
          data: {
            key: `${fileUUID}/${fileName}.${fileExtension}`,
            type: mimeType,
          },
        },
      );
      const signedUrl = signedResponse.presigned_post;

      if (!signedUrl) {
        throw new Error('No presigned url provided by the server.');
      }

      const options = {
        headers: {
          'Content-Type': mimeType,
        },
      };

      const uploadChannel = yield call(
        createUploaderChannel,
        signedUrl,
        fileToUpload,
        options,
      );

      yield fork(uploadProgressWatcher, fileUUID, payload.fieldName, uploadChannel);

      /* const fileResponse = yield call(
        [Files, 'create'],
        {
          token,
          data: {
            name: file.name,
            extension: fileExtension,
            uuid,
          }
        }
      );

      const addFileResponse = yield call(
        [Participants.custom, 'addFile'],
        {
          token,
          id: participantId,
          data: {
            uuid,
          }
        }
      );

      yield put(actions.startFileFetch(participantId));  */
    }
  } catch (e) {
    /* console.log(e); */
  }
}

function* updateFileUploadProgress({ payload }: any) {
  const { UUID, progress, fieldName } = payload;

  const formSelector = formValueSelector('clientForm');
  const fieldValue = yield select(formSelector, fieldName);

  const tempFiles: any[] = fieldValue.files || [];

  yield put(
    change(
      'clientForm',
      fieldName,
      {
        ...fieldValue,
        files: tempFiles.map((file) => {
          if (file.UUID === UUID) {
            return {
              ...file,
              progress,
            };
          }
          return file;
        }),
      },
    ),
  );
}

export function* watchFetchSignFileUrl(): Iterator<any> {
  yield takeLatest(
    FILE_UPLOAD_STARTED,
    fetchSignFileUrl,
  );
}

export function* watchUpdateFileUploadProgress(): Iterator<any> {
  yield takeLatest(
    FILE_UPDATE_UPLOAD_PROGRESS,
    updateFileUploadProgress,
  );
}
