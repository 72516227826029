// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  START_DATE_SETTED_TYPE,
  END_DATE_SETTED_TYPE,
  SELECTED_CYCLE_SETTED_TYPE,
  REPORT_TYPE,
  REPORTS_ADDED_TYPE,
  CLIENTS_COUNT_PER_MONTH_FETCH_STARTED_TYPE,
  CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED_TYPE,
  CLIENTS_COUNT_PER_MONTH_FETCH_FAILED_TYPE,
  CLIENTS_COUNT_PER_STATE_FETCH_STARTED_TYPE,
  CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED_TYPE,
  CLIENTS_COUNT_PER_STATE_FETCH_FAILED_TYPE,
  CLIENTS_COUNT_PER_PHASE_FETCH_STARTED_TYPE,
  CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED_TYPE,
  CLIENTS_COUNT_PER_PHASE_FETCH_FAILED_TYPE,
  CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED_TYPE,
  CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED_TYPE,
  CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED_TYPE,
} from '../types/reports';
import * as types from '../types/reports';

export const setStartDate = (startDate: Date): START_DATE_SETTED_TYPE => ({
  type: types.START_DATE_SETTED,
  payload: startDate,
});

export const setEndDate = (endDate: Date): END_DATE_SETTED_TYPE => ({
  type: types.END_DATE_SETTED,
  payload: endDate,
});

export const setSelectedCycle = (selectedCycle: string): SELECTED_CYCLE_SETTED_TYPE => ({
  type: types.SELECTED_CYCLE_SETTED,
  payload: selectedCycle,
});

export const addReports = (reports: { [ID_TYPE]: REPORT_TYPE }): REPORTS_ADDED_TYPE => ({
  type: types.REPORTS_ADDED,
  payload: {
    entities: reports,
  },
});

export const startFetchingClientsCountPerState = (
  id: ID_TYPE,
): CLIENTS_COUNT_PER_STATE_FETCH_STARTED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_STATE_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientsCountPerState = (
  results: Array<ID_TYPE>,
  reports: { [ID_TYPE]: REPORT_TYPE },
): CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED,
  payload: {
    entities: reports,
    order: results,
  },
});

export const failFetchingClientsCountPerState = (
  error: ERROR_TYPE,
): CLIENTS_COUNT_PER_STATE_FETCH_FAILED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_STATE_FETCH_FAILED,
  payload: error,
});

export const startFetchingClientsCountPerMonth = (
  id: ID_TYPE,
): CLIENTS_COUNT_PER_MONTH_FETCH_STARTED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_MONTH_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientsCountPerMonth = (
  results: Array<ID_TYPE>,
  reports: { [ID_TYPE]: REPORT_TYPE },
): CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED,
  payload: {
    entities: reports,
    order: results,
  },
});

export const failFetchingClientsCountPerMonth = (
  error: ERROR_TYPE,
): CLIENTS_COUNT_PER_MONTH_FETCH_FAILED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_MONTH_FETCH_FAILED,
  payload: error,
});


export const startFetchingClientsCountPerPhase = (
  id: ID_TYPE,
): CLIENTS_COUNT_PER_PHASE_FETCH_STARTED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_PHASE_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientsCountPerPhase = (
  results: Array<ID_TYPE>,
  reports: { [ID_TYPE]: REPORT_TYPE },
): CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED,
  payload: {
    entities: reports,
    order: results,
  },
});

export const failFetchingClientsCountPerPhase = (
  error: ERROR_TYPE,
): CLIENTS_COUNT_PER_PHASE_FETCH_FAILED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_PHASE_FETCH_FAILED,
  payload: error,
});


export const startFetchingClientsCountPerLevel = (
  id: ID_TYPE,
): CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientsCountPerLevel = (
  results: Array<ID_TYPE>,
  reports: { [ID_TYPE]: REPORT_TYPE },
): CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED,
  payload: {
    entities: reports,
    order: results,
  },
});

export const failFetchingClientsCountPerLevel = (
  error: ERROR_TYPE,
): CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED_TYPE => ({
  type: types.CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED,
  payload: error,
});
