// @flow
import { connect } from 'react-redux';

import * as selectors from '../../../reducers';
import User from '../../User';
import placeholder from '../../../../assets/images/user_ph.png';


export default connect(
  (state) => {
    const {
      first_name,
      last_name,
      profile_picture,
      email,
    } = selectors.getCurrentLoggedUser(state) || {};

    return {
      pictureURL: profile_picture ? profile_picture.url : placeholder,
      firstName: first_name,
      lastName: last_name,
      userRole: email,
      size: 'small',
      theme: 'settingsPopover',
    };
  },
)(User);
