// @flow
type GenSelectorsType = {
  selectors: {},
  stateKey: string,
  excluded?: Array<string>
};

export const arrayReplace = (
  list: Array<mixed>,
  element: any,
  newElement: any,
): Array<mixed> => list.map(
  (e) => {
    if (e === element) {
      return newElement;
    }
    return e;
  },
);


// This is limited to extract a range of 1 - 2 Initials from a single string
// For example: 'Solicitud de beca' -> 'SB'
export const getNameInitials = (name: string) => {
  const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  const initials = [...name.matchAll(regex)];
  let finalInitials = '';

  const firstElementArr = initials.shift();
  const lastElementArr = initials.pop();

  if (firstElementArr && firstElementArr.length > 0) finalInitials += firstElementArr[1];
  if (lastElementArr && lastElementArr.length > 0) finalInitials += lastElementArr[1];

  return finalInitials.toUpperCase();
};

export const genSelector = (
  selector: Function,
  stateKey: string,
) => (state: Object, ...args: Array<any>) => selector(state[stateKey], ...args);

export const genSelectors = ({ selectors, stateKey, excluded = [] }: GenSelectorsType) => {
  const wSelectors = {};
  Object.keys(selectors).filter(
    selectorName => selectorName !== 'default' && !excluded.includes(selectorName),
  ).forEach(
    (selectorName) => {
      wSelectors[selectorName] = (
        state,
        ...args
      ) => selectors[selectorName](state[stateKey], ...args);
    },
  );

  return wSelectors;
};

export const onEnter = (fn: Function, nfn: Function = _ => _): Function => (e: any) => {
  if (e.key === 'Enter') {
    return fn(e);
  }

  return nfn(e);
};

export const validateUserBoard = (
  boardUUID: ID_TYPE,
  userBoards: any[],
  history: Object,
  getNewRoute: (boardId: ID_TYPE) => string,
) => {
  if (userBoards.length) {
    const matchBoards = userBoards
      .filter(board => board.short_uuid === boardUUID);
  
    if (matchBoards.length === 0) {
      if (history && userBoards[0].short_uuid) {
        history.push(getNewRoute(userBoards[0].short_uuid));
        setTimeout(() => window.location.reload(), 1);
      }
    } 
  }
}

export const getFileExtension = (file: string) => {
  const splitted = file.split('.');

  return splitted[splitted.length - 1];
};

export const getFileName = (file: string) => {
  const splitted = file.split('.');

  return splitted.length <= 1 ? file : splitted.slice(0, splitted.length - 1).join('.');
};

export const normalize = (data: any[], id: any = 'id'): Object => {
  var ret = {};

  data.forEach((item) => {
    if(item[id]) {
      ret[item[id]] = item; 
    }
  });

  return ret;
};

export const capitalizeFirstLetter = (
  [first, ...rest],
  locale = navigator.language,
) => [first.toLocaleUpperCase(locale), ...rest].join('');

export const onlyNumbers = (toCheck: string) => toCheck 
    ? /^[0-9]+$/.test(toCheck)
    : false;

export const isEmptyStringOrArray = value =>
  typeof value === 'string' && !value.trim() || (Array.isArray(value) && !value.length);