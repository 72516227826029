// @flow
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import MenuItemsPhase from '../MenuItemsSettingsPhase';
import PhaseSettingContent from './PhasesSettingsContent';

import styles from './phaseSettingsPopupContent.module.scss';


type PhaseSettingsContentPropTypes = { };

const PhaseSettingsContent = ({
}: PhaseSettingsContentPropTypes) => (
  <Row>
    <Col xs={3} className={styles.aside}>
      <MenuItemsPhase />
    </Col>
    <Col xs={9}>
      <PhaseSettingContent />
    </Col>
  </Row>
);

export default PhaseSettingsContent;

