// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as selectors from '../../reducers';
import * as actions from '../../actions/calendarViewFilter';
import * as datesActions from '../../actions/dates';
import InfiniteCalendar from '../InfiniteCalendar';

type CalendarViewCalendarFilterPropTypes = {
  onRangeChanged: Function,
  startDate: Date,
  endDate: Date,
  onLoad: Function,
  relevantDates: Array<String>,
};

type CalendarViewCalendarFilterStateTypes = {
  focusedInput: string;
};

class CalendarViewCalendarFilter extends React.Component<
  CalendarViewCalendarFilterPropTypes,
  CalendarViewCalendarFilterStateTypes
> {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: 'startDate',
    };
  }

  componentWillMount() {
    const { onLoad } = this.props;

    if (onLoad) onLoad();
  }

  render() {
    const {
      onRangeChanged,
      startDate,
      endDate,
      relevantDates,
    } = this.props;
    const { focusedInput } = this.state;

    return (
      <InfiniteCalendar
        orientation="verticalScrollable"
        numberOfMonths={6}
        keepOpenOnDateSelect
        startDateOffset={0}
        endDateOffset={0}
        hideKeyboardShortcutsPanel
        noBorder
        startDate={moment(startDate)}
        endDate={moment(endDate)}
        onDatesChange={(dates: any) => {
          onRangeChanged(
            dates.startDate
              ? moment(dates.startDate)
                .startOf('day')
                .toDate()
              : null,
            dates.endDate
              ? moment(dates.endDate)
                .startOf('day')
                .toDate()
              : null,
          );
          if (focusedInput === 'endDate' || !dates.startDate) this.setState({ focusedInput: 'startDate' });
          if (focusedInput === 'startDate' || !dates.endDate) this.setState({ focusedInput: 'endDate' });
        }}
        focusedInput={focusedInput}
        onFocusChange={newFocusedInput => this.setState({ focusedInput: newFocusedInput })}
        isDayHighlighted={
          (day: any) => relevantDates.filter(relevant => moment(day).isSame(relevant)).length > 0
        }
        isDayBlocked={day => moment(day).isBefore(moment(new Date()).subtract(1, 'd'))}
      />
    );
  }
}


export default connect(
  state => ({
    ...selectors.getCalendarViewFilters(state),
    relevantDates: selectors.getRelevantDates(state),
    boardId: (selectors.getCurrentBoard(state) || {}).id,
  }),
  dispatch => ({
    onRangeChanged(startDate, endDate) {
      dispatch(actions.updateCalendarViewFilter({ startDate, endDate }));
      dispatch(actions.startFetchCalendarViewFilter());
    },
    onLoad(boardId) {
      dispatch(datesActions.startFetchingRelevantDates(boardId));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardId);
    },
  }),
)(CalendarViewCalendarFilter);
