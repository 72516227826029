// @flow
import type { ComponentType } from 'react';
import React from 'react';
import { Icon as PaperIcon } from '@nahualventure/paper-ui'

import { OMNISEARCH_DISPLAY_AS_OPTIONS } from '../../settings';
import clientPlaceHolder from '../../../assets/images/user_ph_omnisearch.png';

import styles from './omnisearchResultItem.module.scss';


type OmnisearchResultItemPropTypes = {
  title: string,
  value: string | number;
  subtitle?: string,
  imageURL?: string,
  Icon?: ComponentType<*>,
  isSelected?: boolean,
  size?: string,
  onClick?: Function
};

const OmnisearchResultItem = ({
  title = '',
  subtitle,
  imageURL,
  displayAs,
  displayText,
  // Props not used yet
  Icon,
  onClick,
  isSelected,
  size = 'medium',
  // value,
}: OmnisearchResultItemPropTypes) => (
  <div
    className={
      `
        ${styles.result}
        ${styles[size]}
        ${isSelected ? styles.selected : ''}
      `
    }
    onClick={onClick}
    onKeyPress={onClick}
    role="presentation"
  >
    {
      typeof Icon !== 'undefined' && (
        <div className={styles.iconContainer}>
          <Icon />
        </div>
      )
    }
    {
      typeof Icon === 'undefined' && displayAs === OMNISEARCH_DISPLAY_AS_OPTIONS.CONTACT && (
        <div className={styles.iconContainer}>
          <PaperIcon
            iconSize='sm'
            iconName='envelope'
          />
        </div>
      )
    }
    { 
      displayAs === OMNISEARCH_DISPLAY_AS_OPTIONS.CLIENT && (
        <img
          className={`
            ${!imageURL ? styles.placeholder : ''}
          `}
          src={imageURL || clientPlaceHolder}
          alt={`${subtitle} ${title}`}
        /> 
      )
    }
    <div className={styles.data}>
      <div className={styles.title}>
        {displayText}
      </div>
      {
        typeof subtitle !== 'undefined' && (
          <div className={styles.subtitle}>
            { subtitle }
          </div>
        )
      }
    </div>
  </div>
);


export default OmnisearchResultItem;
