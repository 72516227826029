// @flow
import React from 'react';
import chroma from 'chroma-js';
import ReactSelect from 'react-select';

import type { ID_TYPE } from '../../types/common';
// import Input from '../Input';

import styles from './select.module.scss';


type OPTION_TYPE = {
  value: ID_TYPE
};

type SelectPropTypes = {
  size?: string,
  value?: ID_TYPE,
  options: Array<OPTION_TYPE>,
  onChange?: OPTION_TYPE => void,
  tint?: string,
};

type SelectStateTypes = {
  optionValue?: OPTION_TYPE
};

const getOptionValue = (value?: ID_TYPE, options: Array<OPTION_TYPE>) => {
  const result = options.filter(option => option.value === value);
  return result.length > 0 ? result[0] : undefined;
};

class Select extends React.Component<SelectPropTypes, SelectStateTypes> {
  constructor(props: SelectPropTypes) {
    super(props);

    this.state = {
      optionValue: getOptionValue(props.value, props.options),
    };
  }

  componentWillReceiveProps(nextProps: SelectPropTypes) {
    const { value } = this.props;

    if (value !== nextProps.value) {
      this.setState({
        optionValue: getOptionValue(nextProps.value, nextProps.options),
      });
    }
  }

  render() {
    const {
      size = 'medium',
      onChange,
      tint,
      ...props
    } = this.props;

    const { optionValue } = this.state;
    const customStyles = {
      control: (base, state) => ({
        // none of react-select's styles are passed to <Control />
        ...base,
        border: (state.isFocused || state.isSelected) ? `1px solid ${tint && tint !== '' ? tint : '#37968d'} !important` : '1px solid #dce3e4',
        '&:hover': {
          border: `1px solid ${tint && tint !== '' ? tint : '#37968d'}`,
        },
      }),
      option: (base, { isFocused, isSelected }) => {
        const focusColor = chroma(tint && tint !== '' ? tint : '#37968d').alpha(0.1).css();
        return {
          ...base,
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isSelected ? (tint && tint !== '' ? tint : '#37968d') : isFocused ? focusColor : undefined,
        };
      },
    };

    return (
      <ReactSelect
        {...props}
        className={
          `
            ${styles.select}
            ${styles[size] ? styles[size] : ''}
            ${props.className}
          `
        }
        classNamePrefix="react-select"
        value={optionValue}
        onChange={
          option => onChange && onChange(option.value)
        }
        blurInputOnSelect={false}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: tint ? chroma.mix(tint, 'rgba(255, 255, 255, 0.16)', 0.25, 'rgb') : theme.colors.primary25,
            primary: tint || theme.colors.primary,
          },
        })}
      />
    );
  }
}


export default Select;
