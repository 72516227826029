
import * as EmailValidator from 'email-validator';


export const validate = (values) => {
  const errors = {};
  if (!values.old_password) { errors.old_password = 'Requerido'; }
  if (values.new_email && !EmailValidator.validate(values.new_email)) {
    errors.new_email = 'Formato incorrecto';
  }

  if (values.new_password && !values.confirm_new_password) {
    errors.confirm_new_password = 'Requerido';
  }

  if (!values.new_password && values.confirm_new_password) {
    errors.new_password = 'Requerido';
  }

  if (
    values.new_password
    && values.confirm_new_password
    && values.new_password != values.confirm_new_password
  ) {
    errors.confirm_new_password = 'Valor no coincide';
  }

  return errors;
};
