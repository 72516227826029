// @flow
import uuid from 'uuid';
import type { Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';

import styles from './phasePlaceholder.module.scss';


type PhasePlaceholderPropTypes = {
  children: Node,
  hide?: boolean,
  onClick?: Function
};

const PhasePlaceholder = ({
  onClick,
  hide = false,
  children,
}: PhasePlaceholderPropTypes) => (
  hide
    ? null
    : (
      <div
        className={styles.phasePlaceholder}
        onClick={onClick}
        onKeyPress={onClick}
        role="presentation"
      >
        { children }
      </div>
    )
);


export default connect(
  state => ({
    hide: selectors.isFetchingPhases(state),
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    next: selectors.getPhases(state).length + 1,
  }),
  dispatch => ({
    onClick(boardId: number, next: number) {
      dispatch(actions.startAddingPhase({
        id: uuid(),
        ordinal: next,
        name: `Nueva fase ${next}`,
        board: boardId,
        clients: [],
        next_relevant_date: new Date(),
      }));
    },
  }),
  ({ boardId, next, ...stateProps }, { onClick, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onClick() {
      onClick(boardId, next);
    },
  }),
)(PhasePlaceholder);
