// @flow
import React from 'react';
import { connect } from 'react-redux';

import SidebarListItem from '../SidebarListItem';
import SidebarUserSettings from '../SidebarUserSettings';
import placeholder from '../../../../assets/images/user_ph.png';

import * as selectors from '../../../reducers';
import * as closeableActions from '../../../actions/closeable';

import styles from './sidebarUser.module.scss';

const SETTINGS_POPOVER_ID = "settingsPopover";

type SidebarUserPropTypes = {
  pictureURL?: string,
  firstName: string,
  lastName: string,
  userRole?: string,
  isOpen: boolean,
  onNameClick: Function,
};

const SidebarUser = ({
  pictureURL,
  firstName = '',
  lastName = '',
  onNameClick,
  isOpen = false,
}: SidebarUserPropTypes) => (
  <div
    className={
      `
        ${styles.userContainer}
        ${isOpen ? styles.open : ''}          
      `
    }
  >
    <SidebarListItem
      text={`${firstName} ${lastName}`}
      thumbnail={pictureURL}
      isOpen={isOpen}
      onClick={() => onNameClick()}
      theme="userInfo"
      ActionButton={SidebarUserSettings}
    />
  </div>
);

export default connect(
  (state) => {
    const {
      first_name,
      last_name,
      profile_picture,
      email,
    } = selectors.getCurrentLoggedUser(state) || {};

    return {
      pictureURL: profile_picture ? profile_picture.url : placeholder,
      firstName: first_name,
      lastName: last_name,
      userRole: email,
      isOpen: selectors.isMainSidebarOpen(state),
    };
  },
  dispatch => ({
    onNameClick() {
      dispatch(closeableActions.open(SETTINGS_POPOVER_ID));
    },
  }),
)(SidebarUser);
