// @flow
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { isEqual } from 'lodash';

import ChartContainer from '../ChartContainer';
import * as actions from '../../actions/reports';
import * as selectors from '../../reducers';

const chartState = {
  type: 'bar',
  options: {
    title: { text: 'Por grados' },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },
  },
  series: [],
};

type ClientCountPerLevelChartProps = {
  height?: number | string,
  width?: number | string,
  isLoading: boolean,
  dataSet: any,
  onLoad: Function,
}

const ClientCountPerLevelChart: FC = (props: ClientCountPerLevelChartProps) => {
  const {
    width,
    height,
    isLoading,
    dataSet,
    onLoad,
  } = props;
  const [data, setData] = useState(chartState);
  const chartSeries = {};

  useEffect(() => {
    if (onLoad) onLoad();
  }, []);

  const uniqLabels = new Set(dataSet.map(value => value.level));
  const labels = [...uniqLabels];

  dataSet.forEach((value) => {
    const { phase, count } = value;
    if (phase in chartSeries) chartSeries[phase].push(count);
    else chartSeries[phase] = [count];
  });

  const series = [];
  Object.keys(chartSeries).forEach((key) => {
    series.push({
      name: key,
      data: chartSeries[key],
    });
  });

  if (!isEqual(data.options.xaxis.categories, labels)) {
    setData({
      ...data,
      options: {
        ...data.options,
        xaxis: {
          ...data.options.xaxis,
          categories: [...labels],
        },
      },
    });
  }

  if (!isEqual(data.series, series)) {
    setData({
      ...data,
      series: [...series],
    });
  }

  return (
    <ChartContainer isLoading={isLoading}>
      <Chart
        type={data.type}
        options={data.options}
        series={data.series}
        height={height}
        width={width}
      />
    </ChartContainer>
  );
};

export default connect(
  state => ({
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    isLoading: selectors.getIsFetchingClientCountByLevel(state),
    dataSet: selectors.getReporstByType(state, 'countPerLevel'),
  }),
  dispatch => ({
    onLoad(boardId) {
      dispatch(actions.startFetchingClientsCountPerLevel(boardId));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardId);
    },
  }),
)(ClientCountPerLevelChart);
