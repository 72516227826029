// @flow
import { fork, all } from 'redux-saga/effects';
import { sagas as jwtSagas } from 'nozzmo-redux-jwt';

import * as api from '../api';
import {
  watchRedirectAfterLogin,
  watchLogout,
} from './login';


import {
  watchNotificationsFetch,
  watchReadNotification,
  watchNotificationsCount,
  watchWatchAllNotifications,
} from './notifications';
// import {
//   watchJoinChannelRequired,
// } from './socket';
import {
  watchTypeaheadFilterChange,
} from './typeahead';
import {
  watchPhasesFetch,
  watchPhaseCreation,
  watchPhaseDeletion,
  watchPhaseClientsFetching,
  watchPhaseUpdate,
  watchPhasesSort,
  watchFetchPhase,
} from './phases';
import {
  watchCyclesFetch,
} from './cycles';
import {
  watchFetchSignFileUrl,
  watchUpdateFileUploadProgress,
} from './files';
import {
  watchLevelsFetch,
} from './levels';
import {
  watchRelationshipsFetch,
} from './relationships';
import {
  watchClientFormFetch,
} from './clientForm';
import {
  watchClientDeletion,
  watchClientsPhaseChange,
  watchClientCreation,
  watchClientFetch,
  watchClientContactsFetch,
  watchClientUpdate,
  watchClientToggleStatus,
  watchClientCommentsFetch,
  watchClientSetProfilePicture,
  watchSetFileAsProfilePicture,
  watchClientRemoveProfilePicture,
} from './clients';
import {
  watchUploadingClientsUpload,
} from './uploadingClients';
import {
  watchCommentCreation,
  watchCommentDeletion,
} from './comments';
import {
  watchStartOfCalendarViewFilterClientFetch,
} from './calendarViewFilter';
import {
  watchSchoolFetchStart,
} from './schools';
import {
  watchBoardsFetch,
  watchBoardFetchStart,
  watchBoardUpdate,
} from './boards';
import {
  watchRelevantDatesFetching,
} from './dates';
import {
  watchClientsCountPerMonthFetchStart,
  watchClientsCountPerSateFetchStart,
  watchClientsCountPerPhaseFetchStart,
  watchClientsCountPerLevelFetchStart,
} from './reports';
import {
  watchFetchCurrentLoggedUser,
  watchUpdateCurrentLoggedUser,
  watchRestoreSecuritySettings,
  watchFetchUsers,
  watchChangeUserRole,
  watchChangeUserStatus,
  watchChangeExtraData,
  watchSendUserInvitation,
  watchRequestRecoverPassword,
  watchRecoverPassword,
} from './users';

const extractToken = ({ token }) => token;
const getDecodedPayload = (decode, { token, user }) => ({
  ...decode(token),
  ...user,
});

const watchLogin = jwtSagas.genLoginSaga(
  api.login,
  extractToken,
  getDecodedPayload,
);

function* mainSaga(): Iterator<any> {
  yield all([
    fork(watchLogin),
    fork(watchRedirectAfterLogin),
    fork(watchLogout),
    fork(watchNotificationsFetch),
    fork(watchReadNotification),
    fork(watchNotificationsCount),
    fork(watchWatchAllNotifications),
    // fork(watchJoinChannelRequired),
    fork(watchTypeaheadFilterChange),
    fork(watchPhasesFetch),
    fork(watchPhaseCreation),
    fork(watchPhaseDeletion),
    fork(watchCyclesFetch),
    fork(watchLevelsFetch),
    fork(watchRelationshipsFetch),
    fork(watchClientFormFetch),
    fork(watchPhaseClientsFetching),
    fork(watchClientDeletion),
    fork(watchClientsPhaseChange),
    fork(watchClientCreation),
    fork(watchPhaseUpdate),
    fork(watchPhasesSort),
    fork(watchFetchPhase),
    fork(watchClientFetch),
    fork(watchClientContactsFetch),
    fork(watchClientUpdate),
    fork(watchClientToggleStatus),
    fork(watchUploadingClientsUpload),
    fork(watchClientCommentsFetch),
    fork(watchCommentCreation),
    fork(watchCommentDeletion),
    fork(watchStartOfCalendarViewFilterClientFetch),
    fork(watchSchoolFetchStart),
    fork(watchBoardsFetch),
    fork(watchBoardFetchStart),
    fork(watchBoardUpdate),
    fork(watchRelevantDatesFetching),
    fork(watchClientsCountPerSateFetchStart),
    fork(watchClientsCountPerPhaseFetchStart),
    fork(watchClientsCountPerLevelFetchStart),
    fork(watchClientsCountPerMonthFetchStart),
    fork(watchFetchSignFileUrl),
    fork(watchUpdateFileUploadProgress),
    fork(watchUpdateCurrentLoggedUser),
    fork(watchFetchCurrentLoggedUser),
    fork(watchRestoreSecuritySettings),
    fork(watchFetchUsers),
    fork(watchChangeUserRole),
    fork(watchChangeUserStatus),
    fork(watchChangeExtraData),
    fork(watchSendUserInvitation),
    fork(watchRequestRecoverPassword),
    fork(watchRecoverPassword),
    fork(watchClientSetProfilePicture),
    fork(watchSetFileAsProfilePicture),
    fork(watchClientRemoveProfilePicture),
  ]);
}

export default mainSaga;
