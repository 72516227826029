// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdDone, MdError } from 'react-icons/md';
import isEmptyObject from 'is-empty-object';
import { CSSTransition } from 'react-transition-group';

import styles from './loginMessage.module.scss';
import { USER_LOGIN_EVENTS } from '../../settings';

import * as selectors from '../../reducers';
import * as actions from '../../actions/users';


type LoginMessagePropTypes = {
  tint: 'error' | 'success',
  message: string,
  isShow: boolean,
  onUnmount?: Function,
};

const MD_ICONS = {
  error: MdError,
  success: MdDone,
};


export const LoginMessage = ({
  tint = 'success',
  message,
  isShow = false,
  onUnmount,
}: LoginMessagePropTypes) => {
  useEffect(() => {
    return () => {
      if (onUnmount) onUnmount();
    }
  }, []);
  const Icon = MD_ICONS[tint];

  return (
    <div>
      <CSSTransition
        in={isShow}
        classNames={{
          enter: styles["enter"],
          exit: styles['exit'],
          enterActive: styles["enter-active"],
          exitActive: styles["exit-active"],
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={
          `
            ${styles.container}
            ${styles[tint]}
          `
          }>
          <div className={styles.iconContainer}>
            <Icon size={24}/> 
          </div>
          <div className={styles.content}>
            <span className={styles.text}>
              {message}
            </span>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
};

export default connect(
  state => {
    const error = selectors.getLoginError(state) || {};
    const userLoginEvent = selectors.getUserLoginEvent(state);

    if (userLoginEvent != null) {

      let message = "";
      switch (userLoginEvent) {
        case USER_LOGIN_EVENTS.REQUEST_RECOVER_PASSWORD_COMPLETED:
          message = "Hemos enviado instrucciones a tu correo, por favor revísalo.";
          break;
        case USER_LOGIN_EVENTS.RECOVER_PASSWORD_COMPLETED:
          message = "Has cambiado tu contraseña correctamente."
        default:
          break;
      };

      return ({
        message,
        isShow: true,
        tint: 'success',
      })
    }

    if (!isEmptyObject(error)) {
      const { data = {} } = error;
      
      return ({
        isShow: true,
        tint: 'error',
        message: data.message ? data.message : 'Algo salió mal'
      })
    };

    return {}
  },
  dispatch => ({
    onUnmount() {
      dispatch(actions.clearUserLoginEvent());
    }
  })
)(LoginMessage)
