// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './settingsItem.module.scss';


type SettingsItemPropTypes = {
  Icon?: ComponentType<*>,
  text: string,
  onClick?: Function,
  selectable?: boolean,
  thumbnail?: string,
  isSelected?: boolean,
  theme?: string,
};

const SettingsItem = ({
  Icon,
  text,
  onClick,
  selectable = true,
  thumbnail,
  isSelected = false,
  theme,
}: SettingsItemPropTypes) => (
  <div
    className={
      `
        ${styles.item}
        ${selectable ? styles.selectable : ''}
        ${isSelected ? styles.isSelected : ''}
        ${theme ? styles[theme] : ''}
      `
    }
    onClick={onClick ? () => onClick() : null}>
    {
      typeof Icon !== 'undefined' && (
        <span className={styles.itemIcon}>
          <Icon size={22} />
        </span>
      )
    }
    {
      thumbnail != null && (
        <div className={
          `
            ${styles.thumbail}
            ${isSelected ? styles.isSelected : ''}
          `
        }>
          <img src={thumbnail} alt="" />
        </div>
      )
    }
    <div>{text}</div>
  </div>
);

export default SettingsItem;
