// @flow
import React from 'react';

import styles from './bigPlaceholder.module.scss';


type BigPlaceholderPropTypes = {
  pictureURL?: string,
  composition?: 'vertical' | 'horizontal',
  title: string,
  description: string,
  examples?: string
};

const BigPlaceholder = ({
  pictureURL,
  composition = 'horizontal',
  title,
  description,
  examples,
}: BigPlaceholderPropTypes) => (
  <div
    className={
      `
        ${styles.bigPlaceholder}
        ${typeof styles[composition] !== 'undefined' ? styles[composition] : ''}
      `
    }
  >
    {
      pictureURL
        && (
        <div className={styles.pictureContainer}>
          <img src={pictureURL} alt={title} />
        </div>
        )
    }
    <div className={styles.data}>
      <div className={styles.title}>
        { title }
      </div>
      <div className={styles.description}>
        { description }
      </div>
      {
        examples
          && (
            <div className={styles.examples}>
              { 'Por ejemplo: ' }
              <strong>
                { examples }
              </strong>
            </div>
          )
      }
    </div>
  </div>
);


export default BigPlaceholder;
