// @flow
import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';

import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reduxReset from 'redux-reset';
import { types as jwtTypes } from 'nozzmo-redux-jwt';

import {
  persistStore,
  persistReducer,
  /* FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, */
} from 'redux-persist';

// import createPhoenixSocketMiddleware from '../lib/socketMiddleware';
import mainSaga from '../sagas';
import reducer from '../reducers';
import persistorConfiguration from './persistorConfiguration';


const sagaMiddleware = createSagaMiddleware();
// const socketMiddleware = createPhoenixSocketMiddleware();

const persistedReducer = persistReducer(persistorConfiguration, reducer);

const configureStore = (history: Object): { store: Object, persistor: Function } => {
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    // socketMiddleware,
  ];

  /*
    Good Old Redux Days...

    let composeEnhancers = compose;
    if (process.env.NODE_ENV === 'development') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }
  */

  const preloadedState = {};
  const store = configureStoreRTK({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      // We store non-serializable data in state (i.e. next_relevant_date, that is a Date())
      //  For now, i'll add this config as exception of the fundamentals of Redux Toolkit
      //   Sorry mom for this crazy life, jk 
      serializableCheck: false,
      // Also, this must be disabled (for development, default value for production is `false`)
      immutableCheck: false,
      /* 
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      */
    }).concat(...middlewares),
    reducer: persistedReducer,
    preloadedState,
    enhancers: [reduxReset(jwtTypes.LOGOUT)],
  });

  /*
    Good Old Redux Days...

    const store = composeEnhancers(
    applyMiddleware(...middlewares),
    reduxReset(jwtTypes.LOGOUT),
  )(createStore)(persistedReducer);
  */

  sagaMiddleware.run(mainSaga);
  const persistor = persistStore(store, { debounce: 500 });

  return { store, persistor };
};

export default configureStore;