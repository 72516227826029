// @flow
import React from 'react';

import image from './edoo.png';
import styles from './loginPoweredByEdoo.module.scss';


const LoginPoweredByEdoo = () => (
  <div className={styles.loginPoweredByEdoo}>
    <span className={styles.poweredBy}>
      { 'Powered by' }
    </span>
    <a href="http://edoo.io" target="_blank" rel="noopener noreferrer">
      <img src={image} alt="edoo" />
    </a>
  </div>
);


export default LoginPoweredByEdoo;
