// @flow
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { scroller } from 'react-scroll';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as closeableActions from '../../actions/closeable';
import * as fobiServices from '../FobiRenderer/services';

import { generateClientValidator } from './services';

const flatten = arr => arr.reduce(
  (flattenedArr, toFlatten) => flattenedArr
    .concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), [],
);

/* const flatten = arr => arr.reduce((flattenedArr, toFlatten) => {
  const newConcat = Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten;
  if (newConcat.map) return [...newConcat, ...flattenedArr];
  return [...flattenedArr, newConcat];
}, []); */

const getErrorFieldNames = (obj, name = '') => {
  const errorNamesArr = [];

  errorNamesArr.push(Object.keys(obj).map((key) => {
    const next = obj[key];

    if (next) {
      // If next is already a name return
      if (typeof next === 'string') return name + key;
      // If next is still a array of objects continue extracting names recursively
      if (next.map) errorNamesArr.push(next.map((item, index) => getErrorFieldNames(item, `${name}${key}[${index}].`)).filter(exists => exists));
    }

    return null;
    // Filter only non-nulleable values
  }).filter(exists => exists));

  // Spread all posible arrays into a single flat one
  return flatten(errorNamesArr);
};

export const FORM_ID = 'clientForm';
/*
DEFAULT_RELATIONSHIPS not been used anymore...
export const DEFAULT_RELATIONSHIPS = [
  {
    value: 'Padre',
    label: 'Padre',
  },
  {
    value: 'Madre',
    label: 'Madre',
  },
];
*/

export type ClientFormPropTypes = {
  handleSubmit: Function,
  onMainChange?: Function,
  onLoad?: Function,
  clientId?: ID_TYPE,
  isLoading?: boolean,
  isSaving?: boolean,
  title: string,
  profilePicture: Object,
  actionTitle: string,
  onEditClicked: Function,
  onChangeProfilePicture: Function,
  onRemoveProfilePicture: Function,
  isEditing: boolean,
  boardId: ID_TYPE,
};

export type ClientFormStateTypes = {
  isEditing: boolean
};

export const clientReduxFormDecorator = reduxForm({
  form: FORM_ID,
  validate: (values, { validator }) => validator(values),
  onSubmitFail: (errors, dispatch) => {
    // Extract client errors
    const clientErrors = errors;
    // Extract only contact errors
    const { contacts } = clientErrors;
    delete clientErrors.contacts;

    const clientFields = getErrorFieldNames(clientErrors);
    const contactFields = getErrorFieldNames({ contacts });

    const errorFields = [...clientFields, ...contactFields];

    for (let i = 0; i < errorFields.length; i += 1) {
      const fieldName = `${errorFields[i]}`;
      // Access to the DOM element and call .focus() since redux-form has not a propper way
      const queryResult = document.querySelectorAll(`input[name="${fieldName}"]`);

      if (queryResult.length) {
        // scrollIntoView() not correctly supported yet
        /* document.querySelectorAll(`input[name="${fieldName}"]`)[0]
          .scrollIntoView({ block: 'end', behavior: 'smooth' }); */

        // Scroll to the first element with name=[fildName] and offset down
        // Note that the scrollTo() points to the container and not to the element
        // since some input elements are positioned outside the viewport
        queryResult[0].parentElement.setAttribute('name', fieldName);

        const hasScrollbar = document.documentElement.scrollHeight
          > document.documentElement.clientHeight;

        // if there is no scroll in the window, the overflow is in a div,
        // so scroll inside that container
        if (hasScrollbar) {
          scroller.scrollTo(fieldName, { smooth: true, offset: -300 });
        } else if (document.querySelectorAll('div[id="clientForm"]').length) {
          scroller.scrollTo(fieldName, { smooth: true, offset: -300, containerId: 'clientForm' });
        }

        break;
      }
    }
    // Get contacts main error: no contacts added
    if (contacts && contacts._error) {
      dispatch(closeableActions.open('VALIDATE_CONTACTS'));
    }
  },
});

export const clientFormDecorator = connect(
  (state) => {
    // Generate client school specific validator
    const clientFormDescription = selectors.getClientFormDescription(state);
    const canCreateClientWithoutContacts =
      selectors.getCurrentBoard(state).min_contacts_size === 0 ||
      selectors.getCurrentBoard(state).max_contacts_size === 0;
    const validateSchoolSpecificClientData = fobiServices.generateValidator(
      clientFormDescription || {
        form_elements: [],
      },
    );

    // Generate contact school specific validator
    const contactFormDescription = selectors.getContactFormDescription(state);
    const validateSchoolSpecificContactData = fobiServices.generateValidator(
      contactFormDescription,
    );

    const { client_required_fields } = selectors.getCurrentBoard(state) || {};
    const clientRequiredFields = client_required_fields ? [...client_required_fields] : [];

    return {
      validator: generateClientValidator(
        validateSchoolSpecificClientData,
        validateSchoolSpecificContactData,
        canCreateClientWithoutContacts,
        clientRequiredFields,
      ),
    };
  },
);
