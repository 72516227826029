// @flow
import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';

export const styles = {
  container: 'background: #fff; font-weight: 400; text-align: start; color: #555555;',
  p: 'margin: 0; color: #555555;',
  pre: 'padding: 10px; background-color: #eee; white-space: pre-wrap;',
  code: 'font-family: monospace; background-color: #eee; padding: 3px;',
  img: 'max-width: 100%;',
  blockquote: 'border-left: 2px solid #ddd; margin-left: 0; margin-right: 0; padding-left: 10px; color: #aaa; font-style: italic;',
  table: 'border-collapse: collapse;',
  td: 'padding: 10px; border: 2px solid #ddd;',
  h1: 'border-bottom: none; font-weight: 600; padding: 0; margin: 1rem 0; font-size: 2rem',
  h2: 'padding: 0; margin: 0; font-weight: 400; color: #226865;',
  tag: 'color: #226865; white-space: pre;',
  li: 'line-height: 1.2: color: #555555;',
  ul: 'line-height: 1.2; color: #555555;',
  ol: 'line-height: 1.2; color: #555555;',
  a: 'cursor: pointer; text-decoration: underline;',
};

const Leaf = (node: Object) => {
  let children = node.text;

  if (node.bold) {
    children = `<strong>${node.text}</strong>`
  }
  if (node.code) {
    children = `<code>${node.text}</code>`
  }
  if (node.italic) {
    children = `<em>${node.text}</em>`
  }
  if (node.underline) {
    children = `<u>${node.text}</u>`
  }
  if (node.tag) {
    children = `<span style='${styles.tag}'>${node.text} </span>`
  }
  return children
};

const Element = (node: Object, children: any) => {
  switch (node.type) {
    case 'block-quote':
      return `<blockquote style='${styles.blockquote}'>${children}</blockquote>`
    case 'bulleted-list':
      return `<ul style='${styles.ul}'>${children}</ul>`
    case 'heading-one':
      return `<h1 style='${styles.h1}'>${children}</h1>`
    case 'heading-two':
      return `<h2 style='${styles.h2}'>${children}</h2>`
    case 'list-item':
      return `<li style='${styles.li}'>${children}</li>`
    case 'numbered-list':
      return `<ol style='${styles.ol}'>${children}</ol>`
    case 'link':
      return `<a style='${styles.a}' href='${node.url}'>${children}</a>`
    case 'image':
      return `<img src=${node.url} style="${styles.img}" alt="img" />`
    default:
      if (!children.replace(/\s/g, "").length) {
        return `<p style='${styles.p}'><span><br></span></p>`
      } else {
        return `<p style='${styles.p}'>${children}</p>`
      }
  }
};

const NodeElement = (el: any, children: any) => {
  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children)
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children)
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'BR':
      return '\n'
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'STRONG':
      return { text: el.textContent, bold: true }
    case 'CODE':
      return { text: el.textContent, code: true }
    case 'EM':
      return { text: el.textContent, italic: true }
    case 'U':
      return { text: el.textContent, underline: true }
    case 'SPAN':
      return { text: el.textContent, tag: true }
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    case 'IMG':
      return jsx('element', { type: 'image', url: el.getAttribute('src') }, [''])
    default: {
      return el.textContent;
    }
  }
}



export const serialize = (node: Object) => {
  if (Text.isText(node)) {
    return Leaf(node);
  }
  const children = node.children.map(n => serialize(n)).join('');
  return Element(node, children);
};


export const deserialize = (el: any) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  }

  const children = Array.from(el.childNodes).map(deserialize)

  if (children.length > 0 || el.nodeName === 'IMG') {
    return NodeElement(el, children);
  }
}

export const INIT_EMPTY_SLATE_BODY = () => {
  // Default Email Content
  const body = "<p style='margin: 0; color: #555555;'><p style='margin: 0; color: #555555;'></p></p>";
  const document = new DOMParser().parseFromString(body, 'text/html');
  const deserializedBody = deserialize(document.body);
  return deserializedBody.filter(el => el.children && el.children.length > 0);
}
