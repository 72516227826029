// @flow
import {
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
// import { delay } from 'redux-saga';

import { fibonacciBackoff } from '../lib/retry';
// import { throwTimeout } from '../lib/http';
// import { REQUEST_TIMEOUT } from '../settings';
import * as types from '../types/relationships';
import * as actions from '../actions/relationships';

import { Relationships } from '../api';
import { arrayOfRelationships } from '../api/schemas/relationships';


function* fetchRelationships(action) {
  const { payload } = action;
  // const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          name: "Padre"
        },
        {
          id: 2,
          name: "Madre"
        },
        {
          id: 3,
          name: "Señor y salvador"
        },
        {
          id: 4,
          name: "Sensei"
        }
      ]
    }
  }

  try {
    const response = yield call(
      fibonacciBackoff,
      [ Relationships, 'list' ],
      { /*token,*/ filters: { board: payload }, mock }
    );

    const {
      entities: { relationships },
      result
    } = normalize(response, arrayOfRelationships);

    yield put(actions.completeFetchingRelationships(relationships, result))
  } catch(error) {
    const { statusCode, message, data, isPlain } = error;

    // Fetch error
    yield put(actions.failFetchingRelationships({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor': data,
      retryAction: action
    }));
  }
}

export function* watchRelationshipsFetch(): Iterator<any> {
  yield takeEvery(
    types.RELATIONSHIPS_FETCH_STARTED,
    fetchRelationships
  );
}
