// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdClose, MdArrowBack } from 'react-icons/md';
import cloneDeep from 'lodash/cloneDeep';

import * as closeableActions from '../../../actions/closeable';
import * as uploadingClientsActions from '../../../actions/uploadingClients';
import * as selectors from '../../../reducers';
import PopupHeader from '../../PopupHeader';
import PopupHeaderRightGroup from '../../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../../PopupHeaderLeftGroup';
import PopupCloseButton from '../../PopupCloseButton';
import PopupContent from '../../PopupContent';
import PopupFooter from '../../PopupFooter';
import Button from '../../Button';
import ErrorSummary from './ErrorSummary';
import image from './check.png';
import { FullLoader } from '../../Loader';

import styles from './fileUploaderConfirm.module.scss';


type FileUploaderConfirmPropTypes = {
  uploadingClientsToAdd: Array<Object>,
  uploadingClientsToUpdate: Array<Object>,
  wrongUploadingClients: Array<Object>,
  isUploading: boolean,
  onGoBack: Function,
  onCancel: Function,
  onConfirm: Function,
}

const FileUploaderConfirm = ({
  uploadingClientsToAdd = [],
  uploadingClientsToUpdate = [],
  wrongUploadingClients = [],
  isUploading = false,
  onGoBack,
  onCancel,
  onConfirm,
}: FileUploaderConfirmPropTypes) => (
  <Fragment>
    <PopupHeader clearPadding isAlert>
      <PopupHeaderLeftGroup>
        <Button
          tint="mediumGray"
          clearMargins
          isTransparent
          isOutlined
          iconPosition="right"
          Icon={MdArrowBack}
          className={styles.popupBackButton}
          onClick={onGoBack}
          disabled={isUploading}
        />
      </PopupHeaderLeftGroup>
      <PopupHeaderRightGroup>
        <PopupCloseButton
          tint="mediumGray"
          Icon={MdClose}
          clearMargins
          isTransparent
          isOutlined
          disabled={isUploading}
        />
      </PopupHeaderRightGroup>
    </PopupHeader>
    <PopupContent isAlert className={styles.container}>
      <div className={styles.title}>
        {'Estarás creando '}
        <strong>
          {`${uploadingClientsToAdd.length} estudiantes`}
        </strong>
        {' y actualizando '}
        <strong>
          {`${uploadingClientsToUpdate.length} estudiantes`}
        </strong>
      </div>
      <div className={styles.divider} />
      <Fragment>
        {
          isUploading && <FullLoader tint="main" title="Cargando estudiantes..." />
        }
        {
          !isUploading && (
            <Fragment>
              {
                wrongUploadingClients.length ? (
                  <ErrorSummary clients={wrongUploadingClients} />
                ) : (
                  <div className={styles.confirmUpload}>
                    <img src={image} alt="alt" />
                    <div className={styles.description}>
                      {'No se encontraron errores en el archivo, para crear los estudiantes haz click en '}
                      <strong>
                        {'cargar estudiantes'}
                      </strong>
                    </div>
                  </div>
                )
              }
            </Fragment>
          )
        }
      </Fragment>
      <div className={styles.divider} />
    </PopupContent>
    <PopupFooter isAlert isPadded align="center" className={styles.footerOptions}>
      <Button
        type="button"
        onClick={onCancel}
        title="Cancelar"
        tint="mediumGray"
        disabled={isUploading}
      />
      <Button
        type="file"
        onClick={onConfirm}
        title="cargar estudiantes"
        tint={!isUploading ? 'secondary' : 'mediumGray'}
      />
    </PopupFooter>
  </Fragment>
);

export default connect(
  state => ({
    uploadingClientsToAdd: selectors.getUploadingClientsToAdd(state),
    uploadingClientsToUpdate: selectors.getUploadingClientsToUpdate(state),
    wrongUploadingClients: selectors.getWrongUploadingClients(state),
    isUploading: selectors.isUploadingClients(state),
  }),
  dispatch => ({
    onGoBack() {
      dispatch(uploadingClientsActions.updateUploadingClientsStep(1));
      dispatch(uploadingClientsActions.clearUploadingClients());
    },
    onCancel() {
      dispatch(closeableActions.close('BulkUploadingClientsPopup'));
    },
    onConfirm(uploadingClients) {
      const clients = uploadingClients.map((client) => {
        const operatedClient = cloneDeep(client);
        if (operatedClient.isNew) delete operatedClient.id;
        return operatedClient;
      });
      dispatch(uploadingClientsActions.startUploadingClients(clients));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onCancel() {
      if (!stateProps.isUploading) dispatchProps.onCancel();
    },
    onConfirm() {
      if (
        (stateProps.uploadingClientsToAdd.length
        || stateProps.uploadingClientsToUpdate.length)
        && !stateProps.isUploading
      ) {
        dispatchProps.onConfirm([
          ...stateProps.uploadingClientsToAdd,
          ...stateProps.uploadingClientsToUpdate,
        ]);
      }
    },
  }),
)(FileUploaderConfirm);
