// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import Button from '../Button';
import Select from '../Select';
import { api } from '../../api';

import styles from './PhaseFileExport.module.scss';

import * as selectors from '../../reducers';
import {
    MdCloudDownload,
} from 'react-icons/md';

type PhaseFileExportPropTypes = {
    cycles: any[],
    boardId: Integer,
    phaseId: Integer
};

const PhaseFileExport = ({
    cycles = [],
    boardId,
    phaseId
}: PhaseFileExportPropTypes) => {

    const cyclesOptions = cycles.map(({ id, name }) => ({ value: id, label: name }));
    const [cycle, setCycle] = useState(0);
    let reportURL = ''
    useEffect(() => {
        if (cycle) {
            reportURL = api.getURL(`board-reports/${boardId}/student-phase-export`, { phase: phaseId, board: boardId, cycle: cycle })
            let studentPhaseExport = document.getElementById('studentPhaseExport')
            studentPhaseExport.href = reportURL
            studentPhaseExport.target = "_blank"
            studentPhaseExport.style.cursor = "pointer"
        }
    });
    return (
        <div className={styles.exportDiv}>
            <div className={styles.title}>
                <span>
                    {i18n.t('export')}
                </span>
            </div>
            <div className={styles.headerExportDiv}>
                <div className={styles.subtitle}>
                    <span>
                        <strong>
                            {i18n.t("export leads in CSV for bulk upload to Edoo LMS")}
                        </strong>
                    </span>
                    <span>{i18n.t("this will allow you to export a CSV file in the Edoo format for uploading student leads")}</span>
                </div>
                <div className={styles.input}>
                    <label>{i18n.t('cycle')}</label>
                    <Select
                        name='cycle'
                        options={cyclesOptions}
                        onChange={(cycleSelected) => { setCycle(cycleSelected) }}
                    />
                </div>
            </div>
            <Button
                id="studentPhaseExport"
                Icon={MdCloudDownload}
                title={i18n.t('download')}
                tint="secondary"
                size="medium"
                className={styles.exportButton}
                clearMargins
                useAnchor
            />
        </div>
    );
};

export default connect(
    state => {
        const cycles = selectors.getCycles(state);
        const { id } = selectors.getPhaseSelected(state);
        const board = selectors.getCurrentBoard(state);
        const boardId = board.id;
        const phaseId = id
        
        if (phaseId && cycles) {
            return {
                cycles,
                boardId,
                phaseId
            };
        }
    }
)(PhaseFileExport);
