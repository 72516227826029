// @flow
import React from 'react';
import {
  MdClose,
} from 'react-icons/md';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import PopupContainer from '../PopupContainer';
import Popup from '../Popup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import ClientEditionForm, { HiddenPDFEpilogue, HiddenPDFSignatureFooter } from '../ClientForm/ClientEditionForm';
import ClientComments from '../ClientComments';

import styles from './clientEditionPopup.module.scss';
import Loader from '../Loader';


type ClientEditionPopupPropTypes = {
  clientId: ID_TYPE,
  clientName?: string,
  profilePicture: Object,
  boardId: ID_TYPE,
  omnisearchClientId: any,
};

export const CLIENT_EDITION_POPUP_ID = 'clientEditionPopup';

const ClientEditionPopup = ({
  clientId,
  clientName = '',
  profilePicture,
  boardId,
  omnisearchClientId,
}: ClientEditionPopupPropTypes) => {

  const onClose = () => {
    if (omnisearchClientId) {
      // -> Clear this.props.location.state.omnisearchValue from history & reload
      window.history.replaceState({}, document.title);
      window.location.reload();
    }
  };

  return (
    <Popup id={CLIENT_EDITION_POPUP_ID} transitionMs={600}>
      <PopupContainer
        tint="lightGray"
        className={styles.popupContainer}
        isFullscreen
      >
        <PopupCloseButton
          tint="mediumGray"
          Icon={MdClose}
          clearMargins
          isTransparent
          isOutlined
          onClick={onClose}
        />
        {
          clientId !== -1
            ? (
              <PopupContent className={styles.popupContent} clearPaddings>
                <ClientEditionForm
                  clientId={clientId}
                  title={clientName}
                  profilePicture={profilePicture}
                  actionTitle="Guardar datos"
                />
                <ClientComments clientId={clientId} />
                <HiddenPDFEpilogue boardId={boardId} className={styles.pdfEpilogue} />
                <HiddenPDFSignatureFooter boardId={boardId} />
              </PopupContent>
            ) : (
              <PopupContent className={styles.popupContent} clearPaddings>
                <div className={styles.loaderContainer}>
                  <Loader size="x-large" tint="main" />
                </div>
              </PopupContent>
            )
        }
      </PopupContainer>
    </Popup>
  )
};


export default connect(
  (state) => {
    const client = selectors.getEditingClient(state);
    const { id } = selectors.getCurrentBoard(state) || {};
    if (client) {
      return {
        clientId: client.id,
        clientName: `${client.first_name} ${client.last_name}`,
        profilePicture: client.profile_picture || {},
        boardId: id ?? client.board,
      };
    }

    return {
      clientId: -1,
      clientName: '',
    };
  },
  undefined,
)(ClientEditionPopup);
