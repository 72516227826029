// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './popupCloseButton.module.scss';
import Button from '../Button';
import { withClosePopup } from '../Popup/providers';


type PopupCloseButtonPropTypes = {
  Icon: ComponentType<*>,
  className: string,
  size: string,
  isOutside: boolean,
  onClick?: Function,
  closePopup?: Function
};

const PopupCloseButton = ({
  Icon,
  className = '',
  closePopup,
  onClick,
  size = 'medium',
  isOutside = false,
  ...props
} : PopupCloseButtonPropTypes) => (
  <Button
    {...props}
    onClick={ () => {
      if (onClick) onClick();
      if (closePopup) closePopup();
    }}
    Icon={Icon}
    iconPosition="right"
    className={
      `
        ${styles.popupCloseButton}
        ${styles[size]}
        ${isOutside ? styles.isOutside : ''}
        ${typeof className !== 'undefined' ? className : ''}
    `}
  />
);

export default withClosePopup(PopupCloseButton);
