import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdDelete } from 'react-icons/md';
import { Droppable } from 'react-beautiful-dnd';
import type { DroppableProvided } from 'react-beautiful-dnd';

import * as selectors from '../../reducers/index';
import styles from './bottomFilterActions.module.scss';
import Button from '../Button';

type BottomFilterActionsPropsType = {
  draggingClient: Object;
  isAnyClientDragging: Boolean
};

class BottomFilterActions extends Component<BottomFilterActionsPropsType> {
  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ show: true });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  render() {
    const { isAnyClientDragging, draggingClient } = this.props;
    const { show } = this.state;
    return (
      <div
        className={
          `
            ${!show ? styles.hide : ''}
          `
        }
      >
        <div
          className={`
            ${styles.bottomFilterActions}
            ${isAnyClientDragging ? styles.showFilterActions : ''}
          `}
        >
          <Droppable droppableId="delete-client" type="client">
            {(dropProvided: DroppableProvided, snapshot) => (
              <div
                className={`
                  ${styles.buttonSize}
                  ${snapshot.isDraggingOver ? styles.isDraggingOver : ''}
                `}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                <Button
                  title="Eliminar"
                  tint="mediumGray"
                  Icon={MdDelete}
                  size="large"
                  isOutlined
                />
                <div className={styles.displayNone}>
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Droppable droppableId="archive-client" type="client">
            {(dropProvided: DroppableProvided, snapshot) => (
              <div
                className={`
                  ${styles.buttonSize}
                  ${snapshot.isDraggingOver ? styles.isDraggingOver : ''}
                `}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                <Button
                  title={draggingClient.is_archived ? 'En Proceso' : 'Descartar'}
                  tint={draggingClient.is_archived ? 'secondary' : 'gray'}
                  size="large"
                  className={styles.buttonSize}
                />
                <div className={styles.displayNone}>
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Droppable droppableId="admit-client" type="client">
            {(dropProvided: DroppableProvided, snapshot) => (
              <div
                className={`
                  ${styles.buttonSize}
                  ${snapshot.isDraggingOver ? styles.isDraggingOver : ''}
                `}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                <Button
                  title={draggingClient.is_admitted ? 'En Proceso' : 'Admitir'}
                  tint={draggingClient.is_admitted ? 'secondary' : 'success'}
                  size="large"
                  className={styles.buttonSize}
                />
                <div className={styles.displayNone}>
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  const draggingClientIds = selectors.getDraggingClientsIds(state);
  const client = selectors.getClient(state, draggingClientIds[0]);

  return {
    isAnyClientDragging: selectors.isAnyClientDragging(state),
    draggingClient: client || {},
  };
})(BottomFilterActions);
