// @flow
import type { Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-flexbox-grid';
import ReactMarkdown from 'react-markdown';

import type { FOBI_FORM_TYPE } from '../FobiRenderer/types';
import * as fobiTypes from '../FobiRenderer/types';
import * as selectors from '../../reducers';
import { FobiReduxFormRenderer } from '../FobiRenderer';
import styles from './contactSchoolSpecificFormRender.module.scss';


type ContactSchoolSpecificFormRendererPropTypes = {
  formDescription: FOBI_FORM_TYPE,
  member: string,
  showPreviews?: boolean,
  baseColor?: string,
};

type ElementWrapperPropTypes = {
  plugin_uid: string,
  children?: Node
};

type ContentTextWrapperPropTypes = {
  input?: string,
}

const ElementWrapper = ({ plugin_uid, children }: ElementWrapperPropTypes) => (
  <Col xs={12} sm={(plugin_uid === fobiTypes.TEXTAREA_ELEMENT || plugin_uid === fobiTypes.FILE_ELEMENT ) ? 12 : 6}>
    {children}
  </Col>
);

const ContentTextWrapper = ({ input }: ContentTextWrapperPropTypes) => (
  <Col xs={12}>
    <ReactMarkdown source={input} className={styles.ContentTextWrapper} />
  </Col>
);

const ContactSchoolSpecificFormRendererDummy = ({
  formDescription,
  member,
  showPreviews = false,
  baseColor = '',
  ...props
}: ContactSchoolSpecificFormRendererPropTypes) => (
  <FobiReduxFormRenderer
    formDescription={formDescription}
    ElementWrapper={ElementWrapper}
    ContentTextWrapper={ContentTextWrapper}
    getElementName={name => `${member}.${name}`}
    showPreviews={showPreviews}
    baseColor={baseColor}
    {...props}
  />
);


export default connect(
  state => ({
    formDescription: selectors.getContactFormDescription(state),
  }),
)(ContactSchoolSpecificFormRendererDummy);
