// @flow
import type { ReactNode } from 'react';
import React from 'react';
import { MdClose } from 'react-icons/md';

import Popup from '../Popup';
import PopupHeader from '../PopupHeader';
import PopupFooter from '../PopupFooter';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupContent from '../PopupContent';
import PopupContainer from '../PopupContainer';
// import PopupHeaderTitle from '../PopupHeaderTitle';
import Button from '../Button';
import PopupCloseButton from '../PopupCloseButton';
import i18n from 'i18n-js';

import styles from './genericPopup.module.scss';


type GenericPopupPropTypes = {
  id: ID_TYPE,
  onConfirm: Function,
  title: string,
  onCancel?: Function,
  hideConfirm: ?boolean,
  hideCancel?: boolean,
  bodyText?: string,
  customContent?: ReactNode,
  customTitle?: ReactNode,
  hideCloseButton?: boolean,
  popUpSize?: String,
  confirmButtonTitle?: String,
  confirmButtonColor?: String,
  cancelButtonColor?: String,
};

const GenericPopup = ({
  id,
  onConfirm,
  title,
  onCancel,
  hideConfirm,
  hideCancel,
  bodyText,
  customTitle,
  customContent,
  hideCloseButton,
  popUpSize = "small",
  confirmButtonTitle = i18n.t('accept'),
  confirmButtonColor = "primary",
  cancelButtonColor = "secondary",
}: GenericPopupPropTypes) => (
  <Popup id={id}>
    <PopupContainer
      elevation={8}
      isRound
      size={popUpSize}
    >
      <PopupHeader isAlert>
        {
          !hideCloseButton
          && (
            <PopupHeaderRightGroup>
              <PopupCloseButton
                tint="mediumGray"
                Icon={MdClose}
                isTransparent
                isOutlined
              />
            </PopupHeaderRightGroup>
          )
        }
      </PopupHeader>
      <PopupContent className={styles.container}>
        {
          !customTitle
          && (
            <p className={styles.titleText}>
              {title}
            </p>
          )
        }
        {customTitle}
        {
          !customContent
          && (
            <p className={styles.bodyText}>
              {bodyText}
            </p>
          )
        }
        {customContent}
        <PopupFooter align="center">
          {
            !hideCancel
            && (
              <Button
                className={styles.button}
                title="Cancelar"
                tint={cancelButtonColor}
                onClick={() => {
                  if (onCancel) onCancel();
                }}
              />
            )
          }
          {
            !hideConfirm
            && (
              <Button
                className={styles.button}
                title={confirmButtonTitle}
                tint={confirmButtonColor}
                onClick={() => {
                  onConfirm();
                }}
              />
            )
          }
        </PopupFooter>
      </PopupContent>
    </PopupContainer>
  </Popup>
);
export default GenericPopup;
