// @flow
import type { ERROR_TYPE, ID_TYPE } from '../types/common';
import type {
  PHASE_TYPE,
  PHASE_ADD_STARTED_TYPE,
  PHASE_ADD_COMPLETED_TYPE,
  PHASE_ADD_FAILED_TYPE,
  PHASE_CLIENTS_FETCH_STARTED_TYPE,
  PHASE_CLIENTS_FETCH_COMPLETED_TYPE,
  PHASE_CLIENTS_FETCH_FAILED_TYPE,
  PHASES_FETCH_STARTED_TYPE,
  PHASES_FETCH_COMPLETED_TYPE,
  PHASES_FETCH_FAILED_TYPE,
  PHASE_CLIENTS_ADDED_TYPE,
  PHASE_CLIENTS_REMOVED_TYPE,
  PHASE_CLIENTS_UPDATED_TYPE,
  PHASE_REMOVED_TYPE,
  PHASE_UPDATE_STARTED_TYPE,
  PHASE_UPDATE_COMPLETED_TYPE,
  PHASE_UPDATE_FAILED_TYPE,
  PHASE_REPORT_GENERATED_START_TYPE,
  PHASE_REPORT_GENERATED_COMPLETE_TYPE,
  PHASE_REPORT_GENERATED_FAIL_TYPE,
  PHASE_ALL_CLIENTS_ARCHIVED_TYPE,
  PHASE_FILTER_UPDATED_TYPE,
  SORT_PHASE_STARTED_TYPE,
  SORT_PHASE_SUCCEED_TYPE,
  SORTING_METHOD_UPDATED_TYPE,
  PHASE_SELECTED_TYPE,
  PHASE_UNSELECTED_TYPE,
  FETCH_PHASE_STARTED_TYPE,
  FETCH_PHASE_COMPLETED_TYPE,
} from '../types/phases';
import * as types from '../types/phases';
import * as common from './common';

export const updateSortingMethod = (
  id: ID_TYPE,
  sortingMethod: Object,
): SORTING_METHOD_UPDATED_TYPE => ({
  type: types.SORTING_METHOD_UPDATED,
  payload: {
    id,
    sortingMethod,
  },
});

export const startAddingPhase = (phase: PHASE_TYPE): PHASE_ADD_STARTED_TYPE => ({
  type: types.PHASE_ADD_STARTED,
  payload: phase,
});

export const completeAddingPhase = (
  oldId: ID_TYPE,
  newId: ID_TYPE,
  extra: Object,
): PHASE_ADD_COMPLETED_TYPE => ({
  type: types.PHASE_ADD_COMPLETED,
  payload: {
    oldId,
    newId,
    ...extra,
  },
});

export const failAddingPhase = (error: ERROR_TYPE): PHASE_ADD_FAILED_TYPE => ({
  type: types.PHASE_ADD_FAILED,
  payload: error,
});

export const startFetchingPhaseClients = (id: ID_TYPE): PHASE_CLIENTS_FETCH_STARTED_TYPE => ({
  type: types.PHASE_CLIENTS_FETCH_STARTED,
  payload: id,
});

export const completeFetchingPhaseClients = (
  id: ID_TYPE,
  clients: Array<ID_TYPE>,
): PHASE_CLIENTS_FETCH_COMPLETED_TYPE => ({
  type: types.PHASE_CLIENTS_FETCH_COMPLETED,
  payload: {
    id,
    clients,
  },
});

export const failFetchingPhaseClients = (error: ERROR_TYPE): PHASE_CLIENTS_FETCH_FAILED_TYPE => ({
  type: types.PHASE_CLIENTS_FETCH_FAILED,
  payload: error,
});

export const startFetchingPhases = (board: ID_TYPE): PHASES_FETCH_STARTED_TYPE => ({
  type: types.PHASES_FETCH_STARTED,
  payload: board,
});

export const completeFetchingPhases = (
  phasesById: { [ID_TYPE]: PHASE_TYPE },
  phaseIds: Array<ID_TYPE>,
): PHASES_FETCH_COMPLETED_TYPE => ({
  type: types.PHASES_FETCH_COMPLETED,
  payload: {
    entities: phasesById,
    order: phaseIds,
  },
});

export const failFetchingPhases = (error: ERROR_TYPE): PHASES_FETCH_FAILED_TYPE => ({
  type: types.PHASES_FETCH_FAILED,
  payload: error,
});

export const addClientsToPhase = (
  phaseId: ID_TYPE,
  clientIds: Array<ID_TYPE>,
  atIndex?: number,
): PHASE_CLIENTS_ADDED_TYPE => ({
  type: types.PHASE_CLIENTS_ADDED,
  payload: {
    id: phaseId,
    key: 'clients',
    order: clientIds,
    atIndex,
  },
});

export const removeClientsFromPhase = (
  phaseId: ID_TYPE,
  clientIds: Array<ID_TYPE>,
): PHASE_CLIENTS_REMOVED_TYPE => ({
  type: types.PHASE_CLIENTS_REMOVED,
  payload: {
    id: phaseId,
    key: 'clients',
    order: clientIds,
  },
});

export const removePhase = (id: ID_TYPE): PHASE_REMOVED_TYPE => ({
  type: types.PHASE_REMOVED,
  payload: id,
});

export const updateClientsInPhase = (
  phaseId: ID_TYPE,
  oldIds: Array<ID_TYPE>,
  newIds: Array<ID_TYPE>,
): PHASE_CLIENTS_UPDATED_TYPE => ({
  type: types.PHASE_CLIENTS_UPDATED,
  payload: {
    key: 'clients',
    id: phaseId,
    oldValues: oldIds,
    newValues: newIds,
  },
});

export const startUpdatingPhase = (id: ID_TYPE, attributes: Object): PHASE_UPDATE_STARTED_TYPE => ({
  type: types.PHASE_UPDATE_STARTED,
  payload: {
    id,
    ...attributes,
  },
});

export const completeUpdatingPhase = (id: ID_TYPE): PHASE_UPDATE_COMPLETED_TYPE => ({
  type: types.PHASE_UPDATE_COMPLETED,
  payload: id,
});

export const failUpdatingPhase = (error: ERROR_TYPE): PHASE_UPDATE_FAILED_TYPE => ({
  type: types.PHASE_UPDATE_FAILED,
  payload: error,
});

export const startGeneratingPhaseReport = (id: ID_TYPE): PHASE_REPORT_GENERATED_START_TYPE => ({
  type: types.PHASE_REPORT_GENERATED_START,
  payload: id,
});

export const completeGeneratingPhaseReport = (
  id: ID_TYPE,
): PHASE_REPORT_GENERATED_COMPLETE_TYPE => ({
  type: types.PHASE_REPORT_GENERATED_COMPLETE,
  payload: id,
});

export const failGeneratingPhaseReport = (error: ERROR_TYPE): PHASE_REPORT_GENERATED_FAIL_TYPE => ({
  type: types.PHASE_REPORT_GENERATED_FAIL,
  payload: error,
});

export const archiveAllClientsInPhase = (id: ID_TYPE): PHASE_ALL_CLIENTS_ARCHIVED_TYPE => ({
  type: types.PHASE_ALL_CLIENTS_ARCHIVED,
  payload: id,
});

export const updatePhaseFilter = (id: ID_TYPE, filter: string): PHASE_FILTER_UPDATED_TYPE => ({
  type: types.PHASE_FILTER_UPDATED,
  payload: {
    id,
    filter,
  },
});

export const startSortingPhases = (
  oldIndex: number,
  newIndex: number,
): SORT_PHASE_STARTED_TYPE => ({
  type: types.SORT_PHASE_STARTED,
  payload: {
    oldIndex,
    newIndex,
  },
});

export const succeedSortingPhases = (phase: PHASE_TYPE): SORT_PHASE_SUCCEED_TYPE => ({
  type: types.SORT_PHASE_SUCCEED,
  payload: phase,
});

export const failSortingPhases = common.error(types.SORT_PHASE_FAILED);

export const selectPhase = (id: ID_TYPE): PHASE_SELECTED_TYPE => ({
  type: types.PHASE_SELECTED,
  payload: id,
});

export const unselectPhase = (): PHASE_UNSELECTED_TYPE => ({
  type: types.PHASE_UNSELECTED,
});

export const startFetchPhase = (id: ID_TYPE): FETCH_PHASE_STARTED_TYPE => ({
  type: types.FETCH_PHASE_STARTED,
  payload: id,
});

export const completeFetchPhase = (phase: PHASE_TYPE): FETCH_PHASE_COMPLETED_TYPE => ({
  type: types.FETCH_PHASE_COMPLETED,
  payload: phase,
});

export const failFetchPhase = common.error(types.FETCH_PHASE_FAILED);
