// @flow
import type { ComponentType, Node } from 'react';
import React, { Fragment } from 'react';

import type { FOBI_FORM_TYPE } from './types';
import FobiReduxFormField from './FobiReduxFormField';
// import styles from './fobiFormRenderer.module.scss';


type ElementWrapperPropTypes = {
  plugin_uid: string,
  children?: Node
};

type ContentTextWrapperPropTypes = {
  input?: string
};

type FobiReduxFormPropTypes = {
  formDescription: FOBI_FORM_TYPE,
  ElementWrapper?: ComponentType<ElementWrapperPropTypes>,
  ContentTextWrapper?: ComponentType<ContentTextWrapperPropTypes>,
  getElementName?: string => string,
  showPreviews?: boolean,
  baseColor?: string,
};

const defaultElementNamer = (name: string): string => name;

export const FobiReduxFormRenderer = ({
  formDescription: { name, form_elements },
  ElementWrapper,
  ContentTextWrapper,
  getElementName = defaultElementNamer,
  showPreviews = false,
  baseColor = '',
}: FobiReduxFormPropTypes) => (
  <Fragment>
    {
      form_elements && form_elements.sort((a, b) => a.position - b.position).map(
        (element, index) => {
          if (element.plugin_uid === 'section_title') {
            return (
              typeof ContentTextWrapper !== 'undefined'
                ? (
                  <ContentTextWrapper key={index} input={element.plugin_data.text} />
                ) : (
                  <Fragment>
                    {element.plugin_data.text}
                  </Fragment>
                )
            );
          }


          return (typeof ElementWrapper !== 'undefined'
            ? (
              <ElementWrapper key={element.plugin_data.name} {...element}>
                <FobiReduxFormField
                  formName={name}
                  element={element}
                  getElementName={getElementName}
                  showPreview={showPreviews}
                  baseColor={baseColor}
                  tint={baseColor}
                />
              </ElementWrapper>
            ) : (
              <FobiReduxFormField
                key={element.plugin_data.name}
                formName={name}
                element={element}
                getElementName={getElementName}
                showPreview={showPreviews}
                baseColor={baseColor}
                tint={baseColor}
              />
            )
          );
        },
      )
    }
  </Fragment>
);
