// @flow
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from './logo.png';
import isotype from './isotype.png';
import * as selectors from '../../../reducers';

import styles from './sidebarLogo.module.scss';

type SidebarLogoPropTypes = {
  boardUUID: ID_TYPE,
  isOpen: boolean,
};

const SidebarLogo = ({ boardUUID, isOpen }: SidebarLogoPropTypes) => (
  <NavLink to={`/dashboard/${boardUUID}`} className={styles.header}>
    <div className={styles.titleContainer}>
      {
        isOpen ? (
          <img src={logo} alt="Edoo Admisiones" />
        ) : (
          <img src={isotype} alt="A" />
        )
      }
    </div>
  </NavLink>
);


export default connect(
  state => ({
    boardUUID: selectors.getBoardUUIDParam(state),
    isOpen: selectors.isMainSidebarOpen(state),
  }),
)(SidebarLogo);
