// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdKeyboardArrowDown } from 'react-icons/md';
import i18n from 'i18n-js';

import * as selectors from '../../reducers';
import FlagIcon from '../FlagIcon';

import styles from './languageSelector.module.scss';
import PopMenu from '../PopMenu';
import { LANGUAGES_CONFIG } from '../../settings';

type LanguageSelectorPropTypes = {
  baseColor: string,
  boardUUID: number,
  selectedLanguage: Object,
  boardLanguages: Array<string>,
  className?: string,
};

const LanguageSelector = ({
  baseColor,
  boardUUID,
  selectedLanguage,
  boardLanguages,
  className,
} : LanguageSelectorPropTypes) => (
  baseColor ? (
    <div className={
        `
          ${styles.languageSelector}
          ${className || ''}
        `
      }
    >
      <PopMenu
        id="languageSelectorMenu"
        vertical="below"
        horizontal="left"
        tipPosition="right"
        isRound
        width={130}
        options={LANGUAGES_CONFIG.filter(item => boardLanguages.includes(item.i18nKey)).map(({ name, i18nKey }, index) => ({
          key: index,
          title: name,
          href: `/${i18nKey}/external/${boardUUID}`,
        }))}
      >
        <FlagIcon className={styles.flagIcon} code={selectedLanguage.flagIconCode} size="4x" squared />
        <div className={styles.container}>
          <div className={styles.title}>
            {i18n.t('language')}
          </div>
          <div className={styles.language} style={{ color: baseColor }}>
            {selectedLanguage.name}
            <MdKeyboardArrowDown size={24} />
          </div>
        </div>

      </PopMenu>
    </div>
  ) : null
);

export default connect(
  (state) => {
    const i18nLocale = selectors.getLocaleParam(state, true);

    const selectedLanguage = LANGUAGES_CONFIG.filter(e => e.i18nKey === i18nLocale)[0] || [];

    return ({
      selectedLanguage,
      baseColor: ((selectors.getCurrentBoard(state) || {}).colors || {}).base,
      boardLanguages: (selectors.getCurrentBoard(state) || {}).languages || [],
      boardUUID: selectors.getBoardUUIDParam(state, true),
    });
  },
)(LanguageSelector);
