// @flow
export type CLOSEABLE_ELEMENT_STATE = {
  byId: {[number]:Object},
  groups: Array<string>,
  configuration: Object,
  isOpen: boolean
};

export type CLOSEABLE_REGISTERED_TYPE = {
  type: '@@redux-closeable/REGISTERED',
  payload: {
    id: string,
    isOpen: boolean,
    groups: Array<string>,
    configuration: Object
  }
};
export const CLOSEABLE_REGISTERED = '@@redux-closeable/REGISTERED';

export type CLOSEABLE_ATTACHED_TYPE = {
  type: '@@redux-closeable/ATTACHED',
  payload: {
    id: string
  }
};
export const CLOSEABLE_ATTACHED = '@@redux-closeable/ATTACHED';

export type CLOSEABLE_OPENED_TYPE = {
  type: '@@redux-closeable/OPENED',
  payload: {
    id: string
  }
};
export const CLOSEABLE_OPENED = '@@redux-closeable/OPENED';

export type CLOSEABLE_CLOSED_TYPE = {
  type: '@@redux-closeable/CLOSED',
  payload: {
    id: string
  }
};
export const CLOSEABLE_CLOSED = '@@redux-closeable/CLOSED';

export type CLOSEABLE_GROUPS_CLOSED_TYPE = {
  type: '@@redux-closeable/GROUPS_CLOSED',
  payload: {
    groups: Array<string>
  }
};
export const CLOSEABLE_GROUPS_CLOSED = '@@redux-closeable/GROUPS_CLOSED';

export type CLOSEABLE_GROUPS_OPENED_TYPE = {
  type: '@@redux-closeable/GROUPS_OPENED',
  payload: {
    groups: Array<string>
  }
};
export const CLOSEABLE_GROUPS_OPENED = '@@redux-closeable/GROUPS_OPENED';

export type CLOSEABLE_ALL_CLOSED_TYPE = {
  type: '@@redux-closeable/ALL_CLOSED'
};
export const CLOSEABLE_ALL_CLOSED = '@@redux-closeable/ALL_CLOSED';

export type CLOSEABLE_GROUP_ADDED_TYPE = {
  type: '@@redux-closeable/GROUP_ADDED',
  payload: {
    id: string,
    name: string
  }
};
export const CLOSEABLE_GROUP_ADDED = '@@redux-closeable/GROUP_ADDED';

export type CLOSEABLE_GROUP_REMOVED_TYPE = {
  type: '@@redux-closeable/GROUP_REMOVED',
  payload: {
    id: string,
    name: string
  }
};
export const CLOSEABLE_GROUP_REMOVED = '@@redux-closeable/GROUP_REMOVED';

export type CLOSEABLE_ALL_GROUPS_CLEARED_TYPE = {
  type: '@@redux-closeable/ALL_GROUPS_CLEARED',
  payload: {
    id: string
  }
};
export const CLOSEABLE_ALL_GROUPS_CLEARED = '@@redux-closeable/ALL_GROUPS_CLEARED';

export type CLOSEABLE_CONFIGURATION_UPDATED_TYPE = {
  type: '@@redux-closeable/CONFIGURATION_UPDATED',
  payload: Object
};
export const CLOSEABLE_CONFIGURATION_UPDATED = '@@redux-closeable/CONFIGURATION_UPDATED';

export type CLOSEABLE_CONFIGURATION_RESET_TYPE = {
  type: '@@redux-closeable/@@redux-cTION_RESET',
  payload: { peState: CLOSEABLE_ELEMENT_STATE }
};
export const CLOSEABLE_CONFIGURATION_RESET = '@@redux-closeable/CONFIGURATION_RESET';

type ELEMENT_APPENDINX = { peState: CLOSEABLE_ELEMENT_STATE };

export type CLOSEABLE_ACTION_TYPE =
  | CLOSEABLE_REGISTERED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_ATTACHED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_OPENED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_CLOSED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_GROUPS_CLOSED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_GROUPS_OPENED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_ALL_CLOSED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_GROUP_ADDED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_GROUP_REMOVED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_ALL_GROUPS_CLEARED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_CONFIGURATION_UPDATED_TYPE & ELEMENT_APPENDINX
  | CLOSEABLE_CONFIGURATION_RESET_TYPE & ELEMENT_APPENDINX;
