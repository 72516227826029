// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './cardHeaderTitle.module.scss';


type CardHeaderTitlePropTypes = {
  children: Node,
  size?: string,
  isCentered?: boolean
};

const CardHeaderTitle = ({
  children,
  size = 'medium',
  isCentered = false,
} : CardHeaderTitlePropTypes) => (
  <div
    className={
      `
        ${styles.title}
        ${styles[size]}
        ${isCentered ? styles.centered : ''}
      `
    }
  >
    { children }
  </div>
);

export default CardHeaderTitle;
