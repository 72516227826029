// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { isEqual } from 'lodash';

import ChartContainer from '../ChartContainer';
import * as actions from '../../actions/reports';
import * as selectors from '../../reducers';

/* import styles from './clientCountPerPhasesChart.module.scss'; */

const chartOptions = {
  plotOptions: {
    bar: {
      barHeight: '100%',
      distributed: true,
      horizontal: true,
    },
  },
  dataLabels: { enabled: true },
  title: { text: 'Por fases' },
  xaxis: { categories: [] },
};

type CountByPhaseChartProps = {
  height?: number | string,
  width?: number | string,
  dataSet: Array<any>,
  isLoading: boolean,
  onLoad: Function,
};

class ClientCountPerPhaseChart extends Component<CountByPhaseChartProps> {
  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) onLoad();
  }

  shouldComponentUpdate(nextProps, _nextState) {
    const { dataSet, isLoading } = this.props;
    return !isEqual(nextProps.dataSet, dataSet) || nextProps.isLoading !== isLoading;
  }

  render() {
    const {
      width,
      height,
      isLoading,
      dataSet,
    } = this.props;

    const labels = dataSet.map(phase => phase.label);
    const data = dataSet.map(phase => phase.count);

    chartOptions.xaxis.categories = labels;

    return (
      <ChartContainer isLoading={isLoading}>
        {
          dataSet.length > 0 && (
            <Chart
              type="bar"
              height={height}
              width={width}
              options={chartOptions}
              series={[{
                name: 'Movimientos',
                data,
              }]}
            />
          )
        }
      </ChartContainer>
    );
  }
}

export default connect(
  state => ({
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    isLoading: selectors.getIsFetchingClientCountByPhase(state),
    dataSet: selectors.getReporstByType(state, 'countPerPhase'),
  }),
  dispatch => ({
    onLoad(boardId) {
      dispatch(actions.startFetchingClientsCountPerPhase(boardId));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardId);
    },
  }),
)(ClientCountPerPhaseChart);
