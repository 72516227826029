// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
import Card from '../Card';
import CardContent, { LoadingCardContent } from '../CardContent';
import CardHeader from '../CardHeader';
import CardHeaderTitleContainer from '../CardHeaderTitleContainer';
import CardHeaderTitle from '../CardHeaderTitle';
import PhaseOptions from '../PhaseOptions';
import PhaseClients from '../PhaseClients';
import PhaseAllClientsSelect from '../PhaseAllClientsSelect';
import PhaseFilter from '../PhaseFilter';
import PhaseSortingOptions from '../PhaseSortingOptions';

import styles from './phase.module.scss';

type PhasePropTypes = {
  id: number,
  name: string,
  isLoading?: boolean,
  isConfirmed?: boolean,
  clientsCount: number,
  onLoad?: Function,
  index: number
};

type PhaseHeaderHandlePropTypes = {
  name: string,
  clientsCount: number,
  provided: DraggableProvided,
};

const PhaseHeaderHandle = ({
  name,
  clientsCount,
  provided,
}: PhaseHeaderHandlePropTypes) => (
  <CardHeader
    size="small"
    className={styles.phaseHeader}
    {...provided.dragHandleProps}
  >
    <CardHeaderTitleContainer>
      <CardHeaderTitle size="small">
        {name}
        <span className={styles.count}>
          &nbsp;(
          {clientsCount}
          )
        </span>
      </CardHeaderTitle>
    </CardHeaderTitleContainer>
  </CardHeader>
);

class Phase extends React.Component<PhasePropTypes> {
  componentDidMount() {
    const { onLoad, isConfirmed } = this.props;
    if (isConfirmed && onLoad) {
      onLoad();
    }
  }

  render() {
    const {
      id,
      name,
      isLoading = false,
      isConfirmed = false,
      clientsCount = 0,
      index,
    } = this.props;

    return (
      <Draggable draggableId={String(id)} index={index}>
        {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <div
            className={`
              ${styles.phase}
              ${snapshot.isDragging ? styles.availableToDrop : ''}
              ${!isConfirmed ? styles.waiting : ''}
            `}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            <Card isRound>
              {/* PopOverMenu is now out of card header in an attempt
                to avoid DND while displaying */}
              <div className={styles.popOverOptions}>
                <PhaseOptions id={id} />
              </div>
              <PhaseHeaderHandle
                id={id}
                clientsCount={clientsCount}
                name={name}
                provided={draggableProvided}
              />
              {
                isLoading ? (
                  <LoadingCardContent
                    loaderTint="mediumgray"
                    title="Cargando clientes"
                    className={`
                            ${styles.phaseContent}
                            ${styles.empty}
                          `}
                  />
                ) : (
                  <Fragment>
                    <CardContent className={styles.tools}>
                      <PhaseAllClientsSelect
                        id={id}
                        title="Todos los estudiantes"
                      />
                      <PhaseFilter id={id} />
                    </CardContent>
                    <CardContent
                      className={styles.phaseContent}
                    >
                      {
                        clientsCount > 0 && (
                          <PhaseSortingOptions id={id} />
                        )
                      }
                      <PhaseClients id={id} />
                    </CardContent>
                  </Fragment>
                )
              }
            </Card>
          </div>
        )}
      </Draggable>
    );
  }
}

export default connect(
  (state, { id }) => {
    const cycleFilter = selectors.getGlobalCycleIdFilter(state);
    const levelFilter = selectors.getGlobalLevelIdFilter(state);
    const startBirthDateFilter = selectors.getGlobalStartBirthDateFilter(state);
    const endBirthDateFilter = selectors.getGlobalEndBirthDateFilter(state);

    const clients = selectors
      .getPhaseClients(state, id)
      .filter((client) => {
        if (!cycleFilter) return true;
        if (Array.isArray(cycleFilter)) return cycleFilter.includes(client.cycle);
        return client.cycle === cycleFilter;
      })
      .filter(client => !levelFilter || client.level === levelFilter)
      .filter((client) => {
        const birthDate = moment(client.birth_date);
        const startDate = moment(startBirthDateFilter);
        
        if (!startBirthDateFilter) return true;
        return !endBirthDateFilter ? true : birthDate.isSameOrAfter(startDate, 'days');
      })
      .filter((client) => {
        const birthDate = moment(client.birth_date);
        const endDate = moment(endBirthDateFilter);
        
        if (!endBirthDateFilter) return true;
        return !startBirthDateFilter ? true : birthDate.isSameOrBefore(endDate, 'days');
      });

    return {
      isLoading: selectors.isPhaseFetching(state, id),
      clientsCount: clients.length,
    }
  },
  (dispatch, { id }) => ({
    onLoad() {
      dispatch(actions.startFetchingPhaseClients(id));
    },
  }),
)(Phase);
