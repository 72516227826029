// @flow
import {
  call,
  takeEvery,
  put,
  race,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { reset } from 'redux-form';
import { normalize } from 'normalizr';
import { push } from 'connected-react-router';
import axios from 'axios';

import {
  CURRENT_USER_UPDATE_STARTED,
  FETCH_CURRENT_LOGGED_USER_STARTED,
  RESTORE_CREDENTIALS_STARTED,
  USERS_FETCH_STARTED,
  USER_CHANGE_ROLE_STARTED,
  USER_CHANGE_DISABLED_STATUS_STARTED,
  USER_CHANGE_EXTRA_DATA_STARTED,
  SEND_INVITATION_STARTED,
  REQUEST_RECOVER_PASSWORD_STARTED,
  RECOVER_PASSWORD_STARTED,
} from '../types/users';
import { SETTINGS_POPUP_ID } from '../components/SettingsPopup';
import { ID_FORM_USER_MANAGER } from '../components/UsersManager';

import { Users, api } from '../api';
import { REQUEST_TIMEOUT, USER_LOGIN_EVENTS } from '../settings';
import { throwTimeout } from '../lib/http';
import { fibonacciBackoff } from '../lib/retry';
import { arrayOfUsers } from '../api/schemas/users';

import * as selectors from '../reducers';
import * as actions from '../actions/users';
import * as closableActions from '../actions/closeable';
import * as boardActions from '../actions/boards';



function* uploadUserProfilePicture(action) {
  const { id, file } = action.payload;
  const token = yield select(selectors.getToken);

  const headers = {
    Authorization: token,
    'Content-Type': 'multipart/form-data'
  };
  const data = new FormData();
  data.append('file', file, file.name);

  axios({
    method: 'post',
    url: api.getURL(`users/${id}/set-profile-picture`),
    data,
    headers,
  })
    .then((response) => response.data)
    .catch((error) => { console.log(error) });
}

function* fetchCurrentLoggedUser(action) {
  const id = yield select(selectors.getUserId);
  const token = yield select(selectors.getToken);

  try {
    const response = yield call(
      fibonacciBackoff,
      [Users, 'detail'],
      { token, id }
    );

    yield put(actions.completeFetchCurrentLoggedUser(response));
    yield put(boardActions.startFetchingBoards());

  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.failFetchCurrentLoggedUser({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
};

function* updateCurrentLoggedUser(action) {
  const { payload } = action;
  const id = yield select(selectors.getUserId);
  const token = yield select(selectors.getToken);

  try {
    const profilePicture = payload.profile_picture;

    if (profilePicture && profilePicture.file) {
      yield* uploadUserProfilePicture({
        payload: {
          id,
          file: profilePicture.file,
      }});
    };

    const { timeout } = yield race({
      response: call(
        [Users, 'update'],
        {
          token,
          id,
          data: {
            ...payload,
            profile_picture: undefined,
          }
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('updateCurrentLoggedUser');
    }

    yield put(actions.completeUpdateCurrentuser());
    yield put(closableActions.close(SETTINGS_POPUP_ID));
  } catch (error) {
    yield put(actions.failUpdateCurrentUser(error));
  }
}

function* restoreSecuritySettings(action) {
  const id = yield select(selectors.getUserId);
  const user = yield select(selectors.getCurrentLoggedUser);
  const token = yield select(selectors.getToken);
  const { payload } = action;

  let newEmail = payload.new_email;
  if (newEmail == null || newEmail == user.email) {
    newEmail = undefined;
  }

  try {
    yield race({
      response: call(
        [Users.custom, 'restoreCredentials'],
        {
          token,
          id,
          data: {
            ...payload,
            new_email: newEmail,
            confirm_new_password: undefined,
          }
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    yield put(actions.completeRestoreCredentials());
    yield put(closableActions.close(SETTINGS_POPUP_ID));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.failRestoreCredentials({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchUsers(action) {
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getCurrentLoggedUser);
  // const { payload } = action;

  try {
    const { response, timeout } = yield race({
      response: call(
        [Users, 'list'],
        {
          token,
          filters: {
            schools: user.schools.map(el => el.id),
          }
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    if (timeout) {
      throwTimeout('fetchSchoolUsers');
    }

    const {
      entities: { users },
    } = normalize(response, arrayOfUsers);

    yield put(actions.usersFetchSucceed(users));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.usersFetchFailed({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* changeUserRole(action) {
  const token = yield select(selectors.getToken);
  const { id, is_admin } = action.payload;
  
  try {
    yield call(
      fibonacciBackoff,
      [Users.custom, 'changeUserRole'],
      { token, id, data: { is_admin } }
    );

    yield put(actions.userChangeRoleSucceed(id));

  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.userChangeRoleFailed({
      id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* changeUserStatus(action) {
  const token = yield select(selectors.getToken);
  const { id, is_active } = action.payload;

  try {
    yield call(
      fibonacciBackoff,
      [Users.custom, 'changeUserStatus'],
      { token, id, data: { is_active } }
    );

    yield put(actions.userChangeDisabledStatusSucceed(id));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.userChangeDisabledStatusFailed({
      id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* changeExtraData(action) {
  const token = yield select(selectors.getToken);
  const { id } = yield select(selectors.getCurrentLoggedUser);
  const { extra_data } = action.payload;

  try {
    const response = yield call(
      fibonacciBackoff,
      [Users.custom, 'changeExtraData'],
      { token, id, data: { extra_data } },
    );

    yield put(actions.userChangeExtraDataSucceed(response));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.userChangeExtraDataFailed({
      id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* sendUserInvitation(action) {
  const token = yield select(selectors.getToken);
  // const school = yield select(selectors.getSchool);
  const { payload } = action;

  try {
    const { response, timeout } = yield race({
      response: call(
        [Users.custom, 'sendUserInvitation'],
        {
          token,
          data: {
            id: undefined,
            ...payload,
            // schools: [school.id]
          },
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    if (timeout) {
      throwTimeout('fetchSchoolUsers');
    }

    yield put(actions.sendInvitationSucceed(payload.id, response.id));
    yield put(reset(ID_FORM_USER_MANAGER));

  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.sendInvitationFailed({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* requestRecoverPassword(action) {
  const email = action.payload;

  try {
    const { timeout } = yield race({
      response: call(
        [Users.custom, 'requestRecoverPassword'],
        {
          data: { email },
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    if (timeout) {
      throwTimeout('fetchSchoolUsers');
    }

    yield put(actions.completeRequestRecoverPassword());
    yield put(push('/'));
    yield put(actions.registerUserLoginEvent(USER_LOGIN_EVENTS.REQUEST_RECOVER_PASSWORD_COMPLETED));

  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.failRequestRecoverPassword({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* recoverPassword(action) {
  const { user, token, password } = action.payload;

  try {
    const { timeout } = yield race({
      response: call(
        [Users.custom, 'recoverPassword'],
        {
          data: {
            user,
            token,
            password,
          },
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    if (timeout) {
      throwTimeout('recoverPassword');
    }

    yield put(actions.completeRecoverPassword());
    yield put(push('/'));
    yield put(actions.registerUserLoginEvent(USER_LOGIN_EVENTS.RECOVER_PASSWORD_COMPLETED));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.failRecoverPassword({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}


export function* watchFetchCurrentLoggedUser(): Iterator<any> {
  yield takeEvery(
    FETCH_CURRENT_LOGGED_USER_STARTED,
    fetchCurrentLoggedUser,
  )
};

export function* watchUpdateCurrentLoggedUser(): Iterator<any> {
  yield takeEvery(
    CURRENT_USER_UPDATE_STARTED,
    updateCurrentLoggedUser,
  )
};

export function* watchRestoreSecuritySettings(): Iterator<any> {
  yield takeEvery(
    RESTORE_CREDENTIALS_STARTED,
    restoreSecuritySettings,
  )
};

export function* watchFetchUsers(): Iterator<any> {
  yield takeEvery(
    USERS_FETCH_STARTED,
    fetchUsers,
  )
};

export function* watchChangeUserRole():Iterator<any> {
  yield takeEvery(
    USER_CHANGE_ROLE_STARTED,
    changeUserRole,
  )
};

export function* watchChangeUserStatus():Iterator<any> {
  yield takeEvery(
    USER_CHANGE_DISABLED_STATUS_STARTED,
    changeUserStatus,
  )
};

export function* watchChangeExtraData():Iterator<any> {
  yield takeEvery(
    USER_CHANGE_EXTRA_DATA_STARTED,
    changeExtraData,
  )
};

export function* watchSendUserInvitation():Iterator<any> {
  yield takeEvery(
    SEND_INVITATION_STARTED,
    sendUserInvitation,
  )
}

export function* watchRequestRecoverPassword(): Iterator<any> {
  yield takeEvery(
    REQUEST_RECOVER_PASSWORD_STARTED,
    requestRecoverPassword,
  )
}

export function* watchRecoverPassword(): Iterator<any> {
  yield takeEvery(
    RECOVER_PASSWORD_STARTED,
    recoverPassword,
  )
}
