import React from 'react';
import { connect } from 'react-redux';
import { MdCloudDownload, MdAttachFile } from 'react-icons/md';
import XLSX from 'xlsx';

import Button from '../../Button';

import * as selectors from '../../../reducers';
import styles from './fileUploaderPlaceholder.module.scss';


type FileUploaderPlaceholderPropTypes = {
  reportURL: string,
  onClick: Function,
  clientFormDescription: any,
  contactFormDescription: any,
  filePrefix: string,
}

const FileUploaderPlaceholder = ({
  reportURL,
  onClick,
  clientFormDescription,
  contactFormDescription,
  filePrefix,
}: FileUploaderPlaceholderPropTypes) => {
  const onGenerateXLSXTemplate = () => {
    const clientStaticExcelHeaders = {
      id: null,
      first_name: 'Name',
      last_name: 'Lastname',
      birth_date: '\'02/02/2000',
      birth_place: 'Guatemala',
      phone: '0000 0000',
      cycle: '1',
      level: '1',
    };
    const contactStaticExcelHeaders = {
      [`${filePrefix}first_name`]: 'Name',
      [`${filePrefix}last_name`]: 'Lastname',
      [`${filePrefix}phone`]: '000 0000',
      [`${filePrefix}email`]: 'email@email.com',
      [`${filePrefix}birth_date`]: '\'02/02/2000',
      [`${filePrefix}relationship`]: 'Padre',
      [`${filePrefix}address`]: 'Guatemala',
    };
    const outputSheet = 'bulk_load_sheet';
    const outputFile = 'bulk_template.xlsx';

    const clientDynamicExcelHeaders = {};
    clientFormDescription.form_elements.forEach(
      ({ plugin_data: { name } }) => { clientDynamicExcelHeaders[name] = null; },
    );

    const contactDynamicExcelHeaders = {};
    contactFormDescription.form_elements.forEach(
      ({ plugin_data: { name } }) => { contactDynamicExcelHeaders[filePrefix + name] = null; },
    );

    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet([{
      ...clientStaticExcelHeaders,
      ...clientDynamicExcelHeaders,
      ...contactStaticExcelHeaders,
      ...contactDynamicExcelHeaders,
    }]);

    XLSX.utils.book_append_sheet(book, sheet, outputSheet);
    XLSX.writeFile(book, outputFile);
  }
    
  return (
    <div className={styles.fileUploaderPlaceholder}>
      <div className={styles.title}>
        {'Descarga la plantilla, edítala y súbela'}
      </div>
      <div className={styles.description}>
        {'Si deseas actualizar un estudiante deberás de ingresar su id '}
        <strong>
          {'(Lo puedes consultar en el '}
          <a href={reportURL}>
            {'reporte general'}
          </a>
          {').'}
        </strong>
      </div>
      <div className={styles.description}>
        {'Si deseas crear un nuevo estudiante deberás de dejar la columna id vacía. '}
        {'Recuerda utilizar la '}
        <strong>
          {'plantilla de carga '}
        </strong>
        {'como guía para crear usuarios nuevos.'}
      </div>
      <Button
        title="Descargar plantilla de carga"
        tint="mediumGray"
        Icon={MdCloudDownload}
        clearMargins
        useAnchor
        className={`${styles.button} ${styles.marginTop}`}
        onClick={onGenerateXLSXTemplate}
        download
      />
      <div className={styles.divider} />
      <Button
        type="file"
        title="Subir archivo de carga"
        tint="secondary"
        clearMargins
        Icon={MdAttachFile}
        className={styles.button}
        onClick={onClick}
      />
    </div>
  );
}

export default connect(
  state => ({
    clientFormDescription: selectors.getClientFormDescription(state),
    contactFormDescription: selectors.getContactFormDescription(state),
  }),
)(FileUploaderPlaceholder);
