// @flow

export type PERMISSIONS_ADDED_TYPE = {
  type: 'PERMISSIONS_ADDED',
  payload: Array<string>
}
export const PERMISSIONS_ADDED = 'PERMISSIONS_ADDED';

export type PERMISSION_REMOVED_TYPE = {
  type: 'PERMISSION_REMOVED',
  payload: string
}
export const PERMISSION_REMOVED = 'PERMISSION_REMOVED';

export type PERMISSIONS_CLEARED_TYPE = {
  type: 'PERMISSIONS_CLEARED'
}
export const PERMISSIONS_CLEARED = 'PERMISSIONS_CLEARED';

export type PERMISSION_ACTION_TYPE =
  | PERMISSIONS_ADDED_TYPE
  | PERMISSION_REMOVED_TYPE
  | PERMISSIONS_CLEARED_TYPE
