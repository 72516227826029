import React, { Fragment } from 'react';
import { connect } from 'react-redux';
/* import {
  MdAddCircleOutline,
} from 'react-icons/md'; */

import SidebarListItem from '../SidebarListItem';
import history from '../../../../history';
import SidebarBoardItemOptions from '../SidebarBoardItemOptions';
// import SidebarButton from '../SidebarButton';
// import SidebarSeparator from '../SidebarSeparator';

import type { ID_TYPE } from '../types/common';
import * as selectors from '../../../reducers';
// TODO: It's necessary to fetch board on select? Yes
// import * as boardActions from '../../../actions/boards';

import styles from './sidebarList.module.scss';

type SidebarListPropTypes = {
  boardUUID: ID_TYPE,
  boards: Array,
  isOpen: boolean,
  onSelectBoard: Function,
};

const SidebarList = ({
  boardUUID,
  boards,
  isOpen,
  onSelectBoard,
}: SidebarListPropTypes) => (
  <Fragment>
    { isOpen && (
      <div className={styles.titleContainer}>
        <label className={styles.title}>{'Boards'}</label>
      </div>)
    }
    {
      boards.map(({
        id, short_uuid, name, color,
      }) => (
        <SidebarListItem
          key={id}
          id={id}
          uuid={short_uuid}
          text={name}
          onClick={() => onSelectBoard(short_uuid)}
          color={color || undefined}
          theme="board"
          isSelected={boardUUID === short_uuid}
          isOpen={isOpen}
          // TODO: Uncomment this section when Admissions supports CRUD operations over Boards! :)
          ActionButton={SidebarBoardItemOptions}
        />
      ))
    }
    {
      /*
      TODO: Uncomment this section when Admissions supports CRUD operations over Boards! :)

      isOpen && (
        <Fragment>
          <SidebarSeparator />
          <div className={styles.addBoardContainer}>
            <SidebarButton
              Icon={MdAddCircleOutline}
              title="Crear Board"
            />
          </div>
        </Fragment>
      )
      */
    }
  </Fragment>
);

/* BOARDS: [
  {
    id: 1,
    name: 'Solicitud de información - MAIA',
    color: '#226865',
    isSelected: true,
  },
  ...
] */

export default connect(
  state => ({
    boardUUID: selectors.getBoardUUIDParam(state),
    boards: selectors.getSelectedSchoolBoards(state),
    isOpen: selectors.isMainSidebarOpen(state),
  }),
  () => ({
    onSelectBoard: (boardUUID) => {
      history.push(`/dashboard/${boardUUID}`);
      setTimeout(() => window.location.reload(), 1);
    },
  }),
)(SidebarList);
