// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';
import { ReduxClickPopover, ReduxHoverPopover } from '../Closeable';
import * as closeableActions from '../../actions/closeable';

import styles from './popMenu.module.scss';


type PopMenuOptionPropTypes = {
  title: string,
  Icon?: ComponentType<*>,
  disabled?: boolean,
  isSelected?: boolean,
  onClick?: Function,
  close?: Function,
  tint?: string,
  href?: string,
  target?: string
};

type PopMenuPropTypes = {
  options: Array<PopMenuOptionPropTypes>,
  children: Node,
  width?: number,
};

type PopMenuOptionsPropTypes = {
  options: Array<PopMenuOptionPropTypes>,
  peId: ID_TYPE,
  width?: number,
};

type PopMenuOptionsContainerPropTypes = {
  children: Node,
  hasJustOneOption: boolean,
  width: number,
};

const PopMenuOptionDummy = ({
  title,
  Icon,
  disabled = false,
  isSelected = false,
  onClick,
  close,
  tint,
  href,
  target,
}: PopMenuOptionPropTypes) => (
  <a
    className={
      `
        ${styles.option}
        ${disabled ? styles.disabled : ''}
        ${isSelected ? styles.selected : ''}
        ${typeof styles[tint] !== 'undefined' ? styles[tint] : ''}
      `
    }
    onClick={(e) => {
      if (onClick) { onClick(e); }
      if (close) { close(e); }
    }}
    href={href}
    target={target}
  >
    {
      typeof Icon !== 'undefined' && (
        <span className={styles.iconContainer}>
          <Icon size={20} />
        </span>
      )
    }
    <span className={styles.title}>
      { title }
    </span>
  </a>
);

const PopMenuOption = connect(
  undefined,
  (dispatch, { peId }) => ({
    close() {
      dispatch(closeableActions.close(peId));
    },
  }),
)(PopMenuOptionDummy);

const PopMenuOptionsContainer = ({
  children,
  hasJustOneOption = false,
  width = 180,
}: PopMenuOptionsContainerPropTypes) => (
  <div
    className={
      `
        ${styles.optionsContainer}
        ${hasJustOneOption ? styles.oneOption : ''}
      `
    }
    style={{ width }}
  >
    { children }
  </div>
);

const PopMenuOptions = ({ options, peId, width = 180 }: PopMenuOptionsPropTypes) => (
  <PopMenuOptionsContainer hasJustOneOption={options && options.length === 1 } width={width}>
    { options.map(option => <PopMenuOption {...option} key={option.title} peId={peId} />) }
  </PopMenuOptionsContainer>
);

const PopMenu = ({
  options,
  children,
  width,
  ...props
}: PopMenuPropTypes) => (
  <ReduxClickPopover
    Content={lprops => <PopMenuOptions {...lprops} options={options} width={width} />}
    {...props}
  >
    { children }
  </ReduxClickPopover>
);

export const PopHoverMenu = ({
  options,
  children,
  width,
  ...props
}: PopMenuPropTypes) => (
  <ReduxHoverPopover
        Content={lprops => <PopMenuOptions {...lprops} options={options} width={width} />}
    {...props}
  >
    { children }
  </ReduxHoverPopover>
)

export default PopMenu;
