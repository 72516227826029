// @flow
import {
  takeLatest,
  select,
  call,
  put,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { get, throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';
import { SELECTOR_FILTER_CHANGED } from '../types/selector';
import * as selectors from '../reducers';
import * as selectorActions from '../actions/selector';


function* fetchRemoteTypeaheadResults(action) {
  const token = yield select(selectors.getToken);
  const { payload: { id, filter } } = action;
  const {
    remoteURL,
    debounce = 200,
    authenticate = true,
  } = yield select(selectors.getSelectorConfiguration, id);

  if (typeof remoteURL === 'string') {
    yield put(selectorActions.setLoading(id));
    yield call(delay, debounce);

    // Replace url placeholder
    const url = remoteURL.replace('{filter}', encodeURIComponent(filter));

    // Fetch results
    try {
      const { response, timeout } = yield race({
        response: call(
          get,
          {
            url,
            headers: authenticate ? { Authorization: `JWT ${token}` } : {},
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout(`fetchRemoteTypeaheadResults saga. URL: ${url}`);
      }

      const { byId, order } = action.meta.transformRemoteResults(response);

      yield put(selectorActions.setValues(id, byId, order));
    } catch (_error) {
      // Empty selector
      yield put(selectorActions.setValues(id, {}, []));
    }

    yield put(selectorActions.unsetLoading(id));
  }
}

export function* watchTypeaheadFilterChange(): Iterator<any> {
  yield takeLatest(
    SELECTOR_FILTER_CHANGED,
    fetchRemoteTypeaheadResults,
  );
}
