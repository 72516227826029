// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
import Button from '../Button';
import Label from '../Label';
import { RenderInput, RenderDatePicker } from '../FieldRenderer';
import Tabs from '../Tabs';

import styles from './phaseEditionForm.module.scss';

// Next Relevant Date/Lapse - Views
const DATE_VIEW = 0;
const LAPSE_VIEW = 1;

type PhaseEditionFormPropTypes = {
  // id: ID_TYPE,
  handleSubmit: Function,
  isLoading?: boolean
};

const PhaseEditionForm = ({ handleSubmit, isLoading = false }: PhaseEditionFormPropTypes) => {
  const [activeNextRelevantView, setActiveNextRelevantView] = useState(DATE_VIEW);
  const toggleNextRelevantTab = val => setActiveNextRelevantView(val);

  return (
    <form className={styles.phaseEditionForm} onSubmit={handleSubmit}>
      <Label>
        { 'Nombre de fase:' }
      </Label>
      <Field
        name="name"
        placeholder="Nombre de la fase"
        component={RenderInput}
        autoFocus
      />
      <Label>
        { "Seguimiento por defecto:" }
      </Label>
      <Tabs
        options={[
          {
            title: 'Fecha',
            active: activeNextRelevantView === DATE_VIEW,
            onClick: () => toggleNextRelevantTab(DATE_VIEW),
          },
          {
            title: 'Tiempo',
            active: activeNextRelevantView === LAPSE_VIEW,
            onClick: () => toggleNextRelevantTab(LAPSE_VIEW),
          },
        ]}
      />
      {
        activeNextRelevantView === LAPSE_VIEW && (
          <div className={styles.nextRelevantLapseContainer}>
            <span className={styles.lapseSubtitle}>
              { 'Después de:' }
            </span>
            <Field
              name="nextRelevantLapse"
              type="number"
              placeholder="ie. 5"
              component={RenderInput}
              autoFocus
            />
            <span className={styles.lapseDateUnit}>
              { 'Días' }
            </span>
          </div>
        )
      }
      {
        activeNextRelevantView === DATE_VIEW && (
          <Field
            name="nextRelevantDate"
            component={RenderDatePicker}
            hideClearIcon
          />
        )
      }
      <Button
        isLoading={isLoading}
        title="Guardar"
        type="submit"
        isFullWidth
        clearMargins
      />
    </form>
  );
};


const PhaseEditionReduxForm = reduxForm({
  form: 'phaseEditionForm',
  onSubmit(values, dispatch, { id }) {
    dispatch(actions.startUpdatingPhase(
      id,
      {
        ...values,
        next_relevant_date: values.nextRelevantDate,
        next_relevant_lapse: values.nextRelevantLapse,
      },
    ));
  },
  validate(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Requerido';
    }

    if (!values.nextRelevantDate) {
      errors.name = 'Requerido';
    }

    return errors;
  },
})(PhaseEditionForm);


export default connect(
  (state, { id }) => {
    const { name, next_relevant_date, next_relevant_lapse } = selectors.getPhase(state, id);

    return {
      isLoading: selectors.isPhaseUpdating(state, id),
      initialValues: {
        id,
        name,
        nextRelevantDate: next_relevant_date,
        nextRelevantLapse: next_relevant_lapse,
      },
    };
  },
  undefined,
)(PhaseEditionReduxForm);
