// @flow
import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { MdEdit, MdDelete } from 'react-icons/md';
import { Form, FieldArray, reset } from 'redux-form';
import isEmptyObject from 'is-empty-object';

import type { ClientFormPropTypes, ClientFormStateTypes } from './index';
import * as selectors from '../../reducers';
import * as actions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';
import ClientGenericDataForm from '../ClientGenericDataForm';
import ClientCalculatedData from '../ClientCalculatedData';
import ClientSchoolSpecificFormRenderer from '../ClientSchoolSpecificFormRenderer';
import ClientContactsForm from '../ClientContactsForm';
import { FullLoader } from '../Loader';
import { 
  clientReduxFormDecorator,
  clientFormDecorator,
  // DEFAULT_RELATIONSHIPS,
  FORM_ID,
} from './index';
import ClientEditionFormToolbar from './ClientEditionFormToolbar';
import ClientSchoolLogo from '../ClientSchoolLogo';
import HiddenPDFParagraph from '../HiddenPDFParagraph';
import HiddenPDFSignature from '../HiddenPDFSignature';
import ProfileImagePicker from '../ProfileImagePicker';
import GenericPopup from '../GenericPopup';

import styles from './clientForm.module.scss';


class ClientEditionFormDummy extends React.Component<ClientFormPropTypes, ClientFormStateTypes> {
  componentDidMount() {
    const { onLoad } = this.props;
    return onLoad && onLoad();
  }

  render() {
    const {
      canCreateClientWithoutContacts,
      handleSubmit,
      clientId,
      isLoading = false,
      isSaving = false,
      title,
      profilePicture,
      onMainChange,
      onEditClicked,
      onChangeProfilePicture,
      onRemoveProfilePicture,
      isEditing,
      closeContactsWarningPopup,
      boardId,
    } = this.props;

    return (
      <div
        className={
          `
            ${styles.clientForm}
            ${styles.fixed}
            ${styles.editForm}
          `
        }
        id="clientForm"
      >
        <Form className={styles.formLimits} onSubmit={handleSubmit}>
          <div className={styles.header}>
            <ProfileImagePicker
              value={profilePicture}
              onChange={onChangeProfilePicture}
              mode="compact"
              size="large"
              floatMenuOptions={[
                {
                  Icon: MdEdit,
                  title: "Cambiar",
                },
                {
                  Icon: MdDelete,
                  title: "Eliminar",
                  tint: 'error',
                  disabled: isEmptyObject(profilePicture),
                  onClick: () => onRemoveProfilePicture()
                }
              ]}
            />
            <h1>
              {title}
            </h1>
            <ClientSchoolLogo clientId={clientId} />
            <ClientEditionFormToolbar
              onPrintClicked={() => window.print()}
              onEditClicked={onEditClicked}
              onSubmit={handleSubmit}
              isEditing={isEditing}
              isLoading={isSaving}
            />  
          </div>
          <HiddenPDFIntro boardId={boardId} className={styles.pdfIntro} />
          {
            isLoading
              ? <FullLoader title="Cargando datos calculados del estudiante" tint="gray" />
              : (
                <ClientCalculatedData
                  clientId={clientId}
                />
              )
          }
          {
            isLoading
              ? <FullLoader title="Cargando datos generales del estudiante" tint="gray" />
              : (
                <ClientGenericDataForm
                  showPreviews={!isEditing}
                  onMainChange={onMainChange}
                />
              )
          }
          <hr />
          {
            isLoading
              ? <FullLoader title="Cargando datos adicionales del estudiante" tint="gray" />
              : <ClientSchoolSpecificFormRenderer showPreviews={!isEditing} />
          }
          <hr />
          <FieldArray
            name="contacts"
            component={ClientContactsForm}
            clientId={clientId}
            showPreviews={!isEditing}
            required={!canCreateClientWithoutContacts}
          />
          <hr />
          {/* <HiddenPDFEpilogue boardId={boardId} className={styles.pdfEpilogue} />
          <HiddenPDFSignatureFooter boardId={boardId} /> */}
        </Form>
        <GenericPopup
          id="VALIDATE_CONTACTS"
          title="Contactos"
          bodyText={i18n.t('canCreateWithoutContactsWarning')}
          onConfirm={closeContactsWarningPopup}
          hideCancel
          hideCloseButton
        />
      </div>
    );
  }
}

export default connect(
  (state, { clientId }) => {
    const client = selectors.getClient(state, clientId);
    const priorityRels = selectors.getRelationships(state)
      .filter(rel => rel.priority > 0)
      .map(rel => ({
        relationship: rel.name,
      }));
    
    const canCreateClientWithoutContacts =
      selectors.getCurrentBoard(state).min_contacts_size === 0 ||
      selectors.getCurrentBoard(state).max_contacts_size === 0;
    
    return {
      isLoading: selectors.isClientFetching(state, clientId),
      isSaving: selectors.isClientUpdating(state, clientId),
      oldClientPhase: (client || {}).phase,
      oldNextRelevantDate: (client || {}).next_relevant_date,
      isEditing: (client || {}).isEditing,
      canCreateClientWithoutContacts,
      initialValues: {
        contacts: priorityRels,
        /*
          DEFAULT_RELATIONSHIPS not been used anymore...
          contacts: DEFAULT_RELATIONSHIPS.map(rel => ({ // Default values
            relationship: rel.value,
          })),
        */
      },
    };
  },
  (dispatch, { clientId }) => ({
    onLoad() {
      dispatch(actions.startFetchingClient(clientId));
      dispatch(actions.startFetchingClientComments(clientId));
      dispatch(actions.startFetchingClientContacts(clientId));
    },
    onSubmit(values) {
      dispatch(actions.startUpdatingClient(clientId, values));
      // dispatch(close(CLIENT_EDITION_POPUP_ID));
    },
    onMainChange(values, oldClientPhase, oldNextRelevantDate) {
      dispatch(actions.startUpdatingClient(
        clientId,
        {
          ...values,
          oldClientPhase,
          oldNextRelevantDate,
        },
        true,
      ));
    },
    onEditClicked(isEditing) {
      if (isEditing) {
        dispatch(actions.completeEditingClientForm(clientId));
        dispatch(reset(FORM_ID));
      } else {
        dispatch(actions.selectClientForEdition(clientId));
        dispatch(actions.startEditingClientForm(clientId));
      }
    },
    onChangeProfilePicture(file) {
      dispatch(actions.startSetProfilePicture(clientId, file));
    },
    onRemoveProfilePicture(){
      dispatch(actions.removeProfilePicture(clientId));
    },
    closeContactsWarningPopup() {
      dispatch(closeableActions.close('VALIDATE_CONTACTS'));
    },
  }),
  (
    {
      oldClientPhase,
      oldNextRelevantDate,
      isEditing,
      ...stateProps
    },
    {
      onSubmit,
      onMainChange,
      onEditClicked,
      ...dispatchProps
    },
    ownProps,
  ) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSubmit(values) {
      onSubmit({
        ...values,
        oldClientPhase,
      });
    },
    onMainChange(values) {
      onMainChange(values, oldClientPhase, oldNextRelevantDate);
    },
    onEditClicked() {
      onEditClicked(isEditing);
    },
    isEditing,
  }),
)(clientFormDecorator(clientReduxFormDecorator(ClientEditionFormDummy)));

const HiddenPDFIntro = connect(
  (state, { boardId }) => {
    const { print_introduction } = selectors.getCurrentBoard(state, boardId);
    const i18nLocale = selectors.getLocaleParam(state, false);

    const defaultLocaleKey = Object.keys(print_introduction).length
      ? Object.keys(print_introduction)[0]
      : undefined;
    
    const printIntroductionHTMLText = (i18nLocale in print_introduction || defaultLocaleKey)
      ? print_introduction[i18nLocale] || print_introduction[defaultLocaleKey]
      : ''

    return { text: print_introduction ? { __html: `${printIntroductionHTMLText}` } : null };
  },
  undefined,
)(HiddenPDFParagraph);

export const HiddenPDFEpilogue = connect(
  (state, { boardId }) => {
    const { print_epilogue } = selectors.getCurrentBoard(state, boardId);
    const i18nLocale = selectors.getLocaleParam(state, false);

    const defaultLocaleKey = Object.keys(print_epilogue).length
      ? Object.keys(print_epilogue)[0]
      : undefined;
    
    const printEpilogueHTMLText = (i18nLocale in print_epilogue || defaultLocaleKey)
      ? print_epilogue[i18nLocale] || print_epilogue[defaultLocaleKey]
      : ''
    
    return { text: print_epilogue ? { __html: `${printEpilogueHTMLText}` } : null };
  },
  undefined,
)(HiddenPDFParagraph);

export const HiddenPDFSignatureFooter = connect(
  (state, { boardId }) => {
    // This is the only value that depends on board school...
    const { use_print_signature } = selectors.getCurrentBoard(state, boardId).school;
    return { show: use_print_signature };
  },
  undefined,
)(({ show }) => (
  show ? (
    <div className={styles.hiddenPDFSignatureFooter}>
      <HiddenPDFSignature text="Nombre del Encargado: " />
      <HiddenPDFSignature text="Firma del Encargado: " />
    </div>
  ) : null
));
