// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdKeyboardArrowDown, MdDelete } from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/comments';
import * as clientActions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';
import Button from '../Button';
import PopMenu from '../PopMenu';
import GenericPopup from '../GenericPopup';


type CommentOptionsPropTypes = {
  id: ID_TYPE,
  onDeleteClicked: Function,
  removeComment: Function,
  closeRemoveCommentPopup: Function,
};

const CommentOptions = ({
  id,
  onDeleteClicked,
  removeComment,
  closeRemoveCommentPopup,
}: CommentOptionsPropTypes) => (
  <>
    <PopMenu
      id={`clientMenu-${id}`}
      vertical="below"
      horizontal="left"
      tipPosition="center"
      offset={60}
      isRound
      options={[
        {
          Icon: MdDelete,
          title: 'Eliminar',
          tint: 'error',
          onClick: onDeleteClicked,
        },
      ]}
    >
      <Button
        size="medium"
        Icon={MdKeyboardArrowDown}
        tint="mediumGray"
        clearMargins
        isTransparent
        isOutlined
      />
    </PopMenu>
    <GenericPopup
      id={`DELETE_COMMENT_${id}`}
      title="Eliminar comentario"
      bodyText="¿Está seguro que quiere eliminar el comentario?"
      onConfirm={removeComment}
      onCancel={closeRemoveCommentPopup}
      hideCloseButton
    />
  </>
);

export default connect(
  (state, { id }) => ({
    clientId: selectors.getComment(state, id).object_pk,
  }),
  (dispatch, { id }) => ({
    onDeleteClicked() {
      dispatch(closeableActions.open(`DELETE_COMMENT_${id}`));
    },
    closeRemoveCommentPopup() {
      dispatch(closeableActions.close(`DELETE_COMMENT_${id}`));
    },
    removeComment(clientId: ID_TYPE) {
      dispatch(clientActions.removeClientComment(clientId, id));
      dispatch(actions.removeComment(id));
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    removeComment() {
      dispatchProps.removeComment(stateProps.clientId)
    },
  })
)(CommentOptions);
