// @flow
import type {
  SELECTOR_REGISTERED_TYPE,
  SELECTOR_ATTACHED_TYPE,
  SELECTOR_ACTIVATED_TYPE,
  SELECTOR_DEACTIVATED_TYPE,
  SELECTOR_VALUES_SET_TYPE,
  SELECTOR_SELECTED_CHANGED_TYPE,
  SELECTOR_SELECTED_CHANGED_UP_TYPE,
  SELECTOR_SELECTED_CHANGED_DOWN_TYPE,
  SELECTOR_FILTER_CHANGED_TYPE,
  SELECTOR_LOADING_SET_TYPE,
  SELECTOR_LOADING_UNSET_TYPE,
  // SELECTOR_CONFIGURATION_UPDATED_TYPE,
  // SELECTOR_CONFIGURATION_RESET_TYPE
} from '../types/selector';
import * as types from '../types/selector';

export const register = (
  id: number,
  {
    isActive,
    byId,
    originalOrder = [],
    selected,
    filter,
    configuration = {},
  }: {
    isActive: boolean,
    byId: { [ string ]: Object },
    originalOrder: Array<string>,
    selected: number,
    filter: string,
    configuration: Object
  },
): SELECTOR_REGISTERED_TYPE => ({
  type: types.SELECTOR_REGISTERED,
  payload: {
    id,
    isActive,
    byId,
    originalOrder,
    selected,
    filter,
    configuration,
  },
});

export const attach = (id: string): SELECTOR_ATTACHED_TYPE => ({
  type: types.SELECTOR_ATTACHED,
  payload: { id },
});

export const activate = (id: string): SELECTOR_ACTIVATED_TYPE => ({
  type: types.SELECTOR_ACTIVATED,
  payload: { id },
});

export const deactivate = (id: string): SELECTOR_DEACTIVATED_TYPE => ({
  type: types.SELECTOR_DEACTIVATED,
  payload: { id },
});

export const setValues = (
  id: string,
  byId: { [ string ]: Object },
  order: Array<string>,
): SELECTOR_VALUES_SET_TYPE => ({
  type: types.SELECTOR_VALUES_SET,
  payload: {
    id,
    byId,
    order,
  },
});

export const changeSelected = (id: string, selected: number): SELECTOR_SELECTED_CHANGED_TYPE => ({
  type: types.SELECTOR_SELECTED_CHANGED,
  payload: {
    id,
    selected,
  },
});

export const changeSelectedUp = (id: string): SELECTOR_SELECTED_CHANGED_UP_TYPE => ({
  type: types.SELECTOR_SELECTED_CHANGED_UP,
  payload: { id },
});

export const changeSelectedDown = (id: string): SELECTOR_SELECTED_CHANGED_DOWN_TYPE => ({
  type: types.SELECTOR_SELECTED_CHANGED_DOWN,
  payload: { id },
});

export const changeFilter = (id: string, filter: string): SELECTOR_FILTER_CHANGED_TYPE => ({
  type: types.SELECTOR_FILTER_CHANGED,
  payload: {
    id,
    filter,
  },
});

export const setLoading = (id: string): SELECTOR_LOADING_SET_TYPE => ({
  type: types.SELECTOR_LOADING_SET,
  payload: { id },
});

export const unsetLoading = (id: string): SELECTOR_LOADING_UNSET_TYPE => ({
  type: types.SELECTOR_LOADING_UNSET,
  payload: { id },
});
