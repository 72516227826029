import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  MdNearMe,
  MdSearch,
} from 'react-icons/md';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { USER_ROLES } from '../../settings';
import { RenderInput, RenderSelect, RenderMultiSelect } from '../FieldRenderer';
import Button from '../Button';
import Select from '../Select';
import Input from '../Input';
import UserItem from './UserItem';

import { validate } from './validation';
import * as actions from '../../actions/users';
import * as selectors from '../../reducers';

import styles from './usersManager.module.scss';

type UsersManagerProps = {
  users: any[],
  onSendInvitation: Function,
  selectedRole: any,
  roles: any[],
  isSending: boolean,
  schools: any[],
  invitationError?: any,
  onLoad: Function,
  onSelectRole: Function,
  onChangeUserRole: (user: any, role: any) => any,
  onDisableUserAccount: (user: any) => any,
}

export const ID_FORM_USER_MANAGER = 'sendInvitationForm';

const UsersManager = ({
  users = [],
  schools = [],
  invitationError,
  onLoad,
  isSending,
  onChangeRole,
  onChangeUserStatus,
  handleSubmit,
  // onSendInvitation, 
  // selectedRole,
  // roles,
  // onSelectRole,
}: UsersManagerProps) => {
  useEffect(() => {
    onLoad();
  }, []);

  const [textFilter, setTextFilter] = useState('');
  const [roleFilter, setRolFilter] = useState(null);

  const schoolsValues = schools.map(el => ({ label: el.name, value: el.id }))

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>
        {'Manejar Usuarios'}
      </div>
      <div>
        <form onSubmit={handleSubmit} className={styles.userInvitationFormContainer}>
          <Grid>
            <Row bottom="xs">
              <Col xs={4}>
                <Field
                  name="first_name"
                  placeholder="Nombre"
                  label="Nombre"
                  component={RenderInput}
                />
              </Col>
              <Col xs={4}>
                <Field
                  name="email"
                  placeholder="Correo"
                  label="Correo"
                  component={RenderInput}
                />
              </Col>
              <Col xs={4}>
                <Field
                  name="is_admin"
                  label="Role"
                  component={RenderSelect}
                  // placeholder="Rol"
                  options={USER_ROLES}
                  size="large"
                  className={styles.selectTranslate}
                />
              </Col>
            </Row>
            <Row bottom='xs' className={styles.sendInvitationButton}>
              <Col xs={4}>
                <Field
                  name="schools"
                  label="Escuelas"
                  component={RenderMultiSelect}
                  options={schoolsValues}
                />
              </Col>
              <Col xs={4}>
                <Button
                  Icon={MdNearMe}
                  iconPosition="left"
                  title="Enviar Invitacion"
                  isFullWidth
                  type="submit"
                  clearMargins
                  isLoading={isSending}
                />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
      {/* Users list */}
      <hr className={styles.divider} />
      {/* Error alert message */}
      {
        invitationError && invitationError.message && (
          <div className={styles.alert}>
            { /* eslint-disable-next-line react/no-danger */ }
            <p dangerouslySetInnerHTML={{ __html: invitationError.message }} />
          </div>
        )
      }
      {/* Filters */}
      <div className={styles.usersFilters}>
        <Select
          options={USER_ROLES}
          placeholder="Rol"
          value={roleFilter}
          onChange={setRolFilter}
          className={styles.filterInput}
        />
        <Input
          value={textFilter}
          onChange={event => setTextFilter(event.target.value)}
          className={styles.filterInput}
          Icon={MdSearch}
          iconPosition="right"
        />
      </div>
      <div className={styles.usersList}>
        {
          users
            .filter((user) => {
              if (roleFilter == null && textFilter === '') return true;
              if (
                (user.first_name.includes(textFilter)
                || user.last_name.includes(textFilter)
                || user.email.includes(textFilter))
                && roleFilter === user.is_admin
              ) return true;
              return false;
            })
            .map(user => (
              <UserItem
                key={user.id}
                email={user.email}
                name={`${user.first_name} ${user.last_name}`}
                photo={user.profile_picture}
                role={user.is_admin}
                isDisabled={!user.is_active}
                isConfirmed={user.isConfirmed}
                onChangeRole={value => onChangeRole(user.id, value)}
                onDisableStatusChangeClick={() => onChangeUserStatus(user.id, !user.is_active)}
              />
            ))
        }
      </div>
    </div>
  );
};

export default connect(
  state => ({
    users: selectors.getUsers(state),
    schools: selectors.getUserSchools(state),
    isSending: selectors.getIsSendingInvitation(state),
    invitationError: (selectors.getSendInvitationError(state) || {}).data,
    initialValues: {
      is_admin: true,
    }
  }),
  dispatch => ({
    onLoad() {
      dispatch(actions.usersFetchStarted());
    },
    onChangeRole(id, value) {
      dispatch(actions.userChangeRoleStarted(id, value));
    },
    onChangeUserStatus(id, value) {
      dispatch(actions.userChangeDisabledStatusStarted(id, value));
    }
  }),
)(reduxForm({
  validate,
  form: ID_FORM_USER_MANAGER,
  onSubmit: (values, dispatch) => dispatch(
    actions.sendInvitationStarted(
      values.first_name,
      values.email,
      values.is_admin,
      values.schools,
    )
  ),
})(UsersManager));
