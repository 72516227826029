// @flow
import type { Node } from 'react';
import React, { Fragment } from 'react';

import styles from './loginFormSubtitle.module.scss';

type LoginFormSubtitlePropTypes = {
  children?: Node
};

const LoginFormSubtitle = ({
  children,
}: LoginFormSubtitlePropTypes) => (
  <Fragment>
    <h2 className={
      `
        ${styles.loginformsubtitle}
      `
    }
    >
      { children }
    </h2>
  </Fragment>
);


export default LoginFormSubtitle;
