// @flow
import React from 'react';

import styles from './hiddenPDFParagraph.module.scss';


type HiddenPDFParagraphPropTypes = {
  text: string,
  className?: string,
};

const HiddenPDFParagraph = ({ text, className = '' }: HiddenPDFParagraphPropTypes) => (
  <div
    className={
      `
        ${styles.hiddenPDFParagraph}
        ${className}
      `
    }
  >
    { /* eslint-disable-next-line react/no-danger */ }
    <p dangerouslySetInnerHTML={text} />
  </div>
)

export default HiddenPDFParagraph;
