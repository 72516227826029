// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdLock,
} from 'react-icons/md';
import queryString from 'query-string';
import { Field, reduxForm } from 'redux-form';

import Button from '../Button';
import { RenderInput } from '../FieldRenderer';
import * as selectors from '../../reducers';
import * as actions from '../../actions/users';


type LoginFormPropTypes = {
  handleSubmit: Function,
  isLoading: boolean
};

const triggerRecoverPassword = (values, dispatch) => {
  const params = queryString.parse(window.location.search);

  return dispatch(actions.startRecoverPassword(
    params.user, 
    params.token, 
    values.password))
};

const validate = (values) => {
  const errors = {};

  if (!values.password) { errors.password = 'Requerido'; }
  if (values.password && !values.confirm_password) {
    errors.confirm_password = 'Requerido';
  }
  if (
    values.password
    && values.confirm_password
    && values.password != values.confirm_password) {
    errors.confirm_password = 'La contraseña no coincide'
  }

  return errors;
};

const RecoverPasswordForm = ({ handleSubmit, isLoading = false }: LoginFormPropTypes) => {

  return (
    <form onSubmit={handleSubmit}>
      <Field
        type="password"
        name="password"
        placeholder="Nueva contraseña"
        errorClassName='loginFit'
        props={{ Icon: MdLock }}
        component={RenderInput}
      />
      <Field
        type="password"
        name="confirm_password"
        placeholder="Nueva contraseña"
        errorClassName='loginFit'
        props={{ Icon: MdLock }}
        component={RenderInput}
      />
      <Button
        isLoading={isLoading}
        title="Cambiar contraseña"
        isFullWidth
        type="submit"
      />
    </form>
  )
};


export default connect(
  state => ({
    isLoading: selectors.isLoginLoading(state),
  }),
  undefined,
)(reduxForm({
  form: 'recoverPasswordForm',
  onSubmit: triggerRecoverPassword,
  validate,
})(RecoverPasswordForm));
