import type { ERROR_TYPE } from '../types/common';

export type FILES_FETCH_STARTED_TYPE = {
  type: 'FILES_FETCH_STARTED',
  payload: ID_TYPE,
}
export const FILES_FETCH_STARTED = 'FILES_FETCH_STARTED';

export type FILES_FETCH_FAILED_TYPE = {
  type: 'FILES_FETCH_FAILED',
  paylaod: ERROR_TYPE,
}
export const FILES_FETCH_FAILED = 'FILES_FETCH_FAILED';

export type FILES_FETCH_SUCCEED_TYPE = {
  type: 'FILES_FETCH_SUCCEED',
  paylaod: {
    id: ID_TYPE,
    files: any,
  },
}
export const FILES_FETCH_SUCCEED = 'FILES_FETCH_SUCCEED';

export type FILE_UPLOAD_STARTED_TYPE = {
  type: 'FILE_UPLOAD_STARTED',
  payload: Object,
}
export const FILE_UPLOAD_STARTED = 'FILE_UPLOAD_STARTED';

export type FILE_UPLOAD_FAILED_TYPE = {
  type: 'FILE_UPLOAD_FAILED',
  payload: ERROR_TYPE,
}
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';

export type FILE_UPLOAD_SUCCEED_TYPE = {
  type: 'FILE_UPLOAD_SUCCEED',
}
export const FILE_UPLOAD_SUCCEED = 'FILE_UPLOAD_SUCCEED';

export type FILE_DELETE_STARTED_TYPE = {
  type: 'FILE_DELETE_STARTED',
  payload: string,
}
export const FILE_DELETE_STARTED = 'FILE_DELETE_STARTED';

export type FILE_UPDATE_UPLOAD_PROGRESS_TYPE = {
  type: 'FILE_UPDATE_UPLOAD_PROGRESS',
  payload: {
    UUID: string,
    progress: any,
    fieldName: string,
  },
}
export const FILE_UPDATE_UPLOAD_PROGRESS = 'FILE_UPDATE_UPLOAD_PROGRESS';
