// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';

import {
  RenderInput,
  RenderProfileImagePicker,
} from '../FieldRenderer';
import { SETTINGS_POPUP_ID } from '../SettingsPopup';
import Button from '../Button';
import styles from './userSettings.module.scss';

import * as selectors from '../../reducers';
import * as actions from '../../actions/users';
import * as closableActions from '../../actions/closeable';


type UserSettingsPropTypes = {
  handleSubmit: Function,
  isLoading: boolean,
  onClose: Function,
};

const validate = (values) => {
  const errors = {};
  if (!values.first_name) { errors.first_name = 'Requerido'; }
  if (!values.last_name) { errors.last_name = 'Requerido'; }

  return errors;
};

const UserSettings = ({
  handleSubmit,
  isLoading = false,
  onClose,
}: UserSettingsPropTypes) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {"Configuración personal"}
    </div>
    <form onSubmit={handleSubmit}>
      <Grid>
        <Row className={styles.section}>
          <Col xs={4}>
            <Field
              label="Nombre"
              name="first_name"
              placeholder="Juan"
              component={RenderInput}
            />
          </Col>
          <Col xs={4}>
            <Field
              label="Apellidos"
              name="last_name"
              placeholder="González"
              component={RenderInput}
            />
          </Col>
          <Col xs={4}>
            <Field
              label="Teléfono"
              name="phone"
              placeholder="0000-0000"
              component={RenderInput}
            />
          </Col>
        </Row>
        <Row className={styles.section}>
          <Col xs={12}>
            <Field
              name="profile_picture"
              component={RenderProfileImagePicker}
            />
          </Col>
        </Row>
        <Row className={styles.section}>
          <Col xs={3}>
            <Button
              title="Cancelar"
              tint="gray"
              isFullWidth
              onClick={() => onClose()}
            />
          </Col>
          <Col xs={3}>
            <Button
              isLoading={isLoading}
              title="Guardar Cambios"
              type="submit"
              isFullWidth
            />
          </Col>
        </Row>
      </Grid>
    </form>
  </div>
);

export default connect(
  state => {
    const {
      first_name,
      last_name,
      phone,
      profile_picture,
    } = selectors.getCurrentLoggedUser(state) || {};
    return {
      isLoading: selectors.isUpdatingCurrentLoggedUser(state),
      initialValues: {
        first_name,
        last_name,
        phone,
        profile_picture
      }
    }
  },
  dispatch => ({
    onClose() {
      dispatch(closableActions.close(SETTINGS_POPUP_ID));
    }
  })
)(reduxForm({
  form: 'UserSettingsForm',
  onSubmit: (values, dispatch) => dispatch(
    actions.startUpdateCurrentUser(values),
  ),
  validate,
})(UserSettings));
