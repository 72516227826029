// @flow
import {
  select,
  race,
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import axios from 'axios';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import { arrayOfBoards } from '../api/schemas/boards';

import { fibonacciBackoff } from '../lib/retry';
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';

import * as types from '../types/boards';
import * as selectors from '../reducers';
import * as actions from '../actions/boards';
import * as closeableActions from '../actions/closeable';
import * as cyclesActions from '../actions/cycles';
import * as levelsActions from '../actions/levels';
import * as phasesActions from '../actions/phases';
import * as relationshipsActions from '../actions/relationships';
import * as clientFormActions from '../actions/clientForm';

import { Boards, api } from '../api';
import { BOARD_SETTINGS_POPUP_ID } from '../components/BoardSettingsPopup';

function* fetchBoards(action) {
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUserId);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          name: 'Colegio Test I',
          colors: {
            base: '#4959a4',
          },
        },
        {
          id: 2,
          name: 'Colegio Test II',
          colors: {
            base: '#4959a4',
          },
        },
        {
          id: 1,
          name: 'Colegio Test III',
          colors: {
            base: '#4959a4',
          },
        },
      ],
    },
  };

  try {
    const { boardsResponse, timeout } = yield race({
      boardsResponse: call(
        [Boards.custom, 'myBoards'],
        {
          token,
          id: user,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchBoards saga');
    }

    const {
      entities: { boards },
      result,
    } = normalize(boardsResponse, arrayOfBoards);

    // Set color field as value
    const dBoards = {};
    Object.keys(boards).forEach((cid) => {
      const lBoard = boards[cid];
      dBoards[cid] = {
        ...lBoard,
        color: lBoard.colors ? lBoard.colors.base || '' : '',
      };
    });

    // Register
    yield put(actions.completeFetchingBoards(
      dBoards,
      result,
    ));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingBoards({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchBoard(action) {
  const { payload } = action;
  // This `id` would be the board PK or the board short UUID
  let id = payload;
  if (!id || id === undefined) {
    id = yield(select(selectors.getBoardUUIDParam));
  }
  // Start fetching relationships
  yield put(relationshipsActions.startFetchingRelationships(id));
  // Start fetching phases
  yield put(phasesActions.startFetchingPhases(id));

  // Start fetching cycles
  yield put(cyclesActions.startFetchingCycles(id));

  // Start fetching levels
  yield put(levelsActions.startFetchingLevels(id));

  // Start fetching form descriptions
  yield put(clientFormActions.startFetchingClientForm(id));

  const mock = {
    delay: 2000,
    response: {
      statusCode: 200,
      body: {
        id: 1,
        name: 'Colegio Monte María',
        form_header_picture: {
          url: 'http://www.colegiomontemaria.edu.gt/wp-content/uploads/2018/03/Web-Monte-Maria-CORRECCIONES-05_3-CS5_03.jpg',
        },
        introduction: 'Aliqua officia nulla eiusmod commodo ad aliquip. Reprehenderit aute ut cillum ipsum deserunt ex Lorem ea. Dolore occaecat pariatur incididunt cillum ea qui consequat. Pariatur dolor aliquip commodo esse do amet. Ea velit aute incididunt ullamco occaecat veniam. Qui velit labore esse do et anim ex sit velit fugiat. Ad sit in sint esse excepteur non cupidatat. Irure Lorem non labore aliquip duis irure proident aliquip veniam velit veniam pariatur. Eiusmod id duis consectetur eiusmod sunt nisi.',
        colors: {
          base: '#4959a4',
        },
        color: '#4959a4',
      },
    },
  };

  try {
    const response = yield call(
      fibonacciBackoff,
      [Boards, 'detail'],
      { id: payload, mock },
    );

    const board = {
      ...response,
      color: response.colors ? response.colors.base : ''
    }

    // Register board
    yield put(actions.completeFetchingBoard(board));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingBoard({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* updateBoard(action) {
  const { payload } = action;
  const { id, color, form_header_picture } = payload;
  const token = yield select(selectors.getToken);

  // MOCK
  const lBoard = yield select(selectors.getCurrentBoard, payload.id);
  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: lBoard,
    },
  };
  
  try {
    // Step 1: Update board
    const updateBoard = {
      ...payload,
      colors: { base: color },
    }
    
    const { timeout } = yield race({
      response: call(
        [Boards, 'update'],
        {
          token,
          id: id,
          data: updateBoard,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('updateBoard saga');
    }

    // Step 2: Update board form header img (if uploaded a new one)
    const { UUID, file } = form_header_picture || {};
    if (!UUID && file) {
      const headers = {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      };
      const data = new FormData();
      data.append('file', file, file.name);

      axios({
        method: 'post',
        url: api.getURL(`boards/${id}/form-header-picture`),
        data,
        headers,
      })
        .then((res) => res.data)
        .catch((error) => { console.log(error) });
    }

    // Register
    yield put(actions.completeUpdatingBoard(payload.id));

    // Close popover    
    yield put(closeableActions.close(BOARD_SETTINGS_POPUP_ID));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failUpdatingBoard({
      object_id: id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchBoardsFetch(): Iterator<any> {
  yield takeEvery(
    types.BOARDS_FETCH_STARTED,
    fetchBoards,
  );
}

export function* watchBoardFetchStart(): Iterator<any> {
  yield takeEvery(
    types.BOARD_FETCH_STARTED,
    fetchBoard,
  );
}

export function* watchBoardUpdate(): Iterator<any> {
  yield takeEvery(
    types.BOARD_UPDATE_STARTED,
    updateBoard,
  );
}
