// @flow
import type { ERROR_TYPE, ID_TYPE } from './common';
// import type { CLIENT_TYPE } from './clients';

export type SORTING_METHOD_TYPE = {
  key: string,
  direction?: string
};

export type PHASE_TYPE = {
  id: ID_TYPE,
  ordinal: number,
  name: string,
  clients: Array<ID_TYPE>,
  school: ID_TYPE,
  next_relevant_date: Date,
  next_relevant_lapse: number,
  isConfirmed?: boolean,
  sortingMethod?: SORTING_METHOD_TYPE,
};

export type SORTING_METHOD_UPDATED_TYPE = {
  type: 'SORTING_METHOD_UPDATED',
  payload: {
    id: string,
    sortingMethod: SORTING_METHOD_TYPE,
  }
}
export const SORTING_METHOD_UPDATED = 'SORTING_METHOD_UPDATED';

export type PHASE_ADD_STARTED_TYPE = {
  type: 'PHASE_ADD_STARTED',
  payload: PHASE_TYPE
};
export const PHASE_ADD_STARTED = 'PHASE_ADD_STARTED';

export type PHASE_ADD_COMPLETED_TYPE = {
  type: 'PHASE_ADD_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE
  }
};
export const PHASE_ADD_COMPLETED = 'PHASE_ADD_COMPLETED';

export type PHASE_ADD_FAILED_TYPE = {
  type: 'PHASE_ADD_FAILED',
  payload: ERROR_TYPE
};
export const PHASE_ADD_FAILED = 'PHASE_ADD_FAILED';

export type PHASE_CLIENTS_FETCH_STARTED_TYPE = {
  type: 'PHASE_CLIENTS_FETCH_STARTED',
  payload: ID_TYPE
};
export const PHASE_CLIENTS_FETCH_STARTED = 'PHASE_CLIENTS_FETCH_STARTED';

export type PHASE_CLIENTS_FETCH_COMPLETED_TYPE = {
  type: 'PHASE_CLIENTS_FETCH_COMPLETED',
  payload: {
    id: ID_TYPE,
    clients: Array<ID_TYPE>
  }
};
export const PHASE_CLIENTS_FETCH_COMPLETED = 'PHASE_CLIENTS_FETCH_COMPLETED';

export type PHASE_CLIENTS_FETCH_FAILED_TYPE = {
  type: 'PHASE_CLIENTS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const PHASE_CLIENTS_FETCH_FAILED = 'PHASE_CLIENTS_FETCH_FAILED';

export type PHASES_FETCH_STARTED_TYPE = {
  type: 'PHASES_FETCH_STARTED',
  payload: ID_TYPE
};
export const PHASES_FETCH_STARTED = 'PHASES_FETCH_STARTED';

export type PHASES_FETCH_COMPLETED_TYPE = {
  type: 'PHASES_FETCH_COMPLETED',
  payload: {
    entities: { [ID_TYPE]: PHASE_TYPE },
    order: Array<ID_TYPE>
  }
};
export const PHASES_FETCH_COMPLETED = 'PHASES_FETCH_COMPLETED';

export type PHASES_FETCH_FAILED_TYPE = {
  type: 'PHASES_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const PHASES_FETCH_FAILED = 'PHASES_FETCH_FAILED';

export type PHASE_CLIENTS_ADDED_TYPE = {
  type: 'PHASE_CLIENTS_ADDED',
  payload: {
    id: ID_TYPE,
    key: 'clients',
    // entities: { [number | string]: CLIENT_TYPE },
    order: Array<ID_TYPE>
  }
};
export const PHASE_CLIENTS_ADDED = 'PHASE_CLIENTS_ADDED';

export type PHASE_CLIENTS_REMOVED_TYPE = {
  type: 'PHASE_CLIENTS_REMOVED',
  payload: {
    id: ID_TYPE,
    key: 'clients',
    // entities: { [number | string]: CLIENT_TYPE },
    order: Array<ID_TYPE>
  }
};
export const PHASE_CLIENTS_REMOVED = 'PHASE_CLIENTS_REMOVED';

export type PHASE_CLIENTS_UPDATED_TYPE = {
  type: 'PHASE_CLIENTS_UPDATED',
  payload: {
    key: 'clients',
    id: ID_TYPE,
    oldValues: Array<ID_TYPE>,
    newValues: Array<ID_TYPE>
  }
}
export const PHASE_CLIENTS_UPDATED = 'PHASE_CLIENTS_UPDATED';

export type PHASE_REMOVED_TYPE = {
  type: 'PHASE_REMOVED',
  payload: ID_TYPE
};
export const PHASE_REMOVED = 'PHASE_REMOVED';

export type PHASE_UPDATE_STARTED_TYPE = {
  type: 'PHASE_UPDATE_STARTED',
  payload: {
    id: ID_TYPE
  }
};
export const PHASE_UPDATE_STARTED = 'PHASE_UPDATE_STARTED';

export type PHASE_UPDATE_COMPLETED_TYPE = {
  type: 'PHASE_UPDATE_COMPLETED',
  payload: ID_TYPE
};
export const PHASE_UPDATE_COMPLETED = 'PHASE_UPDATE_COMPLETED';

export type PHASE_UPDATE_FAILED_TYPE = {
  type: 'PHASE_UPDATE_FAILED',
  payload: ERROR_TYPE
};
export const PHASE_UPDATE_FAILED = 'PHASE_UPDATE_FAILED';

export type PHASE_REPORT_GENERATED_START_TYPE = {
  type: 'PHASE_REPORT_GENERATED_START',
  payload: ID_TYPE
};
export const PHASE_REPORT_GENERATED_START = 'PHASE_REPORT_GENERATED_START';

export type PHASE_REPORT_GENERATED_COMPLETE_TYPE = {
  type: 'PHASE_REPORT_GENERATED_COMPLETE',
  payload: ID_TYPE
};
export const PHASE_REPORT_GENERATED_COMPLETE = 'PHASE_REPORT_GENERATED_COMPLETE';

export type PHASE_REPORT_GENERATED_FAIL_TYPE = {
  type: 'PHASE_REPORT_GENERATED_FAIL',
  payload: ERROR_TYPE
};
export const PHASE_REPORT_GENERATED_FAIL = 'PHASE_REPORT_GENERATED_FAIL';

export type PHASE_ALL_CLIENTS_ARCHIVED_TYPE = {
  type: 'PHASE_ALL_CLIENTS_ARCHIVED',
  payload: ID_TYPE
};
export const PHASE_ALL_CLIENTS_ARCHIVED = 'PHASE_ALL_CLIENTS_ARCHIVED';

export type PHASE_FILTER_UPDATED_TYPE = {
  type: 'PHASE_FILTER_UPDATED',
  payload: {
    id: ID_TYPE,
    filter: string,
  }
};
export const PHASE_FILTER_UPDATED = 'PHASE_FILTER_UPDATED';

export type SORT_PHASE_STARTED_TYPE = {
  type: 'SORT_PHASE_STARTED',
  payload: {
    oldIndex: number,
    newIndex: number,
  }
};
export const SORT_PHASE_STARTED = 'SORT_PHASE_STARTED';

export type SORT_PHASE_SUCCEED_TYPE = {
  type: 'SORT_PHASE_SUCCEED',
  payload: PHASE_TYPE
};
export const SORT_PHASE_SUCCEED = 'SORT_PHASE_SUCCEED';

export type SORT_PHASE_FAILED_TYPE = {
  type: 'SORT_PHASE_FAILED',
  payload: ERROR_TYPE
};
export const SORT_PHASE_FAILED = 'SORT_PHASE_FAILED';

export type PHASE_SELECTED_TYPE = {
  type: 'PHASE_SELECTED',
  payload: ID_TYPE,
};
export const PHASE_SELECTED = 'PHASE_SELECTED';

export type PHASE_UNSELECTED_TYPE = {
  type: 'PHASE_UNSELECTED',
};
export const PHASE_UNSELECTED = 'PHASE_UNSELECTED';

export type FETCH_PHASE_STARTED_TYPE = {
  type: 'FETCH_PHASE_STARTED',
  payload: ID_TYPE,
};
export const FETCH_PHASE_STARTED = 'FETCH_PHASE_STARTED';

export type FETCH_PHASE_COMPLETED_TYPE = {
  type: 'FETCH_PHASE_COMPLETED',
  payload: PHASE_TYPE,
};
export const FETCH_PHASE_COMPLETED = 'FETCH_PHASE_COMPLETED';

export type FETCH_PHASE_FAILED_TYPE = {
  type: 'FETCH_PHASE_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_PHASE_FAILED = 'FETCH_PHASE_FAILED';


export type PHASES_ACTION_TYPE =
  | PHASE_ADD_STARTED_TYPE
  | PHASE_ADD_COMPLETED_TYPE
  | PHASE_ADD_FAILED_TYPE
  | PHASE_CLIENTS_FETCH_STARTED_TYPE
  | PHASE_CLIENTS_FETCH_COMPLETED_TYPE
  | PHASE_CLIENTS_FETCH_FAILED_TYPE
  | PHASES_FETCH_STARTED_TYPE
  | PHASES_FETCH_COMPLETED_TYPE
  | PHASES_FETCH_FAILED_TYPE
  | PHASE_CLIENTS_ADDED_TYPE
  | PHASE_CLIENTS_REMOVED_TYPE
  | PHASE_CLIENTS_UPDATED_TYPE
  | PHASE_REMOVED_TYPE
  | PHASE_UPDATE_STARTED_TYPE
  | PHASE_UPDATE_COMPLETED_TYPE
  | PHASE_UPDATE_FAILED_TYPE
  | PHASE_REPORT_GENERATED_START_TYPE
  | PHASE_REPORT_GENERATED_COMPLETE_TYPE
  | PHASE_REPORT_GENERATED_FAIL_TYPE
  | PHASE_ALL_CLIENTS_ARCHIVED_TYPE
  | PHASE_FILTER_UPDATED_TYPE
  | SORT_PHASE_STARTED_TYPE
  | SORT_PHASE_SUCCEED_TYPE
  | SORT_PHASE_FAILED_TYPE
  | PHASE_SELECTED_TYPE
  | PHASE_UNSELECTED_TYPE
  | FETCH_PHASE_STARTED_TYPE
  | FETCH_PHASE_COMPLETED_TYPE
  | FETCH_PHASE_FAILED_TYPE;
