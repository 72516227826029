// @flow
import React from 'react';
import { Row, Grid } from 'react-flexbox-grid';
import { MdClose } from 'react-icons/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import PhaseSettingsPopupHeader from '../PhaseSettingsPopupHeader';
import PhaseSettingsContent from '../PhaseSettingsPopupContent';

import styles from './phaseSettingsPopup.module.scss';

export const PHASE_SETTINGS_POPUP_ID = 'phaseSettingsPopup';

type PhaseSettingsPopupPropTypes = {};

const PhaseSettingsPopup = ({}: PhaseSettingsPopupPropTypes) => (
  <Popup id={PHASE_SETTINGS_POPUP_ID}>
    <PopupContainer
      tint="lightGray"
      isFullscreen
    >
      <PopupCloseButton
        tint="mediumGray"
        Icon={MdClose}
        clearMargins
        isTransparent
        isOutlined
        className={styles.closeButtonColor}
      />
      <PopupContent>
        <Grid>
          <Row>
            <PhaseSettingsPopupHeader />
          </Row>
          <PhaseSettingsContent />
        </Grid>
      </PopupContent>
    </PopupContainer>
  </Popup>
);

export default PhaseSettingsPopup;
