// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';
import styles from './button.module.scss';

import Loader from '../Loader';


export type ButtonPropTypes = {
  size?: string,
  className?: string,
  isRound?: boolean,
  isTransparent?: boolean,
  title?: string,
  tint?: string,
  Icon?: ComponentType<*>,
  iconPosition?: string,
  children?: Node,
  isLeft?: boolean,
  isMiddle?: boolean,
  isFullWidth?: boolean,
  isRight?: boolean,
  isOutlined?: boolean,
  elevation?: number,
  isLoading?: boolean,
  isSquare?: boolean,
  isBottom?: boolean,
  isTop?: boolean,
  isLoaderReplacingContent?: boolean,
  clearMargins?: boolean,
  isInline?: boolean,
  useAnchor?: boolean,
};

type MAnchorPropTypes = {
  children?: Node,
};

type MButtonPropTypes = {
  children?: Node,
  type?: string,
};

const MAnchor = ({ children, ...props }: MAnchorPropTypes) => (
  <a {...props}>
    { children }
  </a>
);

MAnchor.defaultProps = {
  children: undefined,
};

const MButton = ({ children, ...props }: MButtonPropTypes) => (
  <button type="button" {...props}>
    { children }
  </button>
);

MButton.defaultProps = {
  children: undefined,
  type: 'button',
};

const Button = ({
  size = 'medium',
  className = '',
  isRound = false,
  isTransparent = false,
  title,
  tint = 'main',
  Icon,
  iconPosition = 'left',
  children,
  isLeft = false,
  isSquare = false,
  isMiddle = false,
  isRight = false,
  isOutlined = false,
  isBottom = false,
  isTop = false,
  elevation = 1,
  isLoading = false,
  isFullWidth = false,
  isLoaderReplacingContent = false,
  clearMargins = false,
  isInline = false,
  useAnchor = false,
  ...props
}: ButtonPropTypes) => {
  const Component = useAnchor ? MAnchor : MButton;
  return (
    <Component
      {...props}
      className={
        `
          ${styles.button}
          ${styles[size]}
          ${styles[tint]}
          ${className}
          ${isRound ? styles.roundButton : ''}
          ${isOutlined ? styles.outline : ''}
          ${isFullWidth ? styles.fullwidth : ''}
          ${isTransparent ? styles.transparent : ''}
          ${isLeft ? styles.buttonLeft : ''}
          ${isBottom ? styles.buttonBottom : ''}
          ${isTop ? styles.buttonTop : ''}
          ${isSquare ? styles.squareBorderRadius : ''}
          ${isRight ? styles.buttonRight : ''}
          ${isMiddle ? styles.buttonMiddle : ''}
          ${styles[`elevation${elevation}`]}
          ${typeof title !== 'string' ? styles.noTextButton : ''}
          ${clearMargins ? styles.noMargins : ''}
          ${isInline ? styles.inline : ''}
        `
      }
    >
      <span
        className={
          `
          ${styles.content}
          ${isLoading && !isLoaderReplacingContent ? styles.hide : ''}
          `
        }
      >
        {
          typeof Icon !== 'undefined' && (
            <Icon
              className={
                `
                  ${styles[`${iconPosition}ButtonIconPosition`]}
                `
              }
              size={30}
            />
          )
        }
        <span className={styles.title}>
          { title }
        </span>
      </span>
      <div
        className={
          `
          ${styles.loaderContainer}
          ${isLoading ? styles.show : ''}
          ${isLoaderReplacingContent ? styles.isLoaderReplacingContent : ''}
          `
        }
      >
        <Loader
          tint={
            isOutlined ? tint : 'white'
          }
          size={size}
        />
        { typeof children !== 'undefined' ? children : null }
      </div>
    </Component>
  );
};

export default Button;
