// @flow
import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { MdClose } from 'react-icons/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import MenuItemsSettings from '../MenuItems/MenuItemsSettings';
import SettingsPopupContent from './SettingsPopupContent';

import styles from './settingsPopup.module.scss';

export const SETTINGS_POPUP_ID = 'settingsPopup';

type SettingsPopupPropTypes = {};

const SettingsPopup = ({}: SettingsPopupPropTypes) => (
  <Popup id={SETTINGS_POPUP_ID}>
    <PopupContainer
      tint="lightGray"
      isFullscreen
    >
      <PopupCloseButton
        tint="mediumGray"
        Icon={MdClose}
        clearMargins
        isTransparent
        isOutlined
        className={styles.closeButtonColor}
      />
      <PopupContent>
        <Grid>
          <Row>
            <Col xs={12} md={3}>
              <div className={styles.aside}>
                <MenuItemsSettings />
              </div>
            </Col>
            <Col xs={12} md={9}>
              <SettingsPopupContent />
            </Col>
          </Row>
        </Grid>
      </PopupContent>
    </PopupContainer>
  </Popup>
);

export default SettingsPopup;
