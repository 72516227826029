// @flow

export type RPS_CLOSED_TYPE = {
  type: '@@redux-phoenix-socket/CLOSED',
  payload: { host: string, userId: any }
};
export const RPS_CLOSED = '@@redux-phoenix-socket/CLOSED';

export type RPS_DISCONNECTED_TYPE = {
  type: '@@redux-phoenix-socket/DISCONNECTED',
  payload: { host: string }
};
export const RPS_DISCONNECTED = '@@redux-phoenix-socket/DISCONNECTED';

export type RPS_OPEN_STARTED_TYPE = {
  type: '@@redux-phoenix-socket/OPEN_STARTED',
  payload: { host: string, userId: any, token: string, role: string, defaultRooms: Array<string> }
};
export const RPS_OPEN_STARTED = '@@redux-phoenix-socket/OPEN_STARTED';

export type RPS_OPENED_TYPE = {
  type: '@@redux-phoenix-socket/OPENED',
  payload: { host: string, userId: any }
};
export const RPS_OPENED = '@@redux-phoenix-socket/OPENED';

export type RPS_ERROR_OCCURRED_TYPE = {
  type: '@@redux-phoenix-socket/ERROR_OCCURRED',
  payload: {
    data: Object
  }
};
export const RPS_ERROR_OCCURRED = '@@redux-phoenix-socket/ERROR_OCCURRED';

export type RPS_JOIN_TO_CHANNEL_REQUIRED_TYPE = {
  type: '@@redux-phoenix-socket/JOIN_TO_CHANNEL_REQUIRED',
  payload: { channelId: string, requireAuth: boolean }
};
export const RPS_JOIN_TO_CHANNEL_REQUIRED = '@@redux-phoenix-socket/JOIN_TO_CHANNEL_REQUIRED';

export type RPS_JOIN_STARTED_TYPE = {
  type: '@@redux-phoenix-socket/JOIN_STARTED',
  payload: { userId: any, channelId: string, token?: string, role?: string }
};
export const RPS_JOIN_STARTED = '@@redux-phoenix-socket/JOIN_STARTED';

export type RPS_JOINED_TYPE = {
  type: '@@redux-phoenix-socket/JOINED',
  payload: { userId: any, channelId: string }
};
export const RPS_JOINED = '@@redux-phoenix-socket/JOINED';

export type RPS_JOIN_FAILED_TYPE = {
  type: '@@redux-phoenix-socket/JOIN_FAILED',
  payload: { userId: any, channelId: string, data: Object }
};
export const RPS_JOIN_FAILED = '@@redux-phoenix-socket/JOIN_FAILED';

export type RPS_TIMED_OUT_TYPE = {
  type: '@@redux-phoenix-socket/TIMED_OUT',
  payload: { channelId: string, data: Object }
};
export const RPS_TIMED_OUT = '@@redux-phoenix-socket/TIMED_OUT';

export type RPS_DISJOINED_TYPE = {
  type: '@@redux-phoenix-socket/DISJOINED',
  payload: { userId: any, channelId: string }
};
export const RPS_DISJOINED = '@@redux-phoenix-socket/DISJOINED';

export type RPS_PUSHED_TYPE = {
  type: '@@redux-phoenix-socket/PUSHED',
  payload: { channelId: string, eventName: string, data?: Object }
};
export const RPS_PUSHED = '@@redux-phoenix-socket/PUSHED';

// export type RPS_CHANNEL_ERROR_OCCURRED_TYPE = {
//   type: '@@redux-phoenix-socket/CHANNELERROROCCURRED',
//   payload: {
//     channelId: string,
//     data?: Object
//   }
// };
// export const RPS_CHANNEL_ERROR_OCCURRED = '@@redux-phoenix-socket/CHANNELERROROCCURRED';

export type RPS_ACTION_TYPE =
  | RPS_CLOSED_TYPE
  | RPS_DISCONNECTED_TYPE
  | RPS_OPEN_STARTED_TYPE
  | RPS_OPENED_TYPE
  | RPS_ERROR_OCCURRED_TYPE
  | RPS_JOIN_TO_CHANNEL_REQUIRED_TYPE
  | RPS_JOIN_STARTED_TYPE
  | RPS_JOINED_TYPE
  | RPS_JOIN_FAILED_TYPE
  | RPS_TIMED_OUT_TYPE
  | RPS_DISJOINED_TYPE
  | RPS_PUSHED_TYPE;
  // | RPS_CHANNEL_ERROR_OCCURRED_TYPE;
