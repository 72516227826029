// @flow
import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';
import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import FileUploader from './FileUploader';
import FileUploaderConfirm from './FileUploaderConfirm';
import FileUploaderError from './FileUploaderError';

import styles from './bulkUploadingClientsPopup.module.scss';


export const BULK_UPLOADING_CLIENTS_POPUP_NAME = 'BulkUploadingClientsPopup';

type BulkUploadingClientsPopupPropTypes = {
  currentStep: number,
}

const BulkUploadingClientsPopup = (
  {
    currentStep,
  }: BulkUploadingClientsPopupPropTypes,
) => {
  let Content;

  switch (currentStep) {
    case 1:
      Content = FileUploader;
      break;
    case 2:
      Content = FileUploaderConfirm;
      break;
    case 3:
      Content = FileUploaderError;
      break;
    default:
      Content = null;
  }

  return (
    <Popup id={BULK_UPLOADING_CLIENTS_POPUP_NAME}>
      <PopupContainer
        elevation={8}
        isRound
        size="medium"
        className={styles.popupContainer}
      >
        <Content />
      </PopupContainer>
    </Popup>
  );
};

export default connect(
  state => ({
    currentStep: selectors.getUploadingClientsStep(state),
  }),
)(BulkUploadingClientsPopup);
