// @flow
import React from 'react';
import type { Node } from 'react';

import styles from './cardHeader.module.scss';


type CardHeaderPropTypes = {
  children?: Node,
  className?: string,
  size?: string,
  hasContent?: boolean
};

const CardHeader = ({
  children,
  className = '',
  size = 'medium',
  hasContent = true,
  ...props
}: CardHeaderPropTypes) => (
  <div
    className={
      `
        ${styles.cardHeader}
        ${styles[size]}
        ${className}
        ${!hasContent ? styles.cardRowHasNoContent : ''}
      `
    }
    {...props}
  >
    { children }
  </div>
);

export const CardHeaderTitle = ({ title }: { title: string }) => (
  <div className={styles.title}>
    { title }
  </div>
);


export const CardHeaderSubtitle = ({ subtitle }: { subtitle: string }) => (
  <div className={styles.subtitle}>
    { subtitle }
  </div>
);


export default CardHeader;
