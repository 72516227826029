// @flow
import debounce from 'lodash/debounce';
import React from 'react';
import { findDOMNode } from 'react-dom';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';

import styles from './infiniteCalendar.module.scss';
import InfiniteCalendarHeader from './InfiniteCalendarHeader';


type InfiniteCalendarPropTypes = {
  headerOffset?: number
};

type InfiniteCalendarStateTypes = {
  isReady: boolean,
  height: number
};

class InfiniteCalendar extends
  React.Component<InfiniteCalendarPropTypes, InfiniteCalendarStateTypes> {

  constructor(props: InfiniteCalendarPropTypes) {
    super(props);

    this.state = {
      isReady: false,
      height: -1,
    };
  }

  componentDidMount() {
    this.setState({
      isReady: true,
      height: this._getHeight(),
    });

    this._debouncedUpdateHeight = debounce(
      this._updateHeight.bind(this),
      300,
    );

    window.addEventListener(
      'resize',
      this._debouncedUpdateHeight,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this._debouncedUpdateHeight,
    );
  }

  _debouncedUpdateHeight: Function;

  _getHeight() {
    const { headerOffset = 0 } = this.props;
    const node = findDOMNode(this);

    if (node instanceof HTMLElement) {
      return node.offsetHeight - headerOffset;
    }

    return -1;
  }

  _updateHeight() {
    this.setState({
      height: this._getHeight(),
    });
  }


  render() {
    const { isReady } = this.state;
    return (
      <div className={styles.infiniteCalendar}>
        <InfiniteCalendarHeader
          startDate={this.props.startDate}
          endDate={this.props.endDate}
        />
        {
          isReady && (
            <DayPickerRangeController
              weekDayFormat="ddd"
              {...this.props}
            />
          )
        }
      </div>
    );
  }
}


export default InfiniteCalendar;