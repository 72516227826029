// @flow
import React from 'react';

import styles from './hiddenPDFSignature.module.scss';


type HiddenPDFSignaturePropTypes = {
  text: string,
  className?: string,
  isInline?: boolean,
};

const HiddenPDFSignature = ({
  text,
  isInline,
  className = '',
  ...props
}: HiddenPDFSignaturePropTypes) => (
  <div
    className={
      `
        ${styles.hiddenPDFSignature}
        ${isInline ? styles.inline : ''}
        ${className}
      `
    }
    {...props}
  >
    <div className={styles.title}>
      { text }
    </div>
    <div className={styles.line} />
  </div>
);

export default HiddenPDFSignature;
