// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdPhoneAndroid,
  // MdEmail,
  MdEvent,
} from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import type { CLIENT_TYPE } from '../../types/clients';
import * as selectors from '../../reducers';
import { open } from '../../actions/closeable';
import * as actions from '../../actions/calendarViewFilter';
import * as clientActions from '../../actions/clients';
import { FullLoader } from '../Loader';
import User from '../User';
import CardRow, { CardRowLeftGroup, CardRowRightGroup } from '../CardRow';
import CardRowElement from '../CardRow/CardRowElement';
import ClientOptions from '../ClientOptions';
import BigPlaceholder from '../BigPlaceholder';
import imgPlaceholder from './clients_placeholder.png';
import { CLIENT_EDITION_POPUP_ID } from '../ClientEditionPopup';

import styles from './clientList.module.scss';


type ClientListPropTypes = {
  isLoading?: boolean,
  clients: Array<CLIENT_TYPE>,
  onLoad?: Function,
  phaseId?: ID_TYPE,
  openClientPopup?: Function,
};

type ClientPropTypes = {
  id: ID_TYPE,
  firstName: string,
  lastName: string,
  phaseName: string,
  nextRelevantDate: Date,
  phone: string,
  onNameClick: Function,
  // email: string
};

const ClientDummy = ({
  id,
  firstName,
  lastName,
  phaseName,
  nextRelevantDate,
  phone,
  onNameClick,
}: ClientPropTypes) => (
  <CardRow className={styles.client}>
    <CardRowLeftGroup>
      <CardRowElement stack>
        <User
          firstName={firstName}
          lastName={lastName}
          userRole={phaseName}
          onNameClick={onNameClick}
        />
        <div className={styles.nextRelevantDate}>
          <MdEvent size={16} />
          {' '}
          { nextRelevantDate.toDateString() }
        </div>
      </CardRowElement>
    </CardRowLeftGroup>
    <CardRowRightGroup>
      <CardRowElement>
        <MdPhoneAndroid size={16} />
        {' '}
        { phone }
      </CardRowElement>
      <CardRowElement clearPaddingLeft clearPaddingRight>
        <ClientOptions id={id} />
      </CardRowElement>
    </CardRowRightGroup>
  </CardRow>
);

const Client = connect(
  (state, { phaseId }) => ({
    phaseName: (selectors.getPhase(state, phaseId) || {}).name || 'Sin fase definida',
  }),
)(ClientDummy);

class ClientList extends React.Component<ClientListPropTypes> {

  componentDidMount() {
    const {
      onLoad,
      phaseId,
    } = this.props;

    if (onLoad && phaseId) {
      onLoad(phaseId);
    }
  }

  componentDidUpdate(prevSate) {
    const {
      phaseId,
      onLoad,
    } = this.props;

    if (prevSate.phaseId !== phaseId && onLoad) {
      onLoad(phaseId);
    }
  }


  render() {
    const {
      isLoading,
      clients = [],
      openClientPopup,
    } = this.props;

    return (
      <div className={styles.clientList}>
        { isLoading && <FullLoader tint="gray" title="Cargando clientes" /> }
        {
          !isLoading && (
            clients.length > 0
              ? (
                clients.map(
                  (client, index) => (
                    <Client
                      key={client.id}
                      id={client.id}
                      firstName={client.first_name}
                      lastName={client.last_name}
                      phaseId={client.phase}
                      nextRelevantDate={client.next_relevant_date}
                      phone={client.phone}
                      index={index + 1}
                      onNameClick={() =>openClientPopup(client.id)}
                    />
                  ),
                )
              ) : (
                <BigPlaceholder
                  pictureURL={imgPlaceholder}
                  title="No hay clientes"
                  description="No hay clientes que coincidan con los filtros establecidos"
                />
              )
          )
        }
      </div>
    );
  }
}


export default connect(
  (state) => {
    let phaseId = selectors.getCalendarViewFiltersPhaseId(state);

    // Use the next phase closest to today, if there is no, use the first phase fetched
    const phases = selectors.getPhases(state);
    const phasesOptions = [
      { value: -1, label: 'Todas las etapas' },
      ...phases.map(({ id }) => ({ id }))
    ];
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const nextRelevantPhase = selectors.getNextRelevantPhase(state, today);

    if (!phaseId) {
      phaseId = nextRelevantPhase.id || ((phasesOptions || [])[0] || {}).id;
    }

    return ({
      isLoading: selectors.isFetchingCalendarViewFilterClients(state),
      clients: selectors.getCalendarViewFilterClients(state),
      phaseId,
    });
  },
  dispatch => ({
    onLoad(phaseId) {
      dispatch(actions.updateCalendarViewFilter({ phaseId }));
      dispatch(actions.startFetchCalendarViewFilter());
    },
    openClientPopup(id: ID_TYPE) {
      dispatch(clientActions.selectClientForEdition(id));
      dispatch(open(CLIENT_EDITION_POPUP_ID));
    },
  }),
)(ClientList);

// TODO: look why it stopped fetching clients for the very next phase when changing  tab?????????