// @flow
import type { ComponentType } from 'react';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './sidebarButton.module.scss';


type SidebarButtonPropTypes = {
  Icon?: ComponentType<*>,
  title?: string,
  badgeCount?: number,
  isOpaque?: boolean,
  hasSubmenu?: boolean,
  to?: string,
};

const SidebarButton = ({
  Icon,
  title,
  badgeCount = 0,
  isOpaque = false,
  hasSubmenu = false,
  to,
  ...props
}: SidebarButtonPropTypes) => {
  const content = (
    <span
      className={
        `
          ${styles.sidebarButton}
          ${isOpaque ? styles.opaque : ''}
          ${hasSubmenu ? styles.withSubmenu : ''}
  
        `
      }
      {...props}
    >
      {
        typeof Icon !== 'undefined' && (
          <Icon />
        )
      }
      {
        typeof title !== 'undefined' && (
          <div className={styles.title}>
            {
              title
            }
          </div>
        )
      }
      {
        badgeCount > 0 && (
          <span className={styles.badge}>
            { badgeCount }
          </span>
        )
      }
    </span>
  );

  return to ? (
    <NavLink style={{ textDecoration: 'none' }} to={to}>
      {content}
    </NavLink>
  ) : (
    <Fragment>
      {content}
    </Fragment>
  );
};

export default SidebarButton;
