// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';
import type { CONTACT_TYPE } from './contacts';
import type { COMMENT_TYPE } from './comments';


export type CLIENT_TYPE = {
  id: ID_TYPE,
  first_name: string,
  last_name: string,
  next_relevant_date: Date,
  profile_picture: string,
  // email: string,
  phone: string,
  birth_date: Date,
  level: number,
  school: number,
  calculated_fields: Array<Object>,
  phase: number,
  is_archived: boolean,
  is_admitted: boolean,
  contacts: Array<ID_TYPE>,
  comments: Array<ID_TYPE>,
  customData: Object,
  isSelected: boolean,
  isDragging: boolean,
  isConfirmed: boolean,
  isEditing: boolean,
};

export type CLIENTS_ADDED_TYPE = {
  type: 'CLIENTS_ADDED',
  payload: {
    entities: { [ID_TYPE]: CLIENT_TYPE }
  }
};
export const CLIENTS_ADDED = 'CLIENTS_ADDED';

export type CLIENT_SELECTED_FOR_EDITION_TYPE = {
  type: 'CLIENT_SELECTED_FOR_EDITION',
  payload: ID_TYPE
};
export const CLIENT_SELECTED_FOR_EDITION = 'CLIENT_SELECTED_FOR_EDITION';

export type ALL_CLIENTS_DESELECTED_FOR_EDITION_TYPE = {
  type: 'ALL_CLIENTS_DESELECTED_FOR_EDITION'
};
export const ALL_CLIENTS_DESELECTED_FOR_EDITION = 'ALL_CLIENTS_DESELECTED_FOR_EDITION';

export type CLIENT_FETCH_STARTED_TYPE = {
  type: 'CLIENT_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENT_FETCH_STARTED = 'CLIENT_FETCH_STARTED';

export type CLIENT_FETCH_COMPLETED_TYPE = {
  type: 'CLIENT_FETCH_COMPLETED',
  payload: CLIENT_TYPE
};
export const CLIENT_FETCH_COMPLETED = 'CLIENT_FETCH_COMPLETED';

export type CLIENT_FETCH_FAILED_TYPE = {
  type: 'CLIENT_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_FETCH_FAILED = 'CLIENT_FETCH_FAILED';

export type CLIENT_ADD_STARTED_TYPE = {
  type: 'CLIENT_ADD_STARTED',
  payload: CLIENT_TYPE
};
export const CLIENT_ADD_STARTED = 'CLIENT_ADD_STARTED';

export type CLIENT_ADD_COMPLETED_TYPE = {
  type: 'CLIENT_ADD_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE
  }
};
export const CLIENT_ADD_COMPLETED = 'CLIENT_ADD_COMPLETED';

export type CLIENT_ADD_FAILED_TYPE = {
  type: 'CLIENT_ADD_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_ADD_FAILED = 'CLIENT_ADD_FAILED';

export type CLIENT_REMOVED_TYPE = {
  type: 'CLIENT_REMOVED',
  payload: ID_TYPE
};
export const CLIENT_REMOVED = 'CLIENT_REMOVED';

export type CLIENT_UPDATE_STARTED_TYPE = {
  type: 'CLIENT_UPDATE_STARTED',
  payload: {
    id: ID_TYPE,
    isConfirmed: false
  }
};
export const CLIENT_UPDATE_STARTED = 'CLIENT_UPDATE_STARTED';

export type CLIENT_UPDATE_COMPLETED_TYPE = {
  type: 'CLIENT_UPDATE_COMPLETED',
  payload: ID_TYPE
};
export const CLIENT_UPDATE_COMPLETED = 'CLIENT_UPDATE_COMPLETED';

export type CLIENT_UPDATE_FAILED_TYPE = {
  type: 'CLIENT_UPDATE_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_UPDATE_FAILED = 'CLIENT_UPDATE_FAILED';

export type CLIENT_TOGGLE_STATUS_STARTED_TYPE = {
  type: 'CLIENT_TOGGLE_STATUS_STARTED',
  payload: {
    id: ID_TYPE,
    key: string,
  }
}
export const CLIENT_TOGGLE_STATUS_STARTED = 'CLIENT_TOGGLE_STATUS_STARTED';

export type CLIENT_SELECTED_TYPE = {
  type: 'CLIENT_SELECTED',
  payload: {
    id: ID_TYPE,
    isSelected: true
  }
};
export const CLIENT_SELECTED = 'CLIENT_SELECTED';

export type CLIENT_DESELECTED_TYPE = {
  type: 'CLIENT_DESELECTED',
  payload: {
    id: ID_TYPE,
    isSelected: false
  }
};
export const CLIENT_DESELECTED = 'CLIENT_DESELECTED';

export type CLIENTS_SELECTED_TYPE = {
  type: 'CLIENTS_SELECTED',
  payload: {
    order: Array<ID_TYPE>,
    isSelected: true
  }
};
export const CLIENTS_SELECTED = 'CLIENTS_SELECTED';

export type CLIENTS_DESELECTED_TYPE = {
  type: 'CLIENTS_DESELECTED',
  payload: {
    order: Array<ID_TYPE>,
    isSelected: false
  }
};
export const CLIENTS_DESELECTED = 'CLIENTS_DESELECTED';

export type CLIENTS_CONFIRMED_TYPE = {
  type: 'CLIENTS_CONFIRMED',
  payload: {
    order: Array<ID_TYPE>,
    isConfirmed: true
  }
};
export const CLIENTS_CONFIRMED = 'CLIENTS_CONFIRMED';

export type CLIENTS_SET_DIRTY_TYPE = {
  type: 'CLIENTS_SET_DIRTY',
  payload: {
    order: Array<ID_TYPE>,
    isConfirmed: false
  }
};
export const CLIENTS_SET_DIRTY = 'CLIENTS_SET_DIRTY';

export type CLIENTS_CHANGE_PHASE_STARTED_TYPE = {
  type: 'CLIENTS_CHANGE_PHASE_STARTED',
  payload: {
    order: Array<ID_TYPE>,
    phase: ID_TYPE,
    atIndex?: number,
  },
  meta?: any,
};
export const CLIENTS_CHANGE_PHASE_STARTED = 'CLIENTS_CHANGE_PHASE_STARTED';

export type CLIENTS_CHANGE_PHASE_COMPLETED_TYPE = {
  type: 'CLIENTS_CHANGE_PHASE_COMPLETED',
  payload: {
    phase: ID_TYPE
  }
};
export const CLIENTS_CHANGE_PHASE_COMPLETED = 'CLIENTS_CHANGE_PHASE_COMPLETED';

export type CLIENTS_CHANGE_PHASE_FAILED_TYPE = {
  type: 'CLIENTS_CHANGE_PHASE_FAILED',
  payload: ERROR_TYPE
};
export const CLIENTS_CHANGE_PHASE_FAILED = 'CLIENTS_CHANGE_PHASE_FAILED';

export type CLIENT_DRAG_STARTED_TYPE = {
  type: 'CLIENT_DRAG_STARTED',
  payload: {
    id: ID_TYPE,
    isDragging: true
  }
};
export const CLIENT_DRAG_STARTED = 'CLIENT_DRAG_STARTED';

export type CLIENT_DRAG_COMPLETED_TYPE = {
  type: 'CLIENT_DRAG_COMPLETED',
  payload: {
    isDragging: false
  }
};
export const CLIENT_DRAG_COMPLETED = 'CLIENT_DRAG_COMPLETED';

export type CLIENT_CONTACTS_FETCH_STARTED_TYPE = {
  type: 'CLIENT_CONTACTS_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENT_CONTACTS_FETCH_STARTED = 'CLIENT_CONTACTS_FETCH_STARTED';

export type CLIENT_CONTACTS_FETCH_COMPLETED_TYPE = {
  type: 'CLIENT_CONTACTS_FETCH_COMPLETED',
  payload: {
    entities: { [ID_TYPE]: CONTACT_TYPE }
  }
};
export const CLIENT_CONTACTS_FETCH_COMPLETED = 'CLIENT_CONTACTS_FETCH_COMPLETED';

export type CLIENT_CONTACTS_FETCH_FAILED_TYPE = {
  type: 'CLIENT_CONTACTS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_CONTACTS_FETCH_FAILED = 'CLIENT_CONTACTS_FETCH_FAILED';

export type CLIENT_CONTACT_REMOVED_TYPE = {
  type: 'CLIENT_CONTACT_REMOVED',
  payload: {
    id: ID_TYPE,
    key: 'contacts',
    order: Array<ID_TYPE>
  }
};
export const CLIENT_CONTACT_REMOVED = 'CLIENT_CONTACT_REMOVED';

export type CLIENT_COMMENTS_FETCH_STARTED_TYPE = {
  type: 'CLIENT_COMMENTS_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENT_COMMENTS_FETCH_STARTED = 'CLIENT_COMMENTS_FETCH_STARTED';

export type CLIENT_COMMENTS_FETCH_COMPLETED_TYPE = {
  type: 'CLIENT_COMMENTS_FETCH_COMPLETED',
  payload: {
    id: ID_TYPE,
    key: 'comments',
    order: Array<ID_TYPE>,
    entities: { [ID_TYPE]: COMMENT_TYPE }
  }
};
export const CLIENT_COMMENTS_FETCH_COMPLETED = 'CLIENT_COMMENTS_FETCH_COMPLETED';

export type CLIENT_COMMENTS_FETCH_FAILED_TYPE = {
  type: 'CLIENT_COMMENTS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_COMMENTS_FETCH_FAILED = 'CLIENT_COMMENTS_FETCH_FAILED';

export type CLIENT_COMMENT_ADDED_TYPE = {
  type: 'CLIENT_COMMENT_ADDED',
  payload: {
    id: ID_TYPE,
    key: 'comments',
    order: Array<ID_TYPE>
  }
};
export const CLIENT_COMMENT_ADDED = 'CLIENT_COMMENT_ADDED';

export type CLIENT_COMMENT_REMOVED_TYPE = {
  type: 'CLIENT_COMMENT_REMOVED',
  payload: {
    id: ID_TYPE,
    key: 'comments',
    order: Array<ID_TYPE>
  }
};
export const CLIENT_COMMENT_REMOVED = 'CLIENT_COMMENT_REMOVED';

export type CLIENT_COMMENT_CONFIRMED_TYPE = {
  type: 'CLIENT_COMMENT_CONFIRMED',
  payload: {
    id: ID_TYPE,
    key: 'comments',
    oldValues: Array<ID_TYPE>,
    newValues: Array<ID_TYPE>
  }
};
export const CLIENT_COMMENT_CONFIRMED = 'CLIENT_COMMENT_CONFIRMED';

export type CLIENT_FORM_EDITION_STARTED_TYPE = {
  type: 'CLIENT_FORM_EDITION_STARTED',
  payload: {
    id: ID_TYPE,
    isEditing: true,
  },
};
export const CLIENT_FORM_EDITION_STARTED = 'CLIENT_FORM_EDITION_STARTED';

export type CLIENT_FORM_EDITION_COMPLETED_TYPE = {
  type: 'CLIENT_FORM_EDITION_COMPLETED',
  payload: {
    id: ID_TYPE,
    isEditing: false,
  },
};
export const CLIENT_FORM_EDITION_COMPLETED = 'CLIENT_FORM_EDITION_COMPLETED';

export type CLIENT_LAST_SELECTED_TYPE = {
  type: 'CLIENT_LAST_SELECTED',
  payload: ID_TYPE
};
export const CLIENT_LAST_SELECTED = 'CLIENT_LAST_SELECTED';

export type CLIENT_SET_PROFILE_PICTURE_STARTED_TYPE = {
  type: 'CLIENT_SET_PROFILE_PICTURE_STARTED',
  payload: {
    id: ID_TYPE,
    profile_picture: any,
  }
};
export const CLIENT_SET_PROFILE_PICTURE_STARTED = 'CLIENT_SET_PROFILE_PICTURE_STARTED';

export type CLIENT_SET_PROFILE_PICTURE_COMPLETED_TYPE = {
  type: 'CLIENT_SET_PROFILE_PICTURE_COMPLETED',
};
export const CLIENT_SET_PROFILE_PICTURE_COMPLETED = 'CLIENT_SET_PROFILE_PICTURE_COMPLETED';

export type CLIENT_SET_PROFILE_PICTURE_FAILED_TYPE = {
  type: 'CLIENT_SET_PROFILE_PICTURE_FAILED',
  payload: ERROR_TYPE,
};
export const CLIENT_SET_PROFILE_PICTURE_FAILED = 'CLIENT_SET_PROFILE_PICTURE_FAILED';

export type CLIENT_SET_FILE_AS_PROFILE_PICTURE_TYPE = {
  type: 'CLIENT_SET_FILE_AS_PROFILE_PICTURE',
  payload: {
    id: ID_TYPE,
    profile_picture: any,
  }
};
export const CLIENT_SET_FILE_AS_PROFILE_PICTURE = 'CLIENT_SET_FILE_AS_PROFILE_PICTURE';

export type CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED_TYPE = {
  type: 'CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED',
};
export const CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED = 'CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED';

export type CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED_TYPE = {
  type: 'CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED',
  payload: ERROR_TYPE,
};
export const CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED = 'CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED';

export type CLIENT_REMOVE_PROFILE_PICTURE_TYPE = {
  type: 'CLIENT_REMOVE_PROFILE_PICTURE',
  payload: {
    id: ID_TYPE,
  }
};
export const CLIENT_REMOVE_PROFILE_PICTURE = 'CLIENT_REMOVE_PROFILE_PICTURE';

export type CLIENTS_ACTION_TYPE =
  | CLIENTS_ADDED_TYPE
  | CLIENT_SELECTED_FOR_EDITION_TYPE
  | ALL_CLIENTS_DESELECTED_FOR_EDITION_TYPE
  | CLIENT_FETCH_STARTED_TYPE
  | CLIENT_FETCH_COMPLETED_TYPE
  | CLIENT_FETCH_FAILED_TYPE
  | CLIENT_ADD_STARTED_TYPE
  | CLIENT_ADD_COMPLETED_TYPE
  | CLIENT_ADD_FAILED_TYPE
  | CLIENT_REMOVED_TYPE
  | CLIENT_UPDATE_STARTED_TYPE
  | CLIENT_UPDATE_COMPLETED_TYPE
  | CLIENT_UPDATE_FAILED_TYPE
  | CLIENT_TOGGLE_STATUS_STARTED_TYPE
  | CLIENT_SELECTED_TYPE
  | CLIENT_DESELECTED_TYPE
  | CLIENTS_CONFIRMED_TYPE
  | CLIENTS_SET_DIRTY_TYPE
  | CLIENTS_SELECTED_TYPE
  | CLIENTS_DESELECTED_TYPE
  | CLIENTS_CHANGE_PHASE_STARTED_TYPE
  | CLIENTS_CHANGE_PHASE_COMPLETED_TYPE
  | CLIENTS_CHANGE_PHASE_FAILED_TYPE
  | CLIENT_DRAG_STARTED_TYPE
  | CLIENT_DRAG_COMPLETED_TYPE
  | CLIENT_CONTACTS_FETCH_STARTED_TYPE
  | CLIENT_CONTACTS_FETCH_COMPLETED_TYPE
  | CLIENT_CONTACTS_FETCH_FAILED_TYPE
  | CLIENT_COMMENT_ADDED_TYPE
  | CLIENT_CONTACT_REMOVED_TYPE
  | CLIENT_COMMENTS_FETCH_STARTED_TYPE
  | CLIENT_COMMENTS_FETCH_COMPLETED_TYPE
  | CLIENT_COMMENTS_FETCH_FAILED_TYPE
  | CLIENT_COMMENT_REMOVED_TYPE
  | CLIENT_COMMENT_CONFIRMED_TYPE
  | CLIENT_FORM_EDITION_STARTED_TYPE
  | CLIENT_FORM_EDITION_COMPLETED_TYPE
  | CLIENT_LAST_SELECTED_TYPE
  | CLIENT_SET_PROFILE_PICTURE_STARTED_TYPE
  | CLIENT_SET_PROFILE_PICTURE_COMPLETED_TYPE
  | CLIENT_SET_PROFILE_PICTURE_FAILED_TYPE
  | CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED_TYPE
  | CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED_TYPE
  | CLIENT_SET_FILE_AS_PROFILE_PICTURE_TYPE
  | CLIENT_REMOVE_PROFILE_PICTURE_TYPE;
