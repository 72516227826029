// @flow
import React from 'react';

import ClientCountPerStateChart from '../ClientCountPerStateChart';
import ClientCountPerMonthChart from '../ClientCountPerMonthChart';
import ClientCountPerPhaseChart from '../ClientCountPerPhasesChart';
import ClientCountPerLevelChart from '../ClientCountPerLevelChart';

import styles from './reportsDashboard.module.scss';

type ReportsDashboardsPropTypes = {};

const ReportsDashboards = (_props: ReportsDashboardsPropTypes) => (
  <div className={styles.reportsDashboard}>
    <div className={styles.chartContainerLeft}>
      { /* Count by State */}
      <ClientCountPerStateChart />
      { /* Count by Phase */}
      <ClientCountPerPhaseChart height="448x" />
    </div>
    <div className={styles.chartContainerRight}>
      { /* Count by Date */}
      <ClientCountPerMonthChart height="320px" />
      { /* Count by Level */}
      <ClientCountPerLevelChart height="230px" />
    </div>
  </div>
);

export default ReportsDashboards;
