// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { i18nSetup } from '../../../../i18n';

import Sidebar from '../../Sidebar';
import DashboardHeader from '../../DashboardHeader';
import ClientEditionPopup from '../../ClientEditionPopup';
import ClientsList from '../../ClientsList';
import CalendarDetailHeader from '../../CalendarDetailHeader';
import CalendarViewCalendarFilter from '../../CalendarViewCalendarFilter';
import GlobalReportButton from '../../GlobalReportButton';

import SettingsPopup from '../../SettingsPopup';
import BoardSettingsPopup from '../../BoardSettingsPopup';
import dashboardStyles from '../Dashboard/dashboard.module.scss';
import styles from './calendar.module.scss';
import { validateUserBoard } from '../../../utils';

import * as selectors from '../../../reducers';
import * as boardActions from '../../../actions/boards';
import * as usersActions from '../../../actions/users';


type CalendarViewPropTypes = {
  onLoad: Function,
  userBoards: any[],
  boardUUID: ID_TYPE,
  history: any,
};

const CalendarView = ({
  onLoad,
  userBoards,
  boardUUID,
  history,
}: CalendarViewPropTypes) => {

  useEffect(() => {
    validateUserBoard(
      boardUUID,
      userBoards,
      history,
      boardUUID => `/calendar/${boardUUID}`,
    );
    if (onLoad) onLoad();
  }, [boardUUID]);

  return (
    <div className={dashboardStyles.dashboard}>
      <Sidebar />
      <div className={dashboardStyles.dashboardContent}>
        <DashboardHeader>
          {/* All inside will be placed on the right side */}
          <GlobalReportButton />
          <CalendarDetailHeader />
        </DashboardHeader>
        <div className={styles.calendarContent}>
          <div className={styles.calendarColumn}>
            <CalendarViewCalendarFilter />
          </div>
          <div className={styles.detailColumn}>
            <ClientsList />
          </div>
        </div>
        <SettingsPopup />
        <ClientEditionPopup/>
        <BoardSettingsPopup />
      </div>
    </div>
  );
}

export default connect(
  state => ({
    userBoards: selectors.getBoards(state),
    boardUUID: selectors.getBoardUUIDParam(state),
  }),
  dispatch => ({
    onLoad(boardUUID) {
      dispatch(usersActions.startFetchCurrentLoggedUser());
      dispatch(boardActions.startFetchingBoards());
      dispatch(boardActions.startFetchingBoard(boardUUID));
      i18nSetup('es');
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardUUID);
    },
  }),
)(CalendarView);
