// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdMoreVert,
  // MdLocalPrintshop,
  MdEdit,
  MdDelete,
} from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';
import { open } from '../../actions/closeable';
import * as phasesActions from '../../actions/phases';
import Button from '../Button';
import PopMenu from '../PopMenu';
import { CLIENT_EDITION_POPUP_ID } from '../ClientEditionPopup';
import GenericPopup from '../GenericPopup';
import styles from './ClientOptions.module.scss';
import i18n from 'i18n-js';

type ClientOptionsPropTypes = {
  id: ID_TYPE,
  onDeleteClicked: Function,
  onEditClicked: Function
};

const dommyContent = () => <p className={styles.bodyText}>
  <br></br>
  Estas a punto de <strong className={styles.bodyTextAlert}><b>eliminar permanentemente</b></strong> un<br></br> cliente.
  <br></br>
  <span className={styles.actionReserved}><strong className={styles.bodyTextWarning}><b>{i18n.t('this action can not be reversed')}</b></strong></span>
  <br></br>
</p>
const dommyTitle = () => <p className={styles.titleText}>{i18n.t('do you want to delete this client')}</p>


const ClientOptions = ({
  id,
  onDeleteClicked,
  onEditClicked,
  showRemoveClientPopup,
  closeRemoveClientPopup,
}: ClientOptionsPropTypes) => (
  <>
    <PopMenu
      id={`clientMenu-${id}`}
      vertical="above"
      horizontal="left"
      tipPosition="center"
      offset={0}
      isRound
      options={[
        { Icon: MdEdit, title: 'Editar cliente', onClick: onEditClicked },
        {
          Icon: MdDelete,
          title: 'Eliminar cliente',
          tint: 'error',
          onClick: showRemoveClientPopup,
        },
      ]}
    >
      <Button
        size="medium"
        Icon={MdMoreVert}
        tint="mediumGray"
        clearMargins
        isTransparent
        isOutlined
      />
    </PopMenu>
    <GenericPopup
      id={`DELETE_CLIENT_${id}`}
      customTitle={dommyTitle()}
      customContent={dommyContent()}
      onConfirm={onDeleteClicked}
      onCancel={closeRemoveClientPopup}
      popUpSize="medium"
      confirmButtonTitle={i18n.t('permanently delete')}
      confirmButtonColor="error"
      cancelButtonColor="mediumGray"
    />
  </>
);


export default connect(
  (state, { id }) => ({
    phaseId: selectors.getClient(state, id).phase,
  }),
  (dispatch, { id }) => ({
    onDeleteClicked(phaseId: ID_TYPE) {
      dispatch(phasesActions.removeClientsFromPhase(phaseId, [id]));
      dispatch(actions.removeClient(id));
    },
    onEditClicked() {
      dispatch(actions.selectClientForEdition(id));
      dispatch(actions.startEditingClientForm(id));
      dispatch(open(CLIENT_EDITION_POPUP_ID));
    },
    showRemoveClientPopup() {
      dispatch(closeableActions.open(`DELETE_CLIENT_${id}`));
    },
    closeRemoveClientPopup() {
      dispatch(closeableActions.close(`DELETE_CLIENT_${id}`));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onDeleteClicked() {
      dispatchProps.onDeleteClicked(stateProps.phaseId);
    },
  }),
)(ClientOptions);
