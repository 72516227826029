// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './cardHeaderLeftGroup.module.scss';


type CardHeaderLeftGroupPropTypes = {
  children?: Node
};

const CardHeaderLeftGroup = ({
  children,
} : CardHeaderLeftGroupPropTypes) => (
  <div className={styles.headerLeftGroup}>
    { children }
  </div>
);

export default CardHeaderLeftGroup;
