// @flow
import { combineReducers } from '@reduxjs/toolkit';

import { types as authTypes } from 'nozzmo-redux-jwt';

import type { ERROR_TYPE } from '../types/common';
import * as common from './common';
import * as userTypes from '../types/users';

export type LoginState = {
  isLoading: boolean,
  error: ERROR_TYPE,
};

type LOGIN_TYPE = {
  type: string
}

const isLoading = (
  state: boolean = false,
  action: LOGIN_TYPE,
): boolean => {
  switch (action.type) {
    case authTypes.LOGIN_STARTED:
      return true;
    case authTypes.LOGIN_SUCCEED:
    case authTypes.LOGIN_FAILED:
      return false;
    default:
      return state;
  }
};

const error = common.error({
  clear: [
    authTypes.LOGIN_STARTED,
    authTypes.LOGIN_SUCCEED,
    userTypes.USER_LOGIN_EVENTS_CLEANED,
  ],
  populate: [
    authTypes.LOGIN_FAILED,
  ]
})

const login = combineReducers({
  isLoading,
  error,
});

export default login;

export const isLoginLoading = (state: LoginState) => state.isLoading;
export const getLoginError = (state: LoginState): ERROR_TYPE => state.error;
