// @flow
import type { Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';

import SidebarLogo from './SidebarLogo';
import SidebarUser from './SidebarUser';
import SidebarList from './SidebarList';
import SidebarOpen from './SidebarOpen';
import SidebarSeparator from './SidebarSeparator';
import SidebarSearch from './SidebarSearch';
// import SidebarNotifications from './SidebarNotifications';
//import SidebarSchoolSelector from './SidebarSchoolSelector';
import * as selectors from '../../reducers';
import styles from './sidebar.module.scss';

type SidebarPropTypes = {
  isOpen: boolean,
  onOpen: Function,
}

type SidebarContainerPropTypes = {
  children?: Node,
  isOpen: boolean,
};

type SidebarGroupPropTypes = {
  children?: Node,
  isOpen: boolean,
};

type SidebarUpperGroupPropTypes = {
  children?: Node,
  isOpen: boolean,
};

type SidebarLowerGroupPropTypes = {
  children?: Node
};

const SidebarContainer = ({ children }: SidebarContainerPropTypes) => (
  <div className={styles.sidebarContainer}>
    { children }
  </div>
);

export const SidebarGroup = ({ children, isOpen }: SidebarGroupPropTypes) => (
  <div
    className={`
      ${styles.sidebar}
      ${isOpen ? styles.open : ''}
    `}
  >
    { children }
  </div>
);

export const SidebarUpperGroup = ({ children, isOpen }: SidebarUpperGroupPropTypes) => (
  <div
    className={`
      ${styles.upperGroup}
      ${isOpen ? styles.open : ''}
    `}>
    { children }
  </div>
);

export const SidebarLowerGroup = ({ children }: SidebarLowerGroupPropTypes) => (
  <div className={styles.lowerGroup}>
    { children }
  </div>
);


const Sidebar = ({ isOpen }: SidebarPropTypes) => (
  <SidebarContainer>
    <SidebarGroup isOpen={isOpen}>
      <SidebarLogo />
      <SidebarSeparator />
      <SidebarUpperGroup isOpen={isOpen}>
        <SidebarUser />
        <SidebarSeparator />
        <SidebarSearch />
        <SidebarList />
      </SidebarUpperGroup>
      <SidebarLowerGroup>
        <SidebarOpen />
      </SidebarLowerGroup>
    </SidebarGroup>
  </SidebarContainer>
);

export default connect(
  state => ({
    isOpen: selectors.isMainSidebarOpen(state),
  }),
  undefined,
)(Sidebar);
