// @flow
import type { ERROR_TYPE, ID_TYPE } from './common';

export type BOARD_TYPE = {
  id: number,
  name: string,
  form_header_picture: {
    url: string
  },
  introduction: Object,
  epilogue: Object,
  print_introduction: Object,
  print_epilogue: Object,
  colors: {
    base: string
  },
  school: number,
  levels: Array<ID_TYPE>,
  cycles: Array<ID_TYPE>,
  languages: Array<string>,
}

export type BOARDS_FETCH_STARTED_TYPE = {
  type: 'BOARDS_FETCH_STARTED',
  payload: ID_TYPE
};
export const BOARDS_FETCH_STARTED = 'BOARDS_FETCH_STARTED';

export type BOARDS_FETCH_COMPLETED_TYPE = {
  type: 'BOARDS_FETCH_COMPLETED',
  payload: {
    entities: { [ID_TYPE]: PHASE_TYPE },
    order: Array<ID_TYPE>
  }
};
export const BOARDS_FETCH_COMPLETED = 'BOARDS_FETCH_COMPLETED';

export type BOARDS_FETCH_FAILED_TYPE = {
  type: 'BOARDS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const BOARDS_FETCH_FAILED = 'BOARDS_FETCH_FAILED';

export type BOARD_FETCH_STARTED_TYPE = {
  type: 'BOARD_FETCH_STARTED',
  payload: number
};
export const BOARD_FETCH_STARTED = 'BOARD_FETCH_STARTED';

export type BOARD_FETCH_SUCCEED_TYPE = {
  type: 'BOARD_FETCH_SUCCEED',
  payload: BOARD_TYPE
};
export const BOARD_FETCH_SUCCEED = 'BOARD_FETCH_SUCCEED';

export type BOARD_FETCH_FAILED_TYPE = {
  type: 'BOARD_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const BOARD_FETCH_FAILED = 'BOARD_FETCH_FAILED';

export type BOARD_UPDATE_STARTED_TYPE = {
  type: 'BOARD_UPDATE_STARTED',
  payload: {
    id: ID_TYPE
  }
};
export const BOARD_UPDATE_STARTED = 'BOARD_UPDATE_STARTED';

export type BOARD_UPDATE_COMPLETED_TYPE = {
  type: 'BOARD_UPDATE_COMPLETED',
  payload: ID_TYPE
};
export const BOARD_UPDATE_COMPLETED = 'BOARD_UPDATE_COMPLETED';

export type BOARD_UPDATE_FAILED_TYPE = {
  type: 'BOARD_UPDATE_FAILED',
  payload: ERROR_TYPE
};
export const BOARD_UPDATE_FAILED = 'BOARD_UPDATE_FAILED';


export type BOARD_ACTION_TYPE =
  | BOARD_FETCH_STARTED_TYPE
  | BOARD_FETCH_SUCCEED_TYPE
  | BOARD_FETCH_FAILED_TYPE
  | BOARDS_FETCH_STARTED_TYPE
  | BOARDS_FETCH_COMPLETED_TYPE
  | BOARDS_FETCH_FAILED_TYPE
  | BOARD_UPDATE_STARTED_TYPE
  | BOARD_UPDATE_COMPLETED_TYPE
  | BOARD_UPDATE_FAILED_TYPE;
