// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './label.module.scss';


type LabelPropTypes = {
  children?: Node,
  className?: string,
  required?: boolean,
  baseColor?: string,
};

const Label = ({
  children,
  className,
  required,
  baseColor = '',
} : LabelPropTypes) => (
  <div className={
    `
      ${styles.label}
      ${className || ''}
    `
  }
  >
    <span>
      { children }
    </span>
    {
      required && (
        <span style={{ color: baseColor }}>
          *
        </span>
      )
    }
  </div>
);

export default Label;
