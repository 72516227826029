// @flow
import React from 'react';

import {
  transformVerticalDirection,
  transformHorizontalDirection,
} from '../Popover/transform';
import Tip from '../Tip';
import styles from './tipRail.module.scss';


const TipRail = ({
  isHorizontal = true,
  vertical = 'above',
  horizontal = 'center',
  tipColor = 'white',
}: {
  isHorizontal?: boolean,
  vertical?: string,
  horizontal?: string,
  tipColor?: string
}) => {
  const tver = transformVerticalDirection(vertical);
  const thor = transformHorizontalDirection(horizontal);

  return (
    <div className={
      `
        ${styles.tipRail}
        ${isHorizontal ? styles.horizontal : styles.vertical}
        ${styles[tver] || ''}
        ${styles[thor] || ''}
      `
    }
    >
      {
        tver === 'above' && (
          <Tip
            direction="down"
            axis="horizontal"
            position={thor}
            color={tipColor}
          />
        )
      }
      {
        tver === 'below' && (
          <Tip
            direction="up"
            axis="horizontal"
            position={thor}
            color={tipColor}
          />
        )
      }
      {
        thor === 'before' && (
          <Tip
            direction="right"
            axis="vertical"
            position={tver}
            color={tipColor}
          />
        )
      }
      {
        thor === 'after' && (
          <Tip
            direction="left"
            axis="vertical"
            position={tver}
            color={tipColor}
          />
        )
      }
    </div>
  );
};

export default TipRail;
