// @flow
import 'draft-js/dist/Draft.css';

import React, { useEffect, useRef, useState } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { clearEditorContent } from 'draftjs-utils';
import { contentType } from 'mime-types';
import Axios from 'axios';

import * as inlineStyles from './inlineStyles';
import CommentEditorToolbar from '../CommentEditorToolbar';
import FilesUploader from '../FilesUploader';

import styles from './commentEditor.module.scss';
import { Files } from '../../api';
import { getFileName } from '../../utils';

type CommentEditorPropTypes = {
  onActionClicked: Function,
  dontClearOnActionClicked?: boolean
};

const CommentEditor = ({
  onActionClicked,
  dontClearOnActionClicked,
}: CommentEditorPropTypes) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [filesState, setFilesState] = useState({
    files: [],
  });
  const [filesProgress, setFilesProgress] = useState({});
  const editor = useRef(null);
  const fileDialog = useRef(null);

  const onUploadProgress = uuid => (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setFilesProgress({
      ...filesProgress,
      [uuid]: percentCompleted,
    });
  };

  const uploadFiles = (files: any[]) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = getFileName(file.name);
        const mimeType = contentType(`${fileName}.${file.extension}`);
        Files.custom.sign({
          data: {
            key: `${file.UUID}/${fileName}.${file.extension}`,
            type: mimeType,
          },
        // eslint-disable-next-line no-loop-func
        }).then((response) => {
          const signedUrl = response.presigned_post;

          if (!signedUrl) {
            throw new Error('No presigned url provided by the server.');
          }
          Axios.put(signedUrl, file.file, {
            headers: {
              'Content-Type': mimeType,
            },
            onUploadProgress: onUploadProgress(file.UUID),
          });
        });
      }
    } catch (error) { /* noop */ }
  };

  useEffect(() => {
    setFilesState({
      ...filesState,
      files: filesState.files.map((x) => {
        const progress = filesProgress[x.UUID] || x.progress;
        return {
          ...x,
          progress,
        };
      }),
    });
  }, [filesProgress]);

  const onChange = (editorState: EditorState) => setEditorState(editorState);

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const onBoldClick = () => onChange(RichUtils.toggleInlineStyle(editorState, inlineStyles.BOLD));

  const onUnderlineClick = () => onChange(RichUtils.toggleInlineStyle(editorState, inlineStyles.UNDERLINE));

  const onItalicClick = () => onChange(RichUtils.toggleInlineStyle(editorState, inlineStyles.ITALIC));

  const getContent = () => editorState;

  const styleSet = editorState.getCurrentInlineStyle();

  return (
    <div className={styles.commentEditor}>
      <Editor
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={onChange}
        ref={editor}
      />
      <FilesUploader
        value={filesState}
        onChange={setFilesState}
        dropzoneRef={fileDialog}
        onStartUpload={uploadFiles}
        cardsMode
        hideDropArea
        noSaga
      />
      <CommentEditorToolbar
        onBoldClicked={onBoldClick}
        onUnderlineClicked={onUnderlineClick}
        onItalicClicked={onItalicClick}
        onFileSelectClick={() => {
          if (fileDialog.current) fileDialog.current.open();
        }}
        actionProps={{
          title: 'Comentar',
          onClick: () => {
            if (
              getContent().getCurrentContent().hasText()
              || filesState.files.length > 0
            ) {
              onActionClicked(
                getContent(),
                filesState.files.map(x => ({ name: x.name, UUID: x.UUID, extension: x.extension })),
              );

              // Clear editor content if needed
              if (!dontClearOnActionClicked) {
                setEditorState(clearEditorContent(editorState));
              }
              setFilesState({
                files: [],
              });
              setFilesProgress({});

              // refocus the editor again after 500ms
              setTimeout(() => editor && editor.current && editor.current.focus(), 500);
            }
          },
        }}
        overBold={styleSet.has(inlineStyles.BOLD)}
        overUnderline={styleSet.has(inlineStyles.UNDERLINE)}
        overItalic={styleSet.has(inlineStyles.ITALIC)}
      />
    </div>
  );
};

export default CommentEditor;
