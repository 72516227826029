// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { CYCLE_TYPE } from '../types/cycles';
import * as common from './common';
import * as types from '../types/cycles';

export type CyclesState = {
  byId: { [ID_TYPE]: CYCLE_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE
};

const byId = common.byId({
  fetched: [types.CYCLES_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.CYCLES_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.CYCLES_FETCH_STARTED],
  succeed: [types.CYCLES_FETCH_COMPLETED],
  failed: [types.CYCLES_FETCH_FAILED],
});

const error = common.error({
  clear: [types.CYCLES_FETCH_STARTED],
  populate: [types.CYCLES_FETCH_FAILED],
});

const cycles = combineReducers({
  byId,
  order,
  isFetching,
  error,
});


export default cycles;


// Selectors
export const getCycle = (state: CyclesState, id: ID_TYPE): ?CYCLE_TYPE => state.byId[id];
export const getCycles = (
  state: CyclesState,
): Array<?CYCLE_TYPE> => state.order.map(i => getCycle(state, i));
export const isFetchingCycles = (state: CyclesState): boolean => state.isFetching;
export const getCyclesError = (state: CyclesState): ERROR_TYPE => state.error;
export const getCyclesById = (state: CyclesState): { [ID_TYPE]: CYCLE_TYPE } => state.byId;
export const getCyclesOrder = (state: CyclesState): Array<ID_TYPE> => state.order;
export const getActiveCycles = (state: CyclesState): Array<?CYCLE_TYPE> =>
  state.order
    .map((i) => getCycle(state, i))
    .filter((cycle) => cycle.is_active)
    .map((cycle) => cycle.id);
