// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  CYCLE_TYPE,
  CYCLES_FETCH_STARTED_TYPE,
  CYCLES_FETCH_COMPLETED_TYPE,
  CYCLES_FETCH_FAILED_TYPE,
} from '../types/cycles';
import * as types from '../types/cycles';


export const startFetchingCycles = (schoolId: ID_TYPE): CYCLES_FETCH_STARTED_TYPE => ({
  type: types.CYCLES_FETCH_STARTED,
  payload: schoolId,
});

export const completeFetchingCycles = (
  cycles: { [ID_TYPE]: CYCLE_TYPE },
  order: Array<ID_TYPE>,
): CYCLES_FETCH_COMPLETED_TYPE => ({
  type: types.CYCLES_FETCH_COMPLETED,
  payload: {
    entities: cycles,
    order,
  },
});

export const failFetchingCycles = (error: ERROR_TYPE): CYCLES_FETCH_FAILED_TYPE => ({
  type: types.CYCLES_FETCH_FAILED,
  payload: error,
});
