// @flow
import type {
  ALL_OPTIONALS_GLOBAL_FILTER_TYPE,
  GLOBAL_FILTER_UPDATED_TYPE,
  GLOBAL_FILTER_CLEARED_TYPE,
} from '../types/globalFilters';
import * as types from '../types/globalFilters';


export const updateGlobalFilter = (
  filters: ALL_OPTIONALS_GLOBAL_FILTER_TYPE,
): GLOBAL_FILTER_UPDATED_TYPE => ({
  type: types.GLOBAL_FILTER_UPDATED,
  payload: filters,
});

export const clearGlobalFilter = (): GLOBAL_FILTER_CLEARED_TYPE => ({
  type: types.GLOBAL_FILTER_CLEARED,
});
