// @flow
import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { MdClose } from 'react-icons/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import MenuItemsBoardSettings from '../MenuItems/MenuItemsBoardSettings';
import BoardSettingsPopupContent from './BoardSettingsPopupContent';

import styles from './boardSettingsPopup.module.scss';

export const BOARD_SETTINGS_POPUP_ID = 'boardSettingsPopup';

type BoardSettingsPopupPropTypes = {};

const BoardSettingsPopup = ({}: BoardSettingsPopupPropTypes) => (
  <Popup id={BOARD_SETTINGS_POPUP_ID}>
    <PopupContainer
      tint="lightGray"
      isFullscreen
    >
      <PopupCloseButton
        tint="mediumGray"
        Icon={MdClose}
        clearMargins
        isTransparent
        isOutlined
        className={styles.closeButtonColor}
      />
      <PopupContent>
        <Grid>
          <Row>
            <Col xs={12} md={3}>
              <div className={styles.aside}>
                <MenuItemsBoardSettings />
              </div>
            </Col>
            <Col xs={12} md={9}>
              <BoardSettingsPopupContent />
            </Col>
          </Row>
        </Grid>
      </PopupContent>
    </PopupContainer>
  </Popup>
);

export default BoardSettingsPopup;
