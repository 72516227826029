// @flow
// import 'core-js/stable';
import 'regenerator-runtime/runtime';
import moment from 'moment-timezone';
import 'normalize.css';
import 'react-dates/lib/css/_datepicker.css';
import './index.module.scss'

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BannerMessagesProvider,
  lightTheme,
  PaperThemeProvider,
} from '@nahualventure/paper-ui';



import { register } from './scripts/registerServiceWorker';
import configRouter from './scripts/router';
import configureStore from './scripts/store/configureStore';
import history from './history';

moment.tz.setDefault('America/Guatemala');

const { store, persistor } = configureStore(history);
const Router = configRouter(history);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PaperThemeProvider theme={lightTheme}>
        <BannerMessagesProvider>
          <Router />
        </BannerMessagesProvider>
      </PaperThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);

register();