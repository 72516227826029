// @flow
import type { ERROR_TYPE } from './common';

export type NOTIFICATION_TYPE = {
  id?: number,
  title?: string,
  date?: string,
  is_read?: boolean,
  description?: string,
  notification_type?: string
};

export type NOTIFICATIONS_FETCH_STARTED_TYPE = {
  type: 'NOTIFICATIONS_FETCH_STARTED',
  payload: {
    page: number
  }
};
export const NOTIFICATIONS_FETCH_STARTED = 'NOTIFICATIONS_FETCH_STARTED';

export type NOTIFICATIONS_FETCH_SUCCEED_TYPE = {
  type: 'NOTIFICATIONS_FETCH_SUCCEED',
  payload: {
    entities: {[number]: NOTIFICATION_TYPE},
    order: Array<number>,
    page: number,
    next: number,
    count: number
  }
};
export const NOTIFICATIONS_FETCH_SUCCEED = 'NOTIFICATIONS_FETCH_SUCCEED';

export type NOTIFICATIONS_FETCH_FAILED_TYPE = {
  type: 'NOTIFICATIONS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED';

export type NOTIFICATIONS_COUNTED_TYPE = {
  type: 'NOTIFICATIONS_COUNTED',
  payload: number
};
export const NOTIFICATIONS_COUNTED = 'NOTIFICATIONS_COUNTED';

export type NOTIFICATIONS_COUNT_STARTED_TYPE = {
  type: 'NOTIFICATIONS_COUNT_STARTED'
};
export const NOTIFICATIONS_COUNT_STARTED = 'NOTIFICATIONS_COUNT_STARTED';

export type NOTIFICATIONS_WATCHED_TYPE = {
  type: 'NOTIFICATIONS_WATCHED'
};
export const NOTIFICATIONS_WATCHED = 'NOTIFICATIONS_WATCHED';

export type NOTIFICATIONS_WATCH_STARTED_TYPE = {
  type: 'NOTIFICATIONS_WATCH_STARTED'
};
export const NOTIFICATIONS_WATCH_STARTED = 'NOTIFICATIONS_WATCH_STARTED';

export type NOTIFICATION_ADDED_TYPE = {
  type: 'NOTIFICATION_ADDED',
  payload: NOTIFICATION_TYPE
};
export const NOTIFICATION_ADDED = 'NOTIFICATION_ADDED';

export type NOTIFICATION_READ_TYPE = {
  type: 'NOTIFICATION_READ',
  payload: {
    id: number,
    is_read: true
  }
};
export const NOTIFICATION_READ = 'NOTIFICATION_READ';

export type NOTIFICATION_SELECTED_TYPE = {
  type: 'NOTIFICATION_SELECTED',
  payload: number
};
export const NOTIFICATION_SELECTED = 'NOTIFICATION_SELECTED';

export type NOTIFICATION_FETCH_STARTED_TYPE = {
  type: 'NOTIFICATION_FETCH_STARTED',
  payload: number
};
export const NOTIFICATION_FETCH_STARTED = 'NOTIFICATION_FETCH_STARTED';

export type NOTIFICATION_FETCH_SUCCEED_TYPE = {
  type: 'NOTIFICATION_FETCH_SUCCEED',
  payload: NOTIFICATION_TYPE
};
export const NOTIFICATION_FETCH_SUCCEED = 'NOTIFICATION_FETCH_SUCCEED';

export type NOTIFICATION_FETCH_FAILED_TYPE = {
  type: 'NOTIFICATION_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED';

export type NOTIFICATION_POPUP_CHANGED_TYPE = {
  type: 'NOTIFICATION_POPUP_CHANGED',
  payload: number
};
export const NOTIFICATION_POPUP_CHANGED = 'NOTIFICATION_POPUP_CHANGED';

export type NOTIFICATIONS_ACTION_TYPE =
  | NOTIFICATIONS_FETCH_STARTED_TYPE
  | NOTIFICATIONS_FETCH_SUCCEED_TYPE
  | NOTIFICATIONS_FETCH_FAILED_TYPE
  | NOTIFICATION_ADDED_TYPE
  | NOTIFICATION_READ_TYPE
  | NOTIFICATION_SELECTED_TYPE
  | NOTIFICATION_FETCH_STARTED_TYPE
  | NOTIFICATION_FETCH_SUCCEED_TYPE
  | NOTIFICATION_FETCH_FAILED_TYPE
  | NOTIFICATIONS_COUNTED_TYPE
  | NOTIFICATIONS_COUNT_STARTED_TYPE
  | NOTIFICATIONS_WATCHED_TYPE
  | NOTIFICATIONS_WATCH_STARTED_TYPE
  | NOTIFICATION_POPUP_CHANGED_TYPE;
