// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE } from '../types/common';
import type { REPORT_TYPE } from '../types/reports';
import * as common from './common';
import * as types from '../types/reports';


export type ReportsState = {
  byId: { [ID_TYPE]: REPORT_TYPE },
  isFetchingClientCountByState: boolean,
  isFetchingClientCountByPhase: boolean,
  isFetchingClientCountByLevel: boolean,
  isFetchingClientCountByMonth: boolean,
  startDate: Date,
  endDate: Date,
  selectedCycle: string,
};

const byId = common.byId({
  fetched: [
    types.REPORTS_ADDED,
    types.CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED,
    types.CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED,
    types.CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED,
    types.CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED,
  ],
});

const isFetchingClientCountByState = common.isFetching({
  started: [types.CLIENTS_COUNT_PER_STATE_FETCH_STARTED],
  succeed: [types.CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED],
  failed: [types.CLIENTS_COUNT_PER_STATE_FETCH_FAILED],
});

const isFetchingClientCountByPhase = common.isFetching({
  started: [types.CLIENTS_COUNT_PER_PHASE_FETCH_STARTED],
  succeed: [types.CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED],
  failed: [types.CLIENTS_COUNT_PER_PHASE_FETCH_FAILED],
});

const isFetchingClientCountByLevel = common.isFetching({
  started: [types.CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED],
  succeed: [types.CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED],
  failed: [types.CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED],
});

const isFetchingClientCountByMonth = common.isFetching({
  started: [types.CLIENTS_COUNT_PER_MONTH_FETCH_STARTED],
  succeed: [types.CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED],
  failed: [types.CLIENTS_COUNT_PER_MONTH_FETCH_FAILED],
});

const startDate = common.singleton({
  clear: [types.DATE_FILTER_CLEARED],
  populate: [types.START_DATE_SETTED],
});

const endDate = common.singleton({
  clear: [types.DATE_FILTER_CLEARED],
  populate: [types.END_DATE_SETTED],
});

const selectedCycle = common.singleton({
  clear: [types.DATE_FILTER_CLEARED],
  populate: [types.SELECTED_CYCLE_SETTED],
});

const reports = combineReducers({
  byId,
  isFetchingClientCountByState,
  isFetchingClientCountByPhase,
  isFetchingClientCountByLevel,
  isFetchingClientCountByMonth,
  startDate,
  endDate,
  selectedCycle,
});


export default reports;

// Selectors
export const getReport = (state: ReportsState, id: ID_TYPE):
  ?REPORT_TYPE => state.byId[id];
export const getReporstByType = (
  state: ReportsState,
  rType: string,
): Array<any> => Object.keys(state.byId)
  .map(id => getReport(state, id) || {}).filter(r => r.type === rType);

export const getIsFetchingClientCountByState = (
  state: ReportsState,
): boolean => state.isFetchingClientCountByState;

export const getIsFetchingClientCountByPhase = (
  state: ReportsState,
): boolean => state.isFetchingClientCountByPhase;

export const getIsFetchingClientCountByLevel = (
  state: ReportsState,
): boolean => state.isFetchingClientCountByLevel;

export const getIsFetchingClientCountByMonth = (
  state: ReportsState,
): boolean => state.isFetchingClientCountByMonth;


export const getReportDateFilter = (state: ReportsState):
  ?Object => ({ startDate: state.startDate, endDate: state.endDate });
export const getReportSelectedCycle = (state: ReportsState):
  ?string => state.selectedCycle;
