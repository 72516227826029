// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { LEVEL_TYPE } from '../types/levels';
import * as common from './common';
import * as types from '../types/levels';


export type LevelsState = {
  byId: { [ID_TYPE]: LEVEL_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
};

const byId = common.byId({
  fetched: [types.LEVELS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.LEVELS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.LEVELS_FETCH_STARTED],
  succeed: [types.LEVELS_FETCH_COMPLETED],
  failed: [types.LEVELS_FETCH_FAILED],
});

const error = common.error({
  clear: [types.LEVELS_FETCH_STARTED],
  populate: [types.LEVELS_FETCH_FAILED],
});

const levels = combineReducers({
  byId,
  order,
  isFetching,
  error,
});


export default levels;


// Selectors
export const getLevel = (state: LevelsState, id: ID_TYPE): ?LEVEL_TYPE => state.byId[id];
export const getLevels = (
  state: LevelsState,
): Array<?LEVEL_TYPE> => state.order.map(i => getLevel(state, i));
export const isFetchingLevels = (state: LevelsState): boolean => state.isFetching;
export const getLevelsError = (state: LevelsState): ERROR_TYPE => state.error;
export const getLevelsById = (state: LevelsState): { [ID_TYPE]: LEVEL_TYPE } => state.byId;
export const getLevelsOrder = (state: LevelsState): Array<ID_TYPE> => state.order;
