// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { CONTACT_TYPE } from '../types/contacts';
import * as common from './common';
import * as types from '../types/contacts';
import * as clientTypes from '../types/clients';


export type ContactsState = {
  byId: { [ID_TYPE]: CONTACT_TYPE },
  errors: { [ID_TYPE]: ERROR_TYPE }
};

const byId = common.byId({
  updated: [types.CONTACT_UPDATE_STARTED],
  fetched: [types.CONTACTS_FETCH],
  removed: [types.CONTACT_REMOVED],
  confirmed: [types.CONTACT_UPDATE_COMPLETED],
  cascade: {
    [clientTypes.CLIENT_REMOVED]: 'client',
  },
  defaultAttributes: {
    isConfirmed: false,
  },
});

const errors = common.errors({
  clear: [types.CONTACT_UPDATE_STARTED],
  populate: [types.CONTACT_UPDATE_FAILED],
});

const contacts = combineReducers({
  byId,
  errors,
});


export default contacts;


// Selectors
export const getContact = (state: ContactsState, id: ID_TYPE): ?CONTACT_TYPE => state.byId[id];
export const getContactError = (state: ContactsState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
