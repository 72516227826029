import React, { Fragment } from 'react';
import uuid from 'uuid';
import { MdError } from 'react-icons/md';

import styles from './fileUploaderConfirm.module.scss';


type DummyErrorElementPropTypes = {
  field: string,
  description: string,
}

const DummyErrorElement = ({field, description }: DummyErrorElementPropTypes) => (
  <li key={uuid()}>
    <strong>
      { `Contact ${field}: ` }
    </strong>
    { description }
  </li>
);

type ErrorSummaryPropTypes = {
  clients: Array<Object>,
}

const ErrorSummary = ({ clients }: ErrorSummaryPropTypes) => {
  return (
    <Fragment>
      <div className={styles.description}>
        {'Se encontraron '}
        <span>
          {`${clients.length} filas con error`}
        </span>
        {' los siguientes estudiantes '}
        <strong>
          {'no se crearán'}
        </strong>
      </div>
      <div className={styles.errorSummary}>
        <table>
          <tbody>
            {
              clients.map(client => (
                <tr key={uuid()}>
                  <td>
                    <div className={styles.errorMark}>
                      <MdError />
                      <div className={styles.toolTip}>
                        <ul>
                          {
                            Object.keys(client.errors).reduce((accumulated, fieldName) => {
                              if (fieldName === 'contacts') {
                                client.errors && client.errors.contacts && client.errors.contacts.forEach(
                                  (contacts) => {
                                    Object.keys(contacts).forEach(
                                      contactFieldName => accumulated.push(
                                        <DummyErrorElement key={uuid()} field={contactFieldName} description={contacts[contactFieldName]} />,
                                      ),
                                    );
                                  },
                                );
                              } else {
                                accumulated.push(
                                  <DummyErrorElement key={uuid()} field={fieldName} description={client.errors[fieldName]} />,
                                );
                              }
                              return accumulated;
                            }, [])
                          }
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td>
                    {`Fila ${client.rowNumber}`}
                  </td>
                  <td>
                    {`${client.first_name || 'Sin nombres'} ${client.last_name || 'Sin apellidos'}`}
                  </td>
                  <td>
                    {client.bajo_responsabilidad_de || 'Sin valor'}
                  </td>
                  <td>
                    {client.phone || 'Sin valor'}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ErrorSummary;
