// @flow
import { combineReducers } from '@reduxjs/toolkit';
import type { DATES_ACTION_TYPE } from '../types/dates';
import * as types from '../types/dates';


export type DatesState = {
  relevantDates: Array<String>,
};

const relevantDates = (
  state:Array<String> = [],
  action: DATES_ACTION_TYPE,
): Array<String> => {
  switch (action.type) {
    case types.RELEVANT_DATES_FETCH_COMPLETED: {
      return [
        ...action.payload,
      ];
    }
    default: {
      return state;
    }
  }
};

const dates = combineReducers({
  relevantDates,
});

export default dates;


// Selectors
export const getRelevantDates = (
  state: DatesState,
): Array<String> => state.relevantDates.map(date => date);
