// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdLocalPrintshop } from 'react-icons/md';

import * as selectors from '../../reducers';
import { api } from '../../api';
import Button from '../Button';
import moment from 'moment';

type GlobalReportButtonPropTypes = {
  reportURL: string
};

const GlobalReportButton = ({ reportURL }: GlobalReportButtonPropTypes) => (
  <Button
    Icon={MdLocalPrintshop}
    title="Reporte general"
    tint="primary"
    href={reportURL}
    target="_blank"
    clearMargins
    useAnchor
  />
);

export default connect(
  (state) => {
    const { id } = selectors.getCurrentBoard(state) || {};
    const filters = selectors.getGlobalFilters(state);
    let url = '#';
    
    if (id) {
      url = `board-reports/${id}/student-report`;
      Object.keys(filters).forEach((key, i) => {
        if (key == 'startBirthDate' && filters['startBirthDate']) {
          filters['startBirthDate'] = moment(filters['startBirthDate']).format("DD/MM/YYYY h:mm:ss");
        } else if (key == 'endBirthDate' && filters['endBirthDate']) {
          filters['endBirthDate'] = moment(filters['endBirthDate']).format("DD/MM/YYYY h:mm:ss");
        };
        if (filters[key]) url = `${url}${i == 0 ? '?' : '&'}${key}=${filters[key]}`
      });
    }

    return {
      reportURL: api.getURL(url, {}, false),
    };
  },
)(GlobalReportButton);
