// @flow
import memoize from 'lodash/memoize';
import slice from 'lodash/slice';

import Fuse from 'fuse.js';

import { combineReducers } from '@reduxjs/toolkit';
import { pluginReducer as plugin, genConfiguration } from 'redux-plugin';

import type {
  SELECTOR_ACTION_TYPE,
  SELECTOR_ELEMENT_STATE
} from '../types/selector';
import * as types from '../types/selector';


const PLUGIN_NAME = 'selector'
const PLUGIN_PREFIX = `@@redux-${ PLUGIN_NAME }`;

export type SelectorState = {
  [string]: SELECTOR_ELEMENT_STATE
};

const isLoading = (state: boolean = false, action: SELECTOR_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.SELECTOR_LOADING_SET: {
      return true;
    }
    case types.SELECTOR_LOADING_UNSET: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isActive = (state: boolean = false, action: SELECTOR_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.SELECTOR_ACTIVATED: {
      return true;
    }
    case types.SELECTOR_DEACTIVATED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const originalOrder = (state: Array<number> = [], action: SELECTOR_ACTION_TYPE) => {
  switch (action.type) {
    case types.SELECTOR_VALUES_SET: {
      return action.payload.order;
    }
    default: {
      return state;
    }
  }
}

const byId = (state: { [ number ]: Object } = {}, action: SELECTOR_ACTION_TYPE) => {
  switch (action.type) {
    case types.SELECTOR_VALUES_SET: {
      return action.payload.byId;
    }
    // TODO: maybe SELECTOR_VALUES_UPDATED
    default: {
      return state;
    }
  }
};

const showing = (state: Array<number> = [], action: SELECTOR_ACTION_TYPE) => {
  const { peState } = action;

  if (peState) {
    const order = getOriginalOrder(peState);
    const keys = getSearchKeys(peState);
    const maximum = getMaximum(peState);
    const haystack = getHaystack({ order, peState });

    switch (action.type) {
      case types.SELECTOR_VALUES_SET: {

        // Clear search cache and return the original order
        search.cache.clear();
        return action.payload.order;
      }
      case types.SELECTOR_FILTER_CHANGED:
      case types.SELECTOR_REGISTERED: {
        let needle;
        if(action.type === types.SELECTOR_FILTER_CHANGED) {
          needle = action.payload.filter;
        } else {
          needle = getFilter(peState);
        }

        if(needle === '') {
          return order;
        }

        const results = search({ haystack, needle, keys });
        if (maximum > 0) {
          return slice(results, 0, results.length < maximum? results.length: maximum);
        }

        return results;
      }
      default: {
        return state;
      }
    }
  }

  return state;
};

// Modulo for handling negative integers
const mod = (i, l) => i - (l * Math.floor(i / l));

// Limit check
const lim = (i, l) => i >= l ? l -1: i < 0 ? 0: i;

// Selected processing
const pro = (c, i, l) => c ? mod(i, l): lim(i, l);

const selected = (state: number = 0, action: SELECTOR_ACTION_TYPE) => {
  const { peState } = action;

  if (peState) {
    const showing = getShowing(peState);
    const circular = getCircular(peState);
    switch (action.type) {
      case types.SELECTOR_FILTER_CHANGED: {
        return 0;
      }
      case types.SELECTOR_SELECTED_CHANGED: {
        return pro(circular, action.payload.selected, showing.length);
      }
      case types.SELECTOR_SELECTED_CHANGED_UP: {
        return pro(circular, state + 1, showing.length);
      }
      case types.SELECTOR_SELECTED_CHANGED_DOWN: {
        return pro(circular, state - 1, showing.length);
      }
      default: {
        return state;
      }
    }
  }

  return state;
};

const filter = (state: string = '', action: SELECTOR_ACTION_TYPE) => {
  switch (action.type) {
    case types.SELECTOR_FILTER_CHANGED: {
      return action.payload.filter;
    }
    default: {
      return state;
    }
  }
};

const configuration = genConfiguration({
  prefix: PLUGIN_PREFIX,
  defaultConfiguration: {
    searchKeys: [],
    circular: true,
    maximum: 9999
  }
});

const selectorElement = combineReducers({
  isActive,
  isLoading,
  originalOrder,
  byId,
  selected,
  filter,
  configuration,
  showing
});

export default plugin({
  name: PLUGIN_NAME,
  prefix: PLUGIN_PREFIX,
  reducer: selectorElement
});

// Get plugin element
export const getSelector = (state: SelectorState, id: string) => state[id];

// Configurations
export const getConfiguration = (peState: SELECTOR_ELEMENT_STATE) => peState.configuration || {};
export const getSearchKeys = (peState: SELECTOR_ELEMENT_STATE) => getConfiguration(peState).searchKeys || [];
export const getCircular = (peState: SELECTOR_ELEMENT_STATE) => getConfiguration(peState).circular || true;
export const getMaximum = (peState: SELECTOR_ELEMENT_STATE) => getConfiguration(peState).maximum || 9999;

// Logic state
export const getSelected = (peState: SELECTOR_ELEMENT_STATE) => peState.selected;
export const getOriginalOrder = (peState: SELECTOR_ELEMENT_STATE) => peState.originalOrder;
export const getIsActive = (peState: SELECTOR_ELEMENT_STATE) => peState.isActive;
export const getIsLoading = (peState: SELECTOR_ELEMENT_STATE) => peState.isLoading;
export const getFilter = (peState: SELECTOR_ELEMENT_STATE) => peState.filter;
export const getElementById = (peState: SELECTOR_ELEMENT_STATE, id: string) => ({
  id,
  ...peState.byId[id]
});

// Memoize configuration
memoize.Cache = Map;

// Fuse configuration
const FUSE_OPTIONS = {
  id: 'id',
  shouldSort: true,
  findAllMatches: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1
};

const search = memoize(
  ({ haystack, needle, keys }) =>
    needle.length > 0 && keys.length > 0 ?
      new Fuse(haystack, {
        ...FUSE_OPTIONS,
        keys
      }).search(needle):
      haystack.map(({ id }) => id),
  ({ haystack, needle }) =>
      `${needle}:${ haystack.map(e => e.id) }`
)

const getHaystack = ({ order, peState }) =>
  order.map(key => ({
    id: key,
    ...getElementById(peState, key)
  })
);

export const getShowing = (peState: SELECTOR_ELEMENT_STATE) => peState.showing;

export const getSelectedElement = (peState: SELECTOR_ELEMENT_STATE) => {
  if(peState) {
    const showing = getShowing(peState);
    const selected = getSelected(peState);

    if(showing && showing.length > 0 && showing[selected]) {
      return getElementById(peState, showing[selected]);
    }
  }

  return undefined;
}

// TODO ? redux plugin utility that might be helpful...
// for developers that are willing to get a pure state selector
// instead of plugin element selectors.
// export const pluginSelectors = generatePluginSelectors({
//   getPluginTree: state => state.selector,
//   elementSelectors: {
//     getOriginalOrder,
//     getConfiguration,
//     getSearchKeys,
//     getIsActive,
//     getFilter,
//     getById,
//     getElementById,
//     getShowing,
//     getSelected
//   }
// });
