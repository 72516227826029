// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/calendarViewFilter';
import Select from '../Select';
// import ClientCounter from '../ClientCounter';

import styles from './calendarDetailHeader.module.scss';


type CalendarDetailHeaderPropTypes = {
  phasesOptions: Array<{ value: ID_TYPE, label: string }>,
  refreshClients: Function,
  phaseId?: ID_TYPE,
  clientCount?: number
};

const CalendarDetailHeader = ({
  phasesOptions,
  refreshClients,
  phaseId,
  // clientCount,
}: CalendarDetailHeaderPropTypes) => (
  <div className={styles.calendarDetailHeader}>
    {/* <div className={styles.leftGroup}>
      <ClientCounter count={clientCount} />
    </div>
    <div className={styles.rightGroup}>
      <Select .../>
    </div> */}
    <Select
      id="calendarPhaseSelect"
      options={phasesOptions}
      onChange={refreshClients}
      value={phaseId}
    />
  </div>
);

export default connect(
  (state) => {
    let phaseId = selectors.getCalendarViewFiltersPhaseId(state);
    // Use the next phase closest to today, if there is no, use the first phase fetched

    const phases = selectors.getPhases(state);
    const phasesOptions: any[] = [
      { value: -1, label: 'Todas las etapas' },
      ...phases.map(({ id, name }) => ({ value: id, label: name }))
    ];
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const nextRelevantPhase = selectors.getNextRelevantPhase(state, today);

    if (!phaseId) {
      phaseId = nextRelevantPhase.id || ((phasesOptions || [])[0] || {}).value;
    }

    return {
      phasesOptions,
      clientCount: selectors.getCalendarViewFilterClientIds(state).length,
      phaseId,
    };
  },
  dispatch => ({
    refreshClients(phaseId) {
      dispatch(actions.updateCalendarViewFilter({ phaseId }));
      dispatch(actions.startFetchCalendarViewFilter());
    },
  }),
)(CalendarDetailHeader);
