// @flow
import moment from 'moment';
import {
  call,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { delay } from 'redux-saga';

// Test puropose only
import range from 'lodash/range';

import { fibonacciBackoff } from '../lib/retry';
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT, DATE_FORMAT } from '../settings';
import * as types from '../types/phases';
import * as selectors from '../reducers';
import * as actions from '../actions/phases';
import * as closeableActions from '../actions/closeable';
import * as clientActions from '../actions/clients';
import {
  parseClient,
  parsePhase,
} from '../utils/serverStateTransformations';
import { deserialize } from '../utils/slateSerializers';

import { Phases, Clients, Boards } from '../api';
import { phase, arrayOfPhases } from '../api/schemas/phases';
import { arrayOfClients } from '../api/schemas/clients';
import { PHASE_SETTINGS_POPUP_ID } from '../components/PhaseSettingsPopup';


function* fetchPhases(action) {
  const { payload } = action;
  // const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUserId);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          name: 'Llenado de datos',
          next_relevant_date: new Date(),
          ordinal: 91,
        },
        {
          id: 2,
          name: 'Contacto seguimiento',
          next_relevant_date: new Date(),
          isLoading: true,
          ordinal: 92,
        },
        {
          id: 3,
          name: 'Prueba calendarizada',
          next_relevant_date: new Date(),
          ordinal: 93,
        },
        {
          id: 4,
          name: 'Prueba realizada',
          next_relevant_date: new Date(),
          ordinal: 94,
        },
      ],
    },
  };

  try {
    const { phasesResponse, timeout } = yield race({
      phasesResponse: call(
        [Phases, 'list'],
        {
          // token,
          id: user,
          filters: { board: payload },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchPhase saga');
    }

    const {
      entities: { phases },
      result,
    } = normalize(phasesResponse, arrayOfPhases);

    // Convert phase date to a date object
    const dPhases = {};
    Object.keys(phases).forEach((cid) => {
      const lPhase = phases[cid];
      dPhases[cid] = {
        ...lPhase,
        next_relevant_date: moment(
          lPhase.next_relevant_date,
          DATE_FORMAT,
        ).toDate(),
      };
    });

    // Register
    yield put(actions.completeFetchingPhases(
      dPhases,
      result,
    ));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingPhases({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* addPhase(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {
        id: Math.floor(Math.random() * 10000),
        name: payload.name,
        school: payload.school,
        clients: [],
        ordinal: 10,
      },
    },
  };

  try {
    const { phaseResponse, timeout } = yield race({
      phaseResponse: call(
        [Phases, 'create'],
        {
          token,
          data: payload,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('createPhase saga');
    }

    const {
      entities: { phases },
      result,
    } = normalize(phaseResponse, phase);

    const confirmedPhase = parsePhase(phases[result]);

    // Register
    yield put(actions.completeAddingPhase(
      payload.id,
      confirmedPhase.id,
      confirmedPhase,
    ));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failAddingPhase({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* removePhase(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {},
    },
  };

  try {
    yield call(
      fibonacciBackoff,
      [Phases, 'remove'],
      { token, id: payload, mock },
    );
  } catch (e) { /* noop */ }
}

function* updatePhase(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  // MOCK
  const lPhase = yield select(selectors.getPhase, payload.id);
  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: lPhase,
    },
  };

  try {
    const { timeout } = yield race({
      response: call(
        [Phases, 'update'],
        {
          token,
          id: payload.id,
          data: payload,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('updatePhase saga');
    }

    // Register
    yield put(actions.completeUpdatingPhase(payload.id));

    // Close popover
    yield put(closeableActions.close(`phaseEditionPopover-${payload.id}`));

    yield put(closeableActions.close(PHASE_SETTINGS_POPUP_ID));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failUpdatingPhase({
      object_id: payload.id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchClientsInPhase(action) {
  const { payload } = action;
  const phaseId = payload;
  const token = yield select(selectors.getToken);
  const { isAdmitted, isArchived, noStatus } = yield select(selectors.getGlobalFilters);
  let filters = {};
  if (isAdmitted) {
    filters = { is_admitted: true };
  }
  if (isArchived) {
    filters = { is_archived: true };
  }
  if (!isAdmitted && !isArchived) {
    filters = { is_archived: false, is_admitted: false };
  }
  if (noStatus) {
    filters = {};
  }

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: range(24).map(i => ({
        id: phaseId * 10 + i,
        first_name: `Samuel ${phaseId * 10 + i}`,
        last_name: 'Chávez',
        next_relevant_date: new Date(),
        phase: phaseId,
        ordinal: phaseId * 10 + i,
      })),
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients, 'list'],
        {
          token,
          // @TODO: Dynamic list with optional query params
          filters: { phase: phaseId, ...filters },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchClientsInPhase saga');
    }

    const {
      entities: { clients },
      result,
    } = normalize(response, arrayOfClients);

    if (clients) {
      // Parse dates
      const dClients = {};
      const clientKeys = Object.keys(clients);
      for (let i = 0; i < clientKeys.length; i += 1) {
        const cid = clientKeys[i];
        const lClient = clients[cid];
        dClients[cid] = parseClient(lClient);
      }

      // Register
      yield put(clientActions.addClients(dClients));
    }

    yield put(actions.completeFetchingPhaseClients(phaseId, result));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingPhaseClients({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* sortPhases(action) {
  const { payload: { oldIndex, newIndex } } = action;
  const token = yield select(selectors.getToken);
  const board = yield select(selectors.getCurrentBoard);
  const boardId = (board || {}).id;

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {},
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Boards.custom, 'sortPhases'],
        {
          token,
          id: boardId,
          data: {
            old_index: oldIndex,
            new_index: newIndex,
          },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('sortPhases saga');
    }

    yield put(actions.succeedSortingPhases(response));
  } catch (error) {
    const {
      statusCode, message, data, isPlain,
    } = error;

    // Fetch error
    yield put(actions.failSortingPhases({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchPhase(action) {
  const id = action.payload;
  const token = yield select(selectors.getToken);

  try {
    const { response, timeout } = yield race({
      response: call(
        [Phases, 'detail'],
        {
          token,
          id,
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchPhase saga');
    }

    if (response.email_template) {
      const body = response.email_template.body;
      const document = new DOMParser().parseFromString(body, 'text/html');
      /*
        Omit empty children to avoid 'Cannot get the start point in the node at
        path X because it has no start text node' Slate error.
      */
      const deserializedBody = deserialize(document.body);
      console.log(deserializedBody)
      response['email_template']['body'] = deserializedBody.filter(el => el.children && el.children.length > 0);
    }

    yield put(actions.completeFetchPhase(response));

  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(actions.failFetchPhase({
      object_id: id,
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}


export function* watchPhasesFetch(): Iterator<any> {
  yield takeEvery(
    types.PHASES_FETCH_STARTED,
    fetchPhases,
  );
}

export function* watchPhaseCreation(): Iterator<any> {
  yield takeEvery(
    types.PHASE_ADD_STARTED,
    addPhase,
  );
}

export function* watchPhaseDeletion(): Iterator<any> {
  yield takeEvery(
    types.PHASE_REMOVED,
    removePhase,
  );
}

export function* watchPhaseClientsFetching(): Iterator<any> {
  yield takeEvery(
    types.PHASE_CLIENTS_FETCH_STARTED,
    fetchClientsInPhase,
  );
}

export function* watchPhaseUpdate(): Iterator<any> {
  yield takeEvery(
    types.PHASE_UPDATE_STARTED,
    updatePhase,
  );
}

export function* watchPhasesSort(): Iterator<any> {
  yield takeEvery(
    types.SORT_PHASE_STARTED,
    sortPhases,
  );
}

export function* watchFetchPhase(): Iterator<any> {
  yield takeEvery(
    types.FETCH_PHASE_STARTED,
    fetchPhase,
  )
}
