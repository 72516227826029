// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';


export type USER_TYPE = {
  first_name: string,
  last_name: string,
  profile_picture?: string
};

export type COMMENT_TYPE = {
  id: ID_TYPE,
  object_pk: ID_TYPE,
  creation_date: Date,
  body: Object,
  created_by: USER_TYPE,
  isConfirmed?: boolean,
  isLast?: boolean,
  files: any[],
};

export type COMMENTS_FETCH_TYPE = {
  type: 'COMMENTS_FETCH',
  payload: {
    entities: { [ID_TYPE]: COMMENT_TYPE }
  }
};
export const COMMENTS_FETCH = 'COMMENTS_FETCH';

export type COMMENT_REMOVED_TYPE = {
  type: 'COMMENT_REMOVED',
  payload: ID_TYPE
};
export const COMMENT_REMOVED = 'COMMENT_REMOVED';

export type COMMENT_ADD_STARTED_TYPE = {
  type: 'COMMENT_ADD_STARTED',
  payload: COMMENT_TYPE
};
export const COMMENT_ADD_STARTED = 'COMMENT_ADD_STARTED';

export type COMMENT_ADD_COMPLETED_TYPE = {
  type: 'COMMENT_ADD_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE
  }
};
export const COMMENT_ADD_COMPLETED = 'COMMENT_ADD_COMPLETED';

export type COMMENT_ADD_FAILED_TYPE = {
  type: 'COMMENT_ADD_FAILED',
  payload: ERROR_TYPE
};
export const COMMENT_ADD_FAILED = 'COMMENT_ADD_FAILED';

export type COMMENT_UPDATE_STARTED_TYPE = {
  type: 'COMMENT_UPDATE_STARTED',
  payload: {
    id: ID_TYPE,
    isConfirmed: false
  }
};
export const COMMENT_UPDATE_STARTED = 'COMMENT_UPDATE_STARTED';

export type COMMENT_UPDATE_COMPLETED_TYPE = {
  type: 'COMMENT_UPDATE_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE
  }
};
export const COMMENT_UPDATE_COMPLETED = 'COMMENT_UPDATE_COMPLETED';

export type COMMENT_UPDATE_FAILED_TYPE = {
  type: 'COMMENT_UPDATE_FAILED',
  payload: ERROR_TYPE
};
export const COMMENT_UPDATE_FAILED = 'COMMENT_UPDATE_FAILED';

export type COMMENTS_ACTION_TYPE =
  | COMMENTS_FETCH_TYPE
  | COMMENT_REMOVED_TYPE
  | COMMENT_ADD_STARTED_TYPE
  | COMMENT_ADD_COMPLETED_TYPE
  | COMMENT_ADD_FAILED_TYPE
  | COMMENT_UPDATE_STARTED_TYPE
  | COMMENT_UPDATE_COMPLETED_TYPE
  | COMMENT_UPDATE_FAILED_TYPE;
