// @flow

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://admissions-api.edoo.io';
export const IS_API_IN_DEV_MODE = false;
// 250s limit
export const REQUEST_TIMEOUT = 200000;
export const PAGE_SIZE = 50;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_CLIENT_HEIGHT_SIZE = 110;

export const DATA_CHUNKS_SIZE = 40;

export const DEFAULT_SOCKET_ROLE = 'ADMIN';
export const SOCKET_HOST = 'wss://ws.onthebus.io/socket';

export const USER_ROLES = [
  { value: true, label: 'Administrador' },
  { value: false, label: 'Monitor' },
];

export const USER_LOGIN_EVENTS = {
  REQUEST_RECOVER_PASSWORD_COMPLETED: 'requestRecoverPasswordCompleted',
  RECOVER_PASSWORD_COMPLETED: 'recoverPAsswordCompleted',
};

// Tiers
export const TIER_TYPES = {
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
  ADVANCED: 'ADVANCED',
};

export const TIER_USERS_LIMIT = {
  BASIC: 1,
  STANDARD: 5,
  ADVANCED: undefined,
};

export const UserExtraData = {
  OPENED_MAIN_SIDEBAR: 'opened_main_sidebar',
  GLOBAL_CYCLE_FILTER: 'global_cycle_filter',
};

export const OMNISEARCH_DISPLAY_AS_OPTIONS = {
  CONTACT: 'contact',
  CLIENT: 'client',
}

// Languages
export const LANGUAGES_CONFIG = [
  {
    name: 'Español',
    i18nKey: 'es',
    flagIconCode: 'es',
  },
  {
    name: 'English',
    i18nKey: 'en',
    flagIconCode: 'us',
  },
];

// Editor API Key
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY