// @flow
import React from 'react';
import { MdClose, MdCheck, MdPrint } from 'react-icons/md';
import { reset } from 'redux-form';
import { connect } from 'react-redux';

import { FORM_ID } from '../ClientForm';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupFooter from '../PopupFooter';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import Button from '../Button';
import Popup from '../Popup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import { close } from '../../actions/closeable';

import styles from './clientCreationSuccessPopup.module.scss';

type ClientCreationSuccessPopupPropTypes = {
  acceptAction: Function,
  closePopup: Function,
  onPrint: Function,
};

export const CLIENT_CREATION_SUCCESS_POPUP_ID = 'clientCreationSuccessPopup';

const ClientCreationSuccessPopup = ({
  acceptAction,
  closePopup,
  onPrint,
}: ClientCreationSuccessPopupPropTypes) => (
  <Popup id={CLIENT_CREATION_SUCCESS_POPUP_ID} transitionMs={600}>
    <PopupContainer
      elevation={8}
      isRound
      size="small"
      className={styles.clientCreationSuccessPopup}
    >
      <PopupHeader isAlert size="small" clearPadding hideDivision>
        <PopupHeaderLeftGroup />
        <PopupHeaderRightGroup>
          <PopupCloseButton
            size="medium"
            tint="mediumGray"
            Icon={MdClose}
            clearMargins
            isTransparent
            isOutlined
          />
        </PopupHeaderRightGroup>
      </PopupHeader>
      <PopupContent isAlert>
        <h1 className={styles.title}>
          {'Tu solicitud de admisión fue recibida con éxito'}
        </h1>
        <p className={styles.description}>
          {'Espera noticias de parte del colegio, uno de\n'
          + ' nuestros encargados se estará comunicando contigo\n'
          + ' para que conozcas los siguientes pasos.'}
        </p>
        <div className={styles.iconContainer}>
          <MdCheck className={styles.icon} size={250} />
        </div>

      </PopupContent>
      <PopupFooter isAlert isPadded align="spaceAround">
        <Button
          className={`
            margin: 4px;
          `}
          size="medium"
          onClick={acceptAction}
          tint="success"
          title="Entendido"
        />
        <Button
          className={`
            margin: 4px;
          `}
          size="medium"
          onClick={() => {
            closePopup();
            onPrint();
          }}
          tint="primary"
          title="Imprimir"
          icon={MdPrint}
        />
      </PopupFooter>
    </PopupContainer>
  </Popup>
);

export default connect(
  undefined,
  dispatch => ({
    acceptAction() {
      dispatch(reset(FORM_ID));
      dispatch(close(CLIENT_CREATION_SUCCESS_POPUP_ID));
    },
    closePopup() {
      dispatch(close(CLIENT_CREATION_SUCCESS_POPUP_ID));
    },
  }),
)(ClientCreationSuccessPopup);
