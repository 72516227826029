// @flow
import { connect } from 'react-redux';
import isEmptyObject from 'is-empty-object';

import { LoginMessage } from '../LoginMessage';
import * as selectors from '../../reducers';
import * as actions from '../../actions/users';


export default connect(
  state => {
    const error = selectors.getRecoverPasswordError(state);
    const { data = {} } = error;

    return ({
      isShow: !isEmptyObject(error),
      message: data.message ? data.message : 'Algo salió mal',
      tint: 'error',
    })
  },
  dispatch => ({
    onUnmount() {
      dispatch(actions.clearUserRequestRecoverPasswordError());
    }
  })
)(LoginMessage);