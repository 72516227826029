// @flow
import React, {
  useCallback,
  ComponentType,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MdAttachFile,
} from 'react-icons/md';
import i18n from 'i18n-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/fontawesome-pro-regular';

import Button from '../Button';
import FloatButton from './imageFloatButton';

import styles from './imagePicker.module.scss';


type MenuOptionType = {
  Icon: ComponentType<*>,
  title: string,
  tint?: 'main' | 'error',
  onClick?: Function, 
}

type ImagePickerProps = {
  value?: any,
  onChange?: Function,
  mode?: 'compact' | 'normal',
  size?: 'large' | 'medium' | 'banner',
  heightInPx?: number,
  widthInPx?: number,
  floatMenuOptions?: Array<MenuOptionType>,
}

const ImagePicker = ({
  value = {},
  onChange = () => {},
  mode = 'normal',
  size = 'medium',
  floatMenuOptions = [],
}: ImagePickerProps) => {

  const onDrop = useCallback((files: any) => {
    const temp = {
      file: files[0],
      url: URL.createObjectURL(files[0]),
    };
    if (onChange) onChange(temp);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*'
  });

  return (
    <div
      className={
        `
          ${styles.container}
          ${styles[size]}
        `
      }
    >
      <div
        className={
          `
            ${styles[size]}
            ${styles.image}
          `
        }
      > 
        {
          value.url ? (
            <img alt="imageUploaded" src={value.url}/>
          ) : (
            <div className={styles.placeholderBg}>
              <FontAwesomeIcon icon={faImage} />
            </div>
          )
        }
      </div>
      <div className={`
        ${styles.pickerArea}
        ${styles[size]}
      `}>
        <>
          <input {...getInputProps()} />
          {
            mode == 'normal' && (
              <Button
                {...getRootProps()}
                Icon={MdAttachFile}
                title={i18n.t('uploadImage')}
                tint="gray"
                clearMargins
              />
            )
          }
          {
            mode == 'compact'
              && <FloatButton
                    options={floatMenuOptions}
                    getRootProps={getRootProps} />
          }
        </>
      </div>
    </div>
  );
};

export default ImagePicker;
