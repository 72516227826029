// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { RenderInput, RenderTextEditor } from '../FieldRenderer';
import ToggleSendEmail from './ToggleSendMail';
import Button from '../Button';
// import TextEditor from '../TextEditor';

import onSubmit from './submit';
import validate from './validate';
import styles from './phaseSettingsForm.module.scss';

import * as selectors from '../../reducers';
import { INIT_EMPTY_SLATE_BODY } from '../../utils/slateSerializers';


type PhaseSettingsFormPropTypes = {
  handleSubmit: Function,
  isLoading: boolean,
};


const PhaseSettingsForm = ({
  handleSubmit,
  isLoading,
}: PhaseSettingsFormPropTypes) => (
  <form onSubmit={handleSubmit} className={styles.form}>
    <div className={styles.title}>
      <span>
        {"Seguimiento de contactos"}
      </span>
    </div>

    <ToggleSendEmail />

    <div className={styles.headerForm}>
      <div className={styles.subtitle}>
        {"Personaliza la plantilla del correo"}
      </div>

      <div className={styles.input}>
        <Field
          name='subject'
          component={RenderInput}
          placeholder="i.e: Ejemplo de texto"
          label="Asunto"
          size='medium'
          className={styles.input}
        />
      </div>
      <div className={styles.input}>
        <Field
          name='title'
          component={RenderInput}
          placeholder="i.e: Ejemplo de texto"
          label="Preencabezado"
          className={styles.input}
        />
      </div>
    </div>

    <Field
      name='emailContent'
      component={RenderTextEditor}
      label="Cuerpo:"
      // value={[]}
    />

    <Button
      isLoading={isLoading}
      title="Guardar"
      type="submit"
      tint='gray'
      className={styles.submitButton}
    />
  </form>
);

export default connect(
  state => {
    const { id, send_mail } = selectors.getPhaseSelected(state);
    const template = selectors.getPhaseEmailTemplate(state, id) || {};

    return ({
      isLoading: selectors.isPhaseUpdating(state, id),
      initialValues: {
        id,
        sendEmail: send_mail,
        emailContent: template.body || INIT_EMPTY_SLATE_BODY(),
        subject: template.subject,
        title: template.title,
      }
    })
  },
)(reduxForm({
  form: 'phaseSettingsForm',
  enableReinitialize: true,
  onSubmit,
  validate,
})(PhaseSettingsForm));
