// @flow
import debounce from 'lodash/debounce';
import React from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/md';

import * as actions from '../../actions/phases';
import Input from '../Input';

import styles from './phaseFilter.module.scss';

type PhaseFilterPropTypes = {
  onChange: string => void,
};

const PhaseFilter = ({ onChange }: PhaseFilterPropTypes) => (
  <div className={styles.phaseFilter}>
    <Input
      type="text"
      onChange={e => onChange(e.target.value)}
      placeholder="ie. Buscar estudiantes"
      Icon={MdSearch}
      clearMargins
    />
  </div>
);

export default connect(
  undefined,
  (dispatch, { id }) => ({
    onChange: debounce(
      text => dispatch(actions.updatePhaseFilter(id, text)),
      200,
    ),
  }),
)(PhaseFilter);
