// @flow
import type { Node } from 'react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './dim.module.scss';


type DimPropTypes = {
  children?: Node,
  isShowing?: boolean,
  theme?: string,
  onClick?: Function,
  transitionMs?: number
};

const Dim = ({
  isShowing = false,
  theme = 'default',
  onClick,
  transitionMs = 300,
  children,
}: DimPropTypes) => (
  <CSSTransition
    in={isShowing}
    timeout={transitionMs}
    classNames={styles}
    unmountOnExit
  >
    <div
      className={
        `
          ${styles.dim}
          ${styles[theme]}
        `
      }
      style={{
        transition: `${transitionMs}ms`,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="presentation"
    >
      { children }
    </div>
  </CSSTransition>
);

export default Dim;
