// @flow
import moment from 'moment';
// ///// TODO: REMOVE AFTER TESTS ////////
import range from 'lodash/range';
// ///////////////////////////////////////

import {
  call,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { delay } from 'redux-saga';

import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';
import {
  parseClient,
} from '../utils/serverStateTransformations';
import * as types from '../types/calendarViewFilter';
import * as selectors from '../reducers';
import * as actions from '../actions/calendarViewFilter';
import * as clientActions from '../actions/clients';
import { Clients } from '../api';
import { arrayOfClients } from '../api/schemas/clients';


function* fetchCalendarViewFilteredClients(action) {
  const token = yield select(selectors.getToken);
  const { startDate, endDate, phaseId } = yield select(selectors.getCalendarViewFilters);
  const board = yield select(selectors.getCurrentBoard);

  // ///// TODO: REMOVE AFTER TESTS ////////
  const rPhasesIds = yield select(selectors.getPhasesIds);
  const rPhaseId = rPhasesIds && rPhasesIds.length ? rPhasesIds[0] : -1;
  // ///////////////////////////////////////

  const mock = {
    delay: 2000,
    response: {
      statusCode: 200,
      body: range(0).map(i => ({
        id: i,
        first_name: `Samuel ${i}`,
        last_name: 'Chávez',
        next_relevant_date: new Date(),
        phase: phaseId || rPhaseId,
        phone: `${range(8).map(_ => i).join('')}`,
      })),
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients, 'list'],
        {
          token,
          filters: {
            board: board.id,
            phase: phaseId === -1 || phaseId == null ? undefined : phaseId,
            next_relevant_date__gte: moment(startDate).format(),
            next_relevant_date__lte: moment(endDate).format(),
          },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchCalendarViewFilteredClients saga');
    }

    const pResponse = response.map(parseClient);

    const {
      entities: { clients },
      result,
    } = normalize(pResponse, arrayOfClients);

    // Register
    yield put(clientActions.addClients(clients));
    yield put(actions.completeFetchCalendarViewFilter(clients, result));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchCalendarViewFilter({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchStartOfCalendarViewFilterClientFetch(): Iterator<any> {
  yield takeEvery(
    types.CALENDAR_VIEW_FILTER_FETCH_STARTED,
    fetchCalendarViewFilteredClients,
  );
}
