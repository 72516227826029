// @flow
import React from 'react';
import {
  MdPrint,
  MdEdit,
  MdCheck,
  MdClose,
} from 'react-icons/md';

import Button from '../Button';

import styles from './clientForm.module.scss';


type ClientEditionPopupToolbarPropTypes = {
  onPrintClicked: Function,
  onEditClicked: Function,
  onSubmit: Function,
  isEditing?: boolean,
  isLoading?: boolean
};

const ClientEditionFormToolbar = ({
  onPrintClicked,
  onEditClicked,
  onSubmit,
  isEditing,
  isLoading = false,
}: ClientEditionPopupToolbarPropTypes) => (
  <div className={styles.toolbar}>
    {
      !isEditing
        && (
          <Button
            type="button"
            size="large"
            Icon={MdPrint}
            tint="gray"
            onClick={onPrintClicked}
            elevation={3}
            clearMargins
            isRound
          /> 
        )
    }
    {
      isEditing
        ? (
          <Button
            type="button"
            size="large"
            Icon={MdCheck}
            tint="primary"
            elevation={3}
            isLoading={isLoading}
            onClick={onSubmit}
            clearMargins
            isRound
          />
        )
        : (
          <Button
            type="button"
            size="large"
            Icon={MdEdit}
            tint="gray"
            onClick={onEditClicked}
            elevation={3}
            isLoading={isLoading}
            clearMargins
            isRound
          />
        )
    }
    {
      isEditing
        && (
          <Button
            type="button"
            size="large"
            Icon={MdClose}
            tint="gray"
            onClick={onEditClicked}
            elevation={3}
            clearMargins
            isRound
          />
        )
    }
  </div>
);


export default ClientEditionFormToolbar;
