// @flow

import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { UPLOADING_CLIENT_TYPE } from '../types/uploadingClients';
import * as types from '../types/uploadingClients';
import * as common from './common';

export type UploadingClientsState = {
  byId: { [ID_TYPE]: UPLOADING_CLIENT_TYPE },
  order: Array<ID_TYPE>,
  phase: number,
  uploadingClientsStep: number,
  error: ERROR_TYPE,
  isUploading: boolean,
}

const byId = common.byId({
  fetched: [types.UPLOADING_CLIENTS_ADDED],
  replaced: [
    types.UPLOADING_CLIENTS_CLEARED,
  ],
});

const order = common.order({
  fetched: [types.UPLOADING_CLIENTS_ADDED],
  replaced: [
    types.UPLOADING_CLIENTS_CLEARED,
  ],
});

const isUploading = common.isFetching({
  started: [types.UPLOADING_CLIENTS_UPLOAD_STARTED],
  succeed: [types.UPLOADING_CLIENTS_UPLOAD_COMPLETED],
  failed: [types.UPLOADING_CLIENTS_UPLOAD_FAILED],
});

const error = common.error({
  clear: [
    types.UPLOADING_CLIENTS_ADDED,
    types.UPLOADING_CLIENTS_STEP_UPDATED,
  ],
  populate: [types.UPLOADING_CLIENTS_ADD_FAILED],
});

const uploadingClientsStep = common.mux({
  selected: [types.UPLOADING_CLIENTS_STEP_UPDATED],
  default: 1,
});

const phase = common.mux({
  selected: [types.UPLOADING_CLIENT_PHASE_UPDATED],
  default: 0,
});

const uploadingClients = combineReducers({
  byId,
  order,
  phase,
  isUploading,
  uploadingClientsStep,
  error,
});

export default uploadingClients;

// Selectors
export const getUploadingClientsStep = (
  state: UploadingClientsState,
): any => state.uploadingClientsStep;
export const getUploadingClientsPhase = (
  state: UploadingClientsState,
): any => state.phase;
export const getUploadingClient = (
  state: UploadingClientsState,
  id: ID_TYPE,
): Object => state.byId[id];
export const getUploadingClients = (
  state: UploadingClientsState,
): Array<Object> => state.order.map(id => getUploadingClient(state, id));
export const getUploadingClientsToAdd = (
  state: UploadingClientsState,
) => getUploadingClients(state).filter(client => client.isNew && !client.errors);
export const getUploadingClientsToUpdate = (
  state: UploadingClientsState,
) => getUploadingClients(state).filter(client => !client.isNew && !client.errors);
export const getWrongUploadingClients = (
  state: UploadingClientsState,
) => getUploadingClients(state).filter(client => client.errors);
export const getUploadingClientsError = (
  state: UploadingClientsState,
): ERROR_TYPE => state.error;
export const isUploadingClients = state => state.isUploading;
