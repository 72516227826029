// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ERROR_TYPE } from '../types/common';
import type {
  CLIENT_FORM_DESCRIPTION_TYPE,
  CONTACT_FORM_DESCRIPTION_TYPE,
  CLIENT_FORM_ACTION_TYPE,
} from '../types/clientForm';
import * as common from './common';
import * as types from '../types/clientForm';

export type ClientformState = {
  clientFormDescription: CLIENT_FORM_DESCRIPTION_TYPE | null,
  contactFormDescription: CONTACT_FORM_DESCRIPTION_TYPE | null,
  isFetching: boolean,
  error: ERROR_TYPE
};

const clientFormDescription = (
  state: CLIENT_FORM_DESCRIPTION_TYPE | null = null,
  action: CLIENT_FORM_ACTION_TYPE,
): CLIENT_FORM_DESCRIPTION_TYPE | null => {
  switch (action.type) {
    case types.CLIENT_FORM_FETCH_COMPLETED: {
      return action.payload.clientFormDescription;
    }

    case types.CLIENT_FORM_FETCH_FAILED: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const contactFormDescription = (
  state: CONTACT_FORM_DESCRIPTION_TYPE | null = null,
  action: CLIENT_FORM_ACTION_TYPE,
): CONTACT_FORM_DESCRIPTION_TYPE | null => {
  switch (action.type) {
    case types.CLIENT_FORM_FETCH_COMPLETED: {
      return action.payload.contactFormDescription;
    }

    case types.CLIENT_FORM_FETCH_FAILED: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const isFetching = common.isFetching({
  started: [types.CLIENT_FORM_FETCH_STARTED],
  succeed: [types.CLIENT_FORM_FETCH_COMPLETED],
  failed: [types.CLIENT_FORM_FETCH_FAILED],
});

const error = common.error({
  clear: [types.CLIENT_FORM_FETCH_STARTED],
  populate: [types.CLIENT_FORM_FETCH_FAILED],
});

const clientForm = combineReducers({
  clientFormDescription,
  contactFormDescription,
  isFetching,
  error,
});


export default clientForm;


// Selectors
export const getClientFormDescription = (
  state: ClientformState,
): ?CLIENT_FORM_DESCRIPTION_TYPE => state.clientFormDescription;
export const getContactFormDescription = (
  state: ClientformState,
): ?CONTACT_FORM_DESCRIPTION_TYPE => state.contactFormDescription;
export const isFetchingClientForm = (
  state: ClientformState,
): boolean => state.isFetching;
export const getClientformError = (
  state: ClientformState,
): ERROR_TYPE => state.error;
