// @flow
import { takeEvery, put, select } from 'redux-saga/effects';
import { types as jwtTypes } from 'nozzmo-redux-jwt';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { purgeStoredState } from 'redux-persist';

import persistorConfiguration from '../store/persistorConfiguration';
import * as selectors from '../reducers';

function* redirectAfterLogin(_action) {
  yield put(reset('loginForm'));
  const boardUUID = yield select(selectors.getUserFirstSchoolBoard);
  yield put(push(`dashboard/${boardUUID}`));
}

function* cleanAfterLogout() {
  purgeStoredState(persistorConfiguration);
  yield put(push('/'));
}

export function* watchRedirectAfterLogin(): Iterator<any> {
  yield takeEvery(jwtTypes.LOGIN_SUCCEED, redirectAfterLogin);
}

export function* watchLogout(): Iterator<any> {
  yield takeEvery(jwtTypes.LOGOUT, cleanAfterLogout);
}
