// @flow
import React from 'react';
import {
  MdDelete,
  MdAdd,
} from 'react-icons/md';

import placeholder from '../../../assets/images/user_ph.png';
import { USER_ROLES } from '../../settings';
import styles from './usersManager.module.scss';
import Button from '../Button';
import Select from '../Select';

type UserItemProps = {
  photo: Object,
  name: string,
  email: string,
  role: any,
  onChangeRole: Function,
  isDisabled: boolean,
  isConfirmed: Boolean,
  onDisableStatusChangeClick: Function,
}

const UserItem = ({
  photo,
  name,
  email,
  role,
  onChangeRole,
  isDisabled,
  isConfirmed,
  onDisableStatusChangeClick,
}: UserItemProps) => {
  return (
    <div className={styles.userItemContainer}>
      <div className={styles.userItemSection}>
        {/* Photo */}
        <div className={styles.photoContainer}>
          <img
            className={styles.photo}
            src={photo ? photo.url : placeholder}
            alt={name}
          />
        </div>
        {/* User Data */}
        <div className={styles.userDataContainer}>
          <p className={styles.userName}>
            {name}
          </p>
          <p className={styles.userEmail}>
            {email}
          </p>
        </div>
      </div>
      <div className={styles.userItemSection}>
        {/* Vertical Divider */}
        <div className={styles.verticalDivider} />
        {/* Options */}
        <div className={styles.optionsContainer}>
          <Select
            options={USER_ROLES}
            size='small'
            value={role}
            onChange={onChangeRole}
            disabled={!isConfirmed}
            className={styles.userItemRoleInput}
          />
          <Button
            Icon={isDisabled ? MdAdd : MdDelete}
            iconPosition="left"
            isTransparent
            tint={isDisabled ? 'success' : 'error'}
            title={isDisabled ? 'Habilitar' : 'Deshabilitar'}
            size='small'
            isOutlined
            disabled={!isConfirmed}
            onClick={onDisableStatusChangeClick}
          />
        </div>
      </div>
    </div>
  );
}

export default UserItem;
