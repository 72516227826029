// @flow
import React from 'react';
import { connect } from 'react-redux';

import { SETTINGS_MENU_ITEMS_ID } from '../../MenuItems/MenuItemsSettings';
import UsersManager from '../../UsersManager';
import UserSettings from '../../UserSettings';
import SecuritySettings from '../../SecuritySettings';

import * as selectors from '../../../reducers';

type SettingsPopupContentPropTypes = {
  settingsMenuItem: string,
};


const SettingsPopupContent = ({
  settingsMenuItem,
}: SettingsPopupContentPropTypes) => {
  let Content = null;

  switch (settingsMenuItem) {
    case 'usersMaintenance':
      Content = <UsersManager />;
      break;
    case 'userSettings':
      Content = <UserSettings />;
      break;
    case 'securitySettings':
      Content = <SecuritySettings />
      break;
    default:
      Content = <UserSettings  />;
      break;
  }

  return Content;
};


export default connect(
  state => ({
    settingsMenuItem: selectors.getMenuItemsStatus(state, SETTINGS_MENU_ITEMS_ID),
  })
)(SettingsPopupContent);
