// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdCheck,
  // MdFiberManualRecord,
  MdClearAll,
} from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as clientActions from '../../actions/clients';
import Button from '../Button';

import styles from './phaseAllClientsSelect.module.scss';


type PhaseAllClientsSelectPropTypes = {
  selectedState?: 'selected' | 'someSelected' | 'deselected',
  onClick: Function,
};

const ICON_REL = {
  selected: MdCheck,
  someSelected: MdClearAll,
  deselected: MdCheck,
};

const PhaseAllClientsSelect = ({
  selectedState = 'deselected',
  onClick,
}: PhaseAllClientsSelectPropTypes) => (
  <div
    className={styles.phaseAllClientsSelect}
    onClick={onClick}
    onKeyPress={onClick}
    role="presentation"
  >
    <Button
      size="extraSmall"
      onClick={onClick}
      tint={
        selectedState === 'deselected'
          ? 'mediumgray'
          : 'main'
      }
      Icon={ICON_REL[selectedState]}
      clearMargins
    />
  </div>
);
export default connect(
  (state, { id }) => {
    const selectedClients = selectors.getPhaseSelectedClients(state, id);
    const clientsIds = selectors.getPhaseClientsIds(state, id);

    let selectedState = 'someSelected';
    if (selectedClients.length === 0) {
      selectedState = 'deselected';
    } else if (clientsIds.length === selectedClients.length) {
      selectedState = 'selected';
    }

    return {
      selectedState,
      clientsIds,
    };
  },
  dispatch => ({
    onClick(selectedState: string, clientsIds: Array<ID_TYPE>) {
      switch (selectedState) {
        case 'selected': {
          dispatch(clientActions.deselectClients(clientsIds));
          break;
        }
        case 'deselected': {
          dispatch(clientActions.selectClients(clientsIds));
          break;
        }
        case 'someSelected': {
          dispatch(clientActions.deselectClients(clientsIds));
          break;
        }
        default: {
          return null;
        }
      }

      return null;
    },
  }),
  ({ selectedState, clientsIds, ...stateProps }, { onClick, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    selectedState,
    onClick() {
      onClick(selectedState, clientsIds);
    },
  }),
)(PhaseAllClientsSelect);
