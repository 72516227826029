// @flow
import React, {
  useCallback,
  ComponentType,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MdAttachFile,
} from 'react-icons/md';
import i18n from 'i18n-js';

import Button from '../Button';
import FloatButton from './profileFloatButton';

import UserPh from '../../../assets/images/user_ph.png';
import styles from './profileImagePicker.module.scss';


type MenuOptionType = {
  Icon: ComponentType<*>,
  title: string,
  tint?: 'main' | 'error',
  onClick?: Function, 
}

type ProfileImagePickerProps = {
  value?: any,
  onChange?: Function,
  mode?: 'compact' | 'normal',
  size?: 'large' | 'medium',
  floatMenuOptions?: Array<MenuOptionType>,
}

const ProfileImagePicker = ({
  value = {},
  onChange = () => {},
  mode = 'normal',
  size = 'medium',
  floatMenuOptions = [],
}: ProfileImagePickerProps) => {

  const onDrop = useCallback((files: any) => {
    const temp = {
      file: files[0],
      url: URL.createObjectURL(files[0]),
    };
    if (onChange) onChange(temp);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*'
  });

  return (
    <div className={styles.container}>
      <div
        className={
          `
            ${styles[size]}
            ${styles.profileImage}
          `
        }
      >
        <img
          alt="profileimage"
          src={value.url || UserPh}
        />
      </div>
      <div className={styles.pickerArea}>
        <>
          <input {...getInputProps()} />
          {
            mode == 'normal' && (
              <Button
                {...getRootProps()}
                Icon={MdAttachFile}
                title={i18n.t('uploadPhoto')}
                tint="gray"
                clearMargins
              />
            )
          }
          {
            mode == 'compact'
              && <FloatButton
                    options={floatMenuOptions}
                    getRootProps={getRootProps} />
          }
        </>
      </div>
    </div>
  );
};

export default ProfileImagePicker;
