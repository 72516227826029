// @flow
import { push } from 'connected-react-router';
import {
  call,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { delay } from 'redux-saga';
import { initialize, change } from 'redux-form';
import axios from 'axios';

import { fibonacciBackoff } from '../lib/retry';
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';
import { FORM_ID } from '../components/ClientForm';
// import { fibonacciBackoff } from '../lib/retry';
import * as types from '../types/clients';
import * as selectors from '../reducers';
import * as actions from '../actions/clients';
import * as phaseActions from '../actions/phases';
import * as commentActions from '../actions/comments';
import moment from 'moment';

import {
  parseClient,
  parseContact,
  parseComment,
} from '../utils/serverStateTransformations';

import { Clients, Contacts, Phases, api } from '../api';
import { arrayOfContacts } from '../api/schemas/contacts';
import { arrayOfComments } from '../api/schemas/comments';
import { CLIENT_CREATION_SUCCESS_POPUP_ID } from '../components/ClientCreationSuccessPopup';
import { open } from '../actions/closeable';

// const DASHBOARD_SECTION_VIEW = 'dashboard';


function* removeClient(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {},
    },
  };

  try {
    yield call(
      fibonacciBackoff,
      [Clients, 'remove'],
      { token, id: payload, mock },
    );
  } catch (e) { /* noop */ }
}

function* changeClientsPhase(action) {
  const { payload: { phase, order } } = action;
  const { meta: { atIndex } } = action;
  const token = yield select(selectors.getToken);
  const phasesIds = yield select(selectors.getPhasesIds);
  const toRemovePhasesIds = phasesIds.filter(id => id !== phase);

  yield put(phaseActions.addClientsToPhase(phase, order, atIndex));

  // Update clients next relevant date to point the destination phase
  const destinationPhase = yield (select(selectors.getPhase, phase));
  const { next_relevant_date, next_relevant_lapse } = destinationPhase;
  let finalNextRelevantDate = next_relevant_date;

  if (next_relevant_lapse) {
    finalNextRelevantDate = moment().add(next_relevant_lapse, 'days').toDate();
    finalNextRelevantDate.setHours(0, 0, 0, 0);
  }

  /* const newArray = destinationPhase.clients.slice();
  const newOrder = [
    ...newArray.slice(0, atIndex),
    ...order,
    ...newArray.slice(atIndex),
  ];
  yield put(phaseActions.updateClientsInPhase(phase, destinationPhase.clients, newOrder)); */

  // Set clients dirty (not confirmed)
  yield put(actions.setClientsDirty(order));

  const mock = {
    delay: 2000,
    response: {
      statusCode: 200,
      body: {},
    },
  };

  // Clear selected clients for all phases excepting their new phase
  for (let i = 0; i < toRemovePhasesIds.length; i += 1) {
    const toRemovePhaseId = toRemovePhasesIds[i];
    yield put(phaseActions.removeClientsFromPhase(toRemovePhaseId, order));
  }

  try {
    const { timeout } = yield race({
      response: call(
        [Phases.custom, 'addClients'],
        {
          token,
          id: phase,
          data: order,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) { throwTimeout('changeClientsPhase saga'); }

    yield put(actions.confirmClients(order, {
      next_relevant_date: finalNextRelevantDate,
    }));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failChangingPhaseOfClients({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* createClient(action) {
  let finalNextRelevantDate;
  const { payload } = action;
  // const token = yield select(selectors.getToken);
  // If it was an external creation, it is from a multilingual path
  // const uBoardId = yield select(selectors.getBoardUUIDParam, action.meta.isExternal);
  const sBoard = yield select(selectors.getCurrentBoard);
  const boardId = sBoard.id;

  const phaseId = payload.phase;
  const oldId = payload.id;

  // Add client to phase
  yield put(phaseActions.addClientsToPhase(phaseId, [oldId]));

  if (!action.meta.isExternal) {
    const destinationPhase = yield (select(selectors.getPhase, phaseId));
    const { next_relevant_lapse, next_relevant_date } = destinationPhase;
    finalNextRelevantDate = next_relevant_date || payload.next_relevant_date || moment().toDate();
  
    if (next_relevant_lapse) {
      finalNextRelevantDate = moment().add(next_relevant_lapse, 'days').toDate();
      finalNextRelevantDate.setHours(0, 0, 0, 0);
    }
  }

  // // Set clients dirty (not confirmed)
  // yield put(actions.setClientsDirty(order));

  const mock = {
    delay: 500,
    response: {
      statusCode: 200,
      body: {
        id: Math.floor(Math.random() * 9999999),
      },
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients, 'create'],
        {
          // token,
          data: {
            ...payload,
            board: boardId,
          },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('createClient saga');
    }

    // If it was an external creation, show alert and redirect
    if (action.meta.isExternal) {
      const boardUUID = yield select(selectors.getBoardUUIDParam, action.meta.isExternal);
      
      yield put(actions.completeAddingClient(oldId, response.id));

      yield put(push(`${boardUUID}`));
      yield put(open(CLIENT_CREATION_SUCCESS_POPUP_ID));
    } else {
      yield put(actions.completeAddingClient(oldId, response.id));
      // Update next relevant date for new clients
      yield put(actions.confirmClients(
        [response.id],
        {
          next_relevant_date: finalNextRelevantDate,
        },
      ));
      yield put(phaseActions.updateClientsInPhase(phaseId, [oldId], [response.id]));
    }
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failAddingClient({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchClient(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);
  const boardUUID = yield select(selectors.getBoardUUIDParam);
  // const currentSectionView = yield select(selectors.getSectionViewParam);
  const id = payload;

  const mock = {
    delay: 500,
    response: {
      statusCode: 200,
      body: {
        id,
        first_name: 'Lalo',
        last_name: 'Zaszas zas',
        birth_date: new Date(1989, 4, 3),
        next_relevant_date: new Date(2018, 10, 20),
        undefined,
        phone: '12341234',
        cycle: 2,
        level: 4,
        nombres: 'LALO ZASZAS ZAS',
        boolean_field: true,
        date: new Date(1994, 5, 26),
        decimal_field: 10.12,
        integer_field: 10,
        email: 'lalochiletiezo@gmail.com',
        float_type: 3.14159264,
        null_boolean: true,
        select: 2,
        text: 'neneco',
        textarea: 'Jao Gilberto',
        contacts: [],
      },
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients, 'detail'],
        {
          token,
          id,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchClient saga');
    }
    // yield put(reset(FORM_ID));
    // TODO: consider normalizing and saving the client contacts to the state
    const dClient = parseClient(response);

    // Omnisearch purpose: What happens when client does not belong to the current board?
    //    OK, no worries. Just redirect to the desired board!
    if (boardUUID) {
      const clientBoardUUID = dClient.board_short_uuid;
      if (clientBoardUUID !== boardUUID) {
        yield put(push(`/dashboard/${clientBoardUUID}`, { omnisearchValue: id }));
        // window.location.reload();
      }
    }

    yield put(initialize(
      FORM_ID,
      {
        ...dClient,
        // contacts: [],
      },
      false,
    ));

    yield put(actions.completeFetchingClient(dClient));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingClient({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
      object_id: id,
    }));
  }
}

function* fetchClientContacts(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);
  const id = payload;

  const mock = {
    delay: 700,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          first_name: 'Cocontacto',
          last_name: 'XXXXXXX',
          phone: '123412344233453456',
          email: 'cacacacaca@cacacac.com',
          birth_date: new Date(1000, 5, 5),
          relationship: 2,
          address: 'popoyon',
          profession: 'pepeyen',
          workplace: 'pipiyin',
          work_address: 'papayan',
          nombre_del_contacto: 'Jerejo Morales',
          telefono: '9999999999',
        },
        {
          id: 2,
          first_name: '22 Cocontacto',
          last_name: '22 XXXXXXX',
          phone: '22 123412344233453456',
          email: 'cacacacaca22@cacacac.com',
          birth_date: new Date(1222, 2, 2),
          relationship: 1,
          address: '22 popoyon',
          profession: '22 pepeyen',
          workplace: '22 pipiyin',
          work_address: '22 papayan',
          nombre_del_contacto: '22 Jerejo Morales',
          telefono: '22 9999999999',
        },
      ],
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Contacts, 'list'],
        {
          token,
          filters: { client: id },
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchClientContacts saga');
    }

    const dContacts = response.map(parseContact);
    const { entities } = normalize(dContacts, arrayOfContacts);
    yield put(change(FORM_ID, 'contacts', dContacts));
    yield put(actions.completeFetchingClientContacts(id, entities));
    // redux-form change action is not dispatching sometimes, call it again half a second later
    yield call(delay, 500);
    yield put(change(FORM_ID, 'contacts', dContacts));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(change(FORM_ID, 'contacts', [
      {
        birth_date: new Date(),
      },
      {
        birth_date: new Date(),
      },
    ]));

    // Fetch error
    yield put(actions.failFetchingClientContacts({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
      object_id: id,
    }));
  }
}

function* toggleStatusClient(action) {
  const { payload } = action;
  const {
    id,
    key,
  } = payload;

  const statusPayload = {
    is_archived: false,
    is_admitted: false,
  };

  // If is an statusChange, we just need to toggle the needed key
  const clientToUpdate = yield select(selectors.getClient, id);
  const { is_archived, is_admitted } = clientToUpdate;

  const currentStatus = { is_archived, is_admitted };
  statusPayload[key] = !currentStatus[key];
  // Important: we are updating a client. So, we must pass the current client props if we want to
  //              dispatch `startUpdatingClient()` action
  const clientPayload = {
    ...clientToUpdate,
    ...statusPayload,
  }

  yield put(actions.startUpdatingClient(id, clientPayload, undefined, true));
}

function* updateClient(action) {
  const {
    payload,
    meta,
  } = action;
  const {
    id,
    oldNextRelevantDate,
  } = payload;
  const token = yield select(selectors.getToken);
  const oldPhaseId = payload.oldClientPhase;
  const newPhaseId = payload.phase || oldPhaseId;
  const newNextRelevantDate = payload.next_relevant_date || oldNextRelevantDate;

  // Check if in a main change, something actually changed
  if (
    meta.statusChange
    || (
      meta.mainChange && (
        (
          (
            newPhaseId !== oldPhaseId
            && typeof oldPhaseId !== 'undefined'
            && typeof newPhaseId !== 'undefined'
          ) || (
            newNextRelevantDate !== oldNextRelevantDate
            && typeof oldNextRelevantDate !== 'undefined'
            && typeof newNextRelevantDate !== 'undefined'
          )
        )
      )
    )
    || !meta.mainChange
  ) {
    const destinationPhase = yield (select(selectors.getPhase, newPhaseId));
    const { next_relevant_lapse } = destinationPhase;
    let finalNextRelevantDate = newNextRelevantDate;

    if (next_relevant_lapse) {
      finalNextRelevantDate = moment().add(next_relevant_lapse, 'days').toDate();
      finalNextRelevantDate.setHours(0, 0, 0, 0);
    }

    // If phase changed
    if (oldPhaseId !== newPhaseId) {
      // Add client to phase
      yield put(phaseActions.addClientsToPhase(newPhaseId, [id]));

      // Remove client from phase
      yield put(phaseActions.removeClientsFromPhase(oldPhaseId, [id]));
    }

    // Set clients dirty (not confirmed)
    yield put(actions.setClientsDirty([id]));

    const mock = {
      delay: 2000,
      response: {
        statusCode: 200,
        body: {},
      },
    };

    try {
      const { response, timeout } = yield race({
        response: call(
          (meta.mainChange || meta.statusChange)
            ? [Clients, 'update']
            : [Clients, 'replace'],
          {
            token,
            id,
            data: {
              ...payload,
              profile_picture: undefined,
            },
            mock,
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout('updateClient saga');
      }

      if (response && response.board_specific_data) {
        const boardData = response.board_specific_data;

        for (const key in boardData) {
          const value = boardData[key] || {};
          if (value.plugin_uid && value.plugin_uid == 'file') {
            yield put(change(FORM_ID, key, {
              plugin_uid: 'file',
              files: value.files,
            }));
          }
        }
      }

      yield put(actions.completeUpdatingClient(id));
      yield put(actions.confirmClients(
        [id],
        {
          next_relevant_date: finalNextRelevantDate,
        },
      ));
      yield put(actions.completeEditingClientForm(id));
    } catch (error) {
      const {
        statusCode,
        message,
        data,
        isPlain,
      } = error;

      // Fetch error
      yield put(actions.failUpdatingClient({
        statusCode,
        message,
        data: isPlain ? 'Error en el servidor' : data,
        retryAction: action,
        object_id: id,
      }));
    }
  }
}

function* fetchClientComments(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);
  const id = payload;

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        // {
        //   id: 1,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 1,
        //     first_name: "Samuel",
        //     last_name: "Chavez",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 2,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 3,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 4,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 5,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 6,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 7,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // },
        // {
        //   id: 8,
        //   object_pk: id,
        //   creation_date: new Date(),
        //   body: {a: 1, b: 2},
        //   created_by: {
        //     id: 2,
        //     first_name: "Samuel 2",
        //     last_name: "Chavez 2",
        //     profile_picture: "http://www.virtualffs.co.uk/my%20FFS%20thesis%20images/Male%20Androgyne.jpg"
        //   }
        // }
      ],
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients.custom, 'getComments'],
        {
          token,
          id,
          mock,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchClientComments saga');
    }

    const dComments = response.map(parseComment);
    const { result, entities: { comments } } = normalize(dComments, arrayOfComments);

    yield put(commentActions.fetchComments(comments, result));
    yield put(actions.completeFetchingClientComments(id, result, comments));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingClientComments({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
      object_id: id,
    }));
  }
}

function* clientSetProfilePicture(action) {
  const { id, profile_picture } = action.payload;
  const token = yield select(selectors.getToken);
  const file = profile_picture.file;

  const headers = {
    Authorization: token,
    'Content-Type': 'multipart/form-data'
  };
  const data = new FormData();
  data.append('file', file, file.name);

  axios({
    method: 'post',
    url: api.getURL(`clients/${id}/profile-picture`),
    data,
    headers,
  })
    .then((response) => response.data)
    .catch((error) => { console.log(error) });
}

function* clientSetFileAsProfilePicture(action) {
  const { id, profile_picture } = action.payload;
  const token = yield select(selectors.getToken);

  try {
    const { timeout } = yield race({
      response: call(
        [Clients, 'update'],
        {
          token,
          id,
          data: {
            profile_picture: profile_picture.UUID
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) { throwTimeout('changeClientsPhase saga'); }

    yield put(actions.completeSetFileAsProfilePicture());
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failSetFileAsProfilePicture({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
      object_id: id,
    }));
  }
}

function* clientRemoveProfilePicture(action) {
  const { id } = action.payload;
  const token = yield select(selectors.getToken);
  try {
    const { timeout } = yield race({
      response: call(
        [Clients.custom, 'removeProfilePicture'],
        {
          token,
          id,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) { throwTimeout('removeProfilePicture saga'); }

  } catch (error) { /** noop */ }
}


export function* watchClientDeletion(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_REMOVED,
    removeClient,
  );
}

export function* watchClientsPhaseChange(): Iterator<any> {
  yield takeEvery(
    types.CLIENTS_CHANGE_PHASE_STARTED,
    changeClientsPhase,
  );
}

export function* watchClientCreation(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_ADD_STARTED,
    createClient,
  );
}

export function* watchClientFetch(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_FETCH_STARTED,
    fetchClient,
  );
}

export function* watchClientContactsFetch(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_CONTACTS_FETCH_STARTED,
    fetchClientContacts,
  );
}

export function* watchClientUpdate(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_UPDATE_STARTED,
    updateClient,
  );
}

export function* watchClientToggleStatus(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_TOGGLE_STATUS_STARTED,
    toggleStatusClient,
  );
}

export function* watchClientCommentsFetch(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_COMMENTS_FETCH_STARTED,
    fetchClientComments,
  );
}

export function* watchClientSetProfilePicture(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_SET_PROFILE_PICTURE_STARTED,
    clientSetProfilePicture,
  )
}

export function* watchSetFileAsProfilePicture(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_SET_FILE_AS_PROFILE_PICTURE,
    clientSetFileAsProfilePicture,
  )
}

export function* watchClientRemoveProfilePicture(): Iterator<any> {
  yield takeEvery(
    types.CLIENT_REMOVE_PROFILE_PICTURE,
    clientRemoveProfilePicture,
  )
}
