// @flow
import React from 'react';

import logo from './logo.png';

import styles from './externalFooter.module.scss';

const ExternalFooter = () => (
  <div className={styles.externalFooter}>
    <div className={styles.title}>
      {'Formulario generado utilizando el servicio:'}
    </div>
    <img src={logo} alt="Edoo Admissions" />
  </div>
);

export default ExternalFooter;
