// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Row, Grid } from 'react-flexbox-grid';
// import i18n from 'i18n-js';

import * as selectors from '../../reducers';


import styles from './externalEpilogue.module.scss';

type ExternalEpiloguePropTypes = {
  epilogue: any,
};

const ExternalEpilogue = ({
  epilogue,
}: ExternalEpiloguePropTypes) => (
  <div className={styles.externalEpilogue}>
    {
      epilogue && (
        <Grid>
          <Row>
            { /* eslint-disable-next-line react/no-danger */ }
            <p dangerouslySetInnerHTML={epilogue} />
          </Row>
        </Grid>
      )
    }
  </div>
);

export default connect(
  (state) => {
    const { epilogue } = selectors.getCurrentBoard(state) || {};
    const i18nLocale = selectors.getLocaleParam(state, true);

    const defaultLocaleKey = Object.keys(epilogue).length
      ? Object.keys(epilogue)[0]
      : undefined;
    
    const epilogueHTMLText = (i18nLocale in epilogue || defaultLocaleKey)
      ? epilogue[i18nLocale] || epilogue[defaultLocaleKey]
      : ''
    /* const epilogue = i18n.t(
      board.epilogue,
      { defaultValue: board.epilogue, escapeInterpolation: false },
    ); */
    return {
      epilogue: epilogue ? { __html: `${epilogueHTMLText}` } : null,
    };
  },
)(ExternalEpilogue);
