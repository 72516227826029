// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeaderRightGroup.module.scss';


type PopupHeaderRightGroupPropTypes = {
  children?: Node
};

const PopupHeaderRightGroup = ({
  children,
} : PopupHeaderRightGroupPropTypes) => (
  <div className={styles.popupHeaderRightGroup}>
    { children }
  </div>
);

export default PopupHeaderRightGroup;
