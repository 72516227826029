// @flow
import type { ComponentType } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './tabs.module.scss';


type TabsPropTypes = {
  options: Array<{
    title: string,
    active: boolean,
    onClick: Function,
    Icon: ComponentType<*>
  }>
};

const Tabs = ({ options }: TabsPropTypes) => (
  <div
    className={`
      ${styles.tabs}
      ${styles.centered}
    `}
  >
    {
      options.map(
        ({
          title, active, Icon, onClick,
        }, idx) => (
          <div
            key={uuidv4()}
            className={`
              ${styles.tab}
              ${active && styles.active}
            `}
            role="button"
            tabIndex={idx}
            onKeyPress={onClick}
            onClick={onClick}
          >
            {Icon && <Icon size={20} />}
            <span className={styles.text}>
              { title }
            </span>
          </div>
        ),
      )
    }
  </div>
);

export default Tabs;
