// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type RELEVANT_DATES_FETCH_STARTED_TYPE = {
  type: 'RELEVANT_DATES_FETCH_STARTED',
  payload: ID_TYPE,
};
export const RELEVANT_DATES_FETCH_STARTED = 'RELEVANT_DATES_FETCH_STARTED';

export type RELEVANT_DATES_FETCH_COMPLETED_TYPE = {
  type: 'RELEVANT_DATES_FETCH_COMPLETED',
  payload: Array<String>,
};
export const RELEVANT_DATES_FETCH_COMPLETED = 'RELEVANT_DATES_FETCH_COMPLETED';

export type RELEVANT_DATES_FETCH_FAILED_TYPE = {
  type: 'RELEVANT_DATES_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const RELEVANT_DATES_FETCH_FAILED = 'RELEVANT_DATES_FETCH_FAILED';

export type DATES_ACTION_TYPE =
  | RELEVANT_DATES_FETCH_STARTED_TYPE
  | RELEVANT_DATES_FETCH_COMPLETED_TYPE
  | RELEVANT_DATES_FETCH_FAILED_TYPE;
