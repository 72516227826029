// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';
import type { CLIENT_TYPE } from './clients';


export type CALENDAR_VIEW_FILTER_TYPE = {
  startDate: Date,
  endDate: Date,
  phaseId: ?number
};

export type CALENDAR_VIEW_FILTER_UPDATED_TYPE = {
  type: 'CALENDAR_VIEW_FILTER_UPDATED',
  payload: {
    startDate?: Date,
    endDate?: Date,
    phaseId?: number
  }
};
export const CALENDAR_VIEW_FILTER_UPDATED = 'CALENDAR_VIEW_FILTER_UPDATED';

export type CALENDAR_VIEW_FILTER_FETCH_STARTED_TYPE = {
  type: 'CALENDAR_VIEW_FILTER_FETCH_STARTED'
};
export const CALENDAR_VIEW_FILTER_FETCH_STARTED = 'CALENDAR_VIEW_FILTER_FETCH_STARTED';

export type CALENDAR_VIEW_FILTER_FETCH_COMPLETED_TYPE = {
  type: 'CALENDAR_VIEW_FILTER_FETCH_COMPLETED',
  payload: {
    entities: { [ID_TYPE]: CLIENT_TYPE },
    order: Array<ID_TYPE>
  }
};
export const CALENDAR_VIEW_FILTER_FETCH_COMPLETED = 'CALENDAR_VIEW_FILTER_FETCH_COMPLETED';

export type CALENDAR_VIEW_FILTER_FETCH_FAILED_TYPE = {
  type: 'CALENDAR_VIEW_FILTER_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CALENDAR_VIEW_FILTER_FETCH_FAILED = 'CALENDAR_VIEW_FILTER_FETCH_FAILED';

export type CALENDAR_VIEW_FILTER_ACTION_TYPE =
  | CALENDAR_VIEW_FILTER_UPDATED_TYPE
  | CALENDAR_VIEW_FILTER_FETCH_STARTED_TYPE
  | CALENDAR_VIEW_FILTER_FETCH_COMPLETED_TYPE
  | CALENDAR_VIEW_FILTER_FETCH_FAILED_TYPE;
