// @flow
import isEmptyObject from 'is-empty-object';
import * as EmailValidator from 'email-validator';
import i18n from 'i18n-js';


export const generateClientValidator = (
  validateSchoolSpecificClientData: Object => Object,
  validateSchoolSpecificContactData: Object => Object,
  canCreateClientWithoutContacts: boolean,
  clientRequiredFields: Array<string>,
) => (values: Object) => {
  let errors = {};

  // if (!values.next_relevant_date) {
  //   errors.next_relevant_date = "Valor requerido";
  // } else if (values.next_relevant_date.constructor !== Date) {
  //   errors.next_relevant_date = "Fecha inválida";
  // }

  if (!values.phase || values.phase === -1) {
    errors.phase = i18n.t('requiredValue');
  }

  if (!values.first_name) {
    errors.first_name = i18n.t('requiredValue');
  }

  if (!values.last_name) {
    errors.last_name = i18n.t('requiredValue');
  }
  /*
    NOTE: We want to validate this required fields only when we create a new client
    (or load many in bulk) but they are not required when we update an existing client
  */

  if (!values.id) {
    if (!values.birth_date) {
      errors.birth_date = i18n.t('requiredValue');
    } else if (typeof values.birth_date === 'string') {
      if (Number.isNaN(Date.parse(values.birth_date))) {
        errors.birth_date = 'Fecha invalida';
      }
    } else if (values.birth_date.constructor !== Date) {
      errors.birth_date = 'Fecha inválida';
    } else if (values.birth_date > new Date()) {
      errors.birth_date = 'Fecha inválida';
    }

    if (!values.phone && clientRequiredFields.includes('phone')) {
      errors.phone = i18n.t('requiredValue');
    }

    if (!values.cycle || values.cycle === -1) {
      errors.cycle = i18n.t('requiredValue');
    }

    if (!values.level || values.level === -1) {
      errors.level = i18n.t('requiredValue');
    }
  }

  errors = {
    ...errors,
    ...validateSchoolSpecificClientData(values),
  };

  const contactsErrors = [];
  const contactsValues = (values.contacts || []).filter(contact =>
    Object.values(contact).every(el => el !== undefined) ||
    Object.values(contact).some(el => el !== undefined)
  );

  contactsValues.forEach((contact, index) => {
    let contactErrors = {};

    if (!contact.first_name) {
      contactErrors.first_name = 'Valor requerido';
    }

    if (!contact.last_name) {
      contactErrors.last_name = 'Valor requerido';
    }

    if (!contact.phone) {
      contactErrors.phone = 'Valor requerido';
    }

    if (!contact.email) {
      contactErrors.email = 'Valor requerido';
    } else if (!EmailValidator.validate(contact.email)) {
      contactErrors.email = 'Este email no está bien formateado';
    }

    /*
      NOTE: As we seen before, this fields are not required when we update an existing client
    */
    if (!values.id) {
      if (!contact.birth_date) {
        contactErrors.birth_date = 'Valor requerido';
      } else if (typeof values.birth_date === 'string') {
        if (Number.isNaN(Date.parse(values.birth_date))) {
          errors.birth_date = 'Fecha inválida';
        }
      } else if (contact.birth_date.constructor !== Date) {
        contactErrors.birth_date = 'Fecha inválida';
      } else if (contact.birth_date > new Date()) {
        contactErrors.birth_date = 'Fecha inválida';
      }
  
      if (!contact.relationship || contact.relationship === -1) {
        contactErrors.relationship = 'Valor requerido';
      }
  
      if (!contact.address) {
        contactErrors.address = 'Valor requerido';
      }
    }


    // contact.profession
    // contact.workplace
    // contact.work_address

    contactErrors = {
      ...contactErrors,
      ...validateSchoolSpecificContactData(contact),
    };

    if (!isEmptyObject(contactErrors)) {
      contactsErrors[index] = contactErrors;
    }
  });

  const hasEmptyContact = contactsValues.length > 0 ? contactsValues.some((contact) => {
    return isEmptyObject(contact) || Object.values(contact).every(el => el === undefined);
  }) : true;

  if (hasEmptyContact && !canCreateClientWithoutContacts) {
    errors.contacts = { _error: i18n.t('canCreateWithoutContactsWarning') };
  }

  if (contactsErrors.length) {
    errors.contacts = contactsErrors;
  }

  return errors;
};


export const generateClientReplacer = (
  replaceSchoolSpecificClientData: Object => Object,
  replaceSchoolSpecificContactData: Object => Object,
  // canCreateClientWithoutContacts: boolean,
) => (values: Object) => {
  let replacements = {};

  replacements = {
    ...replacements,
    ...replaceSchoolSpecificClientData(values),
  };

  const contactsReplacements = [];
  (values.contacts || []).forEach((contact, index) => {
    let contactReplacements = {};

    contactReplacements = {
      ...contactReplacements,
      ...replaceSchoolSpecificContactData(contact),
    };

    if (!isEmptyObject(contactReplacements)) {
      contactReplacements[index] = contactReplacements;
    }
  });

  if (contactsReplacements.length) {
    replacements.contacts = contactsReplacements;
  }

  return replacements;
};
