// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import { FullLoader } from '../Loader';

import type {ID_TYPE} from '../../types/common';
import * as selectors from '../../reducers';
import uuid from 'uuid'

import styles from './clientCalculatedData.module.scss';


type ClientGenericDataFormPropTypes = {
  isLoading?: boolean,
  clientId: ID_TYPE,
  calculatedFields?: Array<Object>
};
const ClientCalculatedData = ({
  calculatedFields,
  isLoading = false,
  // phasesOptions,
  // clientId,
}: ClientGenericDataFormPropTypes) => (
  <Fragment>
    {isLoading && <FullLoader title="Cargando cliente" tint="gray"/>}
    {
      !isLoading && (
        <Fragment>

          <div className={styles.calculatedDataContainer}>
            {calculatedFields && Object.keys(calculatedFields).map(
              (key) => {
                const elm = calculatedFields[key];

                return (
                  <div className={styles.calculatedField} key={uuid()}>
                    <span className={styles.calculatedFieldTitle}>
                      {elm.name}
                    </span>
                    <span className={styles.calculatedFieldValue}>
                      {elm.value}
                    </span>
                  </div>
                );
              }
            )}
          </div>
          <hr className={styles.metaHr}/>

        </Fragment>
      )
    }
  </Fragment>
);


export default connect(
  (state, { clientId }) => {
    // Deduct phases options
    const client = selectors.getClient(state, clientId);
    const calculatedFields = { ...client.calculated_fields };

    return {
      calculatedFields,
    };
  },
)(ClientCalculatedData);
