// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import type {
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
} from 'react-beautiful-dnd';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';

import type { CLIENT_TYPE } from '../../types/clients';
import type { ID_TYPE } from '../../types/common';
import { DEFAULT_CLIENT_HEIGHT_SIZE } from '../../settings';
import * as selectors from '../../reducers';
import Client from '../Client';
import BigPlaceholder from '../BigPlaceholder';
import imgPlaceholder from './clients_placeholder.png';

// import styles from './phaseClients.module.scss';

type PhaseClientsPropTypes = {
  clients: Array<CLIENT_TYPE>,
  hasNoStatusFilter: boolean,
  id: ID_TYPE
};

/* type InnerListPropTypes = {
  clients: Array<CLIENT_TYPE>,
  hasNoStatusFilter: boolean,
}; */
/*
class InnerList extends Component<InnerListPropTypes> {
  shouldComponentUpdate(nextProps) {
    const { clients } = this.props;

    if (nextProps.clients === clients) return false;

    return true;
  }

  render() {
    const { clients, hasNoStatusFilter } = this.props;

    return clients.map((client: CLIENT_TYPE, index: number) => (
      <Draggable key={client.id} draggableId={String(client.id)} index={index} quepex={'666'}>
        {(
          dragProvided: DraggableProvided,
          dragSnapshot: DraggableStateSnapshot,
        ) => ( 
          <Client
            key={client.id}
            id={client.id}
            phase={client.phase}
            firstName={client.first_name}
            lastName={client.last_name}
            profilePicture={client.profile_picture}
            nextRelevantDate={client.next_relevant_date}
            isConfirmed={client.isConfirmed}
            index={index}
            provided={dragProvided}
            isDragging={dragSnapshot.isDragging}
            hasNoStatusFilter={hasNoStatusFilter}
            isAdmitted={client.is_admitted}
            isArchived={client.is_archived}
          />
        )}
      </Draggable>
    ));
  }
}
*/

type RowProps = {
  data: CLIENT_TYPE[],
  index: number,
  style: Object,
  hasNoStatusFilter: bool,
};
const Row = ({ data: { clients, hasNoStatusFilter }, index, style }: RowProps) => {
  const client: CLIENT_TYPE = clients[index];

  // We are rendering an extra item for the placeholder
  // To do this we increased our data set size to include one 'fake' item
  if (!client) {
    return null;
  }

  return (
    <div style={style}>
      <Draggable key={client.id} draggableId={String(client.id)} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <Client
              key={client.id}
              id={client.id}
              phase={client.phase}
              firstName={client.first_name}
              lastName={client.last_name}
              profilePicture={client.profile_picture}
              nextRelevantDate={client.next_relevant_date}
              isConfirmed={client.isConfirmed}
              index={index}
              provided={provided}
              isDragging={snapshot.isDragging}
              hasNoStatusFilter={hasNoStatusFilter}
              isAdmitted={client.is_admitted}
              isArchived={client.is_archived}
            />
        )}
      </Draggable>
    </div>
  );
};

const PhaseClients = ({
  clients = [],
  hasNoStatusFilter,
  id,
}: PhaseClientsPropTypes) => {

  return (
  <Droppable 
    droppableId={`phase-${id}`} 
    type="client" 
    mode="virtual"
    renderClone={(
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot,
      rubric: DraggableRubric,
    ) => (
        <Client
          key={clients[rubric.source.index].id}
          id={clients[rubric.source.index].id}
          phase={clients[rubric.source.index].phase}
          firstName={clients[rubric.source.index].first_name}
          lastName={clients[rubric.source.index].last_name}
          profilePicture={clients[rubric.source.index].profile_picture}
          nextRelevantDate={clients[rubric.source.index].next_relevant_date}
          isConfirmed={clients[rubric.source.index].isConfirmed}
          index={rubric.source.index}
          provided={provided}
          isDragging={snapshot.isDragging}
          hasNoStatusFilter={hasNoStatusFilter}
          isAdmitted={clients[rubric.source.index].is_admitted}
          isArchived={clients[rubric.source.index].is_archived}
        />
    )}
  >
    {(
      dropProvided: DroppableProvided,
      snapshot: DroppableStateSnapshot,
    ) => (
      clients.length > 0 ? (
        <div
          style={{ height: '100%', overflow: 'hidden' }}
        >
          <AutoSizer
            defaultWidth={1}
            defaultHeight={1}
          >
            {({ width, height }) => (
              <>
                <List
                style={{ willChange: 'unset' }}
                height={height}
                itemCount={ snapshot.isUsingPlaceholder ? clients.length + 1 : clients.length } // Because of the placeholder
                width={width}
                itemSize={DEFAULT_CLIENT_HEIGHT_SIZE}
                // you will want to use List.outerRef rather than List.innerRef as it has the correct height when the list is unpopulated
                outerRef={dropProvided.innerRef}
                itemData={{ clients, hasNoStatusFilter }}
                hasNoStatusFilter={hasNoStatusFilter}
              >
                {Row}                
              </List>
            </>
          )}
          </AutoSizer>
        </div>
      ) : (
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          <BigPlaceholder
            pictureURL={imgPlaceholder}
            title="Esta fase no tiene clientes"
            description="Crea clientes para esta fase en el botón de arriba a la derecha"
            composition="vertical"
          />
        </div>
      )
    )}
  </Droppable>)
};

export default connect(
  (state, { id }) => {
    // TODO: check, the latest filter should not be needed, I used it here because in my mocks,
    // all phases share the same clients and when I deleted a phase, it´s clients got deleted.
    const cycleFilter = selectors.getGlobalCycleIdFilter(state);
    const levelFilter = selectors.getGlobalLevelIdFilter(state);
    const startBirthDateFilter = selectors.getGlobalStartBirthDateFilter(state);
    const endBirthDateFilter = selectors.getGlobalEndBirthDateFilter(state);

    const clients = selectors
      .getPhaseClients(state, id)
      .filter((client) => {
        if (!cycleFilter) return true;
        if (Array.isArray(cycleFilter)) return cycleFilter.includes(client.cycle);
        return client.cycle === cycleFilter;
      })
      .filter(client => !levelFilter || client.level === levelFilter)
      .filter((client) => {
        const birthDate = moment(client.birth_date);
        const startDate = moment(startBirthDateFilter);
        
        if (!startBirthDateFilter) return true;
        return !endBirthDateFilter ? true : birthDate.isSameOrAfter(startDate, 'days');
      })
      .filter((client) => {
        const birthDate = moment(client.birth_date);
        const endDate = moment(endBirthDateFilter);
        
        if (!endBirthDateFilter) return true;
        return !startBirthDateFilter ? true : birthDate.isSameOrBefore(endDate, 'days');
      });

    return {
      clients,
      hasNoStatusFilter: selectors.getGlobalNoStatusFilter(state),
    };
  },
)(PhaseClients);
