// @flow
import React from 'react';
import Dropzone from 'react-dropzone';
import uuid from 'uuid';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';

import pictureURL from '../../../assets/images/drop_file_here.png';
import FileItem from './FileItem';
import { getFileExtension } from '../../utils';
import * as selectors from '../../reducers';
import * as fileActions from '../../actions/files';
import * as clientActions from '../../actions/clients';

import styles from './filesUploader.module.scss';
import FileItemCompact from './FileItemCompact';

type FileUploaderOptionsType = {
  config: Object,
  files: {
    file: File,
    fileName: string,
    fileExtension: string,
    error: Object,
    progress: number,
  }[],
}

type FilesUploaderTypes = {
  value: FileUploaderOptionsType;
  onChange: Function;
  startFileUpload: Function;
  onStartUpload: Function;
  isPreview: boolean;
  allowed_extensions: string,
  onSetAsProfilePicture: Function,
  dropzoneRef: any,
  hideDropArea: boolean,
  cardsMode: boolean,
  noSaga: boolean,
  filesItemsContainerClassName: string,
  tint?: string,
}

const FilesUploader = ({
  value,
  onChange,
  startFileUpload,
  onStartUpload,
  isPreview,
  allowed_extensions,
  onSetAsProfilePicture,
  dropzoneRef,
  hideDropArea,
  cardsMode,
  noSaga,
  filesItemsContainerClassName,
  tint,
  ...props
}: FilesUploaderTypes) => {
  const onDrop = (files: Array<Object>) => {
    const filesShape = [];
    const filesToUpload = [];

    for (let i = 0; i < files.length; i++) {
      const elm = files[i];
      // eslint-disable-next-line no-param-reassign
      const fileTemp = {
        UUID: uuid(),
        name: elm.name,
        extension: getFileExtension(elm.name),
        progress: 0,
      };

      filesShape.push(fileTemp);
      filesToUpload.push({
        ...fileTemp,
        file: elm,
      });
    }

    const currentFiles: any[] = value.files || [];
    const newValue: FileUploaderOptionsType = {
      ...value,
      plugin_uid: 'file',
      files: [
        ...currentFiles,
        ...filesShape,
      ],
    };

    if (onChange) onChange(newValue);
    if (onStartUpload) onStartUpload(filesToUpload);
    if (!noSaga) startFileUpload(filesToUpload, props.name);
  };

  const removeFile = (fileId: string) => {
    const newValue: FileUploaderOptionsType = {
      ...value,
      files: [
        ...value.files.filter(file => file.UUID !== fileId),
      ],
    };

    onChange(newValue);
  };

  return (
    <div className={cardsMode ? styles.cardsContainer : styles.dropzoneContainer}>
      <Dropzone accept={allowed_extensions || ''} onDrop={onDrop} ref={dropzoneRef}>
        {({ getRootProps, getInputProps, isDragActive }) => !isPreview
          && (
            <div
              {...getRootProps()}
              className={!hideDropArea ? styles.dropzone : ''}
            >
              <input {...getInputProps()} />
              { !hideDropArea && (
                <div className={styles.dropFileZone}>
                  <span
                    className={`
                      ${isDragActive ? styles.placeholderHightlight : styles.placeholder}
                    `}
                    style={{ border: isDragActive ? `2px dashed ${tint}` : '' }}
                  >
                    <img src={pictureURL} alt="alt" />
                    <p>
                      {`Adjunta archivos arrástrandolos aquí o`}
                      <span 
                        className={styles.highlight}
                        style={{ color: tint || '' }}
                      >
                        selecciona uno.
                      </span>
                      {
                        allowed_extensions
                        && allowed_extensions !== ''
                        && (
                          <>
                            <br />
                            <span className={styles.fileExtension}>{ allowed_extensions }</span>
                          </>
                        )
                      }
                    </p>
                  </span>
                </div>
              )}
            </div>
          )}
      </Dropzone>
      <div
        className={`
          ${cardsMode ? styles.cardsMode : styles.fileContainer}
          ${filesItemsContainerClassName || ''}
        `}
      >
        {
          value
          && value.files
          && value.files.map((file) => {
            if (!cardsMode) {
              return (
                <FileItem
                  key={file.UUID}
                  fileId={file.UUID}
                  name={file.name}
                  extension={file.extension}
                  progress={file.progress}
                  onDelete={onChange ? removeFile : null}
                  onSetAsProfilePicture={() => onSetAsProfilePicture(file)}
                  isPreview={isPreview}
                  fileLink={file.url}
                  error={{}}
                />
              );
            }
            return (
              <FileItemCompact
                key={file.UUID}
                fileId={file.UUID}
                name={file.name}
                extension={file.extension}
                progress={file.progress}
                onDelete={onChange ? removeFile : null}
                fileLink={file.url}
                error={{}}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default connect(
  (state) => {
    const client = selectors.getEditingClient(state);
    if (client) {
      return {
        clientId: client.id,
      };
    }

    return {
      clientId: -1,
    };
  },
  dispatch => ({
    startFileUpload(files: Array<Object>, fieldName: string = undefined) {
      dispatch(fileActions.startFileUpload(files, fieldName));
    },
    onSetAsProfilePicture(clientId: ID_TYPE, file: any) {
      dispatch(clientActions.setFileAsProfilePicture(clientId, file));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSetAsProfilePicture(file) {
      dispatchProps.onSetAsProfilePicture(stateProps.clientId, file);
    },
  }),
)(FilesUploader);
