// @flow
import uuid from 'uuid';
import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Form, FieldArray } from 'redux-form';
import { Row, Col, Grid } from 'react-flexbox-grid';

import * as actions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';
import * as selectors from '../../reducers';

import Button from '../Button';
import ClientGenericDataForm from '../ClientGenericDataForm';
import ClientSchoolSpecificFormRenderer from '../ClientSchoolSpecificFormRenderer';
import ClientContactsForm from '../ClientContactsForm';
import GenericPopup from '../GenericPopup';

import type { ClientFormPropTypes } from './index';

import {
  clientReduxFormDecorator,
  clientFormDecorator,
} from './index';

import styles from './clientForm.module.scss';

class ClientCreationFormDummy extends React.Component<ClientFormPropTypes> {
  componentDidMount() {
    const { onLoad } = this.props;
    return onLoad && onLoad();
  }

  render() {
    const {
      canCreateClientWithoutContacts,
      handleSubmit,
      clientId,
      title,
      actionTitle,
      closeContactsWarningPopup,
    } = this.props;

    return (
      <div id="clientForm" className={`${styles.clientForm} ${styles.createForm}`}>
        <Form className={styles.formLimits} onSubmit={handleSubmit}>
          <div className={styles.header}>
            <h1>
              { title }
            </h1>
          </div>
          <hr />
          <ClientGenericDataForm />
          <hr />
          <ClientSchoolSpecificFormRenderer />
          <hr />
          <FieldArray
            name="contacts"
            component={ClientContactsForm}
            clientId={clientId}
            required={!canCreateClientWithoutContacts}
          />
          <hr />
          <Grid>
            <Row className={styles.marginBottom} center="xs">
              <Col xs={4}>
                <Button
                  type="submit"
                  tint="mediumGray"
                  title="Cancelar"
                  isFullWidth
                />
              </Col>
              <Col xs={4}>
                <Button
                  type="submit"
                  tint="primary"
                  title={actionTitle}
                  isFullWidth
                />
              </Col>
            </Row>
          </Grid>
        </Form>
        <GenericPopup
          id="VALIDATE_CONTACTS"
          title="Contactos"
          bodyText={i18n.t('canCreateWithoutContactsWarning')}
          onConfirm={closeContactsWarningPopup}
          hideCancel
          hideCloseButton
        />
      </div>
    );
  }
}

export default connect(
  (state) => {
    const canCreateClientWithoutContacts =
      selectors.getCurrentBoard(state).min_contacts_size === 0 ||
      selectors.getCurrentBoard(state).max_contacts_size === 0;

    return {
      canCreateClientWithoutContacts,
    };
  },
  dispatch => ({
    onSubmit(values: Object) {
      dispatch(actions.startAddingClient({
        id: uuid(),
        ...values,
      }));

      dispatch(closeableActions.closeAll());
    },
    closeContactsWarningPopup() {
      dispatch(closeableActions.close('VALIDATE_CONTACTS'));
    },
  }),
)(clientFormDecorator(clientReduxFormDecorator(ClientCreationFormDummy)));
