// @flow
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  /* MdSettings,
  MdAssignment,
  MdArchive, */
  MdColorLens,
  MdMoreVert,
} from 'react-icons/md';

import { BOARD_SETTINGS_POPUP_ID } from '../../BoardSettingsPopup';
import { BOARD_SETTINGS_MENU_ITEMS_ID } from '../../MenuItems/MenuItemsBoardSettings';

import * as closeableActions from '../../../actions/closeable';
import * as menuItemsActions from '../../../actions/menuItems';
import * as selectors from '../../../reducers';

import type { ID_TYPE } from '../../../types/common';
/* 
import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
import * as closeableActions from '../../actions/closeable';
import * as menuItemsActions from '../../actions/menuItems';
import { api } from '../../api'; 
*/
import Button from '../../Button';
import PopMenu from '../../PopMenu';

import styles from './boardItemOptions.module.scss';


type BoardOptionsPropTypes = {
  uuid: ID_TYPE,
  onArchiveClicked: Function,
  onFormClicked: Function,
  onAppearanceClicked: Function,
  onSettingsClicked: Function,
};

const BoardOptions = ({
  uuid,
  /* onArchiveClicked,
  onFormClicked,
  onSettingsClicked, */
  onAppearanceClicked,
  ...props
} : BoardOptionsPropTypes) => (
  <PopMenu
    id={`boardMenu-${uuid}`}
    className={styles.boardSettingsMenu}
    vertical="center"
    horizontal="right"
    tipPosition="right"
    isRound
    options={[
      /* {
        Icon: MdSettings,
        title: 'Configurar',
        onClick: onSettingsClicked,
      }, */
      /* {
        Icon: MdAssignment,
        title: 'Formulario',
        onClick: onFormClicked,
      }, */
      {
        Icon: MdColorLens,
        title: 'Apariencia',
        onClick: onAppearanceClicked,
      },
      /* {
        Icon: MdArchive,
        title: 'Archivar',
        tint: 'error',
        onClick: onArchiveClicked,
      } */
    ]}
    {...props}
  >
    <div className={styles.clickInteractionContainer}>
      <Button
        size="small"
        Icon={MdMoreVert}
        tint="mediumGray"
        clearMargins
        isTransparent
        isOutlined
      />
    </div>
    {/* <ArchiveBoardConfirmPopup uuid={uuid} /> */}
  </PopMenu>
);

// When rendering every 'dots' options popup, we passed the board reference ID as 'id' prop
// So, we can know about which board we have reference of.
export default connect(
  (state, { id }) => ({
    board: selectors.getCurrentBoard(state),
    refBoard: selectors.getBoard(state, id),
  }),
  (dispatch) => ({
    /* onArchiveClicked() {
      // TODO: dispatch & open popup with this tab (with a confirm popup)
    },
    onDeleteConfirmed() {
      // TODO: dispatch & open popup with this tab
    },
    onFormClicked() {
      // TODO: dispatch & open popup with this tab
    }, */
    onAppearanceClicked(currentBoardId, selectedBoardId, boardUUID) {
      // dispatch & open popup with this tab
      if (currentBoardId) {
        const currentBoardIdInt = parseInt(currentBoardId);
        const selectedBoardIdInt = parseInt(selectedBoardId);
        if (currentBoardIdInt !== selectedBoardIdInt) {
          dispatch(push(`/dashboard/${boardUUID}`, { popupId: BOARD_SETTINGS_POPUP_ID, popupItem: 'appearanceSettings'}));
          window.location.reload();
        } else {
          dispatch(menuItemsActions.updateMenuItems(BOARD_SETTINGS_MENU_ITEMS_ID, 'appearanceSettings'));
          dispatch(closeableActions.open(BOARD_SETTINGS_POPUP_ID));
        }
      } 
    },
    /* onSettingsClicked() {
      // TODO: dispatch & open popup with this tab
    }, */
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    onAppearanceClicked() {
      const { id } = stateProps.board;
      const { short_uuid } = stateProps.refBoard;
      dispatchProps.onAppearanceClicked(
        id,
        ownProps.id,
        short_uuid
      );
    },
  }),
)(BoardOptions);
