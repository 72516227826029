// @flow
// import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

import * as selectors from '../../reducers';


type LocaleDateTypes = {
  date: any,
  format?: string,
  locale: string,
};

const LocaleDate = connect(
  state => ({
    locale: selectors.getLocaleParam(state, false) || 'es',
  }),
)(({ date = new Date(), locale, format }: LocaleDateTypes) => moment(date).locale(locale).format(format));

export default LocaleDate;

/* es
moment().format('LT');   // 9:06
moment().format('LTS');  // 9:06:37
moment().format('L');    // 25/07/2019
moment().format('l');    // 25/7/2019
moment().format('LL');   // 25 de julio de 2019
moment().format('ll');   // 25 de jul. de 2019
moment().format('LLL');  // 25 de julio de 2019 9:06
moment().format('lll');  // 25 de jul. de 2019 9:06
moment().format('LLLL'); // jueves, 25 de julio de 2019 9:06
moment().format('llll'); // jue., 25 de jul. de 2019 9:06 */
