import {
  all,
  call,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import chunk from 'lodash/chunk';
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT, DATA_CHUNKS_SIZE } from '../settings';
import * as types from '../types/uploadingClients';
import * as actions from '../actions/uploadingClients';
import * as actionsPhases from '../actions/phases';
import * as selectors from '../reducers';
import { close } from '../actions/closeable';
import { BULK_UPLOADING_CLIENTS_POPUP_NAME } from '../components/BulkUploadingClientsPopup';

import { Clients } from '../api';


function* uploadChunkClients(token, data, boardId) {
  const { timeout } = yield race({
    response: call(
      [Clients.custom, 'bulkCreate'],
      {
        token,
        data: {
          ...data,
          board: boardId,
        },
      },
    ),
    timeout: call(delay, REQUEST_TIMEOUT),
  });

  if (timeout) {
    throwTimeout('createClients saga');
  }
}

function* uploadClients(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);
  // const boardIdParam = yield select(selectors.getBoardUUIDParam);
  const board = yield select(selectors.getCurrentBoard);
  const boardId = board.id;

  try {
    yield call(delay, 2000);

    // Data Chunks: avoid CORS error on large data sets
    const { uploadingClients } = payload;
    const dataChunks = chunk(uploadingClients, DATA_CHUNKS_SIZE);
    yield all(dataChunks.map(dataChunk => call(
      uploadChunkClients, token, { uploadingClients: dataChunk }, boardId,
    )));

    yield put(close(BULK_UPLOADING_CLIENTS_POPUP_NAME));
    yield put(actions.clearUploadingClients());
    const phase = yield select(selectors.getUploadingClientsPhase);
    yield put(actionsPhases.startFetchingPhaseClients(phase));
    yield call(delay, 1000);
    yield put(actions.completeUploadingClients());
    yield put(actions.updateUploadingClientsStep(1));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failUploadingClients({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchUploadingClientsUpload(): Iterator<any> {
  yield takeEvery(
    types.UPLOADING_CLIENTS_UPLOAD_STARTED,
    uploadClients,
  );
}
