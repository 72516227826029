// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { MdClose } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import uuid from 'uuid';
import XLSX from 'xlsx';
import zipObject from 'lodash/zipObject';
import cloneDeep from 'lodash/cloneDeep';
import isEmptyObject from 'is-empty-object';
import { normalize } from 'normalizr';

import * as selectors from '../../../reducers';
import { api } from '../../../api';
import * as fobiServices from '../../FobiRenderer/services';
import * as uploadingClientsActions from '../../../actions/uploadingClients';
import PopupHeader from '../../PopupHeader';
import PopupHeaderRightGroup from '../../PopupHeaderRightGroup';
import PopupCloseButton from '../../PopupCloseButton';
import PopupContent from '../../PopupContent';
import FileUploaderPlaceholder from '../FileUploaderPlaceholder';
import DropFileZone from '../DropFileZone';
import { arrayOfClients } from '../../../api/schemas/clients';
import { generateClientValidator, generateClientReplacer } from '../../ClientForm/services';

import styles from './fileUploader.module.scss';

export const BULK_UPDATE_CONTACT_PREFIX = 'contact$$';

type FileUploaderTypes = {
  validator: Function,
  addUploadingClients: Functions,
  addParsingError: Function,
  phase: number,
  reportURL: string,
}

const FileUploader = ({
  phase,
  validator,
  replacer,
  addUploadingClients,
  addParsingError,
  reportURL,
}: FileUploaderTypes) => {
  // const [isDragginOver, setIsDragginOver] = useState(false);
  // const dropzoneRef = useRef();
  const dropzoneContainerRef = useRef(null);
  // const [clients, setClients] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (dropzoneContainerRef.current) {
      setDimensions({
        width: dropzoneContainerRef.current.offsetWidth,
        height: dropzoneContainerRef.current.offsetHeight,
      });
    }
}, [dropzoneContainerRef]);

  const createData = (parsedRows: Array<Object>) => {
    const resolvedClients = [];
    let currentClient;

    for (let i = 0; i < parsedRows.length; i += 1) {
      currentClient = {};
      const currentRow = cloneDeep(parsedRows[i]);
      currentRow.phase = phase;
      currentRow.rowNumber = i + 2;

      if (currentRow.id != null) currentRow.isNew = false;
      else {
        currentRow.id = uuid();
        currentRow.isNew = true;
      }

      const keys = Object.keys(currentRow);
      const clientKeys = keys.filter(key => !key.includes(BULK_UPDATE_CONTACT_PREFIX));
      const contactKeys = keys.filter(key => key.includes(BULK_UPDATE_CONTACT_PREFIX));
      const client = {};
      const contact = {};

      clientKeys.forEach((key) => {
        client[key] = currentRow[key];
      });

      contactKeys.forEach((key) => {
        contact[key.replace(BULK_UPDATE_CONTACT_PREFIX, '')] = currentRow[key];
      });

      if (currentRow.first_name != null) {
        client.contacts = [contact];
        currentClient = client;
        if (currentClient.first_name != null) {
          resolvedClients.push(currentClient);
        }
      } else if (currentClient.contacts != null) {
        currentClient.contacts.push(contact);
      } /* else {
        return addParsingError('Recuerda utilizar el formato para creación/actualización de usuarios en masa.'
            + ' No olvides llenar los campos obligatorios.');
      } */
    }

    const validatedClients = resolvedClients.map((element) => {
      const resolvedClient = cloneDeep(element);
      const errors = validator(resolvedClient);
      
      const replacements = replacer(resolvedClient);
      const client = {
        ...resolvedClient,
        ...replacements,
      }
      
      if (!isEmptyObject(errors)) client.errors = errors;
      else {
        client.birth_date = new Date(client.birth_date).toISOString();
        delete client.rowNumber;
      }
      return client;
    });

    const {
      entities: { clients },
      result,
    } = normalize(validatedClients, arrayOfClients);

    return addUploadingClients(clients, result);
  }

  const onDrop = (files: Array<Object>) => {
    const excelFile = files[0];
    if (excelFile == null) return;
    const fileReader = new FileReader();
    const rABS = !!fileReader.readAsBinaryString;

    fileReader.onload = (e) => {
      const file = e.target.result;
      const workbook = XLSX.read(file, { type: rABS ? 'binary' : 'array', cellDates: true });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelRows = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF: 'yyyy-mm-dd' });
      const headers = excelRows.splice(0, 1)[0];

      const parsedRows = excelRows.reduce((result, row) => {
        // RAW DATA FILTER
        // Special case: remove question signs on 'select' options
        if (row.length) result.push(zipObject(headers, row));
        return result;
      }, []);

      // Omit empty parsed rows
      const filledRows = parsedRows.filter(row => Object.values(row).some(el => el !== undefined));

      const repeated = {};
      for (let i = 0; i < filledRows.length; i += 1) {
        const { id } = filledRows[i];
        if (id != null) {
          if (repeated[id] !== id) repeated[id] = id;
          else {
            return addParsingError('Se encontrarón dos o más estudiantes con el mismo id. '
            + 'Consulta el id de los estudiantes en el reporte general.');
          }
        }
      }
      return createData(filledRows);
    };

    if (rABS) fileReader.readAsBinaryString(excelFile);
    else fileReader.readAsArrayBuffer(excelFile);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    rootRef,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: '.xlsx, .csv, .xls'
  });

  return (
        <>
          <PopupHeader clearPadding isAlert>
            <PopupHeaderRightGroup>
              <PopupCloseButton
                tint="mediumGray"
                Icon={MdClose}
                clearMargins
                isTransparent
                isOutlined
              />
            </PopupHeaderRightGroup>
          </PopupHeader>
          <PopupContent isAlert>
            <div {...getRootProps({ onClick: e => e.preventDefault() })} >
              <input {...getInputProps()} />
              <ReactCSSTransitionGroup
                transitionName={{
                  enter: styles.enter,
                  enterActive: styles.enterActive,
                  leave: styles.leave,
                  leaveActive: styles.leaveActive,
                }}
                transitionEnterTimeout={300}
                transitionLeaveTimeout={200}
              >
                {
                  isDragActive && (
                    <DropFileZone
                      width={rootRef.current.offsetWidth}
                      height={rootRef.current.offsetHeight}
                    />
                  )
                }
              </ReactCSSTransitionGroup>
              <FileUploaderPlaceholder
                reportURL={reportURL}
                onClick={open}
                filePrefix={BULK_UPDATE_CONTACT_PREFIX}
                />
            </div>
          </PopupContent>
        </>
  );
}

export default connect(
  (state) => {
    const clientFormDescription = selectors.getClientFormDescription(state);
    const canCreateClientWithoutContacts = 
      (selectors.getBoard(state) && selectors.getBoard(state).min_contacts_size === 0)
      || (selectors.getBoard(state) && selectors.getBoard(state).max_contacts_size === 0);
    const validateSchoolSpecificClientData = fobiServices.generateValidator(
      clientFormDescription || {
        form_elements: [],
      },
    );
    const replaceSchoolSpecificClientData = fobiServices.generateReplacer(
      clientFormDescription || {
        form_elements: [],
      },
    );

    const contactFormDescription = selectors.getContactFormDescription(state);
    const validateSchoolSpecificContactData = fobiServices.generateValidator(
      contactFormDescription,
    );
    const replaceSchoolSpecificContactData = fobiServices.generateReplacer(
      contactFormDescription,
    );

    const phase = selectors.getUploadingClientsPhase(state);
    const boardUUID = selectors.getBoardUUIDParam(state);
    const { client_required_fields } = selectors.getCurrentBoard(state) || {};
    const clientRequiredFields = client_required_fields ? [...client_required_fields] : [];

    return {
      validator: generateClientValidator(
        validateSchoolSpecificClientData,
        validateSchoolSpecificContactData,
        canCreateClientWithoutContacts,
        clientRequiredFields,
      ),
      replacer: generateClientReplacer(
        replaceSchoolSpecificClientData,
        replaceSchoolSpecificContactData,
        canCreateClientWithoutContacts,
      ),
      phase,
      reportURL: api.getURL(`board-reports/${boardUUID}/student-report`),
    };
  },
  dispatch => ({
    addUploadingClients(entities, order) {
      dispatch(uploadingClientsActions.addUploadingClients(entities, order));
      dispatch(uploadingClientsActions.updateUploadingClientsStep(2));
    },
    addParsingError(error) {
      dispatch(uploadingClientsActions.updateUploadingClientsStep(3));
      dispatch(uploadingClientsActions.failAddingClients({
        id: uuid(),
        message: error,
      }));
    },
  }),
)(FileUploader);
