// @flow
import {
  call,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { delay } from 'redux-saga';

import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';
import { fibonacciBackoff } from '../lib/retry';
import {
  NOTIFICATIONS_FETCH_STARTED,
  NOTIFICATION_READ,
  NOTIFICATIONS_COUNT_STARTED,
  NOTIFICATIONS_WATCH_STARTED,
} from '../types/notifications';
import * as selectors from '../reducers';
import * as actions from '../actions/notifications';

import { Users, Notifications } from '../api';
import { arrayOfNotifications } from '../api/schemas/notifications';


function* fetchNotifications(action) {
  const { payload } = action;
  let { page } = payload;
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUserId);

  page = page == null ? 1 : page;

  try {
    const { notificationsResponse, timeout } = yield race({
      notificationsResponse: call(
        [Users.custom, 'notifications'],
        { token, id: user, filters: { page } },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchNotifications saga');
    }

    const {
      entities: { notifications },
      result,
    } = normalize(notificationsResponse.results, arrayOfNotifications);

    // Register notifications
    yield put(actions.fetchNotificationsSucceed(
      notifications,
      result,
      page,
      notificationsResponse.next,
      notificationsResponse.count,
    ));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.fetchNotificationsFailed({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* readNotification({ payload: { id } }) {
  const token = yield select(selectors.getToken);

  try {
    yield call(
      fibonacciBackoff,
      [Notifications, 'detail'],
      { token, id },
    );
  } catch (e) { /* noop */ }
}

function* countNotifications() {
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUserId);

  try {
    const response = yield call(
      fibonacciBackoff,
      [Users.custom, 'notificationCount'],
      { token, id: user, filters: { is_read: false } },
    );

    yield put(actions.updateNotificationsCount(response));
  } catch (e) { /* noop */ }
}

function* watchAllNotifications() {
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUserId);

  try {
    yield call(
      fibonacciBackoff,
      [Users.custom, 'notificationReadAll'],
      { token, id: user },
    );

    yield put(actions.completeWatchingNotifications());
  } catch (e) { /* noop */ }
}


export function* watchNotificationsFetch(): Iterator<any> {
  yield takeEvery(
    NOTIFICATIONS_FETCH_STARTED,
    fetchNotifications,
  );
}


export function* watchReadNotification(): Iterator<any> {
  yield takeEvery(
    NOTIFICATION_READ,
    readNotification,
  );
}

export function* watchNotificationsCount(): Iterator<any> {
  yield takeEvery(
    NOTIFICATIONS_COUNT_STARTED,
    countNotifications,
  );
}


export function* watchWatchAllNotifications(): Iterator<any> {
  yield takeEvery(
    NOTIFICATIONS_WATCH_STARTED,
    watchAllNotifications,
  );
}
