// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  RELATIONSHIP_TYPE,
  RELATIONSHIPS_FETCH_STARTED_TYPE,
  RELATIONSHIPS_FETCH_COMPLETED_TYPE,
  RELATIONSHIPS_FETCH_FAILED_TYPE,
} from '../types/relationships';
import * as types from '../types/relationships';


export const startFetchingRelationships = (
  schoolId: ID_TYPE,
): RELATIONSHIPS_FETCH_STARTED_TYPE => ({
  type: types.RELATIONSHIPS_FETCH_STARTED,
  payload: schoolId,
});

export const completeFetchingRelationships = (
  relationships: { [ID_TYPE]: RELATIONSHIP_TYPE },
  order: Array<ID_TYPE>,
): RELATIONSHIPS_FETCH_COMPLETED_TYPE => ({
  type: types.RELATIONSHIPS_FETCH_COMPLETED,
  payload: {
    entities: relationships,
    order,
  },
});

export const failFetchingRelationships = (
  error: ERROR_TYPE,
): RELATIONSHIPS_FETCH_FAILED_TYPE => ({
  type: types.RELATIONSHIPS_FETCH_FAILED,
  payload: error,
});
