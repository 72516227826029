// @flow
import React from 'react';
import ReactDatePicker from 'react-date-picker';
import { MdEvent } from 'react-icons/md';
import moment from 'moment';
import styled from 'styled-components';
import chroma from 'chroma-js';

import Button from '../Button';

// import inputStyles from '../Input/input.module.scss';
import styles from './datePicker.module.scss';

type DatePickerPropTypes = {
  size?: string,
  tint?: string,
  value?: Date,
  onChange?: Function,
  onBlur?: Function,
  hideClearIcon?: boolean,
  hideCalendarIcon?: boolean,
  calendarCustomClassName?: string,
  className?: string,
};

const StyledDateTimeContainer = styled.div`
  .react-date-picker {
    .react-calendar {
      & > div[class*="react-calendar__navigation"] {
        background: ${props => props.tint} !important;
        
        & button {
          &:hover, &:focus {
            background: ${props => chroma.mix(props.tint, 'rgba(255, 255, 255, 0.32)', 0.25, 'rgb')} !important;
          }

          &[class*="react-calendar__navigation__arrow"] {
            color: ${props => chroma.mix(props.tint, 'rgba(255, 255, 255, 0.16)', 0.25, 'rgb')} !important;
          }
        }
      }

      & button[class*="react-calendar__tile--active"] {
        background: ${props => props.tint} !important;

        &:hover {
          background: ${props => props.tint} !important;
        }
      }

      & button[class*="react-calendar__tile--hasActive"] {
        background: ${props => props.tint} !important;

        &:hover {
          background: ${props => props.tint} !important;
        }
      }

    }
  }
`;

class DatePicker extends React.Component<DatePickerPropTypes> {
  constructor(props: DatePickerPropTypes) {
    super(props);

    const { value } = this.props;

    if (value) {
      let rValue = new Date(value);

      if (rValue instanceof Date && isNaN(rValue)) {
        // eslint-disable-line
        rValue = new Date();
        return props.onChange && props.onChange(rValue);
      }
    }
  }

  calendarIcon = () => (
    <Button
      Icon={MdEvent}
      tint="gray"
      size="small"
      isTransparent
      isOutlined
      clearMargins
      useAnchor
    />
  );

  render() {
    const {
      size = 'medium',
      hideClearIcon = false,
      hideCalendarIcon = false,
      calendarCustomClassName,
      className,
      tint,
      ...props
    } = this.props;

    return (
      <StyledDateTimeContainer
        tint={tint || '#37968d'}
      >
        <ReactDatePicker
          {...props}
          className={`
              ${styles.datePicker}
              ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
              ${typeof className !== 'undefined' ? className : ''}
            `}
          calendarClassName={`
              ${styles.calendar}
              ${typeof calendarCustomClassName !== 'undefined' ? calendarCustomClassName : ''}
            `}
          value={moment(props.value).isValid() ? new Date(props.value) : null}
          clearIcon={hideClearIcon ? null : undefined}
          calendarIcon={hideCalendarIcon ? null : this.calendarIcon()}
        />
      </StyledDateTimeContainer>
    );
  }
}

export default DatePicker;
