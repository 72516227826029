// @flow
import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';

import styles from './externalHeader.module.scss';


type ExternalHeaderPropTypes = {
  pictureURL?: { url: string }
};

class ExternalHeader extends React.Component<ExternalHeaderPropTypes> {
  render() {
    const { pictureURL } = this.props;
    return (
      <div
        className={ styles.externalHeader }
        style={ pictureURL ? { backgroundImage: `url(${ pictureURL.url })` }: {}}
      ></div>
    );
  }
}


export default connect(
  state => ({
    pictureURL: (selectors.getCurrentBoard(state) || {}).form_header_picture
  })
)(ExternalHeader);
