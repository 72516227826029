// @flow
import moment from 'moment';


// TODO: parse date fields from clients's and contact's school specific data
// Should get form description to do this.
export const parseClient = (responseClient: Object) => ({
  ...responseClient,
  ...responseClient.board_specific_data,
  birth_date: moment(responseClient.birth_date || new Date()).toDate(),
  next_relevant_date: moment(responseClient.next_relevant_date || new Date()).toDate(),
  created_date: moment(responseClient.created_date || new Date()).toDate(),
  // contacts: responseClient.contacts.map(contact => ({
  //   ...contact,
  //   ...contact.board_specific_data,
  //   birth_date: moment(responseClient.birth_date).toDate()
  // }))
});


// TODO: parse date fields from clients's and contact's school specific data
// Should get form description to do this.
export const parseContact = (responseContact: Object) => ({
  ...responseContact,
  ...responseContact.board_specific_data,
  birth_date: moment(responseContact.birth_date || new Date()).toDate(),
});

export const parseComment = (responseComment: Object) => ({
  ...responseComment,
  creation_date: moment(responseComment.creation_date || new Date()).toDate(),
});

export const parsePhase = (responsePhase: Object) => ({
  ...responsePhase,
  next_relevant_date: moment(responsePhase.next_relevant_date || new Date()).toDate(),
});
