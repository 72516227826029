import type { ComponentType } from 'react';
import React from 'react';

import { getNameInitials } from '../../../utils';
import styles from './sidebarListItem.module.scss';


type SidebarListItemPropTypes = {
  Icon?: ComponentType<*>,
  ActionButton?: ComponentType<*>,
  id: string,
  text: string,
  color: string,
  onClick?: Function,
  selectable?: boolean,
  thumbnail?: string,
  isSelected?: boolean,
  theme?: string,
  isOpen: boolean,
};

type ListItemLeftGroupPropTypes = {
  children?: Node
};

type ListItemRightGroupPropTypes = {
  children?: Node
};

export const ListItemLeftGroup = ({ children }: ListItemLeftGroupPropTypes) => (
  <div className={styles.leftGroup}>
    { children }
  </div>
);

export const ListItemRightGroup = ({ children }: ListItemRightGroupPropTypes) => (
  <div className={styles.rightGroup}>
    { children }
  </div>
);

const SidebarListItem = ({
  id,
  Icon,
  ActionButton,
  text,
  onClick,
  selectable = true,
  thumbnail,
  color,
  isSelected = false,
  theme,
  isOpen,
}: SidebarListItemPropTypes) => (
  <div
    className={styles.itemContainer}
    role="presentation"
  >
    <div
      className={
        `
          ${styles.item}
          ${selectable ? styles.selectable : ''}
          ${isSelected ? styles.isSelected : ''}
          ${isOpen ? styles.open : ''}
          ${theme ? styles[theme] : ''}
        `
      }
    >
      <ListItemLeftGroup>
        {
          typeof Icon !== 'undefined' && (
            <span>
              <Icon size={22} />
            </span>
          )
        }
        {
          !thumbnail && text && (
            <div
              title={text}
              className={
              `
                ${styles.thumbnail}
                ${isSelected ? styles.isSelected : ''}
                ${isOpen ? styles.open : ''}
              `
              }
              style={color && { backgroundColor: color }}
              onClick={onClick ? () => onClick() : null}
              onKeyPress={onClick}
            >
              <span>{getNameInitials(text) || ''}</span>
            </div>
          )
        }
        {
          thumbnail != null && (
            <div className={
                `
                  ${styles.thumbnail}
                  ${styles.userInfo}
                  ${isOpen ? styles.open : ''}      
                `
              }
              onClick={onClick ? () => onClick() : null}
              onKeyPress={onClick}
            >
              <img src={thumbnail} alt="" />
            </div>
          )
        }
        {
          isOpen && (
            <div
              className={
                `
                  ${styles.itemTitle}
                  ${isSelected ? styles.isSelected : ''}
                  ${styles.open}
                  ${theme ? styles[theme] : ''}        
                `
              }
              onClick={onClick ? () => onClick() : null}
              onKeyPress={onClick}
            >
              <span title={text}>{text}</span>
            </div>
          )
        }
      </ListItemLeftGroup>
      {
        ActionButton != null && (theme === 'userInfo' ? true : isOpen) && (
          <ListItemRightGroup>
            <ActionButton id={id} />
          </ListItemRightGroup>
        )
      }
    </div>
  </div>
);

export default SidebarListItem;
