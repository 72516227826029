// @flow
import {
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { fibonacciBackoff } from '../lib/retry';
import * as types from '../types/cycles';

import * as actions from '../actions/cycles';
import * as selectors from '../reducers';


import { Cycles } from '../api';
import { arrayOfCycles } from '../api/schemas/cycles';


function* fetchCycles(action) {
  const { payload } = action;
  const isAuthenticated = yield select(selectors.isAuthenticated);
  // const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          name: "2018",
          ordinal: 1
        },
        {
          id: 2,
          name: "2019",
          ordinal: 2
        },
        {
          id: 3,
          name: "2020",
          ordinal: 3
        },
        {
          id: 4,
          name: "2021",
          ordinal: 4
        }
      ]
    }
  }

  try {
    const response = yield call(
      fibonacciBackoff,
      [Cycles, 'list'],
      {
        filters: {
          board: payload,
          is_external: !isAuthenticated,
        },
        mock,
      }
    );

    const {
      entities: { cycles },
      result,
    } = normalize(response, arrayOfCycles);

    yield put(actions.completeFetchingCycles(cycles, result))
  } catch(error) {
    const { statusCode, message, data, isPlain } = error;

    // Fetch error
    yield put(actions.failFetchingCycles({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor': data,
      retryAction: action
    }));
  }
}

export function* watchCyclesFetch(): Iterator<any> {
  yield takeEvery(
    types.CYCLES_FETCH_STARTED,
    fetchCycles,
  );
}
