// @flow
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter, createMatchSelector } from 'connected-react-router';

import { reducer as form } from 'redux-form';
import { reducer as auth, selectors as fromAuth } from 'nozzmo-redux-jwt';
import history from '../../history';

import { genSelector } from '../utils';

import login, * as fromLogin from './login';

import type { ID_TYPE } from '../types/common';
import type { CONTACT_TYPE } from '../types/contacts';
import type { COMMENT_TYPE } from '../types/comments';
import type { CYCLE_TYPE } from '../types/cycles';
import type { SCHOOL_TYPE } from '../types/schools';
import type { BOARD_TYPE } from '../types/boards';

import type { PermissionsState } from './permissions';
import permissions, * as fromPermissions from './permissions';

import type { NotificationsState } from './notifications';
import notifications, * as fromNotifications from './notifications';

import type { SchoolsState } from './schools';
import schools, * as fromSchools from './schools';

import type { BoardsState } from './boards';
import boards, * as fromBoards from './boards';

import type { LoginState } from './login';

import type { SocketState } from './socket';
import socket, * as fromSocket from './socket';

import type { TimeState } from './time';
import time, * as fromTime from './time';

import type { CloseableState } from './closeable';
import closeable, * as fromCloseable from './closeable';

import type { SelectorState } from './selector';
import selector, * as fromSelector from './selector';

import type { PhasesState } from './phases';
import phases, * as fromPhases from './phases';

import type { LevelsState } from './levels';
import levels, * as fromLevels from './levels';

import type { ClientformState } from './clientForm';
import clientForm, * as fromClientform from './clientForm';

import type { CalendarViewFilterState } from './calendarViewFilter';
import calendarViewFilter, * as fromCalendarviewfilter from './calendarViewFilter';

import type { ClientsState } from './clients';
import clients, * as fromClients from './clients';

import type { RelationshipsState } from './relationships';
import relationships, * as fromRelationships from './relationships';

import type { CommentsState } from './comments';
import comments, * as fromComments from './comments';

import type { ContactsState } from './contacts';
import contacts, * as fromContacts from './contacts';

import type { CyclesState } from './cycles';
import cycles, * as fromCycles from './cycles';

import type { GlobalfiltersState } from './globalFilters';
import globalFilters, * as fromGlobalFilters from './globalFilters';

import type { UploadingClientsState } from './uploadingClients';
import uploadingClients, * as fromUploadingClients from './uploadingClients';

import type { DatesState } from './dates';
import dates, * as fromDates from './dates';

import type { ReportsState } from './reports';
import reports, * as fromReports from './reports';

import type { MenusItemsState } from './menuItems';
import menusItems, * as fromMenusItems from './menuItems';

import type { UsersState } from './users';
import users, * as fromUsers from './users';

export type AppState = {
  router: Object,
  form: Object,
  auth: {
    token: string,
    decoded: Object,
    error: {
      message: string,
      extra: Object
    }
  },
  login: LoginState,
  permissions: PermissionsState,
  notifications: NotificationsState,
  boards: BoardsState,
  schools: SchoolsState,
  socket: SocketState,
  time: TimeState,
  closeable: CloseableState,
  selector: SelectorState,
  phases: PhasesState,
  levels: LevelsState,
  clientForm: ClientformState,
  calendarViewFilter: CalendarViewFilterState,
  clients: ClientsState,
  relationships: RelationshipsState,
  comments: CommentsState,
  contacts: ContactsState,
  cycles: CyclesState,
  globalFilters: GlobalfiltersState,
  uploadingClients: UploadingClientsState,
  dates: DatesState,
  reports: ReportsState,
  menuItems: MenusItemsState,
  users: UsersState,
};

const reducer = combineReducers({
  router: connectRouter(history),
  form,
  auth,
  login,
  permissions,
  notifications,
  boards,
  schools,
  socket,
  time,
  closeable,
  selector,
  phases,
  levels,
  clientForm,
  calendarViewFilter,
  clients,
  relationships,
  comments,
  contacts,
  cycles,
  globalFilters,
  uploadingClients,
  dates,
  reports,
  menusItems,
  users,
});

export default reducer;

// export const getLoginError = (state: AppState) => fromAuth.getError(state.auth);
export const getLoginError = genSelector(fromLogin.getLoginError, 'login');

export const isAuthenticated = genSelector(fromAuth.getIsAuth, 'auth');
export const getUser = genSelector(fromAuth.getDecoded, 'auth');
export const getFirstName = (state: AppState) => getUser(state).first_name;
export const getLastName = (state: AppState) => getUser(state).last_name;
export const getToken = genSelector(fromAuth.getToken, 'auth');
export const getUsername = (state: AppState) => getUser(state).username;
export const getUserProfilePicture = (state: AppState) => getUser(state).profile_picture;
export const getUserId = (state: AppState) => getUser(state).user_id;

export const getRouteParams = (
  state: AppState,
  isMultilingualPath: boolean = false,
) => {
  const basePath = '/:section/:boardUUID';
  const path = isMultilingualPath ? `/:locale${basePath}` : basePath;
  return createMatchSelector(path)(state).params;
};

export const getBoardUUIDParam = (
  state: AppState,
  isMultilingualPath?: boolean,
) => getRouteParams(state, isMultilingualPath).boardUUID;

export const getSectionViewParam = (
  state: AppState,
  isMultilingualPath?: boolean,
) => getRouteParams(state, isMultilingualPath).section;

export const getLocaleParam = (
  state: AppState,
  isMultilingualPath: boolean,
) => getRouteParams(state, isMultilingualPath).locale;

export const hasPermission = genSelector(fromPermissions.hasPermission, 'permissions');
export const getPermissions = genSelector(fromPermissions.getPermissions, 'permissions');

export const isLoginLoading = genSelector(fromLogin.isLoginLoading, 'login');

export const getNotification = genSelector(fromNotifications.getNotification, 'notifications');
export const getNotificationOrder = genSelector(fromNotifications.getNotificationOrder, 'notifications');
export const getNotifications = genSelector(fromNotifications.getNotifications, 'notifications');
export const getNotificationsCount = genSelector(fromNotifications.getNotificationsCount, 'notifications');
export const areNotificationsFetching = genSelector(fromNotifications.areNotificationsFetching, 'notifications');
export const isNotificationFetching = genSelector(fromNotifications.isNotificationFetching, 'notifications');
export const getNotificationsNextPage = genSelector(fromNotifications.getNotificationsNextPage, 'notifications');
export const getNotificationsFetchError = genSelector(fromNotifications.getNotificationsFetchError, 'notifications');
export const getNotificationError = genSelector(fromNotifications.getNotificationError, 'notifications');
export const getCurrentScreen = genSelector(fromNotifications.getCurrentScreen, 'notifications');

export const getSchool = genSelector(fromSchools.getSchool, 'schools');
export const isFetchingSchool = genSelector(fromSchools.isFetchingSchool, 'schools');
export const getSchoolError = genSelector(fromSchools.getSchoolError, 'schools');

// NEW: Boards
export const getCurrentBoard = genSelector(fromBoards.getCurrentBoard, 'boards');
export const getBoard = genSelector(fromBoards.getBoard, 'boards');
export const getBoardsIds = genSelector(fromBoards.getBoardsIds, 'boards');
export const getBoards = genSelector(fromBoards.getBoards, 'boards');
export const isFetchingCurrentBoard = genSelector(fromBoards.isFetchingCurrentBoard, 'boards');
export const isFetchingBoards = genSelector(fromBoards.isFetchingBoards, 'boards');
export const isCurrentBoardUpdating = genSelector(fromBoards.isCurrentBoardUpdating, 'boards');
export const getBoardError = genSelector(fromBoards.getBoardError, 'boards');
export const getBoardsError = genSelector(fromBoards.getBoardsError, 'boards');

export const isSocketOpening = genSelector(fromSocket.isSocketOpening, 'socket');
export const isSocketOpen = genSelector(fromSocket.isSocketOpen, 'socket');
export const isSocketConnected = genSelector(fromSocket.isSocketConnected, 'socket');
export const getSocketError = genSelector(fromSocket.getSocketError, 'socket');
export const getChannel = genSelector(fromSocket.getChannel, 'socket');
export const isChannelJoining = genSelector(fromSocket.isChannelJoining, 'socket');
export const isChannelTimedout = genSelector(fromSocket.isChannelTimedout, 'socket');
export const isChannelJoined = genSelector(fromSocket.isChannelJoined, 'socket');
export const getChannelError = genSelector(fromSocket.getChannelError, 'socket');

export const getCurrentTimestamp = genSelector(fromTime.getCurrentTimestamp, 'time');

export const getSelector = genSelector(fromSelector.getSelector, 'selector');
export const getSelectorFilter = (
  state: AppState,
  id: string,
) => fromSelector.getFilter(getSelector(state, id));
export const getSelectorSelectedElement = (
  state: AppState,
  id: string,
) => fromSelector.getSelectedElement(getSelector(state, id));
export const getSelectorConfiguration = (
  state: AppState,
  id: string,
) => fromSelector.getConfiguration(getSelector(state, id));

export const getCloseable = genSelector(fromCloseable.getCloseable, 'closeable');
export const getIsCloseableOpen = (
  state: AppState,
  id: string,
) => fromCloseable.getIsOpen(getCloseable(state, id));

export const getComment = genSelector(fromComments.getComment, 'comments');
export const getCommentError = genSelector(fromComments.getCommentError, 'comments');

export const getContact = genSelector(fromContacts.getContact, 'contacts');
export const getContactError = genSelector(fromContacts.getContactError, 'contacts');

export const getClient = genSelector(fromClients.getClient, 'clients');
export const isCreatingClient = genSelector(fromClients.isCreatingClient, 'clients');
export const lastSelectedClient = genSelector(fromClients.lastSelectedClient, 'clients');
export const getEditingClientId = genSelector(fromClients.getEditingClientId, 'clients');
export const getEditingClient = genSelector(fromClients.getEditingClient, 'clients');
export const isClientFetching = genSelector(fromClients.isClientFetching, 'clients');
export const isClientFetchingContacts = genSelector(fromClients.isClientFetchingContacts, 'clients');
export const isClientFetchingComments = genSelector(fromClients.isClientFetchingComments, 'clients');
export const isClientUpdating = genSelector(fromClients.isClientUpdating, 'clients');
export const getClientsError = genSelector(fromClients.getClientsError, 'clients');
export const getClientError = genSelector(fromClients.getClientError, 'clients');
export const getSelectedClientsIds = genSelector(fromClients.getSelectedClientsIds, 'clients');
export const countSelectedClients = genSelector(fromClients.countSelectedClients, 'clients');
export const getClientContactIds = genSelector(fromClients.getClientContactIds, 'clients');
export const getAllClients = genSelector(fromClients.getAllClients, 'clients');
export const getClientCommentIds = genSelector(fromClients.getClientCommentIds, 'clients');
export const getClientContacts = (
  state: AppState,
  id: ID_TYPE,
): Array<CONTACT_TYPE> => getClientContactIds(state, id).map(i => getContact(state, i));
export const getClientComments = (
  state: AppState,
  id: ID_TYPE,
): Array<COMMENT_TYPE> => getClientCommentIds(state, id).map(i => getComment(state, i)).filter(comment => typeof comment !== 'undefined');
export const getDraggingClientsIds = genSelector(fromClients.getDraggingClientsIds, 'clients');
export const isAnyClientDragging = genSelector(fromClients.isAnyClientDragging, 'clients');

export const getPhase = genSelector(fromPhases.getPhase, 'phases');
export const getPhasesIds = genSelector(fromPhases.getPhasesIds, 'phases');
export const getPhases = genSelector(fromPhases.getPhases, 'phases');
export const getNextRelevantPhase = genSelector(fromPhases.getNextRelevantPhase, 'phases');
export const isFetchingPhases = genSelector(fromPhases.isFetchingPhases, 'phases');
export const isPhaseFetching = genSelector(fromPhases.isPhaseFetching, 'phases');
export const isPhaseUpdating = genSelector(fromPhases.isPhaseUpdating, 'phases');
export const getPhasesError = genSelector(fromPhases.getPhasesError, 'phases');
export const getPhaseError = genSelector(fromPhases.getPhaseError, 'phases');
export const getPhaseClientsIds = genSelector(fromPhases.getPhaseClientsIds, 'phases');
export const getPhaseFilter = genSelector(fromPhases.getPhaseFilter, 'phases');
export const getPhaseSelected = genSelector(fromPhases.getPhaseSelected, 'phases');
export const getPhaseEmailTemplate = genSelector(fromPhases.getPhaseEmailTemplate, 'phases');
export const isFetchingPhase = genSelector(fromPhases.isFetchingPhase, 'phases');
export const getPhaseClients = (
  state: AppState,
  id: ID_TYPE,
) => {
  const phaseFilter = getPhaseFilter(state, id);
  return getPhaseClientsIds(state, id)
    .map(i => getClient(state, i))
    .filter(client => typeof client !== 'undefined')
    .filter(
      ({ first_name = '', last_name = '' }) => {
        const unaccentFirstName = first_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const unaccentLastName = last_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        return (
          phaseFilter ? (
            first_name.toLowerCase().indexOf(phaseFilter) >= 0
              || last_name.toLowerCase().indexOf(phaseFilter) >= 0
              || unaccentFirstName.toLowerCase().indexOf(phaseFilter) >= 0
              || unaccentLastName.toLowerCase().indexOf(phaseFilter) >= 0
          ) : true
        )
      },
    );
};
export const getUnfilteredPhaseClients = (
  state: AppState,
  id: ID_TYPE,
) => getPhaseClientsIds(state, id)
  .map(i => getClient(state, i))
  .filter(client => typeof client !== 'undefined');

export const getPhaseSelectedClients = (
  state: AppState,
  id: ID_TYPE,
) => getPhaseClients(state, id).filter(client => client && client.isSelected);

export const getLevel = genSelector(fromLevels.getLevel, 'levels');
export const getLevels = genSelector(fromLevels.getLevels, 'levels');
export const isFetchingLevels = genSelector(fromLevels.isFetchingLevels, 'levels');
export const getLevelsError = genSelector(fromLevels.getLevelsError, 'levels');
export const getLevelsById = genSelector(fromLevels.getLevelsById, 'levels');
export const getLevelsOrder = genSelector(fromLevels.getLevelsOrder, 'levels');

export const getUploadingClientsStep = genSelector(fromUploadingClients.getUploadingClientsStep, 'uploadingClients');
export const getUploadingClient = genSelector(fromUploadingClients.getUploadingClient, 'uploadingClients');
export const getUploadingClients = genSelector(fromUploadingClients.getUploadingClients, 'uploadingClients');
export const getUploadingClientsToAdd = genSelector(fromUploadingClients.getUploadingClientsToAdd, 'uploadingClients');
export const getUploadingClientsToUpdate = genSelector(fromUploadingClients.getUploadingClientsToUpdate, 'uploadingClients');
export const getWrongUploadingClients = genSelector(fromUploadingClients.getWrongUploadingClients, 'uploadingClients');
export const getUploadingClientsError = genSelector(fromUploadingClients.getUploadingClientsError, 'uploadingClients');
export const isUploadingClients = genSelector(fromUploadingClients.isUploadingClients, 'uploadingClients');
export const getUploadingClientsPhase = genSelector(fromUploadingClients.getUploadingClientsPhase, 'uploadingClients');

export const getClientFormDescription = genSelector(fromClientform.getClientFormDescription, 'clientForm');
export const getContactFormDescription = genSelector(fromClientform.getContactFormDescription, 'clientForm');
export const isFetchingClientform = genSelector(fromClientform.isFetchingClientForm, 'clientForm');
export const getClientformError = genSelector(fromClientform.getClientformError, 'clientForm');

export const getCalendarViewFilters = genSelector(fromCalendarviewfilter.getCalendarViewFilters, 'calendarViewFilter');
export const getCalendarViewFiltersStartDate = genSelector(fromCalendarviewfilter.getCalendarViewFiltersStartDate, 'calendarViewFilter');
export const getCalendarViewFiltersEndDate = genSelector(fromCalendarviewfilter.getCalendarViewFiltersEndDate, 'calendarViewFilter');
export const getCalendarViewFiltersPhaseId = genSelector(fromCalendarviewfilter.getCalendarViewFiltersPhaseId, 'calendarViewFilter');
export const getCalendarViewFilterClientIds = genSelector(fromCalendarviewfilter.getCalendarViewFilterClientIds, 'calendarViewFilter');
export const getCalendarViewFilterClients = (
  state: AppState,
) => getCalendarViewFilterClientIds(state).map(id => getClient(state, id));
export const isFetchingCalendarViewFilterClients = genSelector(fromCalendarviewfilter.isFetchingCalendarViewFilterClients, 'calendarViewFilter');
export const getCalendarviewfilterError = genSelector(fromCalendarviewfilter.getCalendarviewfilterError, 'calendarViewFilter');

export const getRelationship = genSelector(fromRelationships.getRelationship, 'relationships');
export const getRelationships = genSelector(fromRelationships.getRelationships, 'relationships');
export const isFetchingRelationships = genSelector(fromRelationships.isFetchingRelationships, 'relationships');
export const getRelationshipsError = genSelector(fromRelationships.getRelationshipsError, 'relationships');

export const getCycle = genSelector(fromCycles.getCycle, 'cycles');
export const getCycles = genSelector(fromCycles.getCycles, 'cycles');
export const getActiveCycles = genSelector(fromCycles.getActiveCycles, 'cycles');
export const isFetchingCycles = genSelector(fromCycles.isFetchingCycles, 'cycles');
export const getCyclesError = genSelector(fromCycles.getCyclesError, 'cycles');
export const getCyclesById = genSelector(fromCycles.getCyclesById, 'cycles');
export const getCyclesOrder = genSelector(fromCycles.getCyclesOrder, 'cycles');

export const getReportsDates = genSelector(fromReports.getReportDateFilter, 'reports');
export const getReportsSelectedCycle = genSelector(fromReports.getReportSelectedCycle, 'reports');

export const getGlobalFilters = genSelector(fromGlobalFilters.getGlobalFilters, 'globalFilters');
export const getGlobalBaseDateFilter = genSelector(fromGlobalFilters.getGlobalBaseDateFilter, 'globalFilters');
export const getGlobalStartBirthDateFilter = genSelector(fromGlobalFilters.getGlobalStartBirthDateFilter, 'globalFilters');
export const getGlobalEndBirthDateFilter = genSelector(fromGlobalFilters.getGlobalEndBirthDateFilter, 'globalFilters');
export const getGlobalCycleIdFilter = genSelector(fromGlobalFilters.getGlobalCycleIdFilter, 'globalFilters');
export const getGlobalLevelIdFilter = genSelector(fromGlobalFilters.getGlobalLevelIdFilter, 'globalFilters');
export const getGlobalIsAdmittedFilter = genSelector(fromGlobalFilters.getGlobalIsAdmittedFilter, 'globalFilters');
export const getGlobalIsArchivedFilter = genSelector(fromGlobalFilters.getGlobalIsArchivedFilter, 'globalFilters');
export const getGlobalNoStatusFilter = genSelector(fromGlobalFilters.getGlobalNoStatusFilter, 'globalFilters');
export const getGlobalCycleFilter = (state: AppState): ?CYCLE_TYPE => {
  const cycleId = getGlobalCycleIdFilter(state);
  if (cycleId != null) {
    return getCycle(state, cycleId);
  }

  return null;
};
export const getGlobalLevelFilter = (state: AppState): ?LEVEL_TYPE => {
  const levelId = getGlobalCycleIdFilter(state);
  if (levelId != null) {
    return getLevel(state, levelId);
  }

  return null;
};
export const hasGlobalFilters = genSelector(fromGlobalFilters.hasGlobalFilters, 'globalFilters');

/*
  TODO:
    - getPhaseClients
    - getPhaseGobalFilteredClients
    - getCalendarViewFilterClients
    - getCalendarViewFilterGlobalFilteredClients
*/

// Dates selectors
export const getRelevantDates = genSelector(fromDates.getRelevantDates, 'dates');
// Reports selectors
export const getReport = genSelector(fromReports.getReport, 'reports');
export const getReporstByType = genSelector(fromReports.getReporstByType, 'reports');
export const getIsFetchingClientCountByState = genSelector(fromReports.getIsFetchingClientCountByState, 'reports');
export const getIsFetchingClientCountByPhase = genSelector(fromReports.getIsFetchingClientCountByPhase, 'reports');
export const getIsFetchingClientCountByLevel = genSelector(fromReports.getIsFetchingClientCountByLevel, 'reports');
export const getIsFetchingClientCountByMonth = genSelector(fromReports.getIsFetchingClientCountByMonth, 'reports');

export const getMenuItemsStatus = genSelector(fromMenusItems.getMenuItemsStatus, 'menusItems');

// Users state selectors
export const getUserById = genSelector(fromUsers.getUserById, 'users');
export const getUsers = genSelector(fromUsers.getUsers, 'users');
export const getIsFetchingUsers = genSelector(fromUsers.getIsFetchingUsers, 'users');
export const getIsSendingInvitation = genSelector(fromUsers.getIsSendingInvitation, 'users');
export const getIsChangingUserRole = genSelector(fromUsers.getIsChangingUserRole, 'users');
export const getIsUpdatingUserDisabledStatus = genSelector(fromUsers.getIsUpdatingUserDisabledStatus, 'users');
export const getErrors = genSelector(fromUsers.getErrors, 'users');
export const getCurrentLoggedUser = genSelector(fromUsers.getCurrentLoggedUser, 'users');
export const getCurrentLoggedUserError = genSelector(fromUsers.getCurrentLoggedUserError, 'users');
export const isUpdatingCurrentLoggedUser = genSelector(fromUsers.isUpdatingCurrentLoggedUser, 'users');
export const isRestoringUserCredentials = genSelector(fromUsers.isRestoringUserCredentials, 'users');
export const getErrorRestoringCredentials = genSelector(fromUsers.getErrorRestoringCredentials, 'users');
export const getUsersError = genSelector(fromUsers.getUsersError, 'users');
export const isUserRecoveringPassword = genSelector(fromUsers.isUserRecoveringPassword, 'users');
export const getRecoverPasswordError = genSelector(fromUsers.getRecoverPasswordError, 'users');
export const getSendInvitationError = genSelector(fromUsers.getSendInvitationError, 'users');
export const isUserUpdatingPassword = genSelector(fromUsers.isUserUpdatingPassword, 'users');
export const getUserUpdatePasswordError = genSelector(fromUsers.getUserUpdatePasswordError, 'users');
export const getUserLoginEvent = genSelector(fromUsers.getUserLoginEvent, 'users');

/* IMPORTANT: getCurrentLoggedUser is the updated object, user object from 'auth' state may
    not be up to date!
*/
// Specific data from current logged user
export const getUserExtraData = (state: AppState) => (getCurrentLoggedUser(state) || {}).extra_data;
export const isMainSidebarOpen = (
  state: AppState
) => (getUserExtraData(state) ? getUserExtraData(state).opened_main_sidebar : false);

// School and Boards
export const getUserSchools = (state:AppState): SCHOOL_TYPE[]  => (
  (getCurrentLoggedUser(state) ? getCurrentLoggedUser(state).schools : getUser(state).schools) || []
);

export const getUserBoards = (state:AppState): ID_TYPE[]  => (
  (getCurrentLoggedUser(state) ? getCurrentLoggedUser(state).boards : getUser(state).boards) || []
);

export const getUserFirstSchool = (state:AppState): ?SCHOOL_TYPE => getUserSchools(state)[0] || {};

export const getUserFirstSchoolBoard = (state:AppState): ?BOARD_TYPE => {
  const availableSchoolBoards = getUserFirstSchool(state).boards || [];
  const boardUUID = availableSchoolBoards && availableSchoolBoards.length > 0
    ? availableSchoolBoards[0]
    : 0;

  return boardUUID;
};

export const getSelectedSchool = (state:AppState): ?SCHOOL_TYPE => {
  const selectedBoardUUID = getBoardUUIDParam(state);
  return getUserSchools(state).find(
    school => school.boards && school.boards.includes(selectedBoardUUID),
  ) || {};
};

export const getSelectedSchoolBoards = (state:AppState): BOARD_TYPE[] => {
  const availableSchoolBoards = getSelectedSchool(state).boards || [];
  const assignedSchoolBoards = getBoards(state) || [];
  return assignedSchoolBoards.filter(el => availableSchoolBoards.includes(el.short_uuid)) || [];
};
