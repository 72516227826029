// @flow
import React from 'react';
import { connect } from 'react-redux';

import { PHASE_SETTINGS_MENU_ID } from '../../MenuItemsSettingsPhase';
import { FullLoader } from '../../Loader';
import PhaseSettingsForm from '../../PhaseSettingsForm';
import PhaseFileExport from '../../PhaseFileExport';
// import BigPlaceHolder from '../../BigPlaceholder';

import styles from '../phaseSettingsPopupContent.module.scss';
import * as selectors from '../../../reducers';


type PhaseSettingsContentPropTypes = {
  isLoading: boolean,
  error?: Object,
  menuItem?: string,
  idPhaseSelected: Integer
};


const PhaseSettingsContent = ({
  isLoading = true,
  menuItem = '',
  // idPhaseSelected
  // error = {},
}: PhaseSettingsContentPropTypes) => {
  let Content = null;

  if (isLoading) {
    return (
      <div className={styles.placeholder}>
        <FullLoader
          tint='mediumgray'
          title="Trayendo detalle de fase ..."
        />
      </div>
    )
  }

  switch (menuItem) {
    case 'contacts':
      Content = <PhaseSettingsForm />;
      break;
    case 'fileExport':
      Content = <PhaseFileExport />;
      break;
    default:
      Content = null;
  }

  return Content;
};

export default connect(
  state => {
    const { id } = selectors.getPhaseSelected(state);
    if (id) {
      return ({
        isLoading: selectors.isFetchingPhase(state, id),
        error: selectors.getPhaseError(state, id),
        menuItem: selectors.getMenuItemsStatus(state, PHASE_SETTINGS_MENU_ID),
        idPhaseSelected: id
      })
    }

    return ({
      isLoading: true,
      error: {},
    })
  }
)(PhaseSettingsContent);
