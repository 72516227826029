// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type UPLOADING_CLIENT_TYPE = {
  id: ID_TYPE,
  first_name: string,
  last_name: string,
  birth_date: Date,
  phone: string,
  cycle: number,
  level: number,
  school: number,
  phase: number,
  rowNumber: number,
  isNew: boolean,
  contacts: Array<Object>,
  errors: Array<Object>
}

export type UPLOADING_CLIENTS_ADDED_TYPE = {
  type: 'UPLOADING_CLIENTS_ADDED',
  payload: {
    entities: { [ID_TYPE]: UPLOADING_CLIENT_TYPE },
    order: Array<ID_TYPE>
  }
}
export const UPLOADING_CLIENTS_ADDED = 'UPLOADING_CLIENTS_ADDED';

export type UPLOADING_CLIENTS_CLEARED_TYPE = {
  type: 'UPLOADING_CLIENTS_CLEARED',
}
export const UPLOADING_CLIENTS_CLEARED = 'UPLOADING_CLIENTS_CLEARED';

export type UPLOADING_CLIENTS_UPLOAD_STARTED_TYPE = {
  type: 'UPLOADING_CLIENTS_UPLOAD_STARTED',
  payload: {
    entities: Array<CLIENT_TYPE>,
  },
};
export const UPLOADING_CLIENTS_UPLOAD_STARTED = 'UPLOADING_CLIENTS_UPLOAD_STARTED';

export type UPLOADING_CLIENTS_STEP_UPDATED_TYPE = {
  type: 'UPLOADING_CLIENTS_STEP_UPDATED',
  payload: number,
}
export const UPLOADING_CLIENTS_STEP_UPDATED = 'UPLOADING_CLIENTS_STEP_UPDATED';

export type UPLOADING_CLIENTS_ADD_FAILED_TYPE = {
  type: 'UPLOADING_CLIENTS_ADD_FAILED',
  payload: ERROR_TYPE
};
export const UPLOADING_CLIENTS_ADD_FAILED = 'UPLOADING_CLIENTS_ADD_FAILED';

export type UPLOADING_CLIENTS_UPLOAD_COMPLETED_TYPE = {
  type: 'UPLOADING_CLIENTS_UPLOAD_COMPLETED',
  payload: ID_TYPE
};
export const UPLOADING_CLIENTS_UPLOAD_COMPLETED = 'UPLOADING_CLIENTS_UPLOAD_COMPLETED';

export type UPLOADING_CLIENTS_UPLOAD_FAILED_TYPE = {
  type: 'UPLOADING_CLIENTS_UPLOAD_FAILED',
  payload: ERROR_TYPE
};
export const UPLOADING_CLIENTS_UPLOAD_FAILED = 'UPLOADING_CLIENTS_UPLOAD_FAILED';

export type UPLOADING_CLIENT_PHASE_UPDATED_TYPE = {
  type: 'UPLOADING_CLIENT_PHASE_UPDATED',
  payload: number,
}
export const UPLOADING_CLIENT_PHASE_UPDATED = 'UPLOADING_CLIENT_PHASE_UPDATED';

export type UPLOADING_CLIENTS_ACTIONS_TYPE =
  | UPLOADING_CLIENTS_ADDED_TYPE
  | UPLOADING_CLIENTS_CLEARED_TYPE
  | UPLOADING_CLIENTS_STEP_UPDATED_TYPE
  | UPLOADING_CLIENTS_ADD_FAILED_TYPE
  | UPLOADING_CLIENTS_UPLOAD_STARTED_TYPE
  | UPLOADING_CLIENTS_UPLOAD_COMPLETED_TYPE
  | UPLOADING_CLIENTS_UPLOAD_FAILED_TYPE
  | UPLOADING_CLIENT_PHASE_UPDATED_TYPE;
