// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  CALENDAR_VIEW_FILTER_TYPE,
  CALENDAR_VIEW_FILTER_ACTION_TYPE,
} from '../types/calendarViewFilter';
import * as common from './common';
import * as types from '../types/calendarViewFilter';
import * as clientTypes from '../types/clients';


export type CalendarViewFilterState = {
  filters: CALENDAR_VIEW_FILTER_TYPE,
  isFetching: boolean,
  clientIds: Array<ID_TYPE>,
  error: ERROR_TYPE
};

/* Default dates starting at midgnight */
const initialState = {
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  endDate: new Date(new Date().setHours(0, 0, 0, 0)),
  phaseId: null,
};

// TODO: analyze this fucking case, may be a good 'common' reducer
const filters = (
  state: CALENDAR_VIEW_FILTER_TYPE = initialState,
  action: CALENDAR_VIEW_FILTER_ACTION_TYPE,
): CALENDAR_VIEW_FILTER_TYPE => {
  switch (action.type) {
    case types.CALENDAR_VIEW_FILTER_UPDATED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const clientIds = common.order({
  replaced: [types.CALENDAR_VIEW_FILTER_FETCH_COMPLETED],
  removed: [clientTypes.CLIENT_REMOVED],
});

const isFetching = common.isFetching({
  started: [types.CALENDAR_VIEW_FILTER_FETCH_STARTED],
  succeed: [types.CALENDAR_VIEW_FILTER_FETCH_COMPLETED],
  failed: [types.CALENDAR_VIEW_FILTER_FETCH_FAILED],
});

const error = common.error({
  clear: [types.CALENDAR_VIEW_FILTER_FETCH_STARTED],
  populate: [types.CALENDAR_VIEW_FILTER_FETCH_FAILED],
});

const calendarViewFilter = combineReducers({
  filters,
  clientIds,
  isFetching,
  error,
});


export default calendarViewFilter;


// Selectors
export const getCalendarViewFilters = (
  state: CalendarViewFilterState,
): CALENDAR_VIEW_FILTER_TYPE => state.filters;
export const getCalendarViewFiltersStartDate = (
  state: CalendarViewFilterState,
): Date => state.filters.startDate;
export const getCalendarViewFiltersEndDate = (
  state: CalendarViewFilterState,
): Date => state.filters.endDate;
export const getCalendarViewFiltersPhaseId = (
  state: CalendarViewFilterState,
): ?number => state.filters.phaseId;
export const getCalendarViewFilterClientIds = (
  state: CalendarViewFilterState,
): Array<ID_TYPE> => state.clientIds;
export const isFetchingCalendarViewFilterClients = (
  state: CalendarViewFilterState,
): boolean => state.isFetching;
export const getCalendarviewfilterError = (
  state: CalendarViewFilterState,
): ERROR_TYPE => state.error;
