// @flow
import {
  call,
  takeEvery,
  put,
  // select
} from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { fibonacciBackoff } from '../lib/retry';
import * as types from '../types/levels';
// import * as selectors from '../reducers';
import * as actions from '../actions/levels';

import { Levels } from '../api';
import { arrayOfLevels } from '../api/schemas/levels';


function* fetchLevels(action) {
  const { payload } = action;
  // const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        {
          id: 1,
          name: "Primero Primaria",
          ordinal: 1
        },
        {
          id: 2,
          name: "Segundo Primaria",
          ordinal: 2
        },
        {
          id: 3,
          name: "Tercero Primaria",
          ordinal: 3
        },
        {
          id: 4,
          name: "Cuarto Primaria",
          ordinal: 4
        }
      ]
    }
  }

  try {
    const response = yield call(
      fibonacciBackoff,
      [ Levels, 'list' ],
      { /*token,*/ filters: { board: payload }, mock }
    );

    const {
      entities: { levels },
      result
    } = normalize(response, arrayOfLevels);

    yield put(actions.completeFetchingLevels(levels, result))
  } catch(error) {
    const { statusCode, message, data, isPlain } = error;

    // Fetch error
    yield put(actions.failFetchingLevels({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor': data,
      retryAction: action
    }));
  }
}

export function* watchLevelsFetch(): Iterator<any> {
  yield takeEvery(
    types.LEVELS_FETCH_STARTED,
    fetchLevels
  );
}
