// @flow
import type { ComponentType } from 'react';
import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import styles from './input.module.scss';

type InputPropTypes = {
  size?: string,
  className?: string,
  isRound?: boolean,
  isSquare?: boolean,
  clearMargins?: boolean,
  buttonSize?: string,
  buttonPosition?: string,
  buttonIconPosition?: string,
  buttonTint?: string,
  buttonIcon?: ComponentType<*>,
  buttonTitle?: string,
  placeholderOffsetLeft?: number,
  placeholderOffsetRight?: number,
  isButtonRound?: boolean,
  Icon?: ComponentType<*>,
  elevation?: number,
  iconPosition?: string,
  tint?: string,
  iconColor?: string,
  iconOffsetLeft?: number,
  iconOffsetRight?: number,
  hasError?: boolean,
  isButtonOutlined?: boolean,
  isButtonTransparent?: boolean,
  isStacked?: boolean,
  isLoading?: boolean,
  isBottom?: boolean,
  isTop?: boolean,
  isSingleChar?: boolean,
  onButtonClick?: Function,
  buttonClassName?: string,
  // autofocus?: boolean
};

const StyledInput = styled.input`
  padding-left: ${props => (props.placeholderOffsetLeft ? `${props.placeholderOffsetLeft}rem` : undefined)};
  padding-right: ${props => (props.placeholderOffsetRight ? `${props.placeholderOffsetRight}rem` : undefined)};

  &:focus, &:hover {
    border-color: ${props => props.tint} !important;
  }

  &:focus {
    & + svg {
      color: ${props => props.tint} !important;
    }
  }
`;

class Input extends React.Component<InputPropTypes> {
  getInput() {
    return this._input;
  }

  select() {
    if (this._input != null) {
      this._input.select();
    }
  }

  focus() {
    if (this._input != null) {
      this._input.focus();
    }
  }

  _input: ?HTMLInputElement;

  // componentDidMount() {
  //   this.props.autofocus && this.focus();
  // }

  render() {
    const {
      size = 'medium',
      className = '',
      isRound = false,
      buttonSize,
      buttonPosition = 'right',
      buttonIconPosition = 'left',
      buttonTint,
      buttonIcon,
      buttonTitle,
      placeholderOffsetLeft,
      placeholderOffsetRight,
      // isButtonRound = false,
      Icon,
      elevation = 1,
      iconPosition = 'right',
      iconColor,
      iconOffsetLeft,
      iconOffsetRight,
      hasError = false,
      isButtonOutlined = false,
      isButtonTransparent = false,
      isSquare = false,
      clearMargins = false,
      isStacked = false,
      isLoading = false,
      isTop = false,
      isBottom = false,
      isSingleChar = false,
      onButtonClick = () => {},
      buttonClassName = '',
      tint,
      ...props
    } = this.props;

    return (
      <div className={
        `
          ${styles.inputWrapper}
          ${styles[size]}
          ${styles[`elevation${elevation}`]}
          ${Icon ? styles[`${iconPosition}IconPosition`] : ''}
          ${className}
          ${isRound ? styles.roundInput : ''}
          ${isButtonTransparent ? styles.inputButtonTransparent : ''}
          ${size === buttonSize && buttonPosition === 'right' ? styles.rightOutsideInput : ''}
          ${size === buttonSize && buttonPosition === 'left' ? styles.leftOutsideInput : ''}
          ${buttonPosition === 'right' ? styles.buttonRightInput : ''}
          ${buttonPosition === 'left' ? styles.buttonLeftInput : ''}
          ${hasError ? styles.error : ''}
          ${isSquare ? styles.square : ''}
          ${clearMargins ? styles.clearMargins : ''}
          ${isStacked ? styles.stacked : ''}
          ${isTop ? styles.top : ''}
          ${isBottom ? styles.bottom : ''}
          ${isSingleChar ? styles.singleChar : ''}
        `
      }
      >
        <div className={styles.inputContainer}>
          <StyledInput
            placeholderOffsetLeft={placeholderOffsetLeft}
            placeholderOffsetRight={placeholderOffsetRight}
            tint={tint}
            ref={(node) => { this._input = node; return node; }}
            {...props}
          />
          {
            typeof iconPosition === 'string' && Icon
            && <Icon
              style={{
                left: typeof iconOffsetLeft !== 'undefined'
                  ? `${iconOffsetLeft}rem` : undefined,
                right: typeof iconOffsetRight !== 'undefined'
                  ? `${iconOffsetRight}rem` : undefined,
                color: tint,
              }}
              size={24}
              className={
                `
                  ${styles[iconColor]}
                `
              }
            />
          }
        </div>
        {
          typeof buttonSize === 'string'
            && <Button
              isRight={size === buttonSize && buttonPosition === 'right'}
              isLeft={size === buttonSize && buttonPosition === 'left'}
              size={buttonSize}
              tint={buttonTint}
              Icon={buttonIcon}
              iconPosition={buttonIconPosition}
              isRound={isRound}
              isOutlined={isButtonOutlined}
              isTransparent={isButtonTransparent}
              elevation={elevation}
              title={buttonTitle}
              isLoading={isLoading}
              onClick={onButtonClick}
              className={buttonClassName}
            />
        }
      </div>
    );
  }
}


export default Input;
