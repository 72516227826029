// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type CONTACT_TYPE = {
  id: ID_TYPE,
  first_name: string,
  last_name: string,
  relationship: ID_TYPE,
  client: ID_TYPE
};

export type CONTACTS_FETCH_TYPE = {
  type: 'CONTACTS_FETCH',
  payload: {
    entities: { [ID_TYPE]: CONTACT_TYPE }
  }
};
export const CONTACTS_FETCH = 'CONTACTS_FETCH';

export type CONTACT_REMOVED_TYPE = {
  type: 'CONTACT_REMOVED',
  payload: ID_TYPE
};
export const CONTACT_REMOVED = 'CONTACT_REMOVED';

export type CONTACT_UPDATE_STARTED_TYPE = {
  type: 'CONTACT_UPDATE_STARTED',
  payload: {
    id: ID_TYPE
  }
};
export const CONTACT_UPDATE_STARTED = 'CONTACT_UPDATE_STARTED';

export type CONTACT_UPDATE_COMPLETED_TYPE = {
  type: 'CONTACT_UPDATE_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE
  }
};
export const CONTACT_UPDATE_COMPLETED = 'CONTACT_UPDATE_COMPLETED';

export type CONTACT_UPDATE_FAILED_TYPE = {
  type: 'CONTACT_UPDATE_FAILED',
  payload: ERROR_TYPE
};
export const CONTACT_UPDATE_FAILED = 'CONTACT_UPDATE_FAILED';

export type CONTACTS_ACTION_TYPE =
  | CONTACTS_FETCH_TYPE
  | CONTACT_REMOVED_TYPE
  | CONTACT_UPDATE_STARTED_TYPE
  | CONTACT_UPDATE_COMPLETED_TYPE
  | CONTACT_UPDATE_FAILED_TYPE;
