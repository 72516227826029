// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeader.module.scss';


type PopupHeaderPropTypes = {
  children?: Node,
  size?: string,
  isAlert?: boolean,
  clearPadding?: boolean,
};

const PopupHeader = ({
  children,
  size = 'medium',
  isAlert = false,
  clearPadding = false,
} : PopupHeaderPropTypes) => (
  <div className={
    `
      ${styles.popupHeader}
      ${styles[size]}
      ${isAlert ? styles.alert : ''}
      ${clearPadding ? styles.clearPadding : ''}
    `
  }
  >
    { children }
  </div>
);

export default PopupHeader;
