// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './cardHeaderTitleContainer.module.scss';


type CardHeaderTitleContainerPropTypes = {
  size?: string,
  children?: Node
};

const CardHeaderTitleContainer = ({
  size = 'medium',
  children,
} : CardHeaderTitleContainerPropTypes) => (
  <div className={
    `
      ${styles.headerTitleContainer}
      ${styles[size]}
    `
   }
  >
    { children }
  </div>
);

export default CardHeaderTitleContainer;
