// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdKeyboardArrowDown } from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';

import Button from '../Button';
import PopMenu from '../PopMenu';
import styles from './phasePopoverSelect.module.scss';

import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
// import * as closeableActions from '../../actions/closeable';


type PhaseOptionsPropTypes = {
  id: ID_TYPE,
  phaseId: ID_TYPE,
  name: string,
  phases: Array<Object>,
  onPhaseSelected: Function,
  onClearAllSelectedPhases: Function,
};

const PhasePopoverSelect = ({
  phaseId,
  name,
  phases = [],
  onPhaseSelected,
  onClearAllSelectedPhases,
  // id,
  ...props
} : PhaseOptionsPropTypes) => {
  useEffect(() => {
    return () => onClearAllSelectedPhases();
  }, []);

  const options = phases.map(({ name, id }) => ({
    id,
    title: name,
    isSelected: id == phaseId,
    onClick: () => onPhaseSelected(id),
  }));

  return (
    <PopMenu
      id={'phaseOptionsPop'}
      vertical="below"
      horizontal="right"
      isRound
      hasTip={false}
      options={options}
      className={styles.popMenu}
      {...props}
    >
      <div className={styles.clickInteractionContainer}>
        <div className={styles.name}>
          <span>
            {name ? name : 'Selecciona una fase:'}
          </span>
        </div>
        <Button
          size="medium"
          Icon={MdKeyboardArrowDown}
          tint="mediumGray"
          clearMargins
          isTransparent
          isOutlined
        />
      </div>
    </PopMenu>
  )
};

export default connect(
  state => {
    const phase = selectors.getPhaseSelected(state);
    const phases = selectors.getPhases(state);

    return ({
      name: phase ? phase.name : '',
      phaseId: phase ? phase.id : -1,
      phases: phases,
    });
  },
  dispatch => ({
    onPhaseSelected(id) {
      dispatch(actions.selectPhase(id));
      dispatch(actions.startFetchPhase(id));
    },
    onClearAllSelectedPhases() {
      dispatch(actions.unselectPhase());
    }
  }),
)(PhasePopoverSelect);
