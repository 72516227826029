// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';
import type { FOBI_FORM_TYPE } from '../components/FobiRenderer/types';


export type CLIENT_FORM_DESCRIPTION_TYPE = FOBI_FORM_TYPE;
export type CONTACT_FORM_DESCRIPTION_TYPE = FOBI_FORM_TYPE;

export type CLIENT_FORM_TYPE = {
  clientFormDescription: CLIENT_FORM_DESCRIPTION_TYPE,
  contactFormDescription: CONTACT_FORM_DESCRIPTION_TYPE
};

export type CLIENT_FORM_FETCH_STARTED_TYPE = {
  type: 'CLIENT_FORM_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENT_FORM_FETCH_STARTED = 'CLIENT_FORM_FETCH_STARTED';

export type CLIENT_FORM_FETCH_COMPLETED_TYPE = {
  type: 'CLIENT_FORM_FETCH_COMPLETED',
  payload: CLIENT_FORM_TYPE
};
export const CLIENT_FORM_FETCH_COMPLETED = 'CLIENT_FORM_FETCH_COMPLETED';

export type CLIENT_FORM_FETCH_FAILED_TYPE = {
  type: 'CLIENT_FORM_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENT_FORM_FETCH_FAILED = 'CLIENT_FORM_FETCH_FAILED';

export type CLIENT_FORM_ACTION_TYPE =
  | CLIENT_FORM_FETCH_STARTED_TYPE
  | CLIENT_FORM_FETCH_COMPLETED_TYPE
  | CLIENT_FORM_FETCH_FAILED_TYPE;
