// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  CLIENT_FORM_TYPE,
  CLIENT_FORM_FETCH_STARTED_TYPE,
  CLIENT_FORM_FETCH_COMPLETED_TYPE,
  CLIENT_FORM_FETCH_FAILED_TYPE,
} from '../types/clientForm';
import * as types from '../types/clientForm';


export const startFetchingClientForm = (boardId: ID_TYPE): CLIENT_FORM_FETCH_STARTED_TYPE => ({
  type: types.CLIENT_FORM_FETCH_STARTED,
  payload: boardId,
});

export const completeFetchingClientForm = (
  clientForm: CLIENT_FORM_TYPE,
): CLIENT_FORM_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENT_FORM_FETCH_COMPLETED,
  payload: clientForm,
});

export const failFetchingClientForm = (
  error: ERROR_TYPE,
): CLIENT_FORM_FETCH_FAILED_TYPE => ({
  type: types.CLIENT_FORM_FETCH_FAILED,
  payload: error,
});
