// @flow
import React from 'react';
import { Field } from 'redux-form';

import { RenderToggleSwitch } from '../../FieldRenderer';
import styles from './toggleSendEmail.module.scss';


type ToggleSendEmailPropTypes = { };

const ToggleSendEmail = ({}: ToggleSendEmailPropTypes) => (
  <div className={styles.sendEmail}>
    <div className={styles.sendEmailText}>
      <span>
        <strong>
          {"Enviar correo de seguimiento a los contactos"}
        </strong>
      </span>
      <span>
        {"Este correo se utilizará para notificar a los contactos del cliente que su prospecto acaba de entrar a esta fase."}
      </span>
    </div>
    <div className={styles.toggle}>
      <Field
        name='sendEmail'
        component={RenderToggleSwitch}
        size='medium'
      />
    </div>
  </div>
);


export default ToggleSendEmail;
