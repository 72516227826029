import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { 
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';

import Button from '../Button';
import { RenderInput } from '../FieldRenderer';
import { SETTINGS_POPUP_ID } from '../SettingsPopup';

import styles from './securitySettings.module.scss';
import { validate } from './validation';
import * as selectors from '../../reducers';
import * as actions from '../../actions/users';
import * as closableActions from '../../actions/closeable';


type SecuritySettingsProps = {
  onSaveChanges: Function,
  currentEmail: string,
  isLoading: boolean,
  handleSubmit: Function,
  onClose: Function,
}

const SecuritySettings = ({
  isLoading,
  handleSubmit,
  onClose,
  // onSaveChanges,
  // currentEmail,
}: SecuritySettingsProps) => {
  const [showPasswordStatus, setShowPasswordStatus] = useState({
    confirmPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {'Seguridad'}
      </div>
      <div className={styles.securityMessage}>
        {'Para nosotros, es de vital importancia tu seguridad, por esta razón deberás escribir nuevamente tu contraseña:'}
      </div>
      <form className={styles.securityForm} onSubmit={handleSubmit}>
        <Grid>
          <Row>
            <Col xs={5}>
              <Field
                name="old_password"
                placeholder="**************"
                label="contraseña"
                type={showPasswordStatus.confirmPassword ? '' : 'password'}
                buttonIcon={
                  showPasswordStatus.confirmPassword
                    ? MdVisibilityOff
                    : MdVisibility
                }
                buttonSize="small"
                isButtonTransparent
                buttonClassName={styles.passwordHideColor}
                onButtonClick={
                  () => setShowPasswordStatus({
                    ...showPasswordStatus,
                    confirmPassword: !showPasswordStatus.confirmPassword,
                  })
                }
                component={RenderInput}
              />
            </Col>
          </Row>
          <div className={styles.horizontalDivider} />
          <Row>
            <Col xs={5}>
              <div className={styles.settingSubtitle}>
                {'Cambiar correo electrónico'}
              </div>
              <Field
                name="new_email"
                placeholder="example@admissions.io"
                label="Correo Electrónico"
                component={RenderInput}
              />
            </Col>
          </Row>
          <div className={styles.horizontalDivider} />
          <Row>
            <Col xs={5}>
              <div className={styles.settingSubtitle}>
                {'Cambiar contraseña'}
              </div>
              <Field
                name="new_password"
                placeholder="**************"
                label="Nueva Contraseña"
                type={showPasswordStatus.newPassword ? '' : 'password'}
                buttonIcon={showPasswordStatus.newPassword ? MdVisibilityOff : MdVisibility}
                buttonSize="small"
                isButtonTransparent
                buttonClassName={styles.passwordHideColor}
                onButtonClick={
                  () => setShowPasswordStatus({
                    ...showPasswordStatus,
                    newPassword: !showPasswordStatus.newPassword,
                  })
                }
                component={RenderInput}
              />
              <Field
                name="confirm_new_password"
                placeholder="**************"
                label="Repite Contraseña"
                type={showPasswordStatus.confirmNewPassword ? '' : 'password'}
                buttonIcon={showPasswordStatus.confirmNewPassword ? MdVisibilityOff : MdVisibility}
                buttonSize="small"
                isButtonTransparent
                buttonClassName={styles.passwordHideColor}
                onButtonClick={
                  () => setShowPasswordStatus({
                    ...showPasswordStatus,
                    confirmNewPassword: !showPasswordStatus.confirmNewPassword,
                  })
                }
                component={RenderInput}
              />
            </Col>
          </Row>
          <div className={styles.horizontalDivider} />
          <div className={styles.buttons}>
            <Button
              tint="gray"
              title="Cancelar"
              className={styles.button}
              onClick={() => onClose()}
            />
            <Button
              type="submit"
              title="Guardar Cambios"
              isLoading={isLoading}
              className={styles.button}
            />
          </div>
        </Grid>
      </form>
    </div>
  );
}

export default connect(
  state => ({
    isLoading: selectors.isRestoringUserCredentials(state),
  }),
  dispatch => ({
    onClose() {
      dispatch(closableActions.close(SETTINGS_POPUP_ID));
    }
  })
)(reduxForm({
  validate,
  form: 'restoreUserCredentials',
  onSubmit: (values, dispatch) => dispatch(
    actions.startRestoreCredentials(values),
  ),
})(SecuritySettings));
