import i18n from 'i18n-js';

import es from './languages/es';
import en from './languages/en';

export const i18nSetup = (locale: string) => {
  i18n.fallbacks = true;
  i18n.translations = { es, en };
  i18n.locale = locale;
  // i18n has an object notation for accessing traductions, this makes a " . " (dot) in a
  // string a conflict, change the dot separator for something else to avoid it
  i18n.defaultSeparator = '→';
};
