// @flow
import React from 'react';

import type { COMMENT_TYPE } from '../../types/comments';
import User from '../User';
import CommentOptions from '../CommentOptions';
import CommentRenderer from '../CommentRenderer';
import FilesUploader from '../FilesUploader';

import styles from './comment.module.scss';


const Comment = ({
  id,
  body,
  created_by: { first_name, last_name, profile_picture },
  creation_date,
  isConfirmed = false,
  isLast = false,
  files = [],
}: COMMENT_TYPE) => (
  <div className={
    `
      ${styles.comment}
      ${isConfirmed ? styles.confirmed : ''}
      ${isLast ? styles.last : ''}
    `
  }
  >
    <div className={styles.header}>
      <User
        theme="secondary"
        firstName={first_name}
        lastName={last_name}
        pictureURL={profile_picture}
        userRole={creation_date.toLocaleDateString()}
      />
      <CommentOptions id={id} />
    </div>
    <div className={styles.body}>
      <CommentRenderer raw={body} />
      <FilesUploader
        value={{ files }}
        filesItemsContainerClassName={styles.filesContainer}
        cardsMode
        hideDropArea
        noSaga
      />
    </div>
  </div>
);


export default Comment;
