import * as EmailValidator from 'email-validator';

export const validate = (values) => {
  const errors = {};

  if (!values.first_name) { errors.first_name = 'Requerido' }
  if (!values.email) {
    errors.email = 'Requerido';
  }
  if (values.email && !EmailValidator.validate(values.email)) {
    errors.email = 'Formato incorrecto';
  }

  if (values.is_admin == null) {
    errors.is_admin = "Requerido";
  }

  if (!values.schools || values.schools.length == 0) {
    errors.schools = "Requerido";
  }

  return errors;
}
