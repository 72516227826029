// @flow
import React, { Component } from 'react';
import type { Node } from 'react'

import styles from './cardRowElement.module.scss'


type CardRowElementPropTypes = {
  children?: Node,
  className?: string,
  clearPaddingRight?: boolean,
  clearPaddingLeft?: boolean,
  stack?: boolean
};

class CardRowElement extends Component<CardRowElementPropTypes> {
  render() {
    const {
      children,
      className,
      clearPaddingLeft = false,
      clearPaddingRight = false,
      stack = false
    } = this.props;

    return (
      <div className={
        `
          ${ styles.cardRowElement }
          ${ typeof className !== 'undefined' ? className: '' }
          ${ clearPaddingLeft ? styles.clearPaddingLeft : '' }
          ${ clearPaddingRight ? styles.clearPaddingRight : '' }
          ${ stack ? styles.stack : '' }
        `
      }>
        { children }
      </div>
    )
  }
}


export default CardRowElement;
