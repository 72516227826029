// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './cardContent.module.scss';
import Loader from '../Loader';


type CardContentPropTypes = {
  size?: string,
  children?: Node,
  clearPaddings?: boolean,
  tint?: string,
  className?: string,
};

type LoadingCardContentPropTypes = {
  size?: string,
  loaderTint?: string,
  tint?: string,
  title?: string,
  className?: string
};

const CardContent = ({
  size = 'medium',
  tint = 'white',
  children,
  clearPaddings = false,
  className,
} : CardContentPropTypes) => (
  <div className={
    `
      ${styles.cardContent}
      ${styles[size]}
      ${styles[tint]}
      ${clearPaddings ? styles.clearPaddings : ''}
      ${typeof className !== 'undefined' ? className : ''}
    `
   }
  >
    { children }
  </div>
);

export const LoadingCardContent = ({
  size = 'medium',
  tint = 'white',
  loaderTint = 'gray',
  className,
  title,
}: LoadingCardContentPropTypes) => (
  <CardContent
    size={size}
    tint={tint}
    className={
      `
        ${styles.loadingContent}
        ${typeof className !== 'undefined' ? className : ''}
      `
    }
  >
    <Loader isInline tint={loaderTint} />
    {
      typeof title !== 'undefined' && (
        <div
          className={
            `
              ${styles.loadingContentTitle}
              ${typeof styles[`${tint}Loading`] !== 'undefined' ? styles[`${tint}Loading`] : ''}
            `
          }
        >
          { title }
        </div>
      )
    }
  </CardContent>
);

export default CardContent;
