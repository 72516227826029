// @flow
import React from 'react';

import MenuItem from './MenuItem';
import styles from './menuItems.module.scss';


type MenuItemsPropTypes = {
  items: Array<Object>,
  onSelect?: Function,
  selected?: number,
};

const MenuItems = ({
  items,
  onSelect,
  selected,
}: MenuItemsPropTypes) => (
  <div className={styles.container}>
    {
      items.map(item => (
        <MenuItem
          key={item.id}
          title={item.title}
          Icon={item.Icon}
          isSelected={selected == item.id}
          onClick={onSelect ? () => onSelect(item) : null}
        />
      ))
    }
  </div>
);

export default MenuItems;
