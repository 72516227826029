// @flow
import React from 'react';

import type { ID_TYPE } from '../../types/common';

import styles from './checkboxGroup.module.scss';


type OPTION_TYPE = {
  value: ID_TYPE,
  label: string,
};

type CheckboxGroupPropTypes = {
  value?: Array<OPTION_TYPE>,
  options: Array<OPTION_TYPE>,
  onChange: Function,
};

class CheckboxGroup extends React.Component<CheckboxGroupPropTypes> {
  render() {
    const {
      onChange,
      options,
      value,
    } = this.props;

    const eValue = value || [];

    return (
      <div
        className={
          `
            ${styles.checkboxGroup}
          `
        }
      >
        {
          options.map(({value, label}: OPTION_TYPE, index: number) => (
            <div
              className={
                `
                  ${styles.checkbox}
                `
              }
              key={`${label}[${index}]`}
            >
              <label>
                <input
                  type="checkbox"
                  name={`${label}[${index}]`}
                  value={value}
                  checked={eValue.includes(value)}
                  onChange={event => {
                    const arr = [...eValue];
                    if(event.target.checked) arr.push(value);
                    else arr.splice(arr.indexOf(value), 1);
                    return onChange(arr);
                  }}
                  />
                {label}
              </label>
            </div>
          ))
        }
      </div>
    );
  }
}

export default CheckboxGroup;
