// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdEvent, MdCheck } from 'react-icons/md';
import type {
  DraggableProvided,
} from 'react-beautiful-dnd';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/clients';
import { open } from '../../actions/closeable';
import { CLIENT_EDITION_POPUP_ID } from '../ClientEditionPopup';
import Button from '../Button';
import ClientOptions from '../ClientOptions';
// import DRAG_ITEM_TYPES from '../dragItemTypes';
import LocaleDate from '../LocaleDate';
import placeHolder from '../../../assets/images/user_ph.png';

import styles from './client.module.scss';


type ClientPropTypes = {
  id: ID_TYPE,
  index: number,
  phase: ID_TYPE,
  firstName: string,
  lastName: string,
  level: string,
  nextRelevantDate: Date,
  isSelected?: boolean,
  isConfirmed?: boolean,
  isAnyClientDragging?: boolean,
  isInSelectedClientsIds?: boolean,
  isAdmitted?: boolean,
  isArchived?: boolean,
  hasNoStatusFilter?: boolean,
  onDrop: Function,
  isDragging?: boolean,
  connectDragSource: Function,
  onDragStarted: Function,
  onDragStopped: Function,
  onCheckboxClicked: Function,
  onClick?: Function,
  provided: DraggableProvided,
  profilePicture: Object,
  selectedClientsIds: Array<ID_TYPE>,
};

const Client = ({
  id,
  index,
  firstName,
  lastName,
  level,
  nextRelevantDate,
  isSelected = false,
  isDragging = false,
  isConfirmed = false,
  isAnyClientDragging = false,
  isAdmitted,
  isArchived,
  hasNoStatusFilter,
  isInSelectedClientsIds = false,
  onCheckboxClicked,
  onClick,
  provided,
  selectedClientsIds,
  profilePicture = {},
  ...props
}: ClientPropTypes) => (
  <div
    className={
      `
        ${styles.client}
        ${index != null ? styles.indexSpace : ''}
        ${!isConfirmed ? styles.waiting : ''}
        ${isDragging ? styles.dragging : ''}
        ${isAnyClientDragging && (!isDragging) && isSelected ? styles.isAnyClientDragging : ''}
        ${(isAdmitted && hasNoStatusFilter) ? styles.isAdmitted : ''}
        ${(isArchived && hasNoStatusFilter) ? styles.isArchived : ''}
      `
    }
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    {...props}
  >
    {
      index != null && (
        <div className={styles.toolSection}>
          {index + 1}
          <div className={styles.checkboxContainer}>
            {
              isSelected
                ? (
                  <Button
                    onClick={onCheckboxClicked}
                    size="extraSmall"
                    Icon={MdCheck}
                    clearMargins
                  />
                ) : (
                  <Button
                    onClick={onCheckboxClicked}
                    size="extraSmall"
                    Icon={MdCheck}
                    clearMargins
                    tint="mediumGray"
                  />
                )
            }
          </div>
        </div>
      )
    }
    {
      (isDragging && selectedClientsIds.length > 1) && (
      <div className={styles.badge}>
        {isInSelectedClientsIds ? selectedClientsIds.length : selectedClientsIds.length + 1}
      </div>
      )
    }
    {/* <div className={styles.checkboxContainer}>
      {
        isSelected
          ? (
            <Button
              onClick={onCheckboxClicked}
              size="extraSmall"
              Icon={MdCheck}
              clearMargins
            />
          ) : (
            <Button
              onClick={onCheckboxClicked}
              size="extraSmall"
              Icon={MdCheck}
              clearMargins
              tint="mediumGray"
            />
          )
      }
    </div> */}
    {
      <div className={styles.profileImage}>
        <img
          src={profilePicture ? profilePicture.url : placeHolder} alt='' />
      </div>
    }
    <div className={styles.data}>
      <span
        title={ `${firstName} ${lastName}` }
        className={styles.name}
        onClick={onClick}
        onKeyPress={onClick}
        role="presentation"
      >
        { firstName }
        {' '}
        { lastName }
      </span>
      <div className={styles.level}>
        { level }
      </div>
      <div className={styles.date}>
        <MdEvent size={18} />
        <span className={styles.text}>
          <LocaleDate date={nextRelevantDate} format="dd ll" />
        </span>
      </div>
    </div>
    <div className={styles.optionsContainer}>
      <ClientOptions id={id} />
    </div>
  </div>
);

export default connect(
  (state, { id, phase }) => {
    const { isSelected } = selectors.getClient(state, id);
    const selectedClientsIds = selectors.getSelectedClientsIds(state) || []

    return {
      isSelected,
      selectedClientsIds,
      clientsIds: selectors.getPhaseClientsIds(state, phase),
      lastSelectedClientId: selectors.lastSelectedClient(state),
      isInSelectedClientsIds: selectedClientsIds.includes(id),
      isAnyClientDragging: selectors.isAnyClientDragging(state),
    };
  },
  (dispatch, { id }) => ({
    onCheckboxClicked(isSelected: boolean) {
      if (isSelected) {
        dispatch(actions.deselectClient(id));
        dispatch(actions.selectLastClient(-1));
      } else {
        dispatch(actions.selectClient(id));
        dispatch(actions.selectLastClient(id));
      }
    },
    onMultipleSelection(clients) {
      clients.forEach(clientId => dispatch(actions.selectClient(clientId)));
    },
    onClick() {
      dispatch(actions.selectClientForEdition(id));
      dispatch(open(CLIENT_EDITION_POPUP_ID));
    },
  }),
  (
    {
      isSelected,
      selectedClientsIds,
      clientsIds,
      lastSelectedClientId,
      ...stateProps
    },
    {
      onCheckboxClicked,
      onMultipleSelection,
      onDrop,
      ...dispatchProps
    },
    {
      id,
      ...ownProps
    },
  ) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    id,
    isSelected,
    selectedClientsIds,
    onCheckboxClicked(e) {
      // Validate last selected id is valid
      if (e.shiftKey && lastSelectedClientId > 0) {
        const lastIndex = clientsIds.indexOf(lastSelectedClientId);
        const actualIndex = clientsIds.indexOf(id);

        if (lastIndex !== actualIndex || !isSelected) {
          // Deduce range to check
          const selectedRange = (lastIndex > actualIndex)
            ? clientsIds.slice(actualIndex, lastIndex) : clientsIds.slice(lastIndex, actualIndex);

          // Avoid deselect
          if (!selectedClientsIds.includes(id)) {
            // Select actual client clicked
            onCheckboxClicked(isSelected);
          }
          // Select clients between actual and last selected
          onMultipleSelection(selectedRange);
        }
      } else {
        onCheckboxClicked(isSelected);
      }
    },
    onDrop(clientId: ID_TYPE, destinationPhaseId: ID_TYPE) {
      // Avoid duplicates
      if (selectedClientsIds.includes(clientId) || selectedClientsIds.includes(`${clientId}`)) {
        onDrop(selectedClientsIds, destinationPhaseId);
      } else {
        onDrop([clientId, ...selectedClientsIds], destinationPhaseId);
      }
    },
  }),
)(Client);
