// @flow
import { error } from './common';
import * as types from '../types/schools';
import type {
  SCHOOL_TYPE,
  SCHOOL_FETCH_STARTED_TYPE,
  SCHOOL_FETCH_SUCCEED_TYPE,
} from '../types/schools';

export const startFetchingSchool = (id: number): SCHOOL_FETCH_STARTED_TYPE => ({
  type: types.SCHOOL_FETCH_STARTED,
  payload: id,
});


export const completeFetchingSchool = (school: SCHOOL_TYPE): SCHOOL_FETCH_SUCCEED_TYPE => ({
  type: types.SCHOOL_FETCH_SUCCEED,
  payload: school,
});

export const failFetchingSchool = error(types.SCHOOL_FETCH_FAILED);
