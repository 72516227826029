// @flow
import {
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';

import { fibonacciBackoff } from '../lib/retry';
import * as types from '../types/schools';
import * as actions from '../actions/schools';

import { Schools } from '../api';


function* fetchSchool(action) {
  const { payload } = action;
  // const id = payload;

  const mock = {
    delay: 2000,
    response: {
      statusCode: 200,
      body: {
        id: 1,
        name: 'Colegio Monte María',
        address: 'Villa Nueva',
        phone: '6636-5701',
        logo: {
          url: 'http://colegiomontemaria.edu.gt/wp-content/themes/monte_maria/images/logo_header.png',
        },
      },
    },
  };

  try {
    const response = yield call(
      fibonacciBackoff,
      [Schools, 'detail'],
      { id: payload, mock },
    );

    // Register school
    yield put(actions.completeFetchingSchool(response));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(actions.failFetchingSchool({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchSchoolFetchStart(): Iterator<any> {
  yield takeEvery(
    types.SCHOOL_FETCH_STARTED,
    fetchSchool,
  );
}
