// @flow
import React from 'react';
import styles from './loginlogo.module.scss';

const LoginLogo = () => (
  <div
    className={
      `
        ${styles.loginlogo}
      `
    }
  />
);

export default LoginLogo;
