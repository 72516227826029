// @flow
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { NavLink } from 'react-router-dom';

import * as selectors from '../../../reducers';
import LoginForm from '../../LoginForm';
// import LoginError from '../../LoginError';
import LoginFormContainer from '../../LoginFormContainer';
import LoginFormSubtitle from '../../LoginFormSubtitle';
import LoginLogo from '../../LoginLogo';
import LoginPoweredByEdoo from '../../LoginPoweredByEdoo';
import LoginMessage from '../../LoginMessage';

import styles from './login.module.scss';


type LoginViewPropTypes = {
  isAuthenticated: boolean,
  handleAuthenticated: () => void
};

class LoginView extends React.Component<LoginViewPropTypes> {
  componentDidMount() {
    const {
      isAuthenticated,
      handleAuthenticated,
    } = this.props;

    if (isAuthenticated) { handleAuthenticated(); }
  }

  render() { 
    return (
      <div className={styles.login}>
        <LoginFormContainer>
          <LoginLogo />
          <LoginFormSubtitle>
            {'Obtén completo control sobre tu proceso de admisiones'}
          </LoginFormSubtitle>
          <LoginMessage />
          {/* <LoginError /> */}
          <LoginForm />
          <NavLink to="/forgot-password">
            {'¿Olvidaste tu contraseña?'}
          </NavLink>
        </LoginFormContainer>
        <div className={styles.loginRightPanel}>
          <LoginPoweredByEdoo />
        </div>
      </div>
    );
  }
}


export default connect(
  state => ({
    isAuthenticated: selectors.isAuthenticated(state),
    isAdmin: true,
    boardUUID: selectors.getUserFirstSchoolBoard(state),
  }),
  dispatch => ({
    handleAuthenticated(isAdmin, boardUUID) {
      if (isAdmin) {
        dispatch(push(`dashboard/${boardUUID}`));
      } else {
        // LOGOUT?
      }
    },
  }),
  (
    stateProps,
    dispatchProps,
    ownProps,
  ) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    handleAuthenticated() {
      dispatchProps.handleAuthenticated(stateProps.isAdmin, stateProps.boardUUID);
    },
  }),
)(LoginView);
