// @flow
// import * as EmailValidator from 'email-validator';

import type {
  SELECT_ELEMENT_TYPE,
  /* BOOLEAN_ELEMENT_TYPE,
  DATE_ELEMENT_TYPE,
  DECIMAL_ELEMENT_TYPE,
  EMAIL_ELEMENT_TYPE,
  FLOAT_ELEMENT_TYPE,
  INTEGER_ELEMENT_TYPE,
  NULL_BOOLEAN_ELEMENT_TYPE,
  TEXT_ELEMENT_TYPE,
  TEXTAREA_ELEMENT_TYPE,
  FOBI_ELEMENT_TYPE, */
} from './types';

// import * as errorMessages from './errorMessages';

export const replaceBooleanElement = (
  value: boolean,
  // formElement: BOOLEAN_ELEMENT_TYPE,
): ?string => {
  let testValue = value;
  // Excel - Bulk Upload
  if (typeof testValue === 'string') {
    if (['sí', 'si', 'yes', 'y', 'true', 's'].includes(testValue.toLowerCase())) {
      testValue = true;
    } else if (['no', 'not', 'f', 'false', 'n'].includes(testValue.toLowerCase())) {
      testValue = false;
    }
  }

  if (typeof value === 'boolean') { return value; }
  return testValue;
};

export const replaceSelectElement = (value: any, formElement: SELECT_ELEMENT_TYPE): ?string => {
  if (typeof value !== 'undefined' && typeof value === 'string') {
    if (value === '') return value;

    const { plugin_data: { choices } } = formElement;
    const choicesValues = choices.map(choice => choice.value.toString());

    if (choicesValues.includes(value.toString())) {
      return value;
    }
  }
  return undefined;
};

export const replaceMultiSelectElement = (
  value: any, formElement: SELECT_ELEMENT_TYPE
): ?string => {
  if (typeof value !== 'undefined' && typeof value === 'string') {
    if (value === '') return value;

    const validChoices = [];
    const valuesPassed = value.replace(', ', ',').split(',');
    const { plugin_data: { choices } } = formElement;
    const choicesValues = choices.map(choice => choice.value.toString());

    valuesPassed.forEach((val) => {
      if (choicesValues.includes(val.toString())) {
        validChoices.push(val.toString());
      }
    });
    return validChoices;
  }
  return undefined;
};
