// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { RELATIONSHIP_TYPE } from '../types/relationships';
import * as common from './common';
import * as types from '../types/relationships';

export type RelationshipsState = {
  byId: { [ID_TYPE]: RELATIONSHIP_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE
};

const byId = common.byId({
  fetched: [types.RELATIONSHIPS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.RELATIONSHIPS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.RELATIONSHIPS_FETCH_STARTED],
  succeed: [types.RELATIONSHIPS_FETCH_COMPLETED],
  failed: [types.RELATIONSHIPS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.RELATIONSHIPS_FETCH_STARTED,
    types.RELATIONSHIPS_FETCH_COMPLETED,
  ],
  populate: [types.RELATIONSHIPS_FETCH_FAILED],
});

const relationships = combineReducers({
  byId,
  order,
  isFetching,
  error,
});


export default relationships;


// Selectors
export const getRelationship = (
  state: RelationshipsState,
  id: ID_TYPE,
): ?RELATIONSHIP_TYPE => state.byId[id];
export const getRelationships = (
  state: RelationshipsState,
): Array<?RELATIONSHIP_TYPE> => state.order.map(i => getRelationship(state, i));
export const isFetchingRelationships = (state: RelationshipsState): boolean => state.isFetching;
export const getRelationshipsError = (state: RelationshipsState): ERROR_TYPE => state.error;
