// @flow
import type { ComponentType } from 'react';
import type { FieldProps } from 'redux-form';
import React from 'react';

import Label from '../Label';
import { PopHoverMenu } from '../PopMenu';
import { MdInfo } from 'react-icons/md';

import styles from './fieldRenderer.module.scss';

type generateRendererParams = {
  FieldComponent: ComponentType<*>,
  ErrorComponent?: ComponentType<*>,
  PreviewComponent?: ComponentType<*>,
  realTimeErrors?: boolean,
  overrideOnBlur?: boolean
};

type GeneratedFieldProps = FieldProps & {
  showPreview?: boolean,
  customOnChange?: Function,
  baseColor?: string,
  required?: boolean,
  errorClassName?: string,
};

export const generateRenderer = ({
  FieldComponent,
  ErrorComponent,
  PreviewComponent,
  realTimeErrors = false,
  // overrideOnBlur = false,
}: generateRendererParams) => ({
  input,
  label,
  labelTooltip,
  meta: { touched, error },
  showPreview = false,
  customOnChange,
  required = false,
  baseColor = '',
  errorClassName = '',
  ...props
}: GeneratedFieldProps) => {
  return (
    <div className={styles.fieldRenderer}>
      <div className={styles.labelContainer}>
        {
          label && (
            <Label baseColor={baseColor} required={(required && !showPreview)}>
              { label }
            </Label>
          )
        }

        {
          labelTooltip && (
            <span className={styles.tooltip}>
              <span>
                <PopHoverMenu
                  id={`tooltip_${label}`}
                  vertical='below'
                  horizontal='left'
                  hasTip={false}
                  isRound
                  options={[{ title: labelTooltip }]}
                >
                  <MdInfo size={18} />
                </PopHoverMenu>
              </span>
            </span>
          )
        }
      </div>
      {
        (touched || realTimeErrors)
          && !showPreview
          && error
          && ErrorComponent
            && <ErrorComponent title={error} className={errorClassName}/>
      }
      {
        showPreview && PreviewComponent
          ? <PreviewComponent {...props} value={input.value} />
          : (
            <FieldComponent
              {...input}
              {...props}
              tint={baseColor}
              onChange={
                (e) => {
                  input.onChange(e);
                  if (customOnChange) {
                    customOnChange(e);
                  }
                }
              }
              onBlur={() => input.onBlur(input.value)}
            />
          )
      }
    </div>
  );
};
