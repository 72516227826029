// @flow
import React from 'react';
import { MdClose } from 'react-icons/md';

import PopupContainer from '../PopupContainer';
import Popup from '../Popup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import ClientCreationForm from '../ClientForm/ClientCreationForm';
// import ClientCreationSuccessPopup from '../ClientCreationSuccessPopup';

import styles from './clientCreationPopup.module.scss';


// type ClientCreationPopupPropTypes = {};

export const CLIENT_CREATION_POPUP_ID = 'clientCreationPopup';

const ClientCreationPopup = () => (
  <Popup id={CLIENT_CREATION_POPUP_ID} transitionMs={600}>
    <PopupContainer
      tint="lightGray"
      className={styles.popupContainer}
      isFullscreen
    >
      <PopupCloseButton
        tint="mediumGray"
        Icon={MdClose}
        clearMargins
        isTransparent
        isOutlined
      />
      <PopupContent className={styles.popupContent} clearPaddings>
        <ClientCreationForm
          title="Creación de estudiante"
          actionTitle="Crear estudiante"
        />
      </PopupContent>
    </PopupContainer>
  </Popup>
);

export default ClientCreationPopup;
