// @flow
import React from 'react';
import ReactSelect from 'react-select';
// import chroma from 'chroma-js';

import type { ID_TYPE } from '../../types/common';
import styles from './multiSelect.module.scss';


type OPTION_TYPE = {
  value: ID_TYPE
};

type MultiSelectPropTypes = {
  size?: string,
  value?: Array<ID_TYPE>,
  options: Array<OPTION_TYPE>,
  onChange?: OPTION_TYPE => void,
  size?: string,
  className?: string,
  tint?: string,
};


const MultiSelect = ({
  value = [],
  options,
  onChange,
  size = '',
  className,
  tint,
  ...props
}: MultiSelectPropTypes) => {
  let newValues = [];
  
  if (value != null && value != '') {
    value.map(id => {
      newValues = [...newValues, ...options.filter(el => el.value === id)];
    });
  };

  const customStyles = {
    control: (base, state) => ({
      // none of react-select's styles are passed to <Control />
      ...base,
      border: (state.isFocused || state.isSelected) ? `1px solid ${tint && tint !== '' ? tint : '#37968d'}` : '1px solid #dce3e4',
      '&:hover': {
        border: `1px solid ${tint && tint !== '' ? tint : '#37968d'}`,
      },
    }),
  };

  return (
    <ReactSelect
      {...props}
      className={
        `
          ${styles.select}
          ${{ ...styles }}
          ${styles[size] ? styles[size] : ''}
          ${className ? styles[className] : ''}
        `
      }
      isMulti
      classNamePrefix="react-multi-select"
      value={newValues}
      options={options}
      onChange={
        values => onChange && onChange([...values.map(el => el.value)])
      }
      styles={customStyles}
      theme={theme => ({
        ...theme,
      })}
    />
  )
}


export default MultiSelect;
