import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import HTMLTextEditor from '../HTMLTextEditor';
import LanguageDummySelector from '../LanguageDummySelector';
import { LANGUAGES_CONFIG } from '../../settings';
import * as selectors from '../../reducers';

import styles from './multilingualTextEditor.module.scss';

const HTMLMultilingualTextEditor = ({
  name,
  languages,
  value,
  onChange,
}) => {
  const initialValue = value === '' ? '' : value;
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES_CONFIG[0]);
  const [availableLanguages, setAvailableLanguages] = useState(LANGUAGES_CONFIG);
  const [localValue, setLocalValue] = useState(initialValue);

  
  useEffect(() => {
    const availableLanguages = LANGUAGES_CONFIG.filter(e => languages.includes(e.i18nKey)) || []
    setSelectedLanguage(availableLanguages[0]);
    setAvailableLanguages(availableLanguages);
  }, [languages])

  const handleChange = (text) => {
    const newLocalValue = {
      ...localValue,
      [selectedLanguage.i18nKey]: text
    }
    setLocalValue(newLocalValue);
    if (onChange) onChange({...newLocalValue});
  }

  return (
    <div className={styles.textEditor}>
      {
        availableLanguages && (
          <LanguageDummySelector
            name={name}
            className={styles.languageSelector}
            languages={availableLanguages}
            selectedLanguage={selectedLanguage}
            onSelectLanguage={({ i18nKey }) => setSelectedLanguage(
              availableLanguages.find((item) => item.i18nKey === i18nKey)
            )}
          />
        )
      }
      {
        selectedLanguage && (
          <HTMLTextEditor
            onChange={handleChange}
            value={localValue[selectedLanguage.i18nKey]}
          />
        )
      }
    </div>
  )
}

export default connect(
  (state) => ({
    languages: (selectors.getCurrentBoard(state) || {}).languages || [],
  }),
)(HTMLMultilingualTextEditor);
