// @flow
import React, { useEffect } from 'react';
import { MdKeyboardArrowDown, MdArrowForward } from 'react-icons/md';
import { connect } from 'react-redux';
import * as selectors from '../../reducers';
import * as reportsActions from '../../actions/reports';
import styles from './dashboardReportsHeaders.module.scss';
import DatePicker from '../DatePicker';
import PopMenu from '../PopMenu';

// BIG TODO: Manage reports with our Global Filters
type DashboardReportsHeaderPropTypes = {
  boardId: number,
  dates: { startDate: Date, endDate: Date },
  selectedCycle: any,
  cycles: any[],
  setStartDate: Function,
  setEndDate: Function,
  setSelectedCycle: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  updateData: Function,
  reload: Function,
};

const calculateCyclesYears = (
  cycles: any[],
  action: (cycle: any) => void,
) => cycles
  .map(value => ({
    title: value.name,
    onClick: () => action(value),
  }));

const DashboardReportsHeader = ({
  // eslint-disable-next-line no-unused-vars
  boardId,
  dates,
  setStartDate,
  setEndDate,
  setSelectedCycle,
  selectedCycle,
  cycles,
  reload,
}: DashboardReportsHeaderPropTypes) => {
  useEffect(() => {
    if (selectedCycle === null) {
      setSelectedCycle(cycles[0] || null);
    }
    if (dates.startDate === null && dates.endDate === null && selectedCycle !== null) {
      setStartDate(new Date(new Date().getFullYear(), 0, 1));
      setEndDate(new Date(new Date().getFullYear(), 11, 31));
    }
  });

  return (
    <div className={styles.dashboardHeader}>
      <div className={styles.leftGroup}>
        <div className={styles.selectCycle}>
          <PopMenu
            id={`cycleSelector-${1}`}
            vertical="below"
            horizontal="center"
            tipPosition="center"
            options={calculateCyclesYears(cycles, (cycle) => {
              setSelectedCycle(cycle);
              reload();
            })}
            isRound
          >
            <div className={styles.title}>
              {`Ciclo ${selectedCycle !== null ? selectedCycle.name : ''}`}
              <MdKeyboardArrowDown />
            </div>
          </PopMenu>
        </div>
      </div>
      <div className={styles.rightGroup}>
        <div className={styles.rangePicker}>
          <DatePicker
            calendarCustomClassName={styles.calendarZIndex}
            size="small"
            onChange={(value) => {
              setStartDate(value);
              reload();
            }}
            value={dates.startDate}
            hideClearIcon
            hideCalendarIcon
          />
          <div className={styles.arrowContainer}>
            <MdArrowForward className={styles.arrowSeparator} />
          </div>
          <DatePicker
            calendarCustomClassName={`${styles.moveCalendar} ${styles.calendarZIndex}`}
            size="small"
            onChange={(value) => {
              setEndDate(value);
              reload();
            }}
            value={dates.endDate}
            hideClearIcon
            hideCalendarIcon
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    dates: selectors.getReportsDates(state),
    selectedCycle: selectors.getReportsSelectedCycle(state),
    cycles: selectors.getCycles(state).sort(
      (a, b) => new Date(b.start_date) - new Date(a.start_date),
    ),
  }),
  dispatch => ({
    updateData(boardId) {
      dispatch(reportsActions.startFetchingClientsCountPerMonth(boardId));
      dispatch(reportsActions.startFetchingClientsCountPerLevel(boardId));
      dispatch(reportsActions.startFetchingClientsCountPerState(boardId));
      dispatch(reportsActions.startFetchingClientsCountPerPhase(boardId));
    },
    setStartDate(startDate) {
      dispatch(reportsActions.setStartDate(startDate));
    },
    setEndDate(endDate) {
      dispatch(reportsActions.setEndDate(endDate));
    },
    // TODO: This must be controlled with Global Filters!
    setSelectedCycle(selectedCycle) {
      dispatch(reportsActions.setSelectedCycle(selectedCycle));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    reload() {
      dispatchProps.updateData(stateProps.boardId);
    },
  }),
)(DashboardReportsHeader);
