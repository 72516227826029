// @flow
import * as EmailValidator from 'email-validator';

import type {
  BOOLEAN_ELEMENT_TYPE,
  DATE_ELEMENT_TYPE,
  DECIMAL_ELEMENT_TYPE,
  EMAIL_ELEMENT_TYPE,
  FLOAT_ELEMENT_TYPE,
  INTEGER_ELEMENT_TYPE,
  NULL_BOOLEAN_ELEMENT_TYPE,
  SELECT_ELEMENT_TYPE,
  TEXT_ELEMENT_TYPE,
  TEXTAREA_ELEMENT_TYPE,
  FOBI_ELEMENT_TYPE,
} from './types';

import * as errorMessages from './errorMessages';


export const validateRequired = (value: any, formElement: FOBI_ELEMENT_TYPE): boolean => {
  if (formElement.plugin_data.required) {
    return value;
  }

  return true;
};

export const validateBooleanElement = (
  value: boolean,
  formElement: BOOLEAN_ELEMENT_TYPE,
): ?string => {
  let testValue = value;
  // Excel - Bulk Upload
  if (typeof testValue === 'string') {
    if (['sí', 'si', 'yes', 'y', 'true', 's'].includes(testValue.toLowerCase())) {
      testValue = true;
    } else if (['no', 'not', 'f', 'false', 'n'].includes(testValue.toLowerCase())) {
      testValue = false;
    }
  }

  if (
    formElement.plugin_data.required
    && typeof testValue !== 'boolean'
  ) { return errorMessages.REQUIRED; }

  if (typeof testValue === 'undefined') { return undefined; }
  if (typeof testValue !== 'boolean') { return errorMessages.INVALID_TYPE; }

  return undefined;
};

export const validateDateElement = (value: Date, formElement: DATE_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (value && typeof value === 'string') {
    if (Number.isNaN(Date.parse(value))) {
      return errorMessages.INVALID_TYPE;
    }
    return undefined;
  }
  if (value && value.constructor !== Date) { return errorMessages.INVALID_TYPE; }

  return undefined;
};

export const validateDecimalElement = (
  value: number,
  formElement: DECIMAL_ELEMENT_TYPE,
): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (Number.isNaN(value)) { return errorMessages.INVALID_TYPE; }

  const {
    plugin_data: {
      max_digits,
      decimal_places,
      min_value,
      max_value,
    },
  } = formElement;

  const stringValue = value.toString();
  const splittedValue = stringValue.split('.');
  const decimalPlaces = splittedValue.length > 2 ? splittedValue[1].length : 0;
  const digitsCount = splittedValue.reduce((acc, part) => acc + part.length, 0);

  // Validate max digits
  if (max_digits && max_digits < digitsCount) {
    return errorMessages.maxDigits(max_digits);
  }

  // Validate decimal places
  if (decimal_places && splittedValue.length > 2 && decimal_places < decimalPlaces) {
    return errorMessages.maxDecimalPlaces(decimal_places);
  }

  // Validate min_value
  if (min_value && value < min_value) {
    return errorMessages.minValue(min_value);
  }

  // Validate max_value
  if (max_value && value > max_value) {
    return errorMessages.maxValue(max_value);
  }

  return undefined;
};

export const validateEmailElement = (value: string, formElement: EMAIL_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (typeof value !== 'string') { return errorMessages.INVALID_TYPE; }

  const { plugin_data: { max_length } } = formElement;

  if (max_length && value.length > max_length) {
    return errorMessages.maxLength(max_length);
  }

  if (!EmailValidator.validate(value)) {
    return errorMessages.EMAIL;
  }

  return undefined;
};

export const validateFloatElement = (value: number, formElement: FLOAT_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (Number.isNaN(value)) { return errorMessages.INVALID_TYPE; }

  const {
    plugin_data: {
      min_value,
      max_value,
    },
  } = formElement;

  // Validate min_value
  if (min_value && value < min_value) {
    return errorMessages.minValue(min_value);
  }

  // Validate max_value
  if (max_value && value > max_value) {
    return errorMessages.maxValue(max_value);
  }

  return undefined;
};

export const validateIntegerElement = (
  value: number,
  formElement: INTEGER_ELEMENT_TYPE,
): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (Number.isNaN(value)) { return errorMessages.INVALID_TYPE; }

  // Validate if number is integer
  const nValue = parseFloat(value);
  if (!Number.isInteger(nValue)) {
    return errorMessages.integer(nValue);
  }

  const {
    plugin_data: {
      min_value,
      max_value,
    },
  } = formElement;

  // Validate min_value
  if (min_value && nValue < min_value) {
    return errorMessages.minValue(min_value);
  }

  // Validate max_value
  if (max_value && nValue > max_value) {
    return errorMessages.maxValue(max_value);
  }

  return undefined;
};

export const validateNullBooleanElement = (
  value: ?boolean,
  formElement: NULL_BOOLEAN_ELEMENT_TYPE,
): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (typeof value !== 'boolean' && value !== null) { return errorMessages.INVALID_TYPE; }

  return undefined;
};

export const validateSelectElement = (value: any, formElement: SELECT_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (typeof value === 'string' && (value.length === 0 || value === ' ')) return undefined;

  const { plugin_data: { choices } } = formElement;

  const choicesValues = choices.map(choice => choice.value.toString());
  // const choicesNames = choices.map(choice => choice.name);

  if (!choicesValues.includes(value.toString())) {
    return errorMessages.choices();
  }

  return undefined;
};

export const validateMultipleOptionsElement = (
  value: any, formElement: SELECT_ELEMENT_TYPE
): ?string => {

  let testValue = value;
  if (!validateRequired(testValue, formElement)) { return errorMessages.REQUIRED; }

  if (typeof testValue === 'undefined') { return undefined; }
  if (!Array.isArray(testValue) && typeof testValue === 'string') {
    testValue = testValue.replace(', ', ',').split(',');
  }

  const { plugin_data: { choices } } = formElement;

  const choicesValues = choices.map(choice => choice.value.toString());
  // const choicesNames = choices.map(choice => choice.name);
  let noValidChoice;
  testValue.forEach((option) => {
    if (!choicesValues.includes(option.toString())) {
      noValidChoice = errorMessages.choices();
    }
  });
  return noValidChoice;
};

export const validateTextElement = (value: string, formElement: TEXT_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  // Is it our fault that people only have numbers in a text field? I think not...
  // If people write a number in a text field, for me it's OK
  if (!Number.isNaN(value)) return undefined;

  if (typeof value === 'undefined') return undefined;
  if (typeof value !== 'string') { return errorMessages.INVALID_TYPE; }

  const { plugin_data: { max_length } } = formElement;

  if (max_length && value.length > max_length) {
    return errorMessages.maxLength(max_length);
  }

  return undefined;
};

export const validateTextareaElement = (
  value: string,
  formElement: TEXTAREA_ELEMENT_TYPE,
): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }

  const { plugin_data: { max_length } } = formElement;

  if (max_length && value.length > max_length) {
    return errorMessages.maxLength(max_length);
  }

  return undefined;
};
