// @flow
import { error } from './common';
import * as types from '../types/boards';
import type {
  BOARD_TYPE,
  BOARD_FETCH_STARTED_TYPE,
  BOARD_FETCH_SUCCEED_TYPE,
  BOARDS_FETCH_STARTED_TYPE,
  BOARDS_FETCH_COMPLETED_TYPE,
  BOARDS_FETCH_FAILED_TYPE,
  BOARD_UPDATE_STARTED_TYPE,
  BOARD_UPDATE_FAILED_TYPE,
  BOARD_UPDATE_COMPLETED_TYPE,
} from '../types/boards';

export const startFetchingBoard = (id: number): BOARD_FETCH_STARTED_TYPE => ({
  type: types.BOARD_FETCH_STARTED,
  payload: id,
});

export const completeFetchingBoard = (board: BOARD_TYPE): BOARD_FETCH_SUCCEED_TYPE => ({
  type: types.BOARD_FETCH_SUCCEED,
  payload: board,
});

export const failFetchingBoard = error(types.BOARD_FETCH_FAILED);

// Fetch boards
export const startFetchingBoards = (): BOARDS_FETCH_STARTED_TYPE => ({
  type: types.BOARDS_FETCH_STARTED,
});

export const completeFetchingBoards = (
  boardsById: { [ID_TYPE]: BOARD_TYPE },
  boardIds: Array<ID_TYPE>,
): BOARDS_FETCH_COMPLETED_TYPE => ({
  type: types.BOARDS_FETCH_COMPLETED,
  payload: {
    entities: boardsById,
    order: boardIds,
  },
});

export const failFetchingBoards = (error: ERROR_TYPE): BOARDS_FETCH_FAILED_TYPE => ({
  type: types.BOARDS_FETCH_FAILED,
  payload: error,
});

// Update board
export const startUpdatingBoard = (id: ID_TYPE, attributes: Object): BOARD_UPDATE_STARTED_TYPE => ({
  type: types.BOARD_UPDATE_STARTED,
  payload: {
    id,
    ...attributes,
  },
});

export const completeUpdatingBoard = (id: ID_TYPE): BOARD_UPDATE_COMPLETED_TYPE => ({
  type: types.BOARD_UPDATE_COMPLETED,
  payload: id,
});

export const failUpdatingBoard = (error: ERROR_TYPE): BOARD_UPDATE_FAILED_TYPE => ({
  type: types.BOARD_UPDATE_FAILED,
  payload: error,
});
