// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './menuItem.module.scss';


type MenuItemPropTypes = {
  Icon?: ComponentType<*>,
  title: string,
  onClick?: Function,
  isSelected?: boolean,
  theme?: string,
}

const MenuItem = ({
  Icon,
  title,
  onClick,
  isSelected = false,
  theme,
}: MenuItemPropTypes) => (
  <div
    className={
      `
        ${styles.item}
        ${isSelected ? styles.isSelected : ''}
        ${theme ? styles[theme] : ''}
      `
    }
    onClick={onClick ? () => onClick() : null}>
    {
      typeof Icon !== 'undefined' && (
        <span className={styles.itemIcon}>
          <Icon size={20} />
        </span>
      )
    }
    <div>{title}</div>
  </div>
)

export default MenuItem;
