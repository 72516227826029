// @flow
import { serialize } from '../../utils/slateSerializers';
import * as actions from '../../actions/phases';


const submit = (
  values: Object,
  dispatch: Function,
) => {

  let errorEmailContent = false;
  if (values.emailContent && values.emailContent.length == 1) {
    const children = values.emailContent[0].children;
    if (children && children.length == 1 && children[0].text == '') {
      errorEmailContent = true;
    }
  }

  let content = serialize({ children: values.emailContent });
  const template = {
    subject: values.subject,
    title: values.title,
    body: content,
  };

  // content = `<div style='${styles.container}'>${content}<div>`

  const payload = {
    send_mail: values.sendEmail,
    email_template: errorEmailContent ? undefined : template,
  };

  dispatch(actions.startUpdatingPhase(values.id, payload))
};

export default submit;
