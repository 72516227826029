// @flow
import { error } from './common';
import * as types from '../types/notifications';
import type {
  NOTIFICATION_TYPE,
  NOTIFICATIONS_FETCH_STARTED_TYPE,
  NOTIFICATIONS_FETCH_SUCCEED_TYPE,
  NOTIFICATION_ADDED_TYPE,
  NOTIFICATION_READ_TYPE,
  NOTIFICATION_SELECTED_TYPE,
  NOTIFICATION_FETCH_STARTED_TYPE,
  NOTIFICATION_FETCH_SUCCEED_TYPE,
  NOTIFICATIONS_COUNTED_TYPE,
  NOTIFICATIONS_COUNT_STARTED_TYPE,
  NOTIFICATIONS_WATCHED_TYPE,
  NOTIFICATIONS_WATCH_STARTED_TYPE,
} from '../types/notifications';

export const startFetchingNotifications = (page: number): NOTIFICATIONS_FETCH_STARTED_TYPE => ({
  type: types.NOTIFICATIONS_FETCH_STARTED,
  payload: {
    page,
  },
});

export const fetchNotificationsSucceed = (
  entities: {[number]: NOTIFICATION_TYPE},
  order: Array<number>,
  page: number,
  next: number,
  count: number,
): NOTIFICATIONS_FETCH_SUCCEED_TYPE => ({
  type: types.NOTIFICATIONS_FETCH_SUCCEED,
  payload: {
    entities,
    order,
    page,
    next,
    count,
  },
});

export const fetchNotificationsFailed = error(types.NOTIFICATIONS_FETCH_FAILED);

export const startCountingNotifications = (): NOTIFICATIONS_COUNT_STARTED_TYPE => ({
  type: types.NOTIFICATIONS_COUNT_STARTED,
});

export const updateNotificationsCount = (count: number): NOTIFICATIONS_COUNTED_TYPE => ({
  type: types.NOTIFICATIONS_COUNTED,
  payload: count,
});

export const startWatchingNotifications = (): NOTIFICATIONS_WATCH_STARTED_TYPE => ({
  type: types.NOTIFICATIONS_WATCH_STARTED,
});

export const completeWatchingNotifications = (): NOTIFICATIONS_WATCHED_TYPE => ({
  type: types.NOTIFICATIONS_WATCHED,
});

export const addNotification = (
  id: number,
  title: string,
  notification_type: string,
  date: string,
  body?: string,
): NOTIFICATION_ADDED_TYPE => ({
  type: types.NOTIFICATION_ADDED,
  payload: {
    id,
    notification_type,
    title,
    date,
    body,
  },
});

export const readNotification = (id: number): NOTIFICATION_READ_TYPE => ({
  type: types.NOTIFICATION_READ,
  payload: {
    id,
    is_read: true,
  },
});

export const selectNotification = (id: number): NOTIFICATION_SELECTED_TYPE => ({
  type: types.NOTIFICATION_SELECTED,
  payload: id,
});

export const fetchNotificationStarted = (id: number): NOTIFICATION_FETCH_STARTED_TYPE => ({
  type: types.NOTIFICATION_FETCH_STARTED,
  payload: id,
});

export const fetchNotificationSucceed = (
  id: number,
  title: string,
  notification_type: string,
  date: string,
  description?: string,
): NOTIFICATION_FETCH_SUCCEED_TYPE => ({
  type: types.NOTIFICATION_FETCH_SUCCEED,
  payload: {
    id,
    title,
    notification_type,
    date,
    description,
  },
});

export const fetchNotificationFailed = error(types.NOTIFICATION_FETCH_FAILED);

export const changePopupScreen = (screen: number) => ({
  type: types.NOTIFICATION_POPUP_CHANGED,
  payload: screen,
});
