// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { actions as jwtActions } from 'nozzmo-redux-jwt';
// import { Grid, Row, Col } from 'react-flexbox-grid';


import { i18nSetup } from '../../../../i18n';
import Sidebar from '../../Sidebar';
import DashboardHeader from '../../DashboardHeader';
import Phases from '../../Phases';
import SettingsPopup from '../../SettingsPopup';
import BoardSettingsPopup, { BOARD_SETTINGS_POPUP_ID } from '../../BoardSettingsPopup';
import PhaseSettingsPopup from '../../PhaseSettingsPopup';
// import CornerActions from '../../CornerActions';

// Custom Actions on DashboardHeader
import OpenButton from '../../OpenButton';
import ClientCreationPopup, { CLIENT_CREATION_POPUP_ID } from '../../ClientCreationPopup';
import { CLIENT_EDITION_POPUP_ID } from '../../ClientEditionPopup';
import ClientEditionPopup from '../../ClientEditionPopup';
import ClientStatusGlobalFilter from '../../ClientStatusGlobalFilter';
import GlobalReportButton from '../../GlobalReportButton';
import { open } from '../../../actions/closeable';
import type { ID_TYPE } from '../types/common';
import { validateUserBoard } from '../../../utils';

import * as boardActions from '../../../actions/boards';
import * as usersActions from '../../../actions/users';
import * as clientActions from '../../../actions/clients';
import * as closeableActions from '../../../actions/closeable';
import * as menuItemsActions from '../../../actions/menuItems';
import * as selectors from '../../../reducers';

import styles from './dashboard.module.scss';
import { BOARD_SETTINGS_MENU_ITEMS_ID } from '../../MenuItems/MenuItemsBoardSettings';

type DashboardViewPropTypes = {
  onLoad: Function,
  onPendingOmnisearch: Function,
  onLoadPendingOmnisearch: Function,
  userBoards: any[],
  boardUUID: ID_TYPE,
  history: any,
  editingClient: any,
  phases: any[],
  omnisearchClientId: any,
};

const DashboardView = ({
  onLoad,
  onPendingOmnisearch,
  onLoadPendingOmnisearch,
  userBoards,
  boardUUID,
  history,
  omnisearchClientId,
  editingClient,
}: DashboardViewPropTypes) => {
  
  useEffect(() => {
    validateUserBoard(
      boardUUID,
      userBoards,
      history,
      boardUUID => `/dashboard/${boardUUID}`,
    );
    if (onLoad) onLoad();
    if (omnisearchClientId) {
      onPendingOmnisearch && onPendingOmnisearch(omnisearchClientId);
    }
  }, [omnisearchClientId, boardUUID]);
  
  useEffect(() => {
    if (omnisearchClientId && editingClient) {
      onLoadPendingOmnisearch && onLoadPendingOmnisearch();
    }
  }, [omnisearchClientId, editingClient]);

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <div className={styles.dashboardContent}>
        <DashboardHeader>
          {/* All inside will be placed on the right side */}
          <GlobalReportButton />
          <OpenButton
            title="Crear estudiante"
            id={CLIENT_CREATION_POPUP_ID}
            tint="secondary"
            clearMargins
          />
          {/* TODO: move this status filter to TopBar, but with new styles specified in Adobe XD */}
          <ClientStatusGlobalFilter />
        </DashboardHeader>
        <ClientCreationPopup />
        <ClientEditionPopup omnisearchClientId={omnisearchClientId}/>
        <Phases />
        <SettingsPopup />
        <BoardSettingsPopup />
        <PhaseSettingsPopup />
        {/* <CornerActions /> */}
      </div>
    </div>
  );
}

export default connect(
  (state, { location }) => ({
    userBoards: selectors.getUserBoards(state),
    phases: selectors.getPhases(state),
    boardUUID: selectors.getBoardUUIDParam(state),
    omnisearchClientId: location.state ? location.state.omnisearchValue : undefined,
    editingClient: selectors.getEditingClient(state),
  }),
  dispatch => ({
    onLoad(boardUUID) {
      dispatch(usersActions.startFetchCurrentLoggedUser());
      dispatch(boardActions.startFetchingBoards());
      dispatch(boardActions.startFetchingBoard(boardUUID));
      i18nSetup('es');
    },
    onPendingOmnisearch(id) {
      dispatch(clientActions.startFetchingClient(id));
      dispatch(clientActions.selectClientForEdition(id));
    },
    onLoadPendingOmnisearch() {
      dispatch(open(CLIENT_EDITION_POPUP_ID));
    },
    onPendingPopup(popupId, params) {
      setTimeout(() => {
        switch(popupId) {
          case BOARD_SETTINGS_POPUP_ID:
            const { popupItem } = params;
            dispatch(menuItemsActions.updateMenuItems(BOARD_SETTINGS_MENU_ITEMS_ID, popupItem));
            dispatch(closeableActions.open(BOARD_SETTINGS_POPUP_ID));
            break;
          default:
            console.log('Popup was not present in this view');
        }
      }, 1000);
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardUUID);
      if (ownProps.location.state) {
        if (ownProps.location.state.popupId !== undefined) {
          const popupId = ownProps.location.state.popupId;
          dispatchProps.onPendingPopup(popupId, ownProps.location.state);
        };
      }
    },
  }),
)(DashboardView);
