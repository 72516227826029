// @flow
import type { ID_TYPE } from './common';

export type MENU_ITEMS_TYPE = {
  id: ID_TYPE,
  item?: ID_TYPE,
};

export type MENU_ITEMS_REGISTERED_TYPE = {
  type: 'MENU_ITEMS_REGISTERED',
  payload: MENU_ITEMS_TYPE,
};
export const MENU_ITEMS_REGISTERED = 'MENU_ITEMS_REGISTERED';

export type MENU_ITEMS_UPDATED_TYPE = {
  type: 'MENU_ITEMS_UPDATED',
  payload: {
    id: ID_TYPE,
    item: ID_TYPE,
  },
};
export const MENU_ITEMS_UPDATED = 'MENU_ITEMS_UPDATED';


export type MENU_ITEM_ACTION_TYPE =
  | MENU_ITEMS_UPDATED_TYPE
  | MENU_ITEMS_REGISTERED_TYPE;
