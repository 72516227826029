// @flow
import POPOVER_DIRECTIONS from './directions';
import hoListCheck from '../hoListCheck';

// Transform horizontal direction
export const transformHorizontalDirection = hoListCheck({
  array: POPOVER_DIRECTIONS.horizontal,
  defaultElement: 'center',
});

// Transform vertical direction
export const transformVerticalDirection = hoListCheck({
  array: POPOVER_DIRECTIONS.vertical,
  defaultElement: 'top',
});
