// @flow
import React, { useEffect, useState } from 'react';
import {
  MdBook,
  MdDone,
  MdEvent,
  MdPerson,
  MdToday,
  MdPoll,
  MdClose,
  MdViewColumn,
} from 'react-icons/md';
import { connect } from 'react-redux';
import { Popover } from 'react-tiny-popover'
import moment from 'moment';

import * as selectors from '../../reducers';
import * as globalFilterActions from '../../actions/globalFilters';
import * as userActions from '../../actions/users';
import NavTabs from '../NavTabs';
import Input from '../Input';
import DatePicker from '../DatePicker';
import { ReduxClickPopover } from '../Closeable';

import { onlyNumbers } from '../../utils';
import { TIER_TYPES, UserExtraData } from '../../settings';

import styles from './dashboardHeader.module.scss';


type DashboardHeaderPropTypes = {
  children?: Node,
  totalClients: number,
  setGlobalFilter: Function,
  levels: any[],
  cycle: any[],
  cycleFilter?: any,
  levelFilter?: any,
  startBirthDateFilter?: Date,
  endBirthDateFilter?: Date,
  baseDateFilter?: Date,
  boardUUID: number,
  isAdmin?: boolean,
  isSuperUser?: boolean,
  schoolTier?: string,
};

type FilterButtonPropTypes = {
  title: string,
  popoverId: string,
  Icon: React.ReactNode,
  popOverContent: React.ReactNode,
  isFilterActive: boolean,
  onClearFilter: Function,
  fix: boolean,
}

const FilterButton = ({
  popoverId,
  title,
  Icon,
  popOverContent,
  isFilterActive,
  onClearFilter,
}: FilterButtonPropTypes) => {
  return (
    <div>
      <ReduxClickPopover
        id={popoverId}
        Content={popOverContent}
        hasTip={false}
        isRound
        isPopover
        vertical="below"
        horizontal="right"
        className={styles.popover}
      >
        <button
          type="button"
          className={`
            ${styles.globalFilterButton}
            ${isFilterActive ? styles.hightlightButton : ''}
          `}
        >
          <>
            {
              Icon && <Icon />
            }
            {title}
            {
              isFilterActive && (
                <div
                  onClick={(e) => {
                    onClearFilter();
                    e.stopPropagation();
                  }}
                  className={styles.clearFilterIcon}
                >
                  <MdClose />
                </div>
              )
            }
          </>
        </button>
      </ReduxClickPopover>
    </div>
  );
};

const SmallInput = ({value = '', onChange = () => {}, helperText = ''}) => {
  return (
    <div className={styles.smallInput}>
      <Input
        size="small"
        className={styles.numberInput}
        value={value}
        onChange={onChange}
        clearMargins
      />
      <div className={styles.helperText}>{helperText}</div>
    </div>
  );
}

const OptionSelector = ({
  options = [],
  selectedIndex,
  onSelect,
  emptyPlaceholder = 'Sin opciones'
}) => {
  return (
    <div className={styles.popOverContent}>
      {
        options.map(option => {
          let id = option.id
          if (Array.isArray(option.id)) id = option.id.join(',')
          if (Array.isArray(selectedIndex)) selectedIndex = selectedIndex.join(',')
          return (
            <div key={id} className={styles.levelItem} onClick={() => onSelect(option)}>
              <div className={styles.checkContainer}>
                { selectedIndex === id && <MdDone /> }
              </div>
              {option.name}
            </div>
          );
        })
      }{
        options.length === 0 && (<div className={styles.empty}>{emptyPlaceholder}</div>)
      }
    </div>
  );
}

const AgeRangeSelector = ({
  title,
  Icon,
  isFilterActive,
  onClearFilter,
  baseDate = new Date(),
  setBaseDate,
  fromYear,
  fromMonth,
  toYear,
  toMonth,
  setFromYear,
  setFromMonth,
  setToYear,
  setToMonth,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const checkNumber = (input: string) => {
    return input.length <= 2
    && (
      input.length === 0
      || input === ''
      || onlyNumbers(input)
    );
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom" // preferred position
      align="start"
      onClickOutside={() => setIsPopoverOpen(false)}
      disableReposition
      containerStyle={{ top: '2.5rem', left: '7rem' }}
      content={ 
        <div className={styles.tinyPopover}>
          <div className={styles.ageSelectorArea}>
            <div
              className={`
                ${styles.boldText}
                ${styles.montserrat}
                ${styles.datePopupText}
              `}
            >
              EDAD AL:
            </div>
            <div>
              <DatePicker
                hideClearIcon
                size="small"
                value={baseDate}
                onChange={date => setBaseDate(date)}
                className={styles.datePicker}
                popperPlacement="bottom-start"
                calendarCustomClassName={styles.fixCalendarPosition}
              />
            </div>
            <div className={styles.ageRangeSelector}>
              <div
                className={`
                  ${styles.datePopupText}
                  ${styles.fixText}
                `}
              >
                De
              </div>
              <SmallInput
                value={fromYear}
                onChange={(e) => {
                  if (
                    checkNumber(e.target.value)
                  ) {
                    setFromYear(e.target.value);
                  }
                }}
                helperText="Años"
              />
              <SmallInput
                value={fromMonth}
                onChange={(e) => {
                  if (
                    checkNumber(e.target.value)
                  ) {
                    setFromMonth(e.target.value);
                  }
                }}
                helperText="Meses"
              />
              <div 
                className={`
                  ${styles.datePopupText}
                  ${styles.fixText}
                `}
              >
                Hasta
              </div>
              <SmallInput
                value={toYear}
                onChange={(e) => {
                  if (
                    checkNumber(e.target.value)
                  ) {
                    setToYear(e.target.value);
                  }
                }}
                helperText="Años"
              />
              <SmallInput
                value={toMonth}
                onChange={(e) => {
                  if (
                    checkNumber(e.target.value)
                  ) {
                    setToMonth(e.target.value);
                  }
                }}
                helperText="Meses"
              />
            </div>
          </div>
        </div>  
      }
    >
      <button
        type="button"
        className={`
          ${styles.globalFilterButton}
          ${isFilterActive ? styles.hightlightButton : ''}
        `}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <>
          {
            Icon && <Icon />
          }
          {title}
          {
            isFilterActive && (
              <div
                onClick={(e) => {
                  onClearFilter();
                  e.stopPropagation();
                }}
                className={styles.clearFilterIcon}
              >
                <MdClose />
              </div>
            )
          }
        </>
      </button>
    </Popover>
  );
}

const DashboardHeader = ({
  children,
  totalClients,
  levels,
  cycles,
  setGlobalFilter,
  cycleFilter,
  levelFilter,
  baseDateFilter,
  startBirthDateFilter,
  endBirthDateFilter,
  isFilterActive,
  totalFilteredClients,
  boardUUID,
  isAdmin,
  isSuperUser,
  schoolTier,
  userExtraData,
  saveGlobalCycleFilter,
}: DashboardHeaderPropTypes) => {
  const [useGlobalFilter, setUseGlobalFilter] = useState(true);
  const [fromMonth, setFromMonth] = useState('');
  const [fromYear, setFromYear] = useState('');
  const [toMonth, setToMonth] = useState('');
  const [toYear, setToYear] = useState('');

  useEffect(() => {
    const urlArray = window.location.href.split('/');
    const globalFilter = !urlArray.find(
      (element) => element === 'calendar' || element === 'reports'
    );
    setUseGlobalFilter(globalFilter);
  }, []);

  useEffect(() => {
    const possibleFromDate = moment(baseDateFilter)
      .subtract(fromYear, 'y')
      .subtract(fromMonth, 'months');

    const possibleToDate = moment(baseDateFilter)
      .subtract(toYear, 'y')
      .subtract(toMonth, 'months');

    if (fromMonth !== '' || fromYear !== '') {
      setGlobalFilter(
        'startBirthDate',
        possibleFromDate.isBefore(possibleToDate)
          ? possibleFromDate
              .subtract(1, 'y')
              .add(1, 'd')
              .toDate()
          : possibleToDate
              .subtract(1, 'y')
              .add(1, 'd')
              .toDate()
      );
    } else {
      setGlobalFilter('startBirthDate', null);
    }

    if (toMonth !== '' || toYear !== '') {
      setGlobalFilter(
        'endBirthDate',
        possibleToDate.isAfter(possibleFromDate)
          ? possibleToDate
              .subtract(1, 'd')
              .toDate()
          : possibleFromDate
              .subtract(1, 'd')
              .toDate()
      );
    } else {
      setGlobalFilter('endBirthDate', null);
    }
  }, [fromMonth, fromYear, toMonth, toYear, baseDateFilter]);

  const LevelSelector = () => (
    <OptionSelector
      onSelect={option => setGlobalFilter('level', option.id)}
      options={levels}
      selectedIndex={levelFilter}
      emptyPlaceholder="Sin grados a aplicar"
    />
  );

  const CycleSelector = () => (
    <OptionSelector
      onSelect={option => {
        setGlobalFilter('cycle', option.id);
        saveGlobalCycleFilter(userExtraData, boardUUID, option.id)
      }}
      options={cycles}
      selectedIndex={cycleFilter}
      emptyPlaceholder="Sin ciclos a aplicar"
    />
  );

  return (
    <>
      <div className={styles.globalFilterContainer}>
        <div className={styles.globalFilterLeft}>
          { isFilterActive ? `Total de clientes con filtro: ${totalFilteredClients}` : `Total de clientes: ${totalClients}`}
        </div>
        <div
          className={`${styles.globalFilterRight} ${
            useGlobalFilter ? '' : styles.disableGlobalFilterRight
          }`}
        >
          {/* <div className={styles.advancedFilters}>
            <MdTune />
            Filtros Avanzados
          </div>
          <div className={styles.divider} /> */}
          <FilterButton 
            popOverContent={LevelSelector}
            popoverId="GRADO_APLICAR"
            title="Grado a Aplicar"
            Icon={MdBook}
            isFilterActive={!!levelFilter}
            onClearFilter={() => setGlobalFilter('level', null)}
          />
          <FilterButton 
            popOverContent={CycleSelector}
            popoverId="CICLO_APLICAR"
            title="Ciclo a aplicar"
            Icon={MdToday}
            isFilterActive={!!cycleFilter}
            onClearFilter={() => setGlobalFilter('cycle', null)}
          />
          <AgeRangeSelector
            fromMonth={fromMonth}
            fromYear={fromYear}
            toMonth={toMonth}
            toYear={toYear}
            setFromMonth={setFromMonth}
            setFromYear={setFromYear}
            setToMonth={setToMonth}
            setToYear={setToYear}
            title="Edad"
            Icon={MdPerson}
            baseDate={baseDateFilter}
            setBaseDate={(newDate: Date) => setGlobalFilter('baseDate', newDate)}
            isFilterActive={!!startBirthDateFilter || !!endBirthDateFilter}
            onClearFilter={() => {
              setGlobalFilter('startBirthDate', null);
              setGlobalFilter('endBirthDate', null);
              setFromMonth('');
              setFromYear('');
              setToMonth('');
              setToYear('');
              setGlobalFilter('baseDate', new Date());
            }}
          />
        </div>
      </div>
      <div className={styles.dashboardHeader}>
        <div className={styles.leftGroup}>
          <NavTabs links={[
            {
              title: 'Columnas',
              to: `/dashboard/${boardUUID}`,
              Icon: MdViewColumn,
            },
            /* TODO: Uncomment when exists a defined list view on Phases (At this time, only ColumnView exists) */
            /* {
              title: 'Lista',
              to: `/dashboard/${boardUUID}`,
              Icon: MdViewList,
            }, */
            {
              title: 'Calendario',
              to: `/calendar/${boardUUID}`,
              Icon: MdEvent,
            },
            (isSuperUser || (isAdmin && schoolTier && schoolTier !== TIER_TYPES.BASIC)) ? (
              {
                title: 'Insights',
                to: `/reports/${boardUUID}`,
                Icon: MdPoll,
              }
            ) : undefined,
          ]}
          />
        </div>
        <div className={styles.rightGroup}>
          { children }
        </div>
      </div>
    </>
  );
}

export default connect(
  state => {
    // Tiers
    const { is_admin = false, is_superuser = false } = selectors.getCurrentLoggedUser(state) || {};
    
    const isAdmin = is_admin;
    const isSuperUser = is_superuser;
    const boardUUID = selectors.getBoardUUIDParam(state);
    const schoolTier = ((selectors.getCurrentBoard(state) || {}).school || {}).tier || undefined;
    
    const clients: any[] = selectors.getAllClients(state);
    const baseDateFilter = selectors.getGlobalBaseDateFilter(state);
    const cycleFilter = selectors.getGlobalCycleIdFilter(state);
    const levelFilter = selectors.getGlobalLevelIdFilter(state);
    const startBirthDateFilter = selectors.getGlobalStartBirthDateFilter(state);
    const endBirthDateFilter = selectors.getGlobalEndBirthDateFilter(state);
    const isFilterActive = !!cycleFilter || !!levelFilter || !!startBirthDateFilter || !!endBirthDateFilter
    const totalFilteredClients = clients
      ? isFilterActive
        ? clients
            .filter((client) => {
              if (!cycleFilter) {
                return true;
              } else if (Array.isArray(cycleFilter)) {
                return cycleFilter.includes(client.cycle)
              } else if (client.cycle === cycleFilter) {
                return true;
              }
              return false
            })
            .filter((client) => {
              if (!levelFilter) {
                return true;
              } else if (client.level === levelFilter) {
                return true;
              }
              return false
            })
            .filter((client) => {
              const birthDate = moment(client.birth_date);
              const startDate = moment(startBirthDateFilter);
              
              if (!startBirthDateFilter) return true;
              return !endBirthDateFilter ? true : birthDate.isSameOrAfter(startDate, 'days');
            })
            .filter((client) => {
              const birthDate = moment(client.birth_date);
              const endDate = moment(endBirthDateFilter);
              
              if (!endBirthDateFilter) return true;
              return !startBirthDateFilter ? true : birthDate.isSameOrBefore(endDate, 'days');
            })
            .length
        : clients.length
      : 0;

    const activeCycles = selectors.getActiveCycles(state);
    const cycles = [
      {
        id: activeCycles,
        name: 'Ciclos activos',
      },
      ...selectors.getCycles(state),
    ];
    
    return {
      levels: selectors.getLevels(state),
      cycles,
      activeCycles,
      cycleFilter,
      levelFilter,
      startBirthDateFilter,
      endBirthDateFilter,
      baseDateFilter,
      totalClients: clients ? clients.length : 0,
      isFilterActive,
      totalFilteredClients,
      isAdmin,
      isSuperUser,
      boardUUID,
      schoolTier,
      userExtraData: selectors.getUserExtraData(state),
    };
  },
  dispatch => {
    return {
      setGlobalFilter: (filter, value) => dispatch(globalFilterActions.updateGlobalFilter({ [filter]: value })),
      saveGlobalCycleFilter(extraData, boardUUID, value) {
        const extraDataPayload = {
          ...extraData,
          [`${UserExtraData.GLOBAL_CYCLE_FILTER}-${boardUUID}`]: value,
        };
        dispatch(userActions.userChangeExtraDataStarted(extraDataPayload));
      },
    };
  }
)(DashboardHeader);
