// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdAccountBalance } from 'react-icons/md';

import type { SCHOOL_TYPE } from '../../../types/schools';
import SettingsItem from '../SettingsItem';
import styles from './settingsSchools.module.scss';

import * as selectors from '../../../reducers';


type SettingsSchoolsPropTypes = {
  schools: Array<SCHOOL_TYPE>,
  selectedSchoolId: number,
  onSelectSchool: Function,
};

const SettingsSchools = ({
  schools,
  selectedSchoolId,
  onSelectSchool,
}: SettingsSchoolsPropTypes) => (
  <Fragment>
    <SettingsItem
      Icon={MdAccountBalance}
      text="Instituciones"
      selectable={false}
    />
    <div className={styles.schoolsContainer}>
      {
        schools.map(school => (
          <SettingsItem
            key={school.id}
            text={school.name}
            onClick={() => onSelectSchool(school.id)}
            thumbnail={school.logo ? school.logo.url : ''}
            isSelected={selectedSchoolId == school.id}
            theme='secondary'
          />
        ))
      }
    </div>
  </Fragment>
);

export default connect(
  (state) => {
    const schools = selectors.getUserSchools(state);
    const selectedSchool = selectors.getSelectedSchool(state);

    return ({
      schools,
      selectedSchoolId: selectedSchool.id || 0,
    });
  },
  () => ({
    onSelectSchool(boardId) {
      // Redirection with window.location, so the state starts from its initial value
      window.location.assign(`/dashboard/${boardId}`);
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSelectSchool(schoolId) {
      const targetSchool = stateProps.schools.find(
        school => school.id === schoolId,
      ) || {};
      const inferedFirstBoard = (targetSchool && targetSchool.boards[0]) || 0;
      dispatchProps.onSelectSchool(inferedFirstBoard);
    },
  }),
)(SettingsSchools);
