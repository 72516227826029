// @flow
import {
  call,
  takeEvery,
  put,
  select,
  race
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { fibonacciBackoff } from '../lib/retry';
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';
import * as types from '../types/comments';
import * as selectors from '../reducers';
import * as actions from '../actions/comments';
import * as clientActions from '../actions/clients';
import {
  parseComment,
} from '../utils/serverStateTransformations';
import { Comments, Clients } from '../api';


function* addComment(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {
        id: Math.floor(Math.random() * 10000),
        object_pk: payload.object_pk
      }
    }
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [ Clients.custom, 'addComment' ],
        {
          id: payload.object_pk,
          token,
          data: payload,
          mock
        }),
      timeout: call(delay, REQUEST_TIMEOUT)
    });

    if(timeout) {
      throwTimeout('addComment saga');
    }
    // Register
    yield put(actions.completeAddingComment(
      payload.id,
      response.id,
      parseComment(response)
    ));
    yield put(clientActions.confirmClientComment(
      response.object_pk,
      payload.id,
      response.id
    ));

  } catch(error) {

    const { statusCode, message, data, isPlain } = error;

    // Fetch error
    yield put(actions.failAddingComment({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor': data,
      retryAction: action
    }));
  }
}

function* removeComment(action) {
  const { payload } = action;
  const token = yield select(selectors.getToken);

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: {}
    }
  };

  try {
    yield call(
      fibonacciBackoff,
      [ Comments, 'remove' ],
      { token, id: payload, mock }
    );
  } catch(e) { /* noop */ }
}

export function* watchCommentCreation(): Iterator<any> {
  yield takeEvery(
    types.COMMENT_ADD_STARTED,
    addComment
  );
}

export function* watchCommentDeletion(): Iterator<any> {
  yield takeEvery(
    types.COMMENT_REMOVED,
    removeComment
  );
}
