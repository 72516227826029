// @flow
export type SELECTOR_ELEMENT_STATE = {
  isActive: boolean,
  originalOrder: Array<string>,
  byId: { [ string ]: Object },
  showing: Array<string>,
  selected: number,
  filter: string,
  isLoading: boolean,
  configuration: {
    searchKeys: Array<string>,
    circular: boolean,
    maximum: number
  }
};

export type SELECTOR_REGISTERED_TYPE = {
  type: '@@redux-selector/REGISTERED',
  payload: {
    isActive: boolean,
    byId: { [ string ]: Object },
    originalOrder: Array<string>,
    selected: number,
    filter: string,
    configuration: Object
  }
};
export const SELECTOR_REGISTERED = '@@redux-selector/REGISTERED';

export type SELECTOR_ATTACHED_TYPE = {
  type: '@@redux-selector/ATTACHED',
  payload: {
    id: string
  }
};
export const SELECTOR_ATTACHED = '@@redux-selector/ATTACHED';

export type SELECTOR_ACTIVATED_TYPE = {
  type: '@@redux-selector/ACTIVATED',
  payload: {
    id: string
  }
};
export const SELECTOR_ACTIVATED = '@@redux-selector/ACTIVATED';

export type SELECTOR_DEACTIVATED_TYPE = {
  type: '@@redux-selector/DEACTIVATED',
  payload: {
    id: string
  }
};
export const SELECTOR_DEACTIVATED = '@@redux-selector/DEACTIVATED';

export type SELECTOR_VALUES_SET_TYPE = {
  type: '@@redux-selector/VALUES_SET',
  payload: {
    id: string,
    byId: { [ string ]: Object },
    order: Array<string>
  }
};
export const SELECTOR_VALUES_SET = '@@redux-selector/VALUES_SET';

export type SELECTOR_SELECTED_CHANGED_TYPE = {
  type: '@@redux-selector/SELECTED_CHANGED',
  payload: {
    id: string,
    selected: number
  }
};
export const SELECTOR_SELECTED_CHANGED = '@@redux-selector/SELECTED_CHANGED';

export type SELECTOR_SELECTED_CHANGED_UP_TYPE = {
  type: '@@redux-selector/SELECTED_CHANGED_UP',
  payload: {
    id: string
  }
};
export const SELECTOR_SELECTED_CHANGED_UP = '@@redux-selector/SELECTED_CHANGED_UP';

export type SELECTOR_SELECTED_CHANGED_DOWN_TYPE = {
  type: '@@redux-selector/SELECTED_CHANGED_DOWN',
  payload: {
    id: string
  }
};
export const SELECTOR_SELECTED_CHANGED_DOWN = '@@redux-selector/SELECTED_CHANGED_DOWN';

export type SELECTOR_FILTER_CHANGED_TYPE = {
  type: '@@redux-selector/FILTER_CHANGED',
  payload: {
    id: string,
    filter: string
  }
};
export const SELECTOR_FILTER_CHANGED = '@@redux-selector/FILTER_CHANGED';

export type SELECTOR_CONFIGURATION_UPDATED_TYPE = {
  type: '@@redux-selector/CONFIGURATION_UPDATED',
  payload: {}
};
export const SELECTOR_CONFIGURATION_UPDATED = '@@redux-selector/CONFIGURATION_UPDATED';

export type SELECTOR_CONFIGURATION_RESET_TYPE = {
  type: '@@redux-selector/CONFIGURATION_RESET',
  payload: {}
};
export const SELECTOR_CONFIGURATION_RESET = '@@redux-selector/CONFIGURATION_RESET';

export type SELECTOR_LOADING_SET_TYPE = {
  type: '@@redux-selector/LOADING_SET',
  payload: {
    id: string
  }
};
export const SELECTOR_LOADING_SET = '@@redux-selector/LOADING_SET';

export type SELECTOR_LOADING_UNSET_TYPE = {
  type: '@@redux-selector/LOADING_UNSET',
  payload: {
    id: string
  }
};
export const SELECTOR_LOADING_UNSET = '@@redux-selector/LOADING_UNSET';

type ELEMENT_APPENDINX = { peState: SELECTOR_ELEMENT_STATE };

export type SELECTOR_ACTION_TYPE =
  | SELECTOR_REGISTERED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_ATTACHED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_ACTIVATED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_DEACTIVATED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_VALUES_SET_TYPE & ELEMENT_APPENDINX
  | SELECTOR_SELECTED_CHANGED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_SELECTED_CHANGED_UP_TYPE & ELEMENT_APPENDINX
  | SELECTOR_SELECTED_CHANGED_DOWN_TYPE & ELEMENT_APPENDINX
  | SELECTOR_FILTER_CHANGED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_CONFIGURATION_UPDATED_TYPE & ELEMENT_APPENDINX
  | SELECTOR_CONFIGURATION_RESET_TYPE & ELEMENT_APPENDINX
  | SELECTOR_LOADING_SET_TYPE & ELEMENT_APPENDINX
  | SELECTOR_LOADING_UNSET_TYPE & ELEMENT_APPENDINX;
