import React, { useState, useRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { TINY_API_KEY } from '../../settings';

import styles from './htmlTextEditor.module.scss';

const DEFAULT_EDITOR_HEIGHT = 300;

const HTMLTextEditor = ({
  value,
  onChange,
}) => {
  const editorRef = useRef(null);
  const initialValue = value === '' ? '' : value;
  const [localValue, setLocalValue] = useState(initialValue);

  const handleChange = (nValue) => {
    setLocalValue(nValue);
    if (onChange) onChange(nValue);
  }

  // sync editorRef with `value` if externally modified
  useEffect(() => {
    // editorRef isn't a fully controlled component, but luckily inputs entered in
    // are updated before `onChange` is invoked
    // so when this `useEffect` is called, it's already updated and shouldn't re-init
    // if the updated `value` matches what was initially entered
    // .. but it will re-init if the value was changed externally, which is what we want
    if (editorRef.current && editorRef.current.getContent() !== value) {
      // update the editor value
      editorRef.current.setContent(value, {no_events: true});
    }
  }, [value]);

  return (
    <div className={styles.textEditor}>
      <Editor
        apiKey={TINY_API_KEY}
        onInit={(evt, editor) => editorRef.current = editor}
        value={localValue}
        onEditorChange={handleChange}
        init={{
          height: DEFAULT_EDITOR_HEIGHT,
          menubar: true,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          language: navigator.language.toUpperCase().includes('ES') ? 'es' : 'en',
        }}
      />
    </div>
  )
}

export default HTMLTextEditor;
