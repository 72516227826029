// @flow
import { combineReducers } from '@reduxjs/toolkit';
import { types as authTypes } from 'nozzmo-redux-jwt';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { USER_TYPE } from '../types/users';
import * as common from './common';
import * as types from '../types/users';

export type UsersState = {
  byId: { [ID_TYPE]: USER_TYPE },
  currentLoggedUser: USER_TYPE,
  isFetchingUsers: boolean,
  isSendingInvitation: boolean,
  isChangingUserRole: boolean,
  isUpdatingUserDisabledStatus: boolean,
  isUpdatingLoggedUser: boolean,
  isUpdatingUserCredentials: boolean,
  isRecoveringPassword: boolean,
  isUpdatingPassword: boolean,
  isUpdatePasswordSuccess: boolean,
  errorUpdateUserCredentials: ERROR_TYPE,
  errorLoggedUser: ERROR_TYPE,
  errorFetchUsers: ERROR_TYPE,
  errors: { [ID_TYPE]: ERROR_TYPE },
  errorRecoverPassword: ERROR_TYPE,
  errorUpdatePassword: ERROR_TYPE,
  userLoginEvent: string,
};

const byId = common.byId({
  fetched: [types.USERS_FETCH_SUCCEED],
  updated: [
    types.USER_CHANGE_ROLE_STARTED,
    types.USER_CHANGE_DISABLED_STATUS_STARTED,
    types.USER_CHANGE_EXTRA_DATA_STARTED,
  ],
  added: [types.SEND_INVITATION_STARTED],
  confirmed: [types.SEND_INVITATION_SUCCEED]
});

const currentLoggedUser = common.singleton({
  populate: [types.FETCH_CURRENT_LOGGED_USER_COMPLETED],
  updated: [
    types.CURRENT_USER_UPDATE_STARTED,
    types.USER_CHANGE_EXTRA_DATA_STARTED,
  ],
  clear: [],
});

const isUpdatingLoggedUser = common.isFetching({
  started: [types.CURRENT_USER_UPDATE_STARTED],
  succeed: [types.CURRENT_USER_UPDATE_COMPLETED],
  failed: [types.CURRENT_USER_UPDATE_FAILED],
});

const isFetchingUsers = common.isFetching({
  started: [types.USERS_FETCH_STARTED],
  failed: [types.USERS_FETCH_FAILED],
  succeed: [types.USERS_FETCH_SUCCEED],
});

const isSendingInvitation = common.isFetching({
  started: [types.SEND_INVITATION_STARTED],
  failed: [types.SEND_INVITATION_FAILED],
  succeed: [types.SEND_INVITATION_SUCCEED],
});

const isChangingUserRole = common.isFetching({
  started: [types.USER_CHANGE_ROLE_STARTED],
  failed: [types.USER_CHANGE_ROLE_FAILED],
  succeed: [types.USER_CHANGE_ROLE_SUCCEED],
});

const isUpdatingUserDisabledStatus = common.isFetching({
  started: [types.USER_CHANGE_DISABLED_STATUS_STARTED],
  failed: [types.USER_CHANGE_DISABLED_STATUS_FAILED],
  succeed: [types.USER_CHANGE_DISABLED_STATUS_SUCCEED],
});

const isUpdatingUserCredentials = common.isFetching({
  started: [types.RESTORE_CREDENTIALS_STARTED],
  succeed: [types.RESTORE_CREDENTIALS_COMPLETED],
  failed: [types.RESTORE_CREDENTIALS_FAILED],
});

const isRecoveringPassword = common.isFetching({
  started: [types.REQUEST_RECOVER_PASSWORD_STARTED],
  succeed: [types.REQUEST_RECOVER_PASSWORD_COMPLETED],
  failed: [types.REQUEST_RECOVER_PASSWORD_FAILED],
});

const isUpdatingPassword = common.isFetching({
  started: [types.RECOVER_PASSWORD_STARTED],
  succeed: [types.RECOVER_PASSWORD_COMPLETED],
  failed: [types.RECOVER_PASSWORD_FAILED],
});

const errorUpdateUserCredentials = common.error({
  populate: [types.RESTORE_CREDENTIALS_FAILED],
  clear: [
    types.RESTORE_CREDENTIALS_STARTED,
    types.RESTORE_CREDENTIALS_COMPLETED,
  ]
});

const errorLoggedUser = common.error({
  populate: [types.FETCH_CURRENT_LOGGED_USER_FAILED],
  clear: [types.FETCH_CURRENT_LOGGED_USER_STARTED],
});

const errorFetchUsers = common.error({
  populate: [types.USERS_FETCH_FAILED],
  clear: [
    types.USERS_FETCH_STARTED,
    types.USERS_FETCH_SUCCEED,
  ]
});

const errorSendInvitation = common.error({
  populate: [types.SEND_INVITATION_FAILED],
  clear: [
    types.SEND_INVITATION_STARTED,
    types.SEND_INVITATION_SUCCEED,
  ]
});

// TODO: Fix Samuel lib for multiple errors, population isn't working :(
const errors = common.errors({
  clear: [
    types.SEND_INVITATION_STARTED,
    types.USER_CHANGE_DISABLED_STATUS_STARTED,
    types.USER_CHANGE_ROLE_STARTED,
    types.CURRENT_USER_UPDATE_STARTED,
    types.USER_CHANGE_EXTRA_DATA_STARTED,
  ],
  populate: [
    types.SEND_INVITATION_FAILED,
    types.USERS_FETCH_FAILED,
    types.USER_CHANGE_DISABLED_STATUS_FAILED,
    types.USER_CHANGE_EXTRA_DATA_FAILED,
    types.USER_CHANGE_ROLE_FAILED,
    types.CURRENT_USER_UPDATE_FAILED,
  ],
});

const errorRecoverPassword = common.error({
  clear: [
    types.REQUEST_RECOVER_PASSWORD_STARTED,
    types.REQUEST_RECOVER_PASSWORD_COMPLETED,
    types.USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED,
  ],
  populate: [
    types.REQUEST_RECOVER_PASSWORD_FAILED,
  ],
});

const errorUpdatePassword = common.error({
  clear: [
    types.RECOVER_PASSWORD_STARTED,
    types.RECOVER_PASSWORD_COMPLETED,
    types.USER_RECOVER_PASSWORD_ERROR_CLEARED,
  ],
  populate: [
    types.RECOVER_PASSWORD_FAILED,
  ]
});

const userLoginEvent = common.singleton({
  populate: [],
  updated: [types.USER_LOGIN_EVENT_REGISTERED],
  clear: [
    authTypes.LOGIN_STARTED,
    authTypes.LOGIN_SUCCEED,
    types.USER_LOGIN_EVENTS_CLEANED,
  ],
});


const users = combineReducers({
  byId,
  currentLoggedUser,
  isFetchingUsers,
  isSendingInvitation,
  isChangingUserRole,
  isUpdatingUserDisabledStatus,
  isUpdatingLoggedUser,
  isUpdatingUserCredentials,
  isRecoveringPassword,
  isUpdatingPassword,
  errorUpdateUserCredentials,
  errorLoggedUser,
  errorFetchUsers,
  errorSendInvitation,
  errors,
  errorRecoverPassword,
  errorUpdatePassword,
  userLoginEvent,
});

export default users;

// Selectors
export const getUserById = (state: UsersState, id: ID_TYPE): USER_TYPE => state.byId[id];
export const getUsers = (state: UsersState): USER_TYPE[] => Object.values(state.byId);
export const getIsFetchingUsers = (state: UsersState): boolean => state.isFetchingUsers;
export const getIsSendingInvitation = (state: UsersState): boolean => state.isSendingInvitation;
export const getIsChangingUserRole = (state: UsersState): boolean => state.isChangingUserRole;
export const getIsUpdatingUserDisabledStatus = (
  state: UsersState
): boolean => state.isUpdatingUserDisabledStatus;
export const getErrors = (state: UsersState): ERROR_TYPE[] => Object.values(state.errors);
export const getCurrentLoggedUser = (state: UsersState): USER_TYPE => state.currentLoggedUser;
export const getCurrentLoggedUserError = (state: UsersState): ERROR_TYPE => state.errorLoggedUser;
export const isUpdatingCurrentLoggedUser = (state: UsersState): boolean => state.isUpdatingLoggedUser;
export const isRestoringUserCredentials = (state: UsersState): boolean => state.isUpdatingUserCredentials;
export const getErrorRestoringCredentials = (state: UsersState): ERROR_TYPE => state.errorUpdateUserCredentials;
export const getUsersError = (state: UsersState): ERROR_TYPE => state.errorFetchUsers;
export const getSendInvitationError = (state: UsersState): ERROR_TYPE => state.errorSendInvitation;
export const isUserRecoveringPassword = (state: UsersState): boolean => state.isRecoveringPassword;
export const getRecoverPasswordError = (state: UsersState): ERROR_TYPE => state.errorRecoverPassword;
export const isUserUpdatingPassword = (state: UsersState): boolean => state.isUpdatingPassword;
export const getUserUpdatePasswordError = (state: UsersState): ERROR_TYPE => state.errorUpdatePassword;
export const getUserLoginEvent = (state: UsersState): string => state.userLoginEvent;
