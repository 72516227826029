import React from 'react';
import { connect } from 'react-redux';

import { MdSearch } from 'react-icons/md';
import Button from '../../Button';

import * as selectors from '../../../reducers';
import * as closeableActions from '../../../actions/closeable';
import OmnisearchTypeaheadPopup, { OMNISEARCH_TYPEAHEAD_POPUP_ID } from '../../OmnisearchTypeaheadPopup';

import styles from './search.module.scss';

type SidebarSearchPropTypes = {
  isOpen: boolean,
  onOpenTypeahead: Function,
};

const SidebarSearch = ({
  isOpen = false,
  onOpenTypeahead,
}: SidebarSearchPropTypes) => {
  return (
    <>
      {
        isOpen ? (
          <div
            className={styles.searchContainer}
          >
            <Button
              className={styles.searchBtn}
              Icon={MdSearch}
              title="Buscar"
              tint="secondary"
              size="medium"
              isOutlined
              isFullWidth
              onClick={() => onOpenTypeahead()}
            />
          </div>
        ) : <></>
      }
      <OmnisearchTypeaheadPopup />
    </>
  );
};

export default connect(
  state => ({
    isOpen: selectors.isMainSidebarOpen(state),
  }),
  dispatch => ({
    onOpenTypeahead() {
      dispatch(closeableActions.open(OMNISEARCH_TYPEAHEAD_POPUP_ID));
    },
  }),
)(SidebarSearch);