// @flow
import React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import FlagIcon from '../FlagIcon';
import PopMenu from '../PopMenu';

import styles from './languageDummySelector.module.scss';

type LanguageSelectorPropTypes = {
  id?: String,
  className?: string,
  languages?: Array<string>,
  onSelectLanguage?: Function,
  selectedLanguage?: Object,
};

const LanguageDummySelector = ({
  name,
  className,
  selectedLanguage,
  onSelectLanguage,
  languages = [],
} : LanguageSelectorPropTypes) => (
  <div className={
      `
        ${styles.languageSelector}
        ${className || ''}
      `
    }
  >
    <PopMenu
      id={`languageDummySelectorMenu-${name}`}
      vertical="below"
      horizontal="left"
      tipPosition="right"
      isRound
      width={130}
      options={languages.map(({ name }, index) => ({
        key: index,
        title: name,
        onClick: () => onSelectLanguage(languages[index]),
      }))}
    >
      <FlagIcon className={styles.flagIcon} code={selectedLanguage.flagIconCode} size="2x" squared />
      <div className={styles.container}>
        <div className={styles.language}>
          {selectedLanguage.name}
          <MdKeyboardArrowDown size={24} />
        </div>
      </div>

    </PopMenu>
  </div>
);

export default LanguageDummySelector;
