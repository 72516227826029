// @flow
import React from 'react';
import { Field } from 'redux-form';
import i18n from 'i18n-js';

import type { FOBI_ELEMENT_TYPE } from './types';
import * as types from './types';

import {
  RenderInput,
  RenderTextarea,
  RenderDatePicker,
  RenderSelect,
  RenderSwitch,
  RenderCheckboxGroup,
  RenderFilesUploader,
} from '../FieldRenderer';


type FobiElementPropTypes = {
  element: FOBI_ELEMENT_TYPE,
  formName: string,
  getElementName: Function,
  showPreview?: boolean,
  baseColor?: string,
};

const FobiReduxFormField = ({
  formName,
  element,
  getElementName,
  showPreview = false,
  baseColor = '',
}: FobiElementPropTypes) => {
  const name = getElementName(element.plugin_data.name);
  const plugin_data = {
    ...element.plugin_data,
  };

  // Remove unused plugin attributes
  delete plugin_data.readonly_value;
  delete plugin_data.autocomplete_value;
  delete plugin_data.multiple_value;
  delete plugin_data.autofocus_value;
  delete plugin_data.disabled_value;

  /* TODO: CIG traduction, remove when traduction is done from server */
  plugin_data.label = i18n.t(plugin_data.label, { defaultValue: plugin_data.label });
  plugin_data.placeholder = element.plugin_uid !== types.SELECT_ELEMENT
    ? i18n.t(plugin_data.placeholder, { defaultValue: plugin_data.placeholder })
    : plugin_data.placeholder;

  switch (element.plugin_uid) {
    case types.BOOLEAN_ELEMENT:
      return (
        <Field
          component={RenderSwitch}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.DATE_ELEMENT:
      return (
        <Field
          component={RenderDatePicker}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.FILE_ELEMENT:
      return (
        <Field
          component={RenderFilesUploader}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.DECIMAL_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          type="number"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.EMAIL_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.FLOAT_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          type="number"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.INTEGER_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          type="number"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.NULL_BOOLEAN_ELEMENT:
      return (
        <Field
          component={RenderSwitch}
          {...plugin_data}
          name={name}
          handleNull
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.SELECT_ELEMENT:
      /* TODO: CIG traduction, remove when traduction is done from server */
      return (
        <Field
          component={RenderSelect}
          {...plugin_data}
          name={name}
          id={`${formName}_${element.plugin_data.name}`}
          options={
            element.plugin_data.choices.map(choice => ({
              value: i18n.t(choice.value, { defaultValue: choice.value }),
              label: i18n.t(choice.name, { defaultValue: choice.name }),
            }))
          }
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.TEXT_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          type="text"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.TEXTAREA_ELEMENT:
      return (
        <Field
          component={RenderTextarea}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.CHECKBOX_SELECT_MULTIPLE_ELEMENT:
      /* TODO: AIS traduction, remove when traduction is done from server */
      return(
        <Field
          component={RenderCheckboxGroup}
          {...plugin_data}
          name={name}
          id={`${formName}_${element.plugin_data.name}`}
          options={
            element.plugin_data.choices.map(choice => ({
              value: i18n.t(choice.value, { defaultValue: choice.value }),
              label: i18n.t(choice.name, { defaultValue: choice.name }),
            }))
          }
          showPreview={showPreview}
          baseColor={baseColor}
        />
      )
    default:
      return (
        <Field
          component={RenderInput}
          {...plugin_data}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
  }
};

export default FobiReduxFormField;
