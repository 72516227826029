import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from '../Select';
import * as actions from '../../actions/globalFilters';
import * as phaseActions from '../../actions/phases';
import * as selectors from '../../reducers';
import styles from './clientStatusGlobalFilter.module.scss';

type ClientStatusGlobalFilterPropType = {
  isAdmited: Boolean,
  isArchived: Boolean,
  noStatus: Boolean,
  board: Object,
  setIsAdmitted: () => void,
  setIsArchived: () => void,
  setNoStatus: () => void,
  setIsInProcess: () => void,
  updatePhasesClients: () => void,
};

const calculateFilter = (
  isAdmitted: Boolean,
  isArchived: Boolean,
  noStatus: Boolean,
): string => {
  if (isAdmitted) {
    return 'Admitidos';
  }
  if (isArchived) {
    return 'Descartados';
  }
  if (noStatus) {
    return 'Todos los clientes';
  }
  return 'En proceso';
};

const ClientStatusGlobalFilter = ({
  isAdmited,
  isArchived,
  noStatus,
  board,
  setIsAdmitted,
  setIsArchived,
  setIsInProcess,
  setNoStatus,
  updatePhasesClients,
}: ClientStatusGlobalFilterPropType) => {
  useEffect(() => {
    setNoStatus();
  }, []);

  const selected = calculateFilter(isAdmited, isArchived, noStatus);

  return (
    <div className={styles.margins}>
      <Select
        value={selected}
        options={[
          { value: 'Todos los clientes', label: 'Todos los clientes' },
          { value: 'En proceso', label: 'En proceso' },
          { value: 'Admitidos', label: 'Admitidos' },
          { value: 'Descartados', label: 'Descartados' },
        ]}
        onChange={(filter) => {
          switch (filter) {
            case 'Admitidos':
              setIsAdmitted();
              break;
            case 'Descartados':
              setIsArchived();
              break;
            case 'En proceso':
              setIsInProcess();
              break;
            case 'Todos los clientes':
              setNoStatus();
              break;
            default:
              setNoStatus();
              break;
          }
          updatePhasesClients(board.id);
        }}
      />
    </div>
  );
};

export default connect(
  state => ({
    isAdmited: selectors.getGlobalIsAdmittedFilter(state),
    isArchived: selectors.getGlobalIsArchivedFilter(state),
    noStatus: selectors.getGlobalNoStatusFilter(state),
    board: selectors.getCurrentBoard(state),
  }),
  dispatch => ({
    setIsAdmitted() {
      dispatch(
        actions.updateGlobalFilter({
          isAdmitted: true,
          isArchived: null,
          noStatus: null,
        }),
      );
    },
    setIsArchived() {
      dispatch(
        actions.updateGlobalFilter({
          isAdmitted: null,
          isArchived: true,
          noStatus: null,
        }),
      );
    },
    setIsInProcess() {
      dispatch(
        actions.updateGlobalFilter({
          isAdmitted: null,
          isArchived: null,
          noStatus: null,
        }),
      );
    },
    setNoStatus() {
      dispatch(
        actions.updateGlobalFilter({
          isAdmitted: null,
          isArchived: null,
          noStatus: true,
        }),
      );
    },
    updatePhasesClients(id) {
      dispatch(phaseActions.startFetchingPhases(id));
    },
  }),
)(ClientStatusGlobalFilter);
