// @flow
import React from 'react';
// import { connect } from 'react-redux';
// import { push } from 'connected-react-router';
import { NavLink } from 'react-router-dom';

import LoginLogo from '../../LoginLogo';
// import LoginError from '../../LoginError';
import LoginFormSubtitle from '../../LoginFormSubtitle';
import LoginFormContainer from '../../LoginFormContainer';
import LoginPoweredByEdoo from '../../LoginPoweredByEdoo';
import RequestRecoverPassword from '../../RequestRecoverPasswordForm';
import RequestRecoverPasswordError from '../../RequestRecoverPasswordError';

import styles from './requestRecoverPassword.module.scss';


type RecoverPasswordViewPropTypes = { };


const RequestRecoverPasswordView = ({
}: RecoverPasswordViewPropTypes) => (
  <div className={styles.login}>
    <LoginFormContainer>
      <LoginLogo />
      <div className={styles.title}>
        {"¿Olvidaste tu contraseña?"}
      </div>
      <LoginFormSubtitle>
        {"Te enviaremos instrucciones para que puedas acceder nuevamente a tu cuenta en Admissions"}
      </LoginFormSubtitle>
      <RequestRecoverPasswordError />
      <RequestRecoverPassword />
      <NavLink to="/">
        {"Ya la recordé, acceder"}
      </NavLink>
    </LoginFormContainer>
    <div className={styles.loginRightPanel}>
      <LoginPoweredByEdoo />
    </div>
  </div>
);

export default RequestRecoverPasswordView;
