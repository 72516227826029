// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  LEVEL_TYPE,
  LEVELS_FETCH_STARTED_TYPE,
  LEVELS_FETCH_COMPLETED_TYPE,
  LEVELS_FETCH_FAILED_TYPE,
} from '../types/levels';
import * as types from '../types/levels';


export const startFetchingLevels = (schoolId: ID_TYPE): LEVELS_FETCH_STARTED_TYPE => ({
  type: types.LEVELS_FETCH_STARTED,
  payload: schoolId,
});

export const completeFetchingLevels = (
  entities: {[ID_TYPE]: LEVEL_TYPE },
  order: Array<ID_TYPE>,
): LEVELS_FETCH_COMPLETED_TYPE => ({
  type: types.LEVELS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchingLevels = (error: ERROR_TYPE): LEVELS_FETCH_FAILED_TYPE => ({
  type: types.LEVELS_FETCH_FAILED,
  payload: error,
});
