// @flow
import { post, RESTfulAPI, Resource } from '../lib/http';
import { API_BASE_URL, IS_API_IN_DEV_MODE } from '../settings';
import HTTPError from '../lib/HTTPError';

export const api = new RESTfulAPI(API_BASE_URL, 'api/v1', IS_API_IN_DEV_MODE);// process.env.NODE_ENV !== 'production');

const loginMock = IS_API_IN_DEV_MODE ? {
  delay: 1000,
  response: {
    statusCode: 200,
    body: {
      user: {
        id: 2,
        tiny_profile_picture: '',
        last_login: '2018-09-05T13:20:24.941654',
        email: 'root@root.com',
        first_name: 'root',
        last_name: 'root',
        schools: [
          {
            id: 1,
            name: 'School #1',
          },
          {
            id: 2,
            name: 'School #2',
          },
          {
            id: 3,
            name: 'School #3',
          },
        ],
      },
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJvb3RAcm9vdC5jb20iLCJleHAiOjE1OTQ2NjIyNzksInVzZXJfaWQiOjIsInVzZXJuYW1lIjoicm9vdEByb290LmNvbSJ9.JOQGpbCYa7vWHh4pVCMl-ia0DRSRWMCf4EPl1NZu71c',
    },
  },
} : undefined;

export const login = (email: string, password: string) => (
  post({
    url: api.getURL('token-auth'),
    data: { email, password },
    headers: {
      'Content-Type': 'application/json',
    },
    mock: loginMock,
  }).catch((error) => {
    const { isPlain, statusCode } = error;

    if (isPlain) {
      throw new HTTPError({
        statusCode,
        data: 'Error del servidor',
      });
    }

    throw error;
  })
);

export const Notifications = new Resource({
  name: 'notifications',
  api,
});

export const Users = new Resource({
  name: 'users',
  api,
  customization: {
    groups: {
      method: 'GET',
      urlPart: 'groups',
      isDetail: true,
    },
    notifications: {
      method: 'GET',
      urlPart: 'notifications',
      isDetail: true,
    },
    notificationCount: {
      method: 'GET',
      urlPart: 'notifications-count',
      isDetail: true,
    },
    notificationReadAll: {
      method: 'GET',
      urlPart: 'notification-read-all',
      isDetail: true,
    },
    restoreCredentials: {
      method: 'POST',
      urlPart: 'restore-password',
      isDetail: true,
    },
    changeUserRole: {
      method: 'POST',
      urlPart: 'change-user-role',
      isDetail: true,
    },
    changeUserStatus: {
      method: 'POST',
      urlPart: 'change-user-status',
      isDetail: true,
    },
    changeExtraData: {
      method: 'POST',
      urlPart: 'change-extra-data',
      isDetail: true,
    },
    sendUserInvitation: {
      method: 'POST',
      urlPart: 'send-user-invitation',
      isDetail: false,
    },
    requestRecoverPassword: {
      method: 'POST',
      urlPart: 'request-recover-password',
      isDetail: false,
    },
    recoverPassword: {
      method: 'POST',
      urlPart: 'recover-password',
      isDetail: false,
    }
  },
});

export const Phases = new Resource({
  name: 'phases',
  api,
  customization: {
    addClients: {
      method: 'PATCH',
      urlPart: 'add-clients',
      isDetail: true,
    },
  },
});

export const Search = new Resource({
  name: 'search',
  api,
  customization: {
    omnisearch: {
      method: 'GET',
      urlPart: 'omni-search',
      isDetail: false,
    },
  },
});

export const Cycles = new Resource({
  name: 'cycles',
  api,
});

export const Levels = new Resource({
  name: 'levels',
  api,
});

export const Relationships = new Resource({
  name: 'relationships',
  api,
});

export const Clients = new Resource({
  name: 'clients',
  api,
  customization: {
    getComments: {
      method: 'GET',
      urlPart: 'comments',
      isDetail: true,
    },
    addComment: {
      method: 'POST',
      urlPart: 'comments',
      isDetail: true,
    },
    bulkCreate: {
      method: 'POST',
      urlPart: 'bulk-create',
      isDetail: false,
    },
    countPerState: {
      method: 'GET',
      urlPart: 'count-per-state',
      isDetail: false,
    },
    countPerMonth: {
      method: 'GET',
      urlPart: 'count-per-month',
      isDetail: false,
    },
    countPerPhase: {
      method: 'GET',
      urlPart: 'count-per-phase',
      isDetail: false,
    },
    countPerLevel: {
      method: 'GET',
      urlPart: 'count-per-level',
      isDetail: false,
    },
    setProfilePicture: {
      method: 'POST',
      urlPart: 'profile-picture',
      isDetail: true,
    },
    removeProfilePicture: {
      method: 'DELETE',
      urlPart: 'profile-picture',
      isDetail: true,
    }
  },
});

export const Contacts = new Resource({
  name: 'contacts',
  api,
});

export const Comments = new Resource({
  name: 'comments',
  api,
});

export const Files = new Resource({
  name: 'files',
  api,
  customization: {
    sign: {
      method: 'POST',
      urlPart: 'sign',
      isDetail: false,
    },
  },
});

export const Schools = new Resource({
  name: 'schools',
  api,
});

export const Boards = new Resource({
  name: 'boards',
  api,
  customization: {
    myBoards: {
      method: 'GET',
      urlPart: 'my-boards',
      isDetail: false,
    },
    adminClientForm: {
      method: 'GET',
      urlPart: 'admin-client-form',
      isDetail: true,
    },
    publicClientForm: {
      method: 'GET',
      urlPart: 'client-form',
      isDetail: true,
    },
    sortPhases: {
      method: 'POST',
      urlPart: 'sort-phases',
      isDetail: true,
    },
    relevantDates: {
      method: 'GET',
      urlPart: 'next-relevant-dates',
      isDetail: true,
    },
  },
});
