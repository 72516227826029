// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { RPS_ACTION_TYPE } from '../types/socket';
import * as types from '../types/socket';

export type SocketState = {
  isOpening: boolean,
  isOpen: boolean,
  isConnected: boolean,
  error: Object,
  channels: {
    [string]: {
      isJoining: boolean,
      error: Object,
      isTimedout: boolean,
      isJoined: boolean
    }
  }
};

const isJoining = (state: boolean = false, action: RPS_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.RPS_JOIN_STARTED: {
      return true;
    }
    // case types.RPS_CHANNEL_ERROR_OCCURRED_TYPE:
    case types.RPS_JOINED:
    case types.RPS_JOIN_FAILED:
    case types.RPS_TIMED_OUT:
    case types.RPS_DISJOINED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const channelError = (state: Object = {}, action: RPS_ACTION_TYPE): Object => {
  switch (action.type) {
    case types.RPS_JOIN_STARTED:
    case types.RPS_JOINED:
    case types.RPS_DISJOINED: {
      return {};
    }
    // case types.RPS_CHANNEL_ERROR_OCCURRED_TYPE:
    case types.RPS_JOIN_FAILED:
    case types.RPS_TIMED_OUT: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
};

const isTimedout = (state: boolean = false, action: RPS_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.RPS_TIMED_OUT: {
      return true;
    }
    // case types.RPS_CHANNEL_ERROR_OCCURRED_TYPE:
    case types.RPS_JOINED:
    case types.RPS_JOIN_FAILED:
    case types.RPS_JOIN_STARTED:
    case types.RPS_DISJOINED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isJoined = (state: boolean = false, action: RPS_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.RPS_JOINED: {
      return true;
    }
    case types.RPS_DISJOINED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const channel = combineReducers({
  isJoining,
  error: channelError,
  isTimedout,
  isJoined,
});

const channels = (state: { [string]: Object } = {}, action: RPS_ACTION_TYPE) => {
  switch (action.type) {
    case types.RPS_JOIN_STARTED:
    case types.RPS_JOINED:
    case types.RPS_JOIN_FAILED:
    case types.RPS_TIMED_OUT:
    case types.RPS_DISJOINED:
    case types.RPS_PUSHED: {
      const { channelId } = action.payload;

      if (typeof channelId !== 'undefined') {
        return {
          ...state,
          [channelId]: channel(state[channelId], action),
        };
      }
      break;
    }
    default: {
      return state;
    }
  }

  return state;
};

const isOpening = (state: boolean = false, action: RPS_ACTION_TYPE): boolean => {
  switch (action.type) {
    case types.RPS_OPEN_STARTED: {
      return true;
    }
    case types.RPS_CLOSED:
    case types.RPS_OPENED:
    case types.RPS_ERROR_OCCURRED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isOpen = (state: boolean = false, action: RPS_ACTION_TYPE) => {
  switch (action.type) {
    case types.RPS_OPENED: {
      return true;
    }
    case types.RPS_CLOSED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isConnected = (state: boolean = false, action: RPS_ACTION_TYPE) => {
  switch (action.type) {
    case types.RPS_OPENED:
    case types.RPS_JOINED: {
      return true;
    }
    case types.RPS_CLOSED:
    case types.RPS_DISCONNECTED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const error = (state: Object = {}, action: RPS_ACTION_TYPE) => {
  switch (action.type) {
    case types.RPS_ERROR_OCCURRED: {
      return action.payload.data;
    }
    case types.RPS_CLOSED:
    case types.RPS_OPEN_STARTED:
    case types.RPS_OPENED: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const socket = combineReducers({
  isOpening,
  isOpen,
  isConnected,
  error,
  channels,
});

export default socket;

export const isSocketOpening = (
  state: SocketState,
) => state.isOpening;
export const isSocketOpen = (
  state: SocketState,
) => state.isOpen;
export const isSocketConnected = (
  state: SocketState,
) => state.isConnected;
export const getSocketError = (
  state: SocketState,
) => state.error;
export const getChannel = (
  state: SocketState, id: string,
) => state.channels[id];
export const isChannelJoining = (
  state: SocketState, id: string,
) => (getChannel(state, id) || {}).isJoining;
export const isChannelTimedout = (
  state: SocketState, id: string,
) => (getChannel(state, id) || {}).isTimedout;
export const isChannelJoined = (
  state: SocketState, id: string,
) => (getChannel(state, id) || {}).isJoined;
export const getChannelError = (
  state: SocketState, id: string,
) => (getChannel(state, id) || {}).error;
