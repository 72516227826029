// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { CLIENT_TYPE } from '../types/clients';
import * as common from './common';
import * as types from '../types/clients';
import * as phasesTypes from '../types/phases';


export type ClientsState = {
  byId: { [ID_TYPE]: CLIENT_TYPE },
  isCreating: boolean,
  lastSelected: boolean,
  editingClientId: ID_TYPE,
  fetching: Array<ID_TYPE>,
  fetchingContacts: Array<ID_TYPE>,
  fetchingComments: Array<ID_TYPE>,
  updating: Array<ID_TYPE>,
  errors: { [ID_TYPE]: ERROR_TYPE },
  error: ERROR_TYPE,
};

const byId = common.byId({
  added: [types.CLIENT_ADD_STARTED],
  updated: [
    types.CLIENT_UPDATE_STARTED,
    types.CLIENT_SELECTED,
    types.CLIENT_DESELECTED,
    types.CLIENT_DRAG_STARTED,
    types.CLIENT_DRAG_COMPLETED,
    types.CLIENT_FORM_EDITION_STARTED,
    types.CLIENT_FORM_EDITION_COMPLETED,
    types.CLIENT_SET_PROFILE_PICTURE_STARTED,
    types.CLIENT_SET_FILE_AS_PROFILE_PICTURE,
    types.CLIENT_REMOVE_PROFILE_PICTURE,
  ],
  updatedInBulk: [
    types.CLIENTS_CHANGE_PHASE_STARTED,
    types.CLIENTS_SELECTED,
    types.CLIENTS_DESELECTED,
    types.CLIENTS_CONFIRMED,
    types.CLIENTS_SET_DIRTY,
  ],
  fetched: [
    types.CLIENTS_ADDED,
    types.CLIENT_FETCH_COMPLETED,
  ],
  removed: [types.CLIENT_REMOVED],
  confirmed: [types.CLIENT_ADD_COMPLETED],
  addedToArrayAttribute: [
    types.CLIENT_COMMENTS_FETCH_COMPLETED,
    types.CLIENT_COMMENT_ADDED,
  ],
  removedFromArrayAttribute: [
    types.CLIENT_CONTACT_REMOVED,
    types.CLIENT_COMMENT_REMOVED,
  ],
  replacedInArrayAttribute: [types.CLIENT_COMMENT_CONFIRMED],
  cascade: {
    [phasesTypes.PHASE_REMOVED]: 'phase',
  },
  defaultAttributes: {
    isSelected: false,
    isDragging: false,
    isConfirmed: false,
    isEditing: false,
  },
});

const isCreating = common.isFetching({
  started: [types.CLIENT_ADD_STARTED],
  succeed: [types.CLIENT_ADD_COMPLETED],
  failed: [types.CLIENT_ADD_FAILED],
});

const lastSelected = common.mux({
  selected: [types.CLIENT_LAST_SELECTED],
  default: -1,
});

const editingClientId = common.mux({
  selected: [types.CLIENT_SELECTED_FOR_EDITION],
  cleared: [types.ALL_CLIENTS_DESELECTED_FOR_EDITION],
  default: -1,
});

const fetching = common.fetching({
  started: [types.CLIENT_FETCH_STARTED],
  succeed: [types.CLIENT_FETCH_COMPLETED],
  failed: [types.CLIENT_FETCH_FAILED],
});

const fetchingContacts = common.fetching({
  started: [types.CLIENT_CONTACTS_FETCH_STARTED],
  succeed: [types.CLIENT_CONTACTS_FETCH_COMPLETED],
  failed: [types.CLIENT_CONTACTS_FETCH_FAILED],
});

const fetchingComments = common.fetching({
  started: [types.CLIENT_COMMENTS_FETCH_STARTED],
  succeed: [types.CLIENT_COMMENTS_FETCH_COMPLETED],
  failed: [types.CLIENT_COMMENTS_FETCH_FAILED],
});

const updating = common.fetching({
  started: [types.CLIENT_UPDATE_STARTED],
  succeed: [types.CLIENT_UPDATE_COMPLETED],
  failed: [types.CLIENT_UPDATE_FAILED],
});

const error = common.error({
  clear: [types.CLIENTS_CHANGE_PHASE_STARTED],
  populate: [types.CLIENTS_CHANGE_PHASE_FAILED],
});

const errors = common.errors({
  clear: [
    types.CLIENT_ADD_STARTED,
    types.CLIENT_UPDATE_STARTED,
  ],
  populate: [
    types.CLIENT_ADD_FAILED,
    types.CLIENT_UPDATE_FAILED,
  ],
});

const clients = combineReducers({
  byId,
  isCreating,
  lastSelected,
  editingClientId,
  fetching,
  fetchingContacts,
  fetchingComments,
  updating,
  error,
  errors,
});


export default clients;


// Selectors
export const getClient = (state: ClientsState, id: ID_TYPE):
  ?CLIENT_TYPE => state.byId[id];
export const isCreatingClient = (state: ClientsState): boolean => state.isCreating;
export const lastSelectedClient = (state: ClientsState): boolean => state.lastSelected;
export const getEditingClientId = (state: ClientsState):
  ID_TYPE => state.editingClientId;
export const getEditingClient = (state: ClientsState):
  ?CLIENT_TYPE => getClient(state, state.editingClientId);
export const isClientFetching = (state: ClientsState, id: ID_TYPE):
  boolean => state.fetching.includes(id);
export const isClientFetchingContacts = (state: ClientsState, id: ID_TYPE):
  boolean => state.fetchingContacts.includes(id);
export const isClientFetchingComments = (state: ClientsState, id: ID_TYPE):
  boolean => state.fetchingComments.includes(id);
export const isClientUpdating = (state: ClientsState, id: ID_TYPE):
  boolean => state.updating.includes(id);
export const getClientsError = (state: ClientsState): ERROR_TYPE => state.error;
export const getClientError = (state: ClientsState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
export const countSelectedClients = (state: ClientsState): number => Object.keys(state.byId)
  .map(id => getClient(state, id) || {})
  .filter(({ isSelected }) => isSelected)
  .length;
export const getClientContactIds = (
  state: ClientsState,
  id: ID_TYPE,
): Array<ID_TYPE> => (getClient(state, id) || {}).contacts || [];
export const getClientCommentIds = (
  state: ClientsState,
  id: ID_TYPE,
): Array<ID_TYPE> => (getClient(state, id) || {}).comments || [];

// TODO: check, when extracting ID arrays with Object keys,
// remember that the method always return array of string
// this could harm some comparisons when assuming the keys as numerical.
// That's why I've added the tail map.
export const getSelectedClientsIds = (
  state: ClientsState,
): Array<ID_TYPE> => Object.keys(state.byId)
  .filter(id => (getClient(state, id) || {}).isSelected)
  .map(id => (getClient(state, id) || {}).id);

export const getAllClients = (
  state: ClientsState,
): Array<ID_TYPE> => Object.values(state.byId);

export const getDraggingClientsIds = (
  state: ClientsState,
): Array<ID_TYPE> => Object.keys(state.byId)
  .map(id => getClient(state, id))
  .filter(client => client && client.isDragging)
  .map(client => (client != null ? client.id : -1));
export const isAnyClientDragging = (
  state: ClientsState,
): boolean => getDraggingClientsIds(state).length > 0;
