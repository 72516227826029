// @flow
import type { ID_TYPE } from './common';

export type GLOBALFILTER_TYPE = {
  baseDate: ?Date,
  startBirthDate: ?Date,
  endBirthDate: ?Date,
  cycle: ?ID_TYPE,
  level: ?ID_TYPE,
  isAdmitted: ?boolean,
  isArchived: ?boolean,
  noStatus: ?boolean,
};

export type ALL_OPTIONALS_GLOBAL_FILTER_TYPE = {
  startBirthDate?: ?Date,
  endBirthDate?: ?Date,
  cycle?: ?ID_TYPE,
  level?: ?ID_TYPE
};

export type GLOBAL_FILTER_UPDATED_TYPE = {
  type: 'GLOBAL_FILTER_UPDATED',
  payload: ALL_OPTIONALS_GLOBAL_FILTER_TYPE
};
export const GLOBAL_FILTER_UPDATED = 'GLOBAL_FILTER_UPDATED';

export type GLOBAL_FILTER_CLEARED_TYPE = {
  type: 'GLOBAL_FILTER_CLEARED'
};
export const GLOBAL_FILTER_CLEARED = 'GLOBAL_FILTER_CLEARED';

export type GLOBALFILTERS_ACTION_TYPE =
  | GLOBAL_FILTER_UPDATED_TYPE
  | GLOBAL_FILTER_CLEARED_TYPE;
