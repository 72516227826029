// @flow
import moment from 'moment';

import {
  call,
  takeEvery,
  put,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as types from '../types/dates';
import * as actions from '../actions/dates';
import { REQUEST_TIMEOUT, DATE_FORMAT } from '../settings';
import { throwTimeout } from '../lib/http';
import { Boards } from '../api';

function* fetchRelevantDates(action) {
  const { payload } = action;

  const mock = {
    delay: 1000,
    response: {
      statusCode: 200,
      body: [
        '2019-03-01T06:00:00',
        '2019-09-10T01:00:0',
      ],
    },
  };

  try {
    const { response, timeout } = yield race({
      response: call(
        [Boards.custom, 'relevantDates'],
        {
          id: payload,
        },
        mock,
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) throwTimeout('relevantDates saga');

    const relevantDates = response.map(date => moment(date).format(DATE_FORMAT));

    yield put(actions.completeFetchingRelevantDates(relevantDates));
  } catch (err) {
    const {
      statusCode, message, data, isPlain,
    } = err;

    yield put(actions.failFetchingRelevantDates({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchRelevantDatesFetching(): Iterator<any> {
  yield takeEvery(
    types.RELEVANT_DATES_FETCH_STARTED,
    fetchRelevantDates,
  );
}
