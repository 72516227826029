// @flow
import type {
  ERROR_TYPE,
  ID_TYPE,
} from './common';

export type USER_TYPE = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  tiny_profile_picture: string,
  last_login: Date,
  schools: number[],
};

export type USERS_FETCH_STARTED_TYPE = {
  type: 'USERS_FETCH_STARTED',
};
export const USERS_FETCH_STARTED = 'USERS_FETCH_STARTED';

export type USERS_FETCH_FAILED_TYPE = {
  type: 'USERS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';

export type USERS_FETCH_SUCCEED_TYPE = {
  type: 'USERS_FETCH_SUCCEED',
  payload: {
    entities: { [ID_TYPE]: USER_TYPE }
  }
};
export const USERS_FETCH_SUCCEED = 'USERS_FETCH_SUCCEED';

export type SEND_INVITATION_STARTED_TYPE = {
  type: 'SEND_INVITATION_STARTED',
  payload: {
    first_name: string,
    email: string,
    is_admin: boolean,
  },
};
export const SEND_INVITATION_STARTED = 'SEND_INVITATION_STARTED';

export type SEND_INVITATION_FAILED_TYPE = {
  type: 'SEND_INVITATION_FAILED',
  payload: ERROR_TYPE,
};
export const SEND_INVITATION_FAILED = 'SEND_INVITATION_FAILED';

export type SEND_INVITATION_SUCCEED_TYPE = {
  type: 'SEND_INVITATION_SUCCEED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE,
  }
};
export const SEND_INVITATION_SUCCEED = 'SEND_INVITATION_SUCCEED';

export type USER_CHANGE_ROLE_STARTED_TYPE = {
  type: 'USER_CHANGE_ROLE_STARTED',
  payload: {
    id: ID_TYPE,
    is_admin: boolean,
  },
};
export const USER_CHANGE_ROLE_STARTED = 'USER_CHANGE_ROLE_STARTED';

export type USER_CHANGE_ROLE_FAILED_TYPE = {
  type: 'USER_CHANGE_ROLE_FAILED',
  payload: ERROR_TYPE,
};
export const USER_CHANGE_ROLE_FAILED = 'USER_CHANGE_ROLE_FAILED';

export type USER_CHANGE_ROLE_SUCCEED_TYPE = {
  type: 'USER_CHANGE_ROLE_SUCCEED',
  payload: USER_TYPE,
};
export const USER_CHANGE_ROLE_SUCCEED = 'USER_CHANGE_ROLE_SUCCEED';

export type USER_CHANGE_DISABLED_STATUS_STARTED_TYPE = {
  type: 'USER_CHANGE_DISABLED_STATUS_STARTED',
  payload: {
    id: ID_TYPE,
    is_active: boolean,
  },
};
export const USER_CHANGE_DISABLED_STATUS_STARTED = 'USER_CHANGE_DISABLED_STATUS_STARTED';

export type USER_CHANGE_DISABLED_STATUS_FAILED_TYPE = {
  type: 'USER_CHANGE_DISABLED_STATUS_FAILED',
  payload: ERROR_TYPE,
};
export const USER_CHANGE_DISABLED_STATUS_FAILED = 'USER_CHANGE_DISABLED_STATUS_FAILED';

export type USER_CHANGE_DISABLED_STATUS_SUCCEED_TYPE = {
  type: 'USER_CHANGE_DISABLED_STATUS_SUCCEED',
  payload: USER_TYPE,
};
export const USER_CHANGE_DISABLED_STATUS_SUCCEED = 'USER_CHANGE_DISABLED_STATUS_SUCCEED';

export type CURRENT_USER_UPDATE_STARTED_TYPE = {
  type: 'CURRENT_USER_UPDATE_STARTED',
  payload: USER_TYPE,
};
export const CURRENT_USER_UPDATE_STARTED = 'CURRENT_USER_UPDATE_STARTED';

export type CURRENT_USER_UPDATE_COMPLETED_TYPE = {
  type: 'CURRENT_USER_UPDATE_COMPLETED'
};
export const CURRENT_USER_UPDATE_COMPLETED = 'CURRENT_USER_UPDATE_COMPLETED';

export type CURRENT_USER_UPDATE_FAILED_TYPE = {
  type: 'CURRENT_USER_UPDATE_FAILED',
  payload: ERROR_TYPE,
};
export const CURRENT_USER_UPDATE_FAILED = 'CURRENT_USER_UPDATE_FAILED';

export type FETCH_CURRENT_LOGGED_USER_STARTED_TYPE = {
  type: 'FETCH_CURRENT_LOGGED_USER_STARTED',
};
export const FETCH_CURRENT_LOGGED_USER_STARTED = 'FETCH_CURRENT_LOGGED_USER_STARTED';

export type FETCH_CURRENT_LOGGED_USER_COMPLETED_TYPE = {
  type: 'FETCH_CURRENT_LOGGED_USER_COMPLETED',
  payload: USER_TYPE,
};
export const FETCH_CURRENT_LOGGED_USER_COMPLETED = 'FETCH_CURRENT_LOGGED_USER_COMPLETED';

export type FETCH_CURRENT_LOGGED_USER_FAILED_TYPE = {
  type: 'FETCH_CURRENT_LOGGED_USER_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_CURRENT_LOGGED_USER_FAILED = 'FETCH_CURRENT_LOGGED_USER_FAILED';

export type RESTORE_CREDENTIALS_STARTED_TYPE = {
  type: 'RESTORE_CREDENTIALS_STARTED',
  payload: {
    old_password: string,
    new_password?: string,
    new_email?: string,
  }
};
export const RESTORE_CREDENTIALS_STARTED = 'RESTORE_CREDENTIALS_STARTED';

export type RESTORE_CREDENTIALS_COMPLETED_TYPE = {
  type: 'RESTORE_CREDENTIALS_COMPLETED',
};
export const RESTORE_CREDENTIALS_COMPLETED = 'RESTORE_CREDENTIALS_COMPLETED';

export type RESTORE_CREDENTIALS_FAILED_TYPE = {
  type: 'RESTORE_CREDENTIALS_FAILED',
  payload: ERROR_TYPE,
};
export const RESTORE_CREDENTIALS_FAILED = 'RESTORE_CREDENTIALS_FAILED';


export type REQUEST_RECOVER_PASSWORD_STARTED_TYPE = {
  type: 'REQUEST_RECOVER_PASSWORD_STARTED',
  payload: string,
};
export const REQUEST_RECOVER_PASSWORD_STARTED = 'REQUEST_RECOVER_PASSWORD_STARTED';

export type REQUEST_RECOVER_PASSWORD_COMPLETED_TYPE = {
  type: 'REQUEST_RECOVER_PASSWORD_COMPLETED',
};
export const REQUEST_RECOVER_PASSWORD_COMPLETED = 'REQUEST_RECOVER_PASSWORD_COMPLETED';

export type REQUEST_RECOVER_PASSWORD_FAILED_TYPE = {
  type: 'REQUEST_RECOVER_PASSWORD_FAILED',
  payload: ERROR_TYPE,
};
export const REQUEST_RECOVER_PASSWORD_FAILED = 'REQUEST_RECOVER_PASSWORD_FAILED';

export type RECOVER_PASSWORD_STARTED_TYPE = {
  type: 'RECOVER_PASSWORD_STARTED',
  payload: {
    user: ID_TYPE,
    token: string,
    password: string,
  }
};
export const RECOVER_PASSWORD_STARTED = 'RECOVER_PASSWORD_STARTED';

export type RECOVER_PASSWORD_COMPLETED_TYPE = {
  type: 'RECOVER_PASSWORD_COMPLETED',
};
export const RECOVER_PASSWORD_COMPLETED = 'RECOVER_PASSWORD_COMPLETED';

export type RECOVER_PASSWORD_FAILED_TYPE ={
  type: 'RECOVER_PASSWORD_FAILED',
  payload: ERROR_TYPE,
};
export const RECOVER_PASSWORD_FAILED = 'RECOVER_PASSWORD_FAILED';

export type USER_LOGIN_EVENT_REGISTERED_TYPE = {
  type: 'USER_LOGIN_EVENT_REGISTERED',
  payload: string,
};
export const USER_LOGIN_EVENT_REGISTERED = 'USER_LOGIN_EVENT_REGISTERED';

export type USER_LOGIN_EVENTS_CLEANED_TYPE = {
  type: 'USER_LOGIN_EVENTS_CLEANED',
};
export const USER_LOGIN_EVENTS_CLEANED = 'USER_LOGIN_EVENTS_CLEANED';

export type USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED_TYPE = {
  type: 'USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED',
};
export const USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED = 'USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED';

export type USER_RECOVER_PASSWORD_ERROR_CLEARED_TYPE = {
  type: 'USER_RECOVER_PASSWORD_ERROR_CLEARED',
};
export const USER_RECOVER_PASSWORD_ERROR_CLEARED = 'USER_RECOVER_PASSWORD_ERROR_CLEARED';

// User Extra Data
export const USER_CHANGE_EXTRA_DATA_STARTED = 'USER_CHANGE_EXTRA_DATA_STARTED';

export type USER_CHANGE_EXTRA_DATA_FAILED_TYPE = {
  type: 'USER_CHANGE_EXTRA_DATA_FAILED',
  payload: ERROR_TYPE,
};
export const USER_CHANGE_EXTRA_DATA_FAILED = 'USER_CHANGE_EXTRA_DATA_FAILED';

export type USER_CHANGE_EXTRA_DATA_SUCCEED_TYPE = {
  type: 'USER_CHANGE_EXTRA_DATA_SUCCEED',
  payload: USER_TYPE,
};
export const USER_CHANGE_EXTRA_DATA_SUCCEED = 'USER_CHANGE_EXTRA_DATA_SUCCEED';


export type USERS_ACTION_TYPE =
  | USERS_FETCH_STARTED_TYPE
  | USERS_FETCH_FAILED_TYPE
  | USERS_FETCH_SUCCEED_TYPE
  | CURRENT_USER_UPDATE_FAILED_TYPE
  | CURRENT_USER_UPDATE_COMPLETED_TYPE
  | CURRENT_USER_UPDATE_STARTED_TYPE
  | FETCH_CURRENT_LOGGED_USER_FAILED_TYPE
  | FETCH_CURRENT_LOGGED_USER_COMPLETED_TYPE
  | FETCH_CURRENT_LOGGED_USER_STARTED_TYPE
  | RESTORE_CREDENTIALS_STARTED_TYPE
  | RESTORE_CREDENTIALS_COMPLETED_TYPE
  | RESTORE_CREDENTIALS_FAILED_TYPE
  | REQUEST_RECOVER_PASSWORD_STARTED_TYPE
  | REQUEST_RECOVER_PASSWORD_COMPLETED_TYPE
  | REQUEST_RECOVER_PASSWORD_FAILED_TYPE
  | RECOVER_PASSWORD_STARTED_TYPE
  | RECOVER_PASSWORD_COMPLETED_TYPE
  | RECOVER_PASSWORD_FAILED_TYPE
  | USER_LOGIN_EVENT_REGISTERED_TYPE
  | USER_LOGIN_EVENTS_CLEANED_TYPE
  | USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED_TYPE
  | USER_RECOVER_PASSWORD_ERROR_CLEARED_TYPE
  | USER_CHANGE_EXTRA_DATA_FAILED_TYPE
  | USER_CHANGE_EXTRA_DATA_SUCCEED_TYPE;
