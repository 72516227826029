// @flow
import type { Element } from 'react';
import React from 'react';
import redraft, {
  createStylesRenderer,
  createBlockRenderer,
} from 'redraft';

import styles from './commentRenderer.module.scss';

type CommentRendererPropTypes = {
  raw: Object
};

type InlineWrapperPropTypes = {
  children: Element<any>,
  style: string,
  key: string,
};

const styleMap = {
  BOLD: {
    fontWeight: 'bold',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  UNDERLINE: {
    textDecoration: 'underline',
  },
};


const InlineWrapper = ({ children, style, key }: InlineWrapperPropTypes) => (
  <span key={key} style={style}>
    { children }
  </span>
);

const blockRenderMap = {
  unstyled: {
    element: 'div',
  },
  blockquote: {
    element: 'blockquote',
  },
  'ordered-list-item': {
    element: 'li',
    wrapper: 'ol',
  },
  'unordered-list-item': {
    element: 'li',
    wrapper: 'ul',
  },
};

const renderers = {
  styles: createStylesRenderer(InlineWrapper, styleMap),
  blocks: createBlockRenderer(React.createElement, blockRenderMap),
};

const CommentRenderer = ({ raw }: CommentRendererPropTypes) => {
  if (!raw) {
    return (
      <div className={styles.noContent}>
        Sin contenido
      </div>
    );
  }

  const rendered = redraft(raw, renderers);

  if (!rendered) {
    return (
      <div className={styles.noContent}>
        Sin contenido
      </div>
    );
  }

  return (
    <div className={styles.commentBodyRender}>
      { rendered }
    </div>
  );
};


export default CommentRenderer;
