// @flow
import React from 'react';
// import { Row } from 'react-flexbox-grid';

// import Select from '../Select';
import PhasePopoverSelect from '../PhasePopoverSelect';
import styles from './phaseSettingsPopupHeader.module.scss';


const PhaseSettingsPopupHeader = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <span>
        {"Configurar Fase"}
      </span>
    </div>
    <PhasePopoverSelect />
  </div>
);

export default PhaseSettingsPopupHeader;
