// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import type {
  DragResult,
  DropResult,
  DraggableLocation,
  DroppableProvided,
} from 'react-beautiful-dnd';

import type { PHASE_TYPE } from '../../types/phases';
import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as phaseActions from '../../actions/phases';
import * as clientActions from '../../actions/clients';
import * as globalFilterActions from '../../actions/globalFilters';
import { FullLoader } from '../Loader';
import Phase from '../Phase';
import PhasePlaceholder from '../PhasePlaceholder';
import BigPlaceholder from '../BigPlaceholder';
import phasesPlaceholderImg from './phases_placeholder.png';
import BulkUploadingClientsPopup from '../BulkUploadingClientsPopup';
import BottomFilterActions from '../BottomFilterActions';
import * as closeableActions from '../../actions/closeable';
import { UserExtraData } from '../../settings';
import styles from './phases.module.scss';
type PhasesPropTypes = {
  isOpen: boolean,
  phases?: Array<PHASE_TYPE>,
  isLoading?: boolean,
  onLoad?: Function,
  startSortingPhases: Function,
  onClientDragStopped: Function,
  onClientDragStarted: Function,
  onClientDrop: Function,
  onSortEnd: (options: DragStart) => mixed,
  onDragStart: (options: DropResult) => mixed,
  onClientDragStarted: Function,
  onArchiveClient: Function,
  onAdmitClient: Function,
  onDeleteClient: Function,
};

const Phases = ({
  phases = [],
  isLoading = false,
  isOpen = false,
  boardUUID,
  startSortingPhases,
  onClientDrop,
  onClientDragStopped,
  onArchiveClient,
  onAdmitClient,
  onDeleteClient,
  onClientDragStarted,
  cycleFilter,
  setGlobalFilter,
  userExtraData
}: PhasesPropTypes) => {
  
  useEffect(() => {
    const globalCycleFilter = userExtraData && userExtraData[`${UserExtraData.GLOBAL_CYCLE_FILTER}-${boardUUID}`];
    if (globalCycleFilter) {
      setGlobalFilter('cycle', globalCycleFilter);
      return;
    }

    if (cycleFilter) setGlobalFilter('cycle', cycleFilter);
  }, [cycleFilter, boardUUID, userExtraData]);

  const onDragStart = ({ draggableId, type }: DragResult) => {
    if (type === 'client') {
      onClientDragStarted && onClientDragStarted(Number(draggableId));
    }
  };


  const onDragEnd = ({
    draggableId,
    destination,
    source,
    type,
  }: DropResult) => {
    const draggableItemId = Number(draggableId);

    if (type === 'client') onClientDragStopped(draggableItemId);

    // Dropped nowhere
    if (!destination) return;

    const dragSource: DraggableLocation = source;
    const dragDestination: DraggableLocation = destination;

    // Did not move anywhere - can bail early
    if (
      dragSource.droppableId === dragDestination.droppableId
      && dragSource.index === dragDestination.index
    ) return;

    const oldIndex = Number(dragSource.index);
    const newIndex = Number(dragDestination.index);

    // Move phase
    if (type === 'phase') {
      startSortingPhases(oldIndex, newIndex);
      return;
    }

    // Remove id column identifier
    const identifier = 'phase-';
    const sourcePhaseId = Number(dragSource.droppableId.replace(identifier, ''));
    const destinationPhaseId = Number(dragDestination.droppableId.replace(identifier, ''));
    const selectedClientId = draggableItemId;

    if (dragDestination.droppableId === 'delete-client') {
      onDeleteClient(selectedClientId);
      return;
    }

    if (dragDestination.droppableId === 'archive-client') {
      onArchiveClient(selectedClientId, sourcePhaseId);
      return;
    }

    if (dragDestination.droppableId === 'admit-client') {
      onAdmitClient(selectedClientId, sourcePhaseId);
      return;
    }

    // Move in the same phase
    if (dragSource.droppableId === dragDestination.droppableId) return;

    // Move client to different phase
    if (type === 'client') onClientDrop(selectedClientId, sourcePhaseId, destinationPhaseId, newIndex);

  };

  return (
    <>
      <div className={
        `
          ${styles.phases}
          ${isOpen ? styles.open : ''}
        `
      }>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable 
            droppableId="phases-board" type="phase" direction="horizontal">
            {(provided: DroppableProvided) => (
              <div
                className={styles.phasesScrollContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  isLoading && <FullLoader title="Cargando fases" tint="gray" />
                }
                {
                  !isLoading && phases.map((phase: PHASE_TYPE, index: number) => (
                    <Phase key={phase.id} index={index} title={phase} {...phase} />
                  ))
                }
                {
                  provided.placeholder
                }
                <PhasePlaceholder>
                  <MdAdd size={30} />
                  Agregar nueva fase
                </PhasePlaceholder>
                {
                  !isLoading && phases.length <= 0 && (
                    <div className={styles.noPhases}>
                      <BigPlaceholder
                        pictureURL={phasesPlaceholderImg}
                        title="No tienes fases creadas"
                        description="Las fases son eventos a los cuales podrá estár asignado un estudiante. "
                        examples="Examen de Admisión, Entrevista,  etcétera."
                      />
                    </div>
                  )
                }
                {/* Since the drag of a client exists in the droppable context "phases-board",
                this function must also belong within it. */}
                <BottomFilterActions />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <BulkUploadingClientsPopup />
    </>
  );
}

export default connect(
  state => {
    const cycleSelectedAsFilter = selectors.getGlobalCycleIdFilter(state);
    const activeCycles = selectors.getActiveCycles(state);
    let cycleFilter = cycleSelectedAsFilter ?? activeCycles;
    if (Array.isArray(cycleFilter) && cycleFilter.length === 0) cycleFilter = null;

    return {
      isLoading: selectors.isFetchingPhases(state),
      phases: selectors.getPhases(state),
      boardUUID: selectors.getBoardUUIDParam(state),
      selectedClientsIds: selectors.getSelectedClientsIds(state),
      isAdmittedFilterActive: selectors.getGlobalIsAdmittedFilter(state),
      isArchivedFilterActive: selectors.getGlobalIsArchivedFilter(state),
      isNoStatusFilterActive: selectors.getGlobalNoStatusFilter(state),
      isOpen: selectors.isMainSidebarOpen(state),
      userExtraData: selectors.getUserExtraData(state),
      cycleFilter,
    }
  },
  dispatch => ({
    startSortingPhases(oldIndex: number, newIndex: number) {
      dispatch(phaseActions.startSortingPhases(oldIndex, newIndex));
    },
    onDrop(
      selectedClientIds: Array<ID_TYPE>,
      sourcePhaseId: number,
      destinationPhaseId: number,
      atIndex: number,
    ) {
      dispatch(
        clientActions.startChangingPhaseOfClients(
          selectedClientIds,
          sourcePhaseId,
          destinationPhaseId,
          atIndex,
        ),
      );
    },
    onClientDragStarted(id: ID_TYPE) {
      dispatch(clientActions.startDraggingClient(id));
    },
    onClientDragStopped(id: ID_TYPE) {
      dispatch(clientActions.completeDraggingClient(id));
    },
    toggleClientStatus(id: ID_TYPE, key: string) {
      dispatch(clientActions.startTogglingStatusClient(id, key));
    },
    deleteClient(clientId: ID_TYPE) {
      dispatch(clientActions.removeClient(clientId));
    },
    removeClientsFromPhase(clientId: ID_TYPE, phaseId: ID_TYPE) {
      dispatch(phaseActions.removeClientsFromPhase(phaseId, [clientId]));
    },
    onDeleteClient(clientId: ID_TYPE) {
      setTimeout(() => dispatch(closeableActions.open(`DELETE_CLIENT_${clientId}`)))
    },
    setGlobalFilter: (filter, value) => dispatch(globalFilterActions.updateGlobalFilter({ [filter]: value })),
  }),
  (
    {
      selectedClientsIds,
      isNoStatusFilterActive,
      // isArchivedFilterActive,
      // isAdmittedFilterActive,
      ...stateProps
    },
    {
      onDrop,
      toggleClientStatus,
      removeClientsFromPhase,
      // deleteClient,
      ...dispatchProps
    },
    ownProps,
  ) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onArchiveClient(clientId: ID_TYPE, phaseId: ID_TYPE) {
      toggleClientStatus(clientId, 'is_archived');

      if (isNoStatusFilterActive) return;
      removeClientsFromPhase(clientId, phaseId);
    },
    onAdmitClient(clientId: ID_TYPE, phaseId: ID_TYPE) {
      toggleClientStatus(clientId, 'is_admitted');

      if (isNoStatusFilterActive) return;
      removeClientsFromPhase(clientId, phaseId);
    },
    onClientDrop(
      id: ID_TYPE,
      sourcePhaseId: ID_TYPE,
      destinationPhaseId: ID_TYPE,
      atIndex: number,
    ) {
      if (selectedClientsIds.length > 0) {
        // If dragging more than one
        const list = selectedClientsIds.map(i => Number(i));
        // Avoid duplicates
        if (list.includes(id)) {
          onDrop(list, sourcePhaseId, destinationPhaseId, atIndex);
        } else {
          onDrop([id, ...list], sourcePhaseId, destinationPhaseId, atIndex);
        }
      } else {
        // If draggin only one
        onDrop([id], sourcePhaseId, destinationPhaseId, atIndex);
      }
    },
  }),
)(Phases);
