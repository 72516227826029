// @flow
import { connect } from 'react-redux';
import {
  MdPersonAdd,
  MdSettings,
  MdSecurity,
} from 'react-icons/md';

import MenuItems from '../index';
// import { TIER_TYPES } from '../../../settings';

import * as menuItemsActions from '../../../actions/menuItems';
import * as selectors from '../../../reducers';

const items = [
  {
    id: 'usersMaintenance',
    title: 'Manejar Usuarios',
    Icon: MdPersonAdd,
    requiredPermission: true,
    requiredSuperPermission: true,
    // For future features about add users on specific boards
    /* limitedTiers: [
      TIER_TYPES.BASIC,
    ], */
  },
  {
    id: 'userSettings',
    title: 'Configuración personal',
    Icon: MdSettings,
    requiredPermission: false,
    requiredSuperPermission: false,
  },
  {
    id: 'securitySettings',
    title: 'Seguridad',
    Icon: MdSecurity,
    requiredPermission: false,
    requiredSuperPermission: false,
  },
  // {
  //   id: 'schoolSettings',
  //   title: 'Configuración del colegio',
  //   Icon: MdSettingsApplications,
  // }
];

export const SETTINGS_MENU_ITEMS_ID = 'settingsMenuItems';

export default connect(
  (state) => {
    const { is_admin = false, is_superuser = false } = selectors.getCurrentLoggedUser(state);

    let nItems = items;
    if (!is_admin) {
      nItems = items.filter(el => !el.requiredPermission);
    }

    if (!is_superuser) {
      nItems = items.filter(el => !el.requiredSuperPermission);
    }

    return ({
      items: nItems,
      selected: selectors.getMenuItemsStatus(state, SETTINGS_MENU_ITEMS_ID),
    });
  },
  dispatch => ({
    onSelect(item) {
      dispatch(menuItemsActions.updateMenuItems(SETTINGS_MENU_ITEMS_ID, item.id));
    },
  }),
)(MenuItems);
