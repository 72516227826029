// @flow
import React from 'react';
import { 
  MdArrowForward,
} from 'react-icons/md';
import moment from 'moment';

import { capitalizeFirstLetter } from '../../utils';
import styles from './infiniteCalendar.module.scss';

type InfiniteCalendarHeaderProps = {
  startDate: moment.Moment,
  endDate: moment.Moment,
}

const InfiniteCalendarHeader = ({
  startDate,
  endDate,
}: InfiniteCalendarHeaderProps) => {
  const startAsMoment = moment(startDate);
  const endAsMoment = moment(endDate);

  return (
    <div className={styles.headerContainer}>
      <div>
        <p className={styles.headerYear}>
          {startAsMoment.year()}
        </p>
        <p className={styles.headerDay}>
          {`${capitalizeFirstLetter(startAsMoment.format('ddd')).slice(0, -1)}, ${startAsMoment.format('D')} ${capitalizeFirstLetter(startAsMoment.format('MMM')).slice(0, -1)}`}
        </p>
      </div>
      {
        moment(endDate).isValid() && !moment(startDate).isSame(moment(endDate)) && (
          <>
            <div>
              <MdArrowForward size={24} color="#226865" />
            </div>
            <div>
              <p className={styles.headerYear}>
                {endAsMoment.year()}
              </p>
              <p className={styles.headerDay}>
                {`${capitalizeFirstLetter(endAsMoment.format('ddd')).slice(0, -1)}, ${endAsMoment.format('D')} ${capitalizeFirstLetter(endAsMoment.format('MMM')).slice(0, -1)}`}
              </p>
            </div>
          </>
        )
      }
    </div>
  );
}

export default InfiniteCalendarHeader;
