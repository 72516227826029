// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/md';

import * as actions from '../../actions/phases';
import * as closeableActions from '../../actions/closeable';
import type { ID_TYPE } from '../../types/common';
import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import Button from '../Button';

import styles from './deletePhaseConfirmPopup.module.scss';

type DeletePhaseConfirmPopUpType = {
  id: ID_TYPE;
  onCancel: Function,
  onConfirm: Function
};

export const DELETE_PHASE_POPUP_CONFIRM_DIALOG = 'DeletePhasePopUpConfirmDialog';

const DeletePhaseConfirmPopUp = ({
  id,
  onCancel,
  onConfirm,
}: DeletePhaseConfirmPopUpType) => (
  <Popup id={`${DELETE_PHASE_POPUP_CONFIRM_DIALOG}_${id}`}>
    <PopupContainer
      elevation={8}
      isRound
      size="small"
    >
      <PopupHeader clearPadding isAlert>
        <PopupHeaderRightGroup>
          <PopupCloseButton
            tint="mediumGray"
            Icon={MdClose}
            clearMargins
            isTransparent
            isOutlined
          />
        </PopupHeaderRightGroup>
      </PopupHeader>
      <PopupContent className={styles.popUpContainer}>
        <p className={styles.popUpTitle}>
          Eliminar fase
        </p>
        <p className={styles.popUpMessage}>
          {'Si eliminas ésta fase todos los clientes en ella '}
          <b>
            {'tambien se eliminarán. '}
          </b>
          {'¿Deseas continuar?'}
        </p>
        <div
          className={styles.buttonsContainer}
        >
          <Button
            type="button"
            title="Aceptar"
            tint="primary"
            onClick={() => onConfirm(id)}
          />
          <Button
            type="button"
            title="Cancelar"
            tint="secondary"
            onClick={() => onCancel(id)}
          />
        </div>
      </PopupContent>
    </PopupContainer>
  </Popup>
);

export default connect(null, dispatch => ({
  onCancel(id: ID_TYPE) {
    dispatch(closeableActions.close(`${DELETE_PHASE_POPUP_CONFIRM_DIALOG}_${id}`));
  },
  onConfirm(id: ID_TYPE) {
    dispatch(actions.removePhase(id));
    dispatch(closeableActions.close(`${DELETE_PHASE_POPUP_CONFIRM_DIALOG}_${id}`));
  },
}))(DeletePhaseConfirmPopUp);
