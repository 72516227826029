// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChartContainer from '../ChartContainer';
import * as actions from '../../actions/reports';
import * as selectors from '../../reducers';

import styles from './clientCountPerStateChart.module.scss';

type ClientCountPerStateChartProps = {
  isLoading: boolean,
  dataSet: Array<any>,
  onLoad: Function,
}

class ClientCountPerStateChart extends Component<ClientCountPerStateChartProps> {
  componentDidMount() {
    const { onLoad } = this.props;
    onLoad && onLoad();
  }

  render() {
    const { isLoading, dataSet } = this.props;

    return (
      <ChartContainer isLoading={isLoading}>
        <div className={styles.clientCountPerStateChart}>
          {
            dataSet.map((state, index) => (
              <div className={styles.state} key={index}>
                <div className={styles.label}>
                  {state.label}
                </div>
                <div className={
                  `
                  ${styles.count}
                  ${styles[state.label]}
                  `
                }
                >
                  {state.count}
                </div>
              </div>
            ))
          }
        </div>
      </ChartContainer>
    );
  };
}

export default connect(
  state => ({
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    isLoading: selectors.getIsFetchingClientCountByState(state),
    dataSet: selectors.getReporstByType(state, 'countPerState'),
  }),
  dispatch => ({
    onLoad(boardId) {
      dispatch(actions.startFetchingClientsCountPerState(boardId));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardId);
    },
  }),
)(ClientCountPerStateChart);
