// @flow
import React from 'react';
import { connect } from 'react-redux';
// import i18n from 'i18n-js';

import * as selectors from '../../reducers';

import styles from './externalIntro.module.scss';


type ExternalIntroPropTypes = {
  logo?: {
    url: string
  },
  boardName: string,
  introduction: any,
  // baseColor?: string
};

const ExternalIntro = ({
  logo,
  boardName,
  introduction,
  /*baseColor*/
}: ExternalIntroPropTypes) => (
  <div className={styles.externalIntro}>
    <div className={styles.titleContainer}>
      { logo && <img src={logo.url} alt={boardName} /> }
      {/* <h1 style={{ color: baseColor }}>{ title }</h1> */}
    </div>
    {
      introduction && (
        // eslint-disable-next-line react/no-danger
        <p className={styles.hideOnPrint} dangerouslySetInnerHTML={introduction} />
      )
    }
  </div>
);


export default connect(
  (state) => {
    const { introduction, name, school: { logo } } = selectors.getCurrentBoard(state) || {};
    const i18nLocale = selectors.getLocaleParam(state, true);
    const defaultLocaleKey = Object.keys(introduction).length
      ? Object.keys(introduction)[0]
      : undefined;
    
    const introductionHTMLText = (i18nLocale in introduction || defaultLocaleKey)
      ? introduction[i18nLocale] || introduction[defaultLocaleKey]
      : ''

    return {
      logo: logo,
      boardName: name,
      introduction: introduction ? { __html: `${introductionHTMLText}` } : null,
      // baseColor: (board.colors || {}).base
    };
  },
)(ExternalIntro);
