// @flow
import type { FOBI_FORM_TYPE } from './types';
import * as types from './types';
import * as validators from './elementValidators';
import * as replacers from './elementReplacers';


export const generateValidator = ({ form_elements }: FOBI_FORM_TYPE) => (values: Object) => {
  const errors = {};
  if (form_elements) {
    form_elements.forEach((formElement) => {
      const key = formElement.plugin_data.name ? formElement.plugin_data.name : 'noname';
      const value = values[key];
      let lError;
      switch (formElement.plugin_uid) {
        case types.BOOLEAN_ELEMENT: {
          lError = validators.validateBooleanElement(value, formElement);
          break;
        }
        case types.DATE_ELEMENT: {
          lError = validators.validateDateElement(value, formElement);
          break;
        }
        case types.DECIMAL_ELEMENT: {
          lError = validators.validateDecimalElement(value, formElement);
          break;
        }
        case types.EMAIL_ELEMENT: {
          lError = validators.validateEmailElement(value, formElement);
          break;
        }
        case types.FLOAT_ELEMENT: {
          lError = validators.validateFloatElement(value, formElement);
          break;
        }
        case types.INTEGER_ELEMENT: {
          lError = validators.validateIntegerElement(value, formElement);
          break;
        }
        case types.NULL_BOOLEAN_ELEMENT: {
          lError = validators.validateNullBooleanElement(value, formElement);
          break;
        }
        case types.CHECKBOX_SELECT_MULTIPLE_ELEMENT:
          lError = validators.validateMultipleOptionsElement(value, formElement)
          break;
        case types.SELECT_ELEMENT: {
          lError = validators.validateSelectElement(value, formElement);
          break;
        }
        case types.TEXT_ELEMENT: {
          lError = validators.validateTextElement(value, formElement);
          break;
        }
        case types.TEXTAREA_ELEMENT: {
          lError = validators.validateTextareaElement(value, formElement);
          break;
        }
        default: { /* noop */ }
      }
      
      if (lError != null) {
        errors[key] = lError;
      }
    });
  }
  
  return errors;
};

export const generateReplacer = ({ form_elements }: FOBI_FORM_TYPE) => (values: Object) => {
  const replacements = {};
  if (form_elements) {
    form_elements.forEach((formElement) => {
      const key = formElement.plugin_data.name ? formElement.plugin_data.name : 'noname';
      const value = values[key];
      let replaceElem;
      switch (formElement.plugin_uid) {
        case types.BOOLEAN_ELEMENT: {
          replaceElem = replacers.replaceBooleanElement(value, formElement);
          break;
        }
        case types.CHECKBOX_SELECT_MULTIPLE_ELEMENT: {
          replaceElem = replacers.replaceMultiSelectElement(value, formElement);
          break;
        }
        case types.SELECT_ELEMENT: {
          replaceElem = replacers.replaceSelectElement(value, formElement);
          break;
        }
        default: { /* noop */ }
      }
      if (replaceElem != null) {
        replacements[key] = replaceElem;
      }
    });
  }
  return replacements;
};

export const extractInitialValues = ({ form_elements }: FOBI_FORM_TYPE): Object => {
  const initialValues = {};

  form_elements.forEach(({ plugin_data: { name, initial } }) => {
    if (name != null) {
      initialValues[name] = initial;
    }
  });

  return initialValues;
};
