// @flow
import React from 'react';

import styles from './toggleSwitch.module.scss';


type ToggleSwitchPropTypes = {
  trueTitle?: string,
  falseTitle?: string,
  handleNull?: boolean,
  onChange?: Function,
  value?: ?boolean,
  isDisabled?: boolean,
  clearMargins?: boolean,
  size?: string,
  position?: string,
  hasText?: boolean,
  tint?: string,
};

class ToggleSwitch extends React.Component<ToggleSwitchPropTypes> {
  constructor(props: ToggleSwitchPropTypes) {
    super(props);

    const { onChange, value, handleNull } = props;

    if ([true, false].includes(value)) {
      return onChange && onChange(value);
    }

    if (!handleNull) {
      return onChange && onChange(false);
    }

    return onChange && onChange(null);
  }

  render() {
    const {
      onChange,
      trueTitle = '',
      falseTitle = '',
      size = 'medium',
      position = 'right',
      hasText = false,
      value,
      tint,
    } = this.props;

    return (
      <div className={`
        ${styles.toggleSwitchContainer}
        ${styles[size]} 
        ${styles[position]} 
      `}
      >
        <div
          role="switch"
          tabIndex={0}
          onClick={() => onChange && onChange(!value)}
          className={styles.toggleSwitch}
          onKeyDown={() => {}}
        >
          <div 
            className={value ? styles.check : ''}
            style={{ backgroundColor: value ? tint : '' }}
            />
          <span
            className={styles.slider}
            style={{ backgroundColor: value ? tint : '' }}
          />
        </div>
        {
          hasText && (
            <div className={styles.toggleText}>{value ? trueTitle : falseTitle}</div>
          )
        }
      </div>
    );
  }
}


export default ToggleSwitch;
