// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  CLIENT_TYPE,
  CLIENTS_ADDED_TYPE,
  CLIENT_SELECTED_FOR_EDITION_TYPE,
  ALL_CLIENTS_DESELECTED_FOR_EDITION_TYPE,
  CLIENT_FETCH_STARTED_TYPE,
  CLIENT_FETCH_COMPLETED_TYPE,
  CLIENT_FETCH_FAILED_TYPE,
  CLIENT_ADD_STARTED_TYPE,
  CLIENT_ADD_COMPLETED_TYPE,
  CLIENT_ADD_FAILED_TYPE,
  CLIENT_REMOVED_TYPE,
  CLIENT_UPDATE_STARTED_TYPE,
  CLIENT_UPDATE_COMPLETED_TYPE,
  CLIENT_UPDATE_FAILED_TYPE,
  CLIENT_TOGGLE_STATUS_STARTED_TYPE,
  CLIENT_SELECTED_TYPE,
  CLIENT_DESELECTED_TYPE,
  CLIENTS_SELECTED_TYPE,
  CLIENTS_CONFIRMED_TYPE,
  CLIENTS_SET_DIRTY_TYPE,
  CLIENTS_DESELECTED_TYPE,
  CLIENTS_CHANGE_PHASE_STARTED_TYPE,
  CLIENTS_CHANGE_PHASE_COMPLETED_TYPE,
  CLIENTS_CHANGE_PHASE_FAILED_TYPE,
  CLIENT_DRAG_STARTED_TYPE,
  CLIENT_DRAG_COMPLETED_TYPE,
  CLIENT_CONTACTS_FETCH_STARTED_TYPE,
  CLIENT_CONTACTS_FETCH_COMPLETED_TYPE,
  CLIENT_CONTACTS_FETCH_FAILED_TYPE,
  CLIENT_CONTACT_REMOVED_TYPE,
  CLIENT_COMMENTS_FETCH_STARTED_TYPE,
  CLIENT_COMMENTS_FETCH_COMPLETED_TYPE,
  CLIENT_COMMENTS_FETCH_FAILED_TYPE,
  CLIENT_COMMENT_ADDED_TYPE,
  CLIENT_COMMENT_REMOVED_TYPE,
  CLIENT_COMMENT_CONFIRMED_TYPE,
  CLIENT_FORM_EDITION_STARTED_TYPE,
  CLIENT_FORM_EDITION_COMPLETED_TYPE,
  CLIENT_LAST_SELECTED_TYPE,
  CLIENT_SET_PROFILE_PICTURE_STARTED_TYPE,
  CLIENT_SET_PROFILE_PICTURE_COMPLETED_TYPE,
  CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED_TYPE,
  CLIENT_SET_FILE_AS_PROFILE_PICTURE_TYPE,
  CLIENT_REMOVE_PROFILE_PICTURE_TYPE,
} from '../types/clients';
import type { CONTACT_TYPE } from '../types/contacts';
import type { COMMENT_TYPE } from '../types/comments';
import { error } from './common';
import * as types from '../types/clients';


export const addClients = (clients: { [ID_TYPE]: CLIENT_TYPE }): CLIENTS_ADDED_TYPE => ({
  type: types.CLIENTS_ADDED,
  payload: {
    entities: clients,
  },
});

export const selectClientForEdition = (clientId: ID_TYPE): CLIENT_SELECTED_FOR_EDITION_TYPE => ({
  type: types.CLIENT_SELECTED_FOR_EDITION,
  payload: clientId,
});

export const deselectAllClientsForEdition = (): ALL_CLIENTS_DESELECTED_FOR_EDITION_TYPE => ({
  type: types.ALL_CLIENTS_DESELECTED_FOR_EDITION,
});

export const startFetchingClient = (id: ID_TYPE): CLIENT_FETCH_STARTED_TYPE => ({
  type: types.CLIENT_FETCH_STARTED,
  payload: id,
});

export const selectLastClient = (id: ID_TYPE): CLIENT_LAST_SELECTED_TYPE => ({
  type: types.CLIENT_LAST_SELECTED,
  payload: id,
});

export const completeFetchingClient = (client: CLIENT_TYPE): CLIENT_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENT_FETCH_COMPLETED,
  payload: client,
});

export const failFetchingClient = (error: ERROR_TYPE): CLIENT_FETCH_FAILED_TYPE => ({
  type: types.CLIENT_FETCH_FAILED,
  payload: error,
});

export const startAddingClient = (
  client: CLIENT_TYPE,
  isExternal?: boolean = false,
): CLIENT_ADD_STARTED_TYPE => ({
  type: types.CLIENT_ADD_STARTED,
  payload: client,
  meta: {
    isExternal,
  },
});

export const completeAddingClient = (
  oldId: ID_TYPE, newId: ID_TYPE,
): CLIENT_ADD_COMPLETED_TYPE => ({
  type: types.CLIENT_ADD_COMPLETED,
  payload: {
    oldId,
    newId,
  },
});

export const failAddingClient = (error: ERROR_TYPE): CLIENT_ADD_FAILED_TYPE => ({
  type: types.CLIENT_ADD_FAILED,
  payload: error,
});

export const removeClient = (id: ID_TYPE): CLIENT_REMOVED_TYPE => ({
  type: types.CLIENT_REMOVED,
  payload: id,
});

export const startUpdatingClient = (
  id: ID_TYPE,
  attributes: Object,
  mainChange?: boolean,
  statusChange?: boolean,
): CLIENT_UPDATE_STARTED_TYPE => {
  const { ...nAttributes } = attributes;
  return ({
    type: types.CLIENT_UPDATE_STARTED,
    payload: {
      ...nAttributes,
      id,
    },
    meta: {
      mainChange,
      statusChange,
    },
  })
};

export const completeUpdatingClient = (id: ID_TYPE): CLIENT_UPDATE_COMPLETED_TYPE => ({
  type: types.CLIENT_UPDATE_COMPLETED,
  payload: id,
});

export const failUpdatingClient = (error: ERROR_TYPE): CLIENT_UPDATE_FAILED_TYPE => ({
  type: types.CLIENT_UPDATE_FAILED,
  payload: error,
});

export const startTogglingStatusClient = (
  id: ID_TYPE,
  key: string,
): CLIENT_TOGGLE_STATUS_STARTED_TYPE => ({
  type: types.CLIENT_TOGGLE_STATUS_STARTED,
  payload: {
    id,
    key,
  },
});

export const selectClient = (id: ID_TYPE): CLIENT_SELECTED_TYPE => ({
  type: types.CLIENT_SELECTED,
  payload: {
    id,
    isSelected: true,
  },
});

export const deselectClient = (id: ID_TYPE): CLIENT_DESELECTED_TYPE => ({
  type: types.CLIENT_DESELECTED,
  payload: {
    id,
    isSelected: false,
  },
});

export const selectClients = (order: Array<ID_TYPE>): CLIENTS_SELECTED_TYPE => ({
  type: types.CLIENTS_SELECTED,
  payload: {
    order,
    isSelected: true,
  },
});

export const deselectClients = (order: Array<ID_TYPE>): CLIENTS_DESELECTED_TYPE => ({
  type: types.CLIENTS_DESELECTED,
  payload: {
    order,
    isSelected: false,
  },
});

export const confirmClients = (order: Array<ID_TYPE>, extra: Object): CLIENTS_CONFIRMED_TYPE => ({
  type: types.CLIENTS_CONFIRMED,
  payload: {
    ...extra,
    order,
    isConfirmed: true,
  },
});

export const setClientsDirty = (order: Array<ID_TYPE>): CLIENTS_SET_DIRTY_TYPE => ({
  type: types.CLIENTS_SET_DIRTY,
  payload: {
    order,
    isConfirmed: false,
  },
});

export const startChangingPhaseOfClients = (
  clientIdsToUpdate: Array<ID_TYPE>,
  oldPhase: ID_TYPE,
  newPhase: ID_TYPE,
  atIndex?: number,
): CLIENTS_CHANGE_PHASE_STARTED_TYPE => ({
  type: types.CLIENTS_CHANGE_PHASE_STARTED,
  payload: {
    order: clientIdsToUpdate,
    phase: newPhase,
  },
  meta: {
    oldPhase,
    atIndex,
  },
});

export const completeChangingPhaseOfClients = (
  newPhase: ID_TYPE,
): CLIENTS_CHANGE_PHASE_COMPLETED_TYPE => ({
  type: types.CLIENTS_CHANGE_PHASE_COMPLETED,
  payload: {
    phase: newPhase,
  },
});

export const failChangingPhaseOfClients = (
  error: ERROR_TYPE,
): CLIENTS_CHANGE_PHASE_FAILED_TYPE => ({
  type: types.CLIENTS_CHANGE_PHASE_FAILED,
  payload: error,
});

export const startDraggingClient = (id: ID_TYPE): CLIENT_DRAG_STARTED_TYPE => ({
  type: types.CLIENT_DRAG_STARTED,
  payload: {
    id,
    isDragging: true,
  },
});

export const completeDraggingClient = (id: ID_TYPE): CLIENT_DRAG_COMPLETED_TYPE => ({
  type: types.CLIENT_DRAG_COMPLETED,
  payload: {
    id,
    isDragging: false,
  },
});

export const startFetchingClientContacts = (id: ID_TYPE): CLIENT_CONTACTS_FETCH_STARTED_TYPE => ({
  type: types.CLIENT_CONTACTS_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientContacts = (
  clientId: ID_TYPE,
  contacts: { [ID_TYPE]: CONTACT_TYPE },
): CLIENT_CONTACTS_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENT_CONTACTS_FETCH_COMPLETED,
  payload: {
    id: clientId,
    entities: contacts,
  },
});

export const failFetchingClientContacts = (
  error: ERROR_TYPE,
): CLIENT_CONTACTS_FETCH_FAILED_TYPE => ({
  type: types.CLIENT_CONTACTS_FETCH_FAILED,
  payload: error,
});

export const removeContactFromClient = (
  clientId: ID_TYPE,
  contactId: ID_TYPE,
): CLIENT_CONTACT_REMOVED_TYPE => ({
  type: types.CLIENT_CONTACT_REMOVED,
  payload: {
    id: clientId,
    key: 'contacts',
    order: [contactId],
  },
});

export const startFetchingClientComments = (id: ID_TYPE): CLIENT_COMMENTS_FETCH_STARTED_TYPE => ({
  type: types.CLIENT_COMMENTS_FETCH_STARTED,
  payload: id,
});

export const completeFetchingClientComments = (
  clientId: ID_TYPE,
  results: Array<ID_TYPE>,
  comments: { [ID_TYPE]: COMMENT_TYPE },
): CLIENT_COMMENTS_FETCH_COMPLETED_TYPE => ({
  type: types.CLIENT_COMMENTS_FETCH_COMPLETED,
  payload: {
    id: clientId,
    entities: comments,
    order: results,
    key: 'comments',
  },
});

export const failFetchingClientComments = (
  error: ERROR_TYPE,
): CLIENT_COMMENTS_FETCH_FAILED_TYPE => ({
  type: types.CLIENT_COMMENTS_FETCH_FAILED,
  payload: error,
});

export const startAddingCommentToClient = (
  clientId: ID_TYPE,
  commentId: ID_TYPE,
): CLIENT_COMMENT_ADDED_TYPE => ({
  type: types.CLIENT_COMMENT_ADDED,
  payload: {
    id: clientId,
    key: 'comments',
    order: [commentId],
  },
});

export const removeClientComment = (
  clientId: ID_TYPE,
  commentId: ID_TYPE,
): CLIENT_COMMENT_REMOVED_TYPE => ({
  type: types.CLIENT_COMMENT_REMOVED,
  payload: {
    id: clientId,
    key: 'comments',
    order: [commentId],
  },
});

export const confirmClientComment = (
  clientId: ID_TYPE,
  oldCommentId: ID_TYPE,
  newCommentId: ID_TYPE,
): CLIENT_COMMENT_CONFIRMED_TYPE => ({
  type: types.CLIENT_COMMENT_CONFIRMED,
  payload: {
    id: clientId,
    key: 'comments',
    oldValues: [oldCommentId],
    newValues: [newCommentId],
  },
});

export const startEditingClientForm = (id: ID_TYPE): CLIENT_FORM_EDITION_STARTED_TYPE => ({
  type: types.CLIENT_FORM_EDITION_STARTED,
  payload: {
    id,
    isEditing: true,
  },
});

export const completeEditingClientForm = (id: ID_TYPE): CLIENT_FORM_EDITION_COMPLETED_TYPE => ({
  type: types.CLIENT_FORM_EDITION_COMPLETED,
  payload: {
    id,
    isEditing: false,
  },
});

export const startSetProfilePicture = (
  id: ID_TYPE,
  profile_picture: any,
): CLIENT_SET_PROFILE_PICTURE_STARTED_TYPE => ({
  type: types.CLIENT_SET_PROFILE_PICTURE_STARTED,
  payload: {
    id,
    profile_picture,
  }
});

export const completeSetProfilePicture = (): CLIENT_SET_PROFILE_PICTURE_COMPLETED_TYPE => ({
  type: types.CLIENT_SET_PROFILE_PICTURE_COMPLETED,
});

export const failSetProfilePicture = error(types.CLIENT_SET_PROFILE_PICTURE_FAILED);

export const setFileAsProfilePicture = (
  id: ID_TYPE,
  profile_picture: any,
): CLIENT_SET_FILE_AS_PROFILE_PICTURE_TYPE => ({
  type: types.CLIENT_SET_FILE_AS_PROFILE_PICTURE,
  payload: {
    id,
    profile_picture,
  }
});

export const completeSetFileAsProfilePicture = (): CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED_TYPE => ({
  type: types.CLIENT_SET_FILE_AS_PROFILE_PICTURE_COMPLETED,
});

export const failSetFileAsProfilePicture = error(types.CLIENT_SET_FILE_AS_PROFILE_PICTURE_FAILED);

export const removeProfilePicture = (id: ID_TYPE): CLIENT_REMOVE_PROFILE_PICTURE_TYPE => ({
  type: types.CLIENT_REMOVE_PROFILE_PICTURE,
  payload: {
    id,
    profile_picture: null,
  }
});
