// @flow
import React from 'react';

import type { ID_TYPE } from '../../types/common';
import Input from '../Input';
import Textarea from '../Textarea';
import Select from '../Select';
import DatePicker from '../DatePicker';
import Switch from '../Switch';
import ToggleSwitch from '../ToggleSwitch';
import InputError from '../InputError';
import InputPreview from '../InputPreview';
import TextareaPreview from '../TextareaPreview';
import CheckboxGroup from '../CheckboxGroup';
import { generateRenderer } from './services';
import FilesUploader from '../FilesUploader';
import ProfileImagePicker from '../ProfileImagePicker';
import ImagePicker from '../ImagePicker';
import MultiSelect from '../MultiSelect';
import TextEditor from '../TextEditor';
import HexColorPicker from '../HexColorPicker';
import HTMLTextEditor from '../HTMLTextEditor';
import HTMLMultilingualTextEditor from '../HTMLMultilingualTextEditor';


type SelectPreviewPropTypes = {
  options: Array<{ value: ID_TYPE, label: string }>,
  value: Array<ID_TYPE>,
};

type SwitchPreviewPropTypes = {
  value: boolean,
};

type CheckboxGroupPropTypes = {
  options: Array<{ value: ID_TYPE, label: string }>,
  value: Array<ID_TYPE>,
};

export const RenderInput = generateRenderer({
  FieldComponent: Input,
  ErrorComponent: InputError,
  PreviewComponent: InputPreview,
});

export const RenderTextarea = generateRenderer({
  FieldComponent: Textarea,
  ErrorComponent: InputError,
  PreviewComponent: TextareaPreview,
});

export const RenderDatePicker = generateRenderer({
  FieldComponent: props => <DatePicker {...props} hideClearIcon />,
  ErrorComponent: InputError,
  PreviewComponent: null,
});

export const RenderFilesUploader = generateRenderer({
  FieldComponent: props => <FilesUploader {...props} />,
  ErrorComponent: InputError,
  PreviewComponent: props => <FilesUploader isPreview {...props} />,
});

export const RenderSelect = generateRenderer({
  FieldComponent: Select,
  ErrorComponent: InputError,
  PreviewComponent: ({ options, value }: SelectPreviewPropTypes) => {
    const matched = options.filter(option => option.value === value);
    return (
      <InputPreview
        value={
          matched.length > 0
            ? matched[0].label
            : 'Sin especificar'
        }
      />
    );
  },
  overrideOnBlur: true,
});

export const RenderSwitch = generateRenderer({
  FieldComponent: Switch,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: SwitchPreviewPropTypes) => {
    let strValue = '';
    switch (value) {
      case true: strValue = 'Sí'; break;
      case false: strValue = 'No'; break;
      default: strValue = 'Sin especificar';
    }

    return <InputPreview value={strValue} />;
  },
});

export const RenderToggleSwitch = generateRenderer({
  FieldComponent: ToggleSwitch,
  ErrorComponent: InputError,
});

export const RenderCheckboxGroup = generateRenderer({
  FieldComponent: CheckboxGroup,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: CheckboxGroupPropTypes) => {
    let previewText = ''

    if (value && Array.isArray(value) && value.length > 0) {
      value.forEach((str, index) => {
        if (value.length === index + 1) previewText += `${str}`
        else previewText += `${str}, `
      })
    }
    else previewText = 'Sin especificar'; 

    return (
      <InputPreview
        value={previewText}
      />
    );
  },
  overrideOnBlur: true,
});

export const RenderProfileImagePicker = generateRenderer({
  FieldComponent: ProfileImagePicker,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
});

export const RenderMultiSelect = generateRenderer({
  FieldComponent: MultiSelect,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
})

export const RenderTextEditor = generateRenderer({
  FieldComponent: TextEditor,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
})

export const RenderHTMLTextEditor = generateRenderer({
  FieldComponent: HTMLTextEditor,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
})

export const RenderHTMLMultilingualTextEditor = generateRenderer({
  FieldComponent: HTMLMultilingualTextEditor,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
})

// TODO: check maybe a FieldRenderer switch

export const RenderHexColorPicker = generateRenderer({
  FieldComponent: HexColorPicker,
  ErrorComponent: InputError,
  PreviewComponent: props => <HexColorPicker isPreview {...props} />,
});

export const RenderImagePicker = generateRenderer({
  FieldComponent: ImagePicker,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
});
