import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Button from '../Button';
import {
  RenderHexColorPicker,
  RenderHTMLMultilingualTextEditor,
  RenderImagePicker
} from '../FieldRenderer';
import { BOARD_SETTINGS_POPUP_ID } from '../BoardSettingsPopup';

import * as closeableActions from '../../actions/closeable';
import * as boardActions from '../../actions/boards';
import * as selectors from '../../reducers';

import styles from './appearanceSettings.module.scss';

type BoardAppearanceSettingsProps = {
  handleSubmit: Function,
  isLoading: boolean,
  onLoad: Function,
  onClose: Function,
}

const validate = (values) => {
  const errors = {};
  if (!values.color) { errors.color = 'Requerido'; }

  return errors;
};

const BoardAppearanceSettings = ({
  onLoad,
  isLoading,
  handleSubmit,
  onClose,
}: BoardAppearanceSettingsProps) => {
  useEffect(() => {
    onLoad && onLoad();
  }, []);

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>
        {'Apariencia'}
      </div>
      
      <form className={styles.appearanceForm} onSubmit={handleSubmit}>
        <Grid>
          <div className={styles.settingSubtitle}>
            {'General'}
          </div>
          <br/>
          <Row>
            <Col xs={5}>
              <Field
                name="color"
                placeholder="Color principal"
                label="Color principal"
                component={RenderHexColorPicker}
              />
            </Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col xs={12}>
              <Field
                label="Banner (1920 x 500 px)"
                name="form_header_picture"
                component={RenderImagePicker}
                size="banner"
              />
            </Col>
          </Row>
          <div className={styles.horizontalDivider} />
          <div className={styles.settingSubtitle}>
            {'Información'}
          </div>
          <Row className={styles.information}>
            <Col xs={12}>
              <Field
                label="Descripción/Introducción"
                name="introduction"
                component={RenderHTMLMultilingualTextEditor}
              />
            </Col>
          </Row>
          <Row className={styles.information}>
            <Col xs={12}>
              <Field
                label="Despedida/Outro"
                name="epilogue"
                component={RenderHTMLMultilingualTextEditor}
              />
            </Col>
          </Row>
          <Row className={styles.information}>
            <Col xs={12}>
              <Field
                label="Descripción/Introducción Impresa"
                labelTooltip="Este texto se mostrará únicamente al imprimir el formulario"
                name="print_introduction"
                component={RenderHTMLMultilingualTextEditor}
              />
            </Col>
          </Row>
          <Row className={styles.information}>
            <Col xs={12}>
              <Field
                label="Despedida/Outro Impresa"
                labelTooltip="Este texto se mostrará únicamente al imprimir el formulario"
                name="print_epilogue"
                component={RenderHTMLMultilingualTextEditor}
              />
            </Col>
          </Row>
          <div className={styles.horizontalDivider} />
          <div className={styles.buttons}>
            <Button
              tint="gray"
              title="Cancelar"
              className={styles.button}
              onClick={() => onClose && onClose()}
            />
            <Button
              tint="lightMainColor"
              type="submit"
              title="Guardar Cambios"
              isLoading={isLoading}
              className={styles.button}
            />
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default connect(
  state => {
    const {
      id,
      color,
      form_header_picture,
      introduction,
      print_introduction,
      epilogue,
      print_epilogue
    } = selectors.getCurrentBoard(state) || {};
    return {
      isLoading: selectors.isCurrentBoardUpdating(state),
      initialValues: {
        id,
        color,
        form_header_picture,
        introduction,
        print_introduction,
        epilogue,
        print_epilogue,
      }
    }
  },
  dispatch => ({
    onClose() {
      dispatch(closeableActions.close(BOARD_SETTINGS_POPUP_ID));
    }
  })
)(reduxForm({
  form: 'BoardAppearanceSettingsForm',
  onSubmit: (values, dispatch, { id }) => {
    dispatch(boardActions.startUpdatingBoard(id, values));
  },
  validate,
})(BoardAppearanceSettings));
