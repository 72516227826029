// @flow

const validate = (values: Object) => {
  const errors = {};

  let hasEmailContentError = false;
  if (values.emailContent && values.emailContent.length == 1) {

    const children = values.emailContent[0].children;
    if (children && children.length == 1 && children[0].text == '') {
      hasEmailContentError = true;
    }
  }

  // To send email call for template
  if (values.sendEmail && !values.subject) {
    errors.subject = 'Requerido';
  }

  if (values.sendEmail && !values.title) {
    errors.title = 'Requerido';
  }

  if (values.sendEmail && hasEmailContentError) {
    errors.emailContent = 'Requerido';
  }

  // const hasMailValue = values.subject || values.title;

  // if (hasMailValue && !values.subject) {
  //   errors.subject = 'Requerido';
  // }

  // if (hasMailValue && !values.title) {
  //   errors.title = 'Requerido';
  // }

  // if (hasMailValue && hasEmailContentError) {
  //   errors.emailContent = 'Requerido';
  // }

  return errors;
};

export default validate;