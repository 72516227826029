// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdEmail } from 'react-icons/md';
import { Field, reduxForm } from 'redux-form';

import Button from '../Button';
import { RenderInput } from '../FieldRenderer';
import * as selectors from '../../reducers';
import * as actions from '../../actions/users';


type RecoverPasswordFormPropTypes = {
  handleSubmit: Function,
  isLoading: boolean
};

const triggerRecoverPassword = (values, dispatch) => dispatch(
  actions.startRequestRecoverPassword(values.username)
);

const validate = (values) => {
  const errors = {};

  if (!values.username) { errors.username = 'Requerido'; }

  return errors;
};

const RequestRecoverPasswordForm = ({
  handleSubmit,
  isLoading = false,
}: RecoverPasswordFormPropTypes) => (
  <form onSubmit={handleSubmit}>
    <Field 
      name="username"
      placeholder="Email"
      errorClassName='loginFit'
      props={{ Icon: MdEmail }}
      component={RenderInput}
    />
    <Button
      isLoading={isLoading}
      title="Recuperar Contraseña"
      isFullWidth
      type="submit"
    />
  </form>
);


export default connect(
  state => ({
    isLoading: selectors.isLoginLoading(state),
  }),
  undefined,
)(reduxForm({
  form: 'requestRecoverPasswordForm',
  onSubmit: triggerRecoverPassword,
  validate,
})(RequestRecoverPasswordForm));
