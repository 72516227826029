// @flow
import React from 'react';

import styles from './textareaPreview.module.scss';

type TextareaPreviewPropTypes = {
  size?: string,
  className?: string,
  clearMargins?: boolean,
  hasError?: boolean,
  isSingleChar?: boolean,
  value: string
};

const TextareaPreview = ({
  size = 'medium',
  className,
  clearMargins = false,
  hasError = false,
  isSingleChar = false,
  value,
}: TextareaPreviewPropTypes) => (
  <div
    className={
      `
        ${styles.textareaPreview}
        ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
        ${typeof className !== 'undefined' ? className : ''}
        ${clearMargins ? styles.clearMargins : ''}
        ${hasError ? styles.error : ''}
        ${isSingleChar ? styles.singleChar : ''}
      `
    }
  >
    { value.trim() ? value.trim() : 'Sin especificar' }
  </div>
);


export default TextareaPreview;
