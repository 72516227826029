// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  MdLock,
  MdEmail,
} from 'react-icons/md';
import { Field, reduxForm } from 'redux-form';
import { actions as jwtActions } from 'nozzmo-redux-jwt';

import * as selectors from '../../reducers';
import Button from '../Button';
import { RenderInput } from '../FieldRenderer';


type LoginFormPropTypes = {
  handleSubmit: Function,
  isLoading: boolean
};

const triggerLogin = (values, dispatch) => dispatch(
  jwtActions.startLogin(values.username, values.password),
);

const validate = (values) => {
  const errors = {};

  if (!values.username) { errors.username = 'Requerido'; }

  if (!values.password) { errors.password = 'Requerido'; }

  return errors;
};

const LoginForm = ({ handleSubmit, isLoading = false }: LoginFormPropTypes) => (
  <form onSubmit={handleSubmit}>
    <Field 
      name="username"
      placeholder="Email"
      errorClassName='loginFit'
      props={{ Icon: MdEmail }}
      component={RenderInput}
    />
    <Field
      type="password"
      name="password"
      placeholder="Contraseña"
      errorClassName='loginFit'
      props={{ Icon: MdLock }}
      component={RenderInput}
    />
    <Button
      isLoading={isLoading}
      title="Ingresar"
      isFullWidth
      type="submit"
    />
  </form>
);


export default connect(
  state => ({
    isLoading: selectors.isLoginLoading(state),
  }),
  undefined,
)(reduxForm({
  form: 'loginForm',
  onSubmit: triggerLogin,
  validate,
})(LoginForm));
