// @flow
import React from 'react';

import styles from './inputPreview.module.scss';

type InputPreviewPropTypes = {
  size?: string,
  className?: string,
  clearMargins?: boolean,
  hasError?: boolean,
  isSingleChar?: boolean,
  value: string
};

const InputPreview = ({
  size = 'medium',
  className,
  clearMargins = false,
  hasError = false,
  isSingleChar = false,
  value,
}: InputPreviewPropTypes) => {

  const renderValue = (value) => {
    switch (typeof value) {
      case 'string':
        return value.trim();
      case 'number':
        return value.toString();
      default:
        return 'Sin especificar';
    }
  };

  return (
    <div
      className={
        `
          ${styles.inputPreview}
          ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
          ${typeof className !== 'undefined' ? className : ''}
          ${clearMargins ? styles.clearMargins : ''}
          ${hasError ? styles.error : ''}
          ${isSingleChar ? styles.singleChar : ''}
        `
      }
    >
      {
        renderValue(value)
      }
    </div>
  )
};


export default InputPreview;
