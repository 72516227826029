// @flow
import { connect } from 'react-redux';
import {
  MdContactMail,
  MdImportExport
} from 'react-icons/md';

import MenuItems from '../MenuItems';
import { TIER_TYPES } from '../../settings';

import * as menuItemsActions from '../../actions/menuItems';
import * as selectors from '../../reducers';

const items = [
  {
    id: 'contacts',
    title: 'Contactos',
    Icon: MdContactMail,
    requiredPermission: false,
    limitedTiers: [
      TIER_TYPES.BASIC,
      TIER_TYPES.STANDARD,
    ],
  },
  {
    id: 'fileExport',
    title: 'Exportar',
    Icon: MdImportExport,
    requiredPermission: false,
  },
];

export const PHASE_SETTINGS_MENU_ID = 'phaseSettingsMenuItems';

export default connect(
  (state) => {
    const schoolTier = ((selectors.getCurrentBoard(state) || {}).school || {}).tier || undefined;
    const { is_admin = false, is_superuser = false } = selectors.getCurrentLoggedUser(state);

    let nItems = items;
    if (!is_admin) {
      nItems = items.filter(el => !el.requiredPermission);
    }

    // Tiers
    if (!is_superuser) {
      nItems = nItems.filter(el => (
        el.limitedTiers ? !el.limitedTiers.includes(schoolTier) : true
      ));
    }

    return ({
      items: nItems,
      selected: selectors.getMenuItemsStatus(state, PHASE_SETTINGS_MENU_ID),
    });
  },
  dispatch => ({
    onSelect(item) {
      dispatch(menuItemsActions.updateMenuItems(PHASE_SETTINGS_MENU_ID, item.id));
    },
  }),
)(MenuItems);
