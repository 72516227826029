// @flow
import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Input from '../Input';
import { MdColorize } from 'react-icons/md';

import styles from './hexColorPicker.module.scss';

type HexColorPickerInputPropTypes = {
  size?: string,
  value?: Date,
  onChange?: Function,
  onBlur?: Function,
  className?: string,
  isPreview?: boolean,
};


export const HexColorPickerInput = ({
  value = '#37968d',
  size = 'medium',
  isPreview = false,
  onChange,
  className,
  ...props
}: HexColorPickerInputPropTypes) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useOnClickOutside(popover, close);

  return (
    <div
      className={`
        ${styles.picker}
        ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
        ${typeof className !== 'undefined' ? className : ''}
      `}
      {...props}
    >
      <div
        className={styles.swatch}
        style={{ backgroundColor: value }}
        onClick={() => !isPreview && toggle(!isOpen)}
      />

      {isOpen && !isPreview && (
        <div className={styles.popover} ref={popover}>
          <HexColorPicker color={value} onChange={onChange} />
          <Input
            className={styles.colorInput}
            placeholder='Hex'
            size='small'
            Icon={ MdColorize }
            iconPosition='left'
            value={value}
            onChange={onChange}
            elevation={3}
          />
        </div>
      )}
    </div>
  );
};

export default HexColorPickerInput;
