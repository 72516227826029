// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { i18nSetup } from '../../../../i18n';

import Sidebar from '../../Sidebar';
import DashboardHeader from '../../DashboardHeader';
import DashboardReportsHeader from '../../DashboardReportsHeader';
import ReportsDashboard from '../../ReportsDashboard';
// import GlobalReportButton from '../../GlobalReportButton';

import SettingsPopup from '../../SettingsPopup';
import BoardSettingsPopup from '../../BoardSettingsPopup';
import dashboardStyles from '../Dashboard/dashboard.module.scss';
import { FullLoader } from '../../Loader';
import { validateUserBoard } from '../../../utils';

import * as selectors from '../../../reducers';
import * as boardActions from '../../../actions/boards';
import * as usersActions from '../../../actions/users';

// import styles from './reports.module.scss';

type ReportsViewPropTypes = {
  selectedCycle: any,
  reportsDates: any,
  onLoad: Function,
  userBoards: any[],
  boardUUID: ID_TYPE,
  history: any,
};

const ReportsView = ({
  onLoad,
  userBoards,
  boardUUID,
  history,
  selectedCycle,
  reportsDates,
}: ReportsViewPropTypes) => {

  useEffect(() => {
    validateUserBoard(
      boardUUID,
      userBoards,
      history,
      boardUUID => `/dashboard/${boardUUID}`,
    );
    if (onLoad) onLoad();
  }, [boardUUID]);

  return (
    <div className={`${dashboardStyles.dashboard}`}>
      <Sidebar />
      <div className={dashboardStyles.dashboardContent}>
        {/* This needs some changes if you want to filter students data in Reports... */}
        <DashboardHeader>
          {/* <GlobalReportButton /> */}
          <DashboardReportsHeader />
        </DashboardHeader> 
        {selectedCycle === null || reportsDates.startDate === null && reportsDates.startDate === null
          ? <FullLoader size="x-large" title="Cargando reportes..." tint="gray" />
          : <ReportsDashboard />
        }
        <SettingsPopup />
        <BoardSettingsPopup />
      </div>
    </div>
  );
}

export default connect(
  state => ({
    boardUUID: selectors.getBoardUUIDParam(state),
    userBoards: selectors.getBoards(state),
    selectedCycle: selectors.getReportsSelectedCycle(state),
    reportsDates: selectors.getReportsDates(state),
  }),
  dispatch => ({
    onLoad(boardUUID) {
      dispatch(usersActions.startFetchCurrentLoggedUser());
      dispatch(boardActions.startFetchingBoards());
      dispatch(boardActions.startFetchingBoard(boardUUID));
      i18nSetup('es');
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardUUID);
    },
  }),
)(ReportsView);
