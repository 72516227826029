// @flow
import { combineReducers } from '@reduxjs/toolkit';

import * as types from '../types/boards';
import * as common from './common';
import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { BOARD_TYPE } from '../types/boards';


export type BoardsState = {
  board: BOARD_TYPE,
  byId: { [ID_TYPE]: BOARD_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  fetching: Array<ID_TYPE>,
  error: ERROR_TYPE,
  errors: { [ID_TYPE]: ERROR_TYPE },
  updating: Array<ID_TYPE>,
};

const byId = common.byId({
  updated: [
    types.BOARD_UPDATE_STARTED,
    types.BOARD_FETCH_SUCCEED,
  ],
  fetched: [types.BOARDS_FETCH_COMPLETED],
  defaultAttributes: {
    isConfirmed: false,
    filter: '',
  },
});

const order = common.order({
  fetched: [types.BOARDS_FETCH_COMPLETED],
  confirmed: [
    types.BOARD_UPDATE_COMPLETED
  ],
});

const board = common.singleton({
  populate: [types.BOARD_FETCH_SUCCEED],
  updated: [types.BOARD_UPDATE_STARTED],
  clear: [
    types.BOARD_FETCH_STARTED,
    types.BOARD_FETCH_FAILED,
  ],
});

const isFetching = common.isFetching({
  started: [types.BOARDS_FETCH_STARTED],
  succeed: [types.BOARDS_FETCH_COMPLETED],
  failed: [types.BOARDS_FETCH_FAILED],
});

const fetchingDetail = common.fetching({
  started: [types.BOARD_FETCH_STARTED],
  failed: [types.BOARD_FETCH_FAILED],
  succeed: [types.BOARD_FETCH_SUCCEED],
});

const updating = common.fetching({
  started: [types.BOARD_UPDATE_STARTED],
  succeed: [types.BOARD_UPDATE_COMPLETED],
  failed: [types.BOARD_UPDATE_FAILED],
});

const error = common.error({
  clear: [types.BOARDS_FETCH_STARTED],
  populate: [types.BOARDS_FETCH_FAILED],
});

const errors = common.errors({
  clear: [
    types.BOARD_FETCH_STARTED,
    types.BOARD_FETCH_SUCCEED,
  ],
  populate: [
    types.BOARD_FETCH_FAILED,
  ],
});

const boards = combineReducers({
  byId,
  order,
  board,
  isFetching,
  fetchingDetail,
  updating,
  error,
  errors,
});


export default boards;


export const getCurrentBoard = (state: BoardsState): BOARD_TYPE => state.board;
export const getBoard = (state: BoardsState, id: ID_TYPE): ?BOARD_TYPE => state.byId[id];
export const getBoardsIds = (state: BoardsState): Array<ID_TYPE> => state.order;
export const getBoards = (
  state: BoardsState,
): Array<?BOARD_TYPE> => state.order.map(i => getBoard(state, i));
export const isFetchingCurrentBoard = (state: BoardsState, id: ID_TYPE): boolean => state.fetchingDetail.includes(id);
export const isFetchingBoards = (state: BoardsState): boolean => state.isFetching;
export const isCurrentBoardUpdating = (
  state: BoardsState,
  id: ID_TYPE,
): boolean => state.updating.includes(id);
export const getBoardsError = (state: BoardsState): ERROR_TYPE => state.error;
export const getBoardError = (state: BoardsState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
