// @flow
import React from 'react';
import { connect } from 'react-redux';

import { ReduxClickPopover } from '../../Closeable';
import SettingsPopover from '../../SettingsPopover';
import SidebarButton from '../SidebarButton';

import * as selectors from '../../../reducers';

// import styles from './userSettings.module.scss';


type SidebarUserSettingsPropTypes = {
  isOpen: boolean,
};

const SETTINGS_POPOVER_ID = "settingsPopover";


const SidebarUserSettings = ({ isOpen }: SidebarUserSettingsPropTypes) => (
  <ReduxClickPopover
    Content={SettingsPopover}
    id={SETTINGS_POPOVER_ID}
    hasTip={false}
    isInline
    isRound
    isPopover
    transition="fadeUp"
    vertical="below"
    horizontal={!isOpen ? 'left' : undefined}
  >
    {
      isOpen && (
        <SidebarButton
          hasSubmenu
        />
      )
    }
  </ReduxClickPopover>
);

export default connect(
  state => ({
    isOpen: selectors.isMainSidebarOpen(state),
  }),
)(SidebarUserSettings);
