// @flow
import {
  call,
  race,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

/* import { fibonacciBackoff } from '../lib/retry'; */
import { throwTimeout } from '../lib/http';
import { REQUEST_TIMEOUT } from '../settings';

import { arrayOfReports } from '../api/schemas/reports';
import * as types from '../types/reports';
import * as reportsActions from '../actions/reports';
import * as selectors from '../reducers';

import { Clients } from '../api';

const parseReport = (type: string) => (responseReport: Object, index: number) => ({
  ...responseReport,
  id: `${index}-${type}`,
  type,
});

function* fetchClientsCountPerState(action) {
  const reportMethod = 'countPerState';
  const dates = yield select(selectors.getReportsDates);
  // TODO: apply global filters here!
  const selectedCycle = yield select(selectors.getReportsSelectedCycle);
  const { payload } = action;
  // const ranges = yield select(selectors.getReportsDates);
  // const cycle = yield select(selectors.getReportsSelectedCycle);

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients.custom, reportMethod],
        { 
          filters: { 
            board: payload,
            start_date: dates.startDate.toISOString().substring(0, 10),
            end_date: dates.endDate.toISOString().substring(0, 10),
            cycle_pk: selectedCycle.id,
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) throwTimeout('oh no! fetchClientsCountPerState saga exploded');

    const data = response.map(parseReport(reportMethod));

    const { result, entities: { reports } } = normalize(data, arrayOfReports);

    yield put(reportsActions.completeFetchingClientsCountPerState(result, reports));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(reportsActions.failFetchingClientsCountPerState({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchClientsCountPerPhase(action) {
  const reportMethod = 'countPerPhase';
  const dates = yield select(selectors.getReportsDates);
  // TODO: apply global filters here!
  const selectedCycle = yield select(selectors.getReportsSelectedCycle);
  const { payload } = action;

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients.custom, reportMethod],
        { 
          filters: { 
            board: payload,
            start_date: dates.startDate.toISOString().substring(0, 10),
            end_date: dates.endDate.toISOString().substring(0, 10),
            cycle_pk: selectedCycle.id,
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) throwTimeout('oh no! fetchClientsCountPerPhase saga exploded');


    const data = response.map(parseReport(reportMethod));
    const { result, entities: { reports } } = normalize(data, arrayOfReports);

    yield put(reportsActions.completeFetchingClientsCountPerPhase(result, reports));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(reportsActions.failFetchingClientsCountPerPhase({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchClientsCountPerLevel(action) {
  const reportMethod = 'countPerLevel';
  const dates = yield select(selectors.getReportsDates);
  // TODO: apply global filters here!
  const selectedCycle = yield select(selectors.getReportsSelectedCycle);
  const { payload } = action;

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients.custom, reportMethod],
        { 
          filters: { 
            board: payload,
            start_date: dates.startDate.toISOString().substring(0, 10),
            end_date: dates.endDate.toISOString().substring(0, 10),
            cycle_pk: selectedCycle.id,
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) throwTimeout('oh no! fetchClientsCountPerLevel saga exploded');

    const data = response.map(parseReport(reportMethod));
    const { result, entities: { reports } } = normalize(data, arrayOfReports);

    yield put(reportsActions.completeFetchingClientsCountPerLevel(result, reports));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    yield put(reportsActions.failFetchingClientsCountPerLevel({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchClientsCountPerMonth(action) {
  const reportMethod = 'countPerMonth';
  const { payload } = action;
  const dates = yield select(selectors.getReportsDates);
  // TODO: apply global filters here!
  const selectedCycle = yield select(selectors.getReportsSelectedCycle);
  // const ranges = yield select(selectors.getReportsDates);
  // const cycle = yield select(selectors.getReportsSelectedCycle);

  try {
    const { response, timeout } = yield race({
      response: call(
        [Clients.custom, reportMethod],
        { 
          filters: { 
            board: payload,
            start_date: dates.startDate.toISOString().substring(0, 10),
            end_date: dates.endDate.toISOString().substring(0, 10),
            cycle_pk: selectedCycle.id,
          },
        }
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) throwTimeout('oh no! fetchClientsCountPerMonth saga exploded');

    const data = response.map(parseReport(reportMethod));

    const { result, entities: { reports } } = normalize(data, arrayOfReports);

    yield put(reportsActions.completeFetchingClientsCountPerMonth(result, reports));
  } catch (error) {
    const {
      statusCode,
      message,
      data,
      isPlain,
    } = error;

    // Fetch error
    yield put(reportsActions.failFetchingClientsCountPerMonth({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchClientsCountPerMonthFetchStart(): Iterator<any> {
  yield takeEvery(
    types.CLIENTS_COUNT_PER_MONTH_FETCH_STARTED,
    fetchClientsCountPerMonth,
  );
}

export function* watchClientsCountPerPhaseFetchStart(): Iterator<any> {
  yield takeEvery(
    types.CLIENTS_COUNT_PER_PHASE_FETCH_STARTED,
    fetchClientsCountPerPhase,
  );
}

export function* watchClientsCountPerLevelFetchStart(): Iterator<any> {
  yield takeEvery(
    types.CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED,
    fetchClientsCountPerLevel,
  );
}

export function* watchClientsCountPerSateFetchStart(): Iterator<any> {
  yield takeEvery(
    types.CLIENTS_COUNT_PER_STATE_FETCH_STARTED,
    fetchClientsCountPerState,
  );
}
