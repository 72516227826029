// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';
import i18n from 'i18n-js';

import * as selectors from '../../reducers';
import {
  RenderInput,
  RenderDatePicker,
  RenderSelect,
} from '../FieldRenderer';
import { FORM_ID } from '../ClientForm';
import { FullLoader } from '../Loader';
import LocaleDate from '../LocaleDate';

import styles from './clientGenericDataForm.module.scss';


type ClientGenericDataFormPropTypes = {
  phasesOptions: Array<{ [ID_TYPE]: string }>,
  cyclesOptions: Array<{ [ID_TYPE]: string }>,
  levelsOptions: Array<{ [ID_TYPE]: string }>,
  canCreateClientWithoutContacts: boolean,
  fieldsToHide?: Array<string>,
  clientRequiredFields?: Array<string>,
  creationDate?: string,
  isLoading?: boolean,
  showPreviews?: boolean,
  onMainChange?: Function,
  hideMain?: boolean,
  baseColor?: string,
};

const localFormValueSelector = formValueSelector(FORM_ID);

const PHASE_SELECT_ID = 'clientPhase';
const CYCLE_SELECT_ID = 'clientCycle';
const LEVEL_SELECT_ID = 'clientLevel';

const ClientGenericDataForm = ({
  phasesOptions,
  cyclesOptions,
  levelsOptions,
  creationDate,
  fieldsToHide = [],
  clientRequiredFields = [],
  isLoading = false,
  showPreviews = false,
  onMainChange,
  hideMain = false,
  baseColor,
  // canCreateClientWithoutContacts,
}: ClientGenericDataFormPropTypes) => (
  <Fragment>
    { isLoading && <FullLoader title={i18n.t('loadingClient')} tint="gray" /> }
    {
      !isLoading && (
        <Fragment>
          {
            !hideMain
              ? (
                <Fragment>
                  <Grid>
                    <Row className={styles.meta}>
                      <Col xs={6}>
                        <Field
                          label="Fase actual:"
                          component={RenderSelect}
                          id={PHASE_SELECT_ID}
                          name="phase"
                          options={phasesOptions}
                          customOnChange={
                            value => showPreviews
                                && value
                                  && onMainChange
                                    && onMainChange({ phase: value })
                          }
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <Field
                          label="Siguiente fecha de seguimiento:"
                          component={RenderDatePicker}
                          name="next_relevant_date"
                          customOnChange={
                            value => showPreviews
                                && value
                                  && onMainChange
                                    && onMainChange({ next_relevant_date: value })
                          }
                          hideClearIcon
                        />
                      </Col>
                    </Row>
                    {
                      creationDate && (
                        <Row>
                          <Col xs={12}>
                            <span className={styles.creationDateTag}>
                              {i18n.t('applicationSavedOn')}
                              <strong>
                                <LocaleDate date={creationDate} format="LLLL" />
                              </strong>
                            </span>
                          </Col>
                        </Row>
                      )
                    }
                    <br />
                  </Grid>
                  <hr className={styles.metaHr} />
                </Fragment>
              ) : null
          }
          <Grid>
            <Row>
              {
                !showPreviews && (
                  <>
                    <Col xs={12}>
                      <p className={styles.requiredTag} style={{ color: baseColor }}>
                        {i18n.t('fieldsMarkedWithAsteriskAreRequired')}
                      </p>
                    </Col>
                    {/* <Col xs={12}>
                      <p
                        className={`
                          ${styles.requiredTag}
                          ${styles.fixMarginRequiredTag}
                        `}
                        style={{ color: baseColor }}
                      >
                        {i18n.t('canCreateWithoutContactsWarning')}
                      </p>
                    </Col> */}
                  </>
                )
              }
              <Col xs={12}>
                { /* eslint-disable-next-line react/no-danger */ }
                <h2 style={{ color: baseColor }} dangerouslySetInnerHTML={{ __html: i18n.t('generalApplicantInformation') }} />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  showPreview={showPreviews}
                  label={i18n.t('firstName')}
                  component={RenderInput}
                  name="first_name"
                  placeholder={i18n.t('egFirstName')}
                  baseColor={baseColor}
                  required
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  showPreview={showPreviews}
                  label={i18n.t('lastName')}
                  component={RenderInput}
                  name="last_name"
                  placeholder={i18n.t('egLastName')}
                  baseColor={baseColor}
                  required
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  showPreview={showPreviews}
                  label={i18n.t('birthDate')}
                  component={RenderDatePicker}
                  name="birth_date"
                  hideClearIcon
                  baseColor={baseColor}
                  required
                  />
              </Col>
              {
                !fieldsToHide.includes('birth_place') && (
                  <Col xs={12} sm={6}>
                    <Field
                      showPreview={showPreviews}
                      label={i18n.t('birthplace')}
                      component={RenderInput}
                      name="birth_place"
                      placeholder={i18n.t('egBirthplace')}
                      baseColor={baseColor}
                      required={clientRequiredFields.includes('birth_place')}
                    />
                  </Col>
                )
              }
              {
                !fieldsToHide.includes('phone') && (
                  <Col xs={12} sm={6}>
                    <Field
                      showPreview={showPreviews}
                      label={i18n.t('phone')}
                      component={RenderInput}
                      name="phone"
                      placeholder={i18n.t('egPhone')}
                      baseColor={baseColor}
                      required={clientRequiredFields.includes('phone')}
                    />
                  </Col>
                )
              }
              {
                !fieldsToHide.includes('cycleToApply') && (
                  <Col xs={12} sm={6}>
                    <Field
                      showPreview={showPreviews}
                      label={i18n.t('cycleToApply')}
                      component={RenderSelect}
                      id={CYCLE_SELECT_ID}
                      name="cycle"
                      options={cyclesOptions}
                      baseColor={baseColor}
                      required={clientRequiredFields.includes('cycleToApply')}
                    />
                  </Col>
                )
              }
              {
                !fieldsToHide.includes('levelToApply') && (
                  <Col xs={12} sm={6}>
                    <Field
                      showPreview={showPreviews}
                      label={i18n.t('levelToApply')}
                      component={RenderSelect}
                      id={LEVEL_SELECT_ID}
                      name="level"
                      options={levelsOptions}
                      baseColor={baseColor}
                      required={clientRequiredFields.includes('levelToApply')}
                    />
                  </Col>
                )
              }
            </Row>
          </Grid>
        </Fragment>
      )
    }
  </Fragment>
);


export default connect(
  (state) => {
    // Deduct phases options
    const phases = selectors.getPhases(state);
    const phasesOptions = phases.map(({ id, name }) => ({ value: id, label: name }));
    const canCreateClientWithoutContacts =
      selectors.getCurrentBoard(state).min_contacts_size === 0 ||
      selectors.getCurrentBoard(state).max_contacts_size === 0;

    // Deduct cycle options
    const cycles = selectors.getCycles(state);
    const cyclesOptions = cycles.map(({ id, name }) => ({ value: id, label: name }));

    // Deduct levels options
    const levels = selectors.getLevels(state);
    const levelsOptions = levels.map(({ id, name }) => ({ value: id, label: name }));

    // Form creation date
    const creationDate = localFormValueSelector(state, 'created_date');

    // Hidden & Required client fields list
    const { client_hidden_fields, client_required_fields } = selectors.getCurrentBoard(state) || {};
    const fieldsToHide = client_hidden_fields ? [...client_hidden_fields] : [];
    const clientRequiredFields = client_required_fields ? [...client_required_fields] : [];

    return {
      phasesOptions,
      cyclesOptions,
      levelsOptions,
      creationDate,
      fieldsToHide,
      clientRequiredFields,
      canCreateClientWithoutContacts,
    };
  },
)(ClientGenericDataForm);
