import React, { Ref, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
// import { cx, css } from 'emotion';
import { MdInfo } from 'react-icons/md';

import { PopHoverMenu } from '../PopMenu';
import styles from './textEditor.module.scss';

interface BaseProps {
  className: string,
  [key]: unknown
}
type OrNull<T> = T | null

export const Button = React.forwardRef(
  (
    {
      active,
      // className,
      // reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean,
        reversed: boolean
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <span
      {...props}
      ref={ref}
      className={
        `
          ${styles.button}
          ${active ? styles.black : ''}
        `
      }
    />
  )
)

// export const EditorValue = React.forwardRef(
//   (
//     {
//       className,
//       value,
//       ...props
//     }: PropsWithChildren<
//       {
//         value: any
//       } & BaseProps
//     >,
//     ref: Ref<OrNull<null>>
//   ) => {
//     const textLines = value.document.nodes
//       .map(node => node.text)
//       .toArray()
//       .join('\n')
//     return (
//       <div
//         ref={ref}
//         {...props}
//         style={{ margin: '30px -20px' }}
//       >
//         <div
//           style={{
//             fontSize: '14px',
//             padding: '5px 20px',
//             color: '#404040',
//             borderTop: '2px solid #eeeeee',
//             background: '#f8f8f8',
//           }}
//         >
//           Slate's value as text
//         </div>
//         <div
//           style={{
//             color: '#404040',
//             font: '12px monospace',
//             whiteSpace: 'pre-wrap',
//             padding: '10px 20px',
//           }}
//         >
//           {textLines}
//         </div>
//       </div>
//     )
//   }
// )

export const Instruction = React.forwardRef(
  (
    // { className, ...props }: PropsWithChildren<BaseProps>,
    { ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      style={{
          whiteSpace: 'pre-wrap',
          margin: '0 -20px 10px',
          padding: '10px 20px',
          fontSize: '14px',
          background: 'red',
      }}
    />
  )
)

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      className={styles[className]}
    />
  )
)

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = React.forwardRef(
  (
    {...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <Menu
      {...props}
      ref={ref}
      className='toolbar'
    />
  )
)

export const TagBar = React.forwardRef(
  (
    {...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <Menu
      {...props}
      ref={ref}
      className='tagbar'
    />
  )
)

export const Tag = React.forwardRef(
  (
    {
      value,
      tooltip,
      // className,
      // active,
      // reversed,
      ...props
    }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <span
      {...props}
      ref={ref}
      className={styles.tag}
    >
      {value}
      <span className={styles.icon}
        style={{ marginLeft: '2rem' }}
      >
        <span>
          <PopHoverMenu
            id={`tooltip_${value}`}
            vertical='below'
            horizontal='left'
            hasTip={false}
            isRound
            options={[{ title: tooltip }]}
          >
            <MdInfo size={18} />
          </PopHoverMenu>
        </span>
      </span>
    </span>
  )
);

export const TagsDirections = () => (
  <div className={styles.tagsDirections}>
    <span>
      <strong>{"Etiquetas"}</strong>
    </span>
    <span>
      {"Las etiquetas permiten agregar campos del cliente de forma dinámica al correo. Los campos del cliente que estén vacíos se mostrarán como un espacio en blanco. Utiliza la forma { client_name | El cliente } para utilizar un valor por defecto en cambio."}
    </span>
  </div>
);

export const TagsList = React.forwardRef(
  (
    // { className, ...props }: PropsWithChildren<BaseProps>,
    { ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      className={styles.tags}
    />
  )
)
