// @flow
import uuid from 'uuid';
import React from 'react';
import { connect } from 'react-redux';
import { convertToRaw } from 'draft-js';

import type { COMMENT_TYPE } from '../../types/comments';
import * as selectors from '../../reducers';
import * as actions from '../../actions/comments';
import * as clientActions from '../../actions/clients';
import Comment from '../Comment';
import { FullLoader } from '../Loader';
import CommentEditor from '../CommentEditor';
import User from '../User';
import BigPlaceholder from '../BigPlaceholder';
import imgPlaceholder from './comments_placeholder.png';

import styles from './clientComments.module.scss';


type ClientCommentsPropTypes = {
  isLoading?: boolean,
  comments?: Array<COMMENT_TYPE>,
  onCreateActionClicked: Function
};


class ClientComments extends React.Component<ClientCommentsPropTypes> {
  componentDidMount() {
    this._scrollToBottom();
  }

  componentWillReceiveProps(nextProps: ClientCommentsPropTypes) {
    const { comments = [] } = this.props;
    if (nextProps.comments && nextProps.comments.length > comments.length) {
      this._scrollToBottom();
    }
  }

  _scrollToBottom() {
    setTimeout(
      () => (
        this.endSentry
          && this.endSentry.scrollIntoView({
            behavior: 'smooth',
          })
      ),
      150,
    );
  }

  endSentry: ?HTMLDivElement;

  render() {
    const {
      isLoading = false,
      comments = [],
      onCreateActionClicked,
    } = this.props;

    return (
      <div
        className={`
          ${styles.clientComments}
          ${comments.length > 0 ? '' : styles.hideOnEmpty}
        `}
      >
        <span className={styles.printTitle}>
          Comentarios
        </span>
        <div className={styles.commentsContainer}>
          { isLoading && <FullLoader title="Cargando comentarios" tint="mediumgray" /> }
          {
            !isLoading && (
              comments.length > 0
                ? (
                  comments.map(
                    (comment, i) => (
                      <Comment
                        key={comment.id}
                        {...comment}
                        isLast={i === comments.length - 1}
                      />
                    ),
                  )
                )
                : (
                  <BigPlaceholder
                    pictureURL={imgPlaceholder}
                    title="No hay comentarios aún"
                    description="Para comentar en este studiante, utiliza el campo de abajo"
                    composition="vertical"
                  />
                )
            )
          }
          <div ref={(node) => { this.endSentry = node; return node; }} />
        </div>
        <div className={styles.addCommentContainer}>
          <CommentFace />
          <CommentEditor
            onActionClicked={
              (editorState, files) => onCreateActionClicked(
                convertToRaw(
                  editorState.getCurrentContent(),
                ),
                files,
              )
            }
          />
        </div>
      </div>
    );
  }
}


const CommentFace = connect(
  state => ({
    pictureURL: selectors.getUserProfilePicture(state),
  }),
)(User);

export default connect(
  (state, { clientId }) => ({
    comments: selectors.getClientComments(state, clientId),
    isLoading: selectors.isClientFetchingComments(state, clientId),
    user: selectors.getUser(state),
  }),
  (dispatch, { clientId }) => ({
    onCreateActionClicked(rawEditorData, files, user) {
      const id = uuid();
      dispatch(actions.startAddingComment({
        id,
        object_pk: clientId,
        body: rawEditorData,
        created_by: user,
        creation_date: new Date(),
        files,
      }));

      dispatch(clientActions.startAddingCommentToClient(clientId, id));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onCreateActionClicked(rawEditorData, files) {
      dispatchProps.onCreateActionClicked(rawEditorData, files, stateProps.user);
    },
  }),
)(ClientComments);
