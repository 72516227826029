export const omnisearchMock = {
  delay: 500,
  response: {
    statusCode: 200,
    body: [
      {
        id: 1,
        text: 'George Doe',
        firstName: 'George',
        lastName: 'Doe',
        email: 'test@edoo.io',
        displayAs: 'client',
        profilePicture: 'https://admisions-files.s3.amazonaws.com/feb488a8-cb92-4131-95f2-a9ade8a8bd0e/fotografia.jpeg?AWSAccessKeyId=AKIAJ32RUS6VCJNDV37Q&Signature=TpTZ%2FWmvR30ppat7loyz2wam5K8%3D&Expires=1673377549',
        board: 'Board D',
        phase: 'Entrance',
      },
      {
        id: 2,
        text: 'Jane Doe',
        firstName: 'Jane',
        lastName: 'Doe',
        email: 'test@edoo.io',
        displayAs: 'contact',
      },
      {
        id: 3,
        text: 'John Doe',
        firstName: 'John',
        lastName: 'Doe',
        email: 'test@edoo.io',
        displayAs: 'contact',
      },
    ],
  },
};
