// @flow
import React from 'react';
import type { Node } from 'react';

import styles from './cardRow.module.scss';


type CardRowPropTypes = {
  children?: Node,
  className?: string,
  size?: string,
  clearPaddingRight?: boolean,
  clearPaddingLeft?: boolean
};

type GroupPropTypes = {
  children: Node
};

const CardRow = ({
  children,
  className = '',
  size = 'medium',
  clearPaddingLeft = false,
  clearPaddingRight = false,
} :CardRowPropTypes) => (
  <div className={
    `
      ${styles.cardRow}
      ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
      ${typeof className !== 'undefined' ? className : ''}
      ${clearPaddingLeft ? styles.clearPaddingLeft : ''}
      ${clearPaddingRight ? styles.clearPaddingRight : ''}

    `
  }
  >
    { children }
  </div>
);

export const CardRowLeftGroup = ({ children }: GroupPropTypes) => (
  <div className={styles.leftGroup}>
    { children }
  </div>
);

export const CardRowRightGroup = ({ children }: GroupPropTypes) => (
  <div className={styles.rightGroup}>
    { children }
  </div>
);


export default CardRow;
