// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type REPORT_TYPE = {
  id: ID_TYPE,
  label: string,
  count: number,
  type: string,
};

export type SELECTED_CYCLE_SETTED_TYPE = {
  type: 'SELECTED_CYCLE_SETTED',
  payload: string,
}
export const SELECTED_CYCLE_SETTED = 'SELECTED_CYCLE_SETTED';

export type START_DATE_SETTED_TYPE = {
  type: 'START_DATE_SETTED',
  payload: Date,
};
export const START_DATE_SETTED = 'START_DATE_SETTED';

export type END_DATE_SETTED_TYPE = {
  type: 'END_DATE_SETTED',
  payload: Date,
};
export const END_DATE_SETTED = 'END_DATE_SETTED';

export type DATE_FILTER_CLEARED_TYPE = {
  type: 'DATE_FILTER_CLEARED',
};
export const DATE_FILTER_CLEARED = 'DATE_FILTER_CLEARED';

export type REPORTS_ADDED_TYPE = {
  type: 'REPORTS_ADDED',
  payload: {
    entities: { [ID_TYPE]: REPORT_TYPE }
  }
};
export const REPORTS_ADDED = 'REPORTS_ADDED';

export type CLIENTS_COUNT_PER_MONTH_FETCH_STARTED_TYPE = {
  type: 'CLIENTS_COUNT_PER_MONTH_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENTS_COUNT_PER_MONTH_FETCH_STARTED = 'CLIENTS_COUNT_PER_MONTH_FETCH_STARTED';

export type CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED_TYPE = {
  type: 'CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED',
  payload: {
    order: Array<ID_TYPE>,
    entities: { [ID_TYPE]: REPORT_TYPE }
  }
};
export const CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED = 'CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED';

export type CLIENTS_COUNT_PER_MONTH_FETCH_FAILED_TYPE = {
  type: 'CLIENTS_COUNT_PER_MONTH_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENTS_COUNT_PER_MONTH_FETCH_FAILED = 'CLIENTS_COUNT_PER_MONTH_FETCH_FAILED';

export type CLIENTS_COUNT_PER_STATE_FETCH_STARTED_TYPE = {
  type: 'CLIENTS_COUNT_PER_STATE_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENTS_COUNT_PER_STATE_FETCH_STARTED = 'CLIENTS_COUNT_PER_STATE_FETCH_STARTED';

export type CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED_TYPE = {
  type: 'CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED',
  payload: {
    order: Array<ID_TYPE>,
    entities: { [ID_TYPE]: REPORT_TYPE }
  }
};
export const CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED = 'CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED';

export type CLIENTS_COUNT_PER_STATE_FETCH_FAILED_TYPE = {
  type: 'CLIENTS_COUNT_PER_STATE_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENTS_COUNT_PER_STATE_FETCH_FAILED = 'CLIENTS_COUNT_PER_STATE_FETCH_FAILED';

export type CLIENTS_COUNT_PER_PHASE_FETCH_STARTED_TYPE = {
  type: 'CLIENTS_COUNT_PER_PHASE_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENTS_COUNT_PER_PHASE_FETCH_STARTED = 'CLIENTS_COUNT_PER_PHASE_FETCH_STARTED';

export type CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED_TYPE = {
  type: 'CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED',
  payload: {
    order: Array<ID_TYPE>,
    entities: { [ID_TYPE]: REPORT_TYPE }
  }
};
export const CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED = 'CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED';

export type CLIENTS_COUNT_PER_PHASE_FETCH_FAILED_TYPE = {
  type: 'CLIENTS_COUNT_PER_PHASE_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENTS_COUNT_PER_PHASE_FETCH_FAILED = 'CLIENTS_COUNT_PER_PHASE_FETCH_FAILED';

export type CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED_TYPE = {
  type: 'CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED',
  payload: ID_TYPE
};
export const CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED = 'CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED';

export type CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED_TYPE = {
  type: 'CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED',
  payload: {
    order: Array<ID_TYPE>,
    entities: { [ID_TYPE]: REPORT_TYPE }
  }
};
export const CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED = 'CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED';

export type CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED_TYPE = {
  type: 'CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED = 'CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED';

export type REPORTS_ACTION_TYPE =
  | SELECTED_CYCLE_SETTED_TYPE
  | DATE_FILTER_CLEARED_TYPE
  | START_DATE_SETTED_TYPE
  | END_DATE_SETTED_TYPE
  | REPORTS_ADDED_TYPE
  | CLIENTS_COUNT_PER_MONTH_FETCH_STARTED_TYPE
  | CLIENTS_COUNT_PER_MONTH_FETCH_COMPLETED_TYPE
  | CLIENTS_COUNT_PER_MONTH_FETCH_FAILED_TYPE
  | CLIENTS_COUNT_PER_STATE_FETCH_STARTED_TYPE
  | CLIENTS_COUNT_PER_STATE_FETCH_COMPLETED_TYPE
  | CLIENTS_COUNT_PER_STATE_FETCH_FAILED_TYPE
  | CLIENTS_COUNT_PER_PHASE_FETCH_STARTED_TYPE
  | CLIENTS_COUNT_PER_PHASE_FETCH_COMPLETED_TYPE
  | CLIENTS_COUNT_PER_PHASE_FETCH_FAILED_TYPE
  | CLIENTS_COUNT_PER_LEVEL_FETCH_STARTED_TYPE
  | CLIENTS_COUNT_PER_LEVEL_FETCH_COMPLETED_TYPE
  | CLIENTS_COUNT_PER_LEVEL_FETCH_FAILED_TYPE;
