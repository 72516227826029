// @flow
import React from 'react';

import styles from './inputError.module.scss';


type InputErrorPropTypes = {
  title: string,
  className?: string,
};

const InputError = ({
  title,
  className,
}: InputErrorPropTypes) => (
  <div className={
    `
      ${styles.error}
      ${styles[className]}
    `
    }
  >
    { title }
  </div>
);


export default InputError;
