// @flow
import type { ComponentType } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './navTabs.module.scss';


type NavTabsPropTypes = {
  links: Array<{
    title: string,
    to: string,
    Icon: ComponentType<*>
  }>
};

const NavTabs = ({ links }: NavTabsPropTypes) => (
  <div className={styles.navTabs}>
    {
      // Omit undefined options
      links.filter(x => x !== undefined).map(
        ({ title, to, Icon }) => (
          <NavLink
            key={to}
            className={styles.tab}
            activeClassName={styles.active}
            to={to}
          >
            <Icon size={20} />
            <span className={styles.text}>
              { title }
            </span>
          </NavLink>
        ),
      )
    }
  </div>
);

export default NavTabs;
