// @flow
import uuid from 'uuid';
import type {
  USER_TYPE,
  SEND_INVITATION_FAILED_TYPE,
  SEND_INVITATION_STARTED_TYPE,
  SEND_INVITATION_SUCCEED_TYPE,
  USERS_FETCH_FAILED_TYPE,
  USERS_FETCH_STARTED_TYPE,
  USERS_FETCH_SUCCEED_TYPE,
  USER_CHANGE_DISABLED_STATUS_FAILED_TYPE,
  USER_CHANGE_DISABLED_STATUS_STARTED_TYPE,
  USER_CHANGE_DISABLED_STATUS_SUCCEED_TYPE,
  USER_CHANGE_EXTRA_DATA_FAILED_TYPE,
  USER_CHANGE_EXTRA_DATA_STARTED_TYPE,
  USER_CHANGE_EXTRA_DATA_SUCCEED_TYPE,
  USER_CHANGE_ROLE_FAILED_TYPE,
  USER_CHANGE_ROLE_STARTED_TYPE,
  USER_CHANGE_ROLE_SUCCEED_TYPE,
  CURRENT_USER_UPDATE_COMPLETED_TYPE,
  CURRENT_USER_UPDATE_STARTED_TYPE,
  FETCH_CURRENT_LOGGED_USER_STARTED_TYPE,
  FETCH_CURRENT_LOGGED_USER_COMPLETED_TYPE,
  RESTORE_CREDENTIALS_STARTED_TYPE,
  RESTORE_CREDENTIALS_COMPLETED_TYPE,
  REQUEST_RECOVER_PASSWORD_STARTED_TYPE,
  REQUEST_RECOVER_PASSWORD_COMPLETED_TYPE,
  RECOVER_PASSWORD_STARTED_TYPE,
  RECOVER_PASSWORD_COMPLETED_TYPE,
  USER_LOGIN_EVENT_REGISTERED_TYPE,
  USER_LOGIN_EVENTS_CLEANED_TYPE,
  USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED_TYPE,
  USER_RECOVER_PASSWORD_ERROR_CLEARED_TYPE,
} from '../types/users';
import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import * as types from '../types/users';

import { error } from './common';

export const sendInvitationStarted = (
  first_name: string,
  email: string,
  is_admin: boolean,
  schools: Array<ID_TYPE>,
): SEND_INVITATION_STARTED_TYPE => ({
  type: types.SEND_INVITATION_STARTED,
  payload: {
    id: uuid(),
    first_name,
    email,
    is_admin,
    is_active: true,
    schools,
  },
});

export const sendInvitationFailed = (
  errors: ERROR_TYPE,
): SEND_INVITATION_FAILED_TYPE => ({
  type: types.SEND_INVITATION_FAILED,
  payload: errors,
});

export const sendInvitationSucceed = (
  oldId: ID_TYPE,
  newId: ID_TYPE,
): SEND_INVITATION_SUCCEED_TYPE => ({
  type: types.SEND_INVITATION_SUCCEED,
  payload: {
    oldId,
    newId,
  }
});

export const usersFetchStarted = (): USERS_FETCH_STARTED_TYPE => ({
  type: types.USERS_FETCH_STARTED,
});

export const usersFetchFailed = (
  error: ERROR_TYPE,
): USERS_FETCH_FAILED_TYPE => ({
  type: types.USERS_FETCH_FAILED,
  payload: error,
});

export const usersFetchSucceed = (
  entities: { [ID_TYPE]: USER_TYPE },
): USERS_FETCH_SUCCEED_TYPE => ({
  type: types.USERS_FETCH_SUCCEED,
  payload: {
    entities,
  },
});

export const userChangeDisabledStatusStarted = (
  id: ID_TYPE,
  is_active: boolean,
): USER_CHANGE_DISABLED_STATUS_STARTED_TYPE => ({
  type: types.USER_CHANGE_DISABLED_STATUS_STARTED,
  payload: {
    id,
    is_active,
  },
});

export const userChangeDisabledStatusFailed = (
  errors: ERROR_TYPE,
): USER_CHANGE_DISABLED_STATUS_FAILED_TYPE => ({
  type: types.USER_CHANGE_DISABLED_STATUS_FAILED,
  payload: errors,
});

export const userChangeDisabledStatusSucceed = (
  user: USER_TYPE,
): USER_CHANGE_DISABLED_STATUS_SUCCEED_TYPE => ({
  type: types.USER_CHANGE_DISABLED_STATUS_SUCCEED,
  payload: user,
});

export const userChangeRoleStarted = (
  id: ID_TYPE,
  is_admin: boolean,
): USER_CHANGE_ROLE_STARTED_TYPE => ({
  type: types.USER_CHANGE_ROLE_STARTED,
  payload: {
    id,
    is_admin,
  },
});

export const userChangeRoleFailed = (
  errors: ERROR_TYPE,
): USER_CHANGE_ROLE_FAILED_TYPE => ({
  type: types.USER_CHANGE_ROLE_FAILED,
  payload: errors,
});

export const userChangeRoleSucceed = (
  user: USER_TYPE,
): USER_CHANGE_ROLE_SUCCEED_TYPE => ({
  type: types.USER_CHANGE_ROLE_SUCCEED,
  payload: user,
});

export const startUpdateCurrentUser = (
  user: USER_TYPE,
): CURRENT_USER_UPDATE_STARTED_TYPE => ({
  type: types.CURRENT_USER_UPDATE_STARTED,
  payload: user,
});

export const completeUpdateCurrentuser = (): CURRENT_USER_UPDATE_COMPLETED_TYPE => ({
  type: types.CURRENT_USER_UPDATE_COMPLETED,
});

export const failUpdateCurrentUser = error(types.CURRENT_USER_UPDATE_COMPLETED);

export const startFetchCurrentLoggedUser = (): FETCH_CURRENT_LOGGED_USER_STARTED_TYPE => ({
  type: types.FETCH_CURRENT_LOGGED_USER_STARTED,
});

export const completeFetchCurrentLoggedUser = (
  user: USER_TYPE,
): FETCH_CURRENT_LOGGED_USER_COMPLETED_TYPE => ({
  type: types.FETCH_CURRENT_LOGGED_USER_COMPLETED,
  payload: user,
});

export const failFetchCurrentLoggedUser = error(types.FETCH_CURRENT_LOGGED_USER_FAILED);

export const startRestoreCredentials = (
  values: Object,
): RESTORE_CREDENTIALS_STARTED_TYPE => ({
  type: types.RESTORE_CREDENTIALS_STARTED,
  payload: values,
});

export const completeRestoreCredentials = (): RESTORE_CREDENTIALS_COMPLETED_TYPE => ({
  type: types.RESTORE_CREDENTIALS_COMPLETED
});

export const failRestoreCredentials = error(types.RESTORE_CREDENTIALS_FAILED);

export const startRequestRecoverPassword = (username: string): REQUEST_RECOVER_PASSWORD_STARTED_TYPE => ({
  type: types.REQUEST_RECOVER_PASSWORD_STARTED,
  payload: username,
});

export const completeRequestRecoverPassword = (): REQUEST_RECOVER_PASSWORD_COMPLETED_TYPE => ({
  type: types.REQUEST_RECOVER_PASSWORD_COMPLETED,
});

export const failRequestRecoverPassword = error(types.REQUEST_RECOVER_PASSWORD_FAILED);

export const startRecoverPassword = (
  user: ID_TYPE,
  token: string,
  password: string,
): RECOVER_PASSWORD_STARTED_TYPE => ({
  type: types.RECOVER_PASSWORD_STARTED,
  payload: {
    user,
    token,
    password,
  }
});

export const completeRecoverPassword = (): RECOVER_PASSWORD_COMPLETED_TYPE => ({
  type: types.RECOVER_PASSWORD_COMPLETED,
});

export const failRecoverPassword = error(types.RECOVER_PASSWORD_FAILED);

export const registerUserLoginEvent = (event: string): USER_LOGIN_EVENT_REGISTERED_TYPE => ({
  type: types.USER_LOGIN_EVENT_REGISTERED,
  payload: event,
});

export const clearUserLoginEvent = (): USER_LOGIN_EVENTS_CLEANED_TYPE => ({
  type: types.USER_LOGIN_EVENTS_CLEANED,
});

export const clearUserRequestRecoverPasswordError = (
): USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED_TYPE => ({
  type: types.USER_REQUEST_RECOVER_PASSWORD_ERROR_CLEARED,
});

export const clearUserRecoverPasswordError = (
): USER_RECOVER_PASSWORD_ERROR_CLEARED_TYPE => ({
  type: types.USER_RECOVER_PASSWORD_ERROR_CLEARED,
});


// User Extra Data
export const userChangeExtraDataStarted = (
  extra_data: any,
): USER_CHANGE_EXTRA_DATA_STARTED_TYPE => ({
  type: types.USER_CHANGE_EXTRA_DATA_STARTED,
  payload: {
    extra_data,
  },
});

export const userChangeExtraDataFailed = (
  errors: ERROR_TYPE,
): USER_CHANGE_EXTRA_DATA_FAILED_TYPE => ({
  type: types.USER_CHANGE_EXTRA_DATA_FAILED,
  payload: errors,
});

export const userChangeExtraDataSucceed = (
  user: USER_TYPE,
): USER_CHANGE_EXTRA_DATA_SUCCEED_TYPE => ({
  type: types.USER_CHANGE_EXTRA_DATA_SUCCEED,
  payload: user,
});