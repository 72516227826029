// @flow
import type { ID_TYPE } from '../types/common';

import type {
  MENU_ITEMS_TYPE,
  MENU_ITEMS_UPDATED_TYPE,
} from '../types/menuItems';
import * as types from '../types/menuItems';


export const registerMenuItems = (menu: MENU_ITEMS_TYPE) => ({
  type: types.MENU_ITEMS_REGISTERED,
  payload: menu,
});

export const updateMenuItems = (
  id: ID_TYPE,
  item: ID_TYPE,
): MENU_ITEMS_UPDATED_TYPE => ({
  type: types.MENU_ITEMS_UPDATED,
  payload: {
    id,
    item,
  },
});
