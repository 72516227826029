// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type RELATIONSHIP_TYPE = {
  id: ID_TYPE,
  name: string
};

export type RELATIONSHIPS_FETCH_STARTED_TYPE = {
  type: 'RELATIONSHIPS_FETCH_STARTED',
  payload: ID_TYPE
};
export const RELATIONSHIPS_FETCH_STARTED = 'RELATIONSHIPS_FETCH_STARTED';

export type RELATIONSHIPS_FETCH_COMPLETED_TYPE = {
  type: 'RELATIONSHIPS_FETCH_COMPLETED',
  payload: {
    entities: { [ID_TYPE]: RELATIONSHIP_TYPE },
    order: Array<ID_TYPE>
  }
};
export const RELATIONSHIPS_FETCH_COMPLETED = 'RELATIONSHIPS_FETCH_COMPLETED';

export type RELATIONSHIPS_FETCH_FAILED_TYPE = {
  type: 'RELATIONSHIPS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const RELATIONSHIPS_FETCH_FAILED = 'RELATIONSHIPS_FETCH_FAILED';

export type RELATIONSHIPS_ACTION_TYPE =
  | RELATIONSHIPS_FETCH_STARTED_TYPE
  | RELATIONSHIPS_FETCH_COMPLETED_TYPE
  | RELATIONSHIPS_FETCH_FAILED_TYPE;
