// @flow
import { combineReducers } from '@reduxjs/toolkit';
import { pluginReducer as plugin, genConfiguration } from 'redux-plugin';

import type {
  CLOSEABLE_ELEMENT_STATE,
} from '../types/closeable';
import * as types from '../types/closeable';


const PLUGIN_NAME = 'closeable';
const PLUGIN_PREFIX = `@@redux-${PLUGIN_NAME}`;

export type CloseableState = {
  [number]: CLOSEABLE_ELEMENT_STATE
};

const isOpen = (state = false, action) => {
  if (action.type === types.CLOSEABLE_GROUPS_CLOSED
    || action.type === types.CLOSEABLE_GROUPS_OPENED) {
    const { groupsToClose } = action.payload;
    const { groups } = action.peState;
    const ownGroupsSet = new Set(groups);
    const intersection = new Set(groupsToClose.filter(i => ownGroupsSet.has(i)));

    if (intersection.size > 0) {
      return action.type === types.CLOSEABLE_GROUPS_OPENED;
    }

    return state;
  }

  switch (action.type) {
    case types.CLOSEABLE_OPENED:
      return true;
    case types.CLOSEABLE_CLOSED:
    case types.CLOSEABLE_ALL_CLOSED:
      return false;
    default:
      return state;
  }
};

const groups = (state = [], action) => {
  switch (action.type) {
    case types.CLOSEABLE_GROUP_ADDED:
      return [...state, action.payload.name];
    case types.CLOSEABLE_GROUP_REMOVED:
      return state.filter(group => group !== action.payload.name);
    case types.CLOSEABLE_ALL_GROUPS_CLEARED:
      return [];
    default:
      return state;
  }
};

const configuration = genConfiguration({
  prefix: PLUGIN_PREFIX,
});

const closeableElement = combineReducers({
  isOpen,
  groups,
  configuration,
});

const closeable = plugin({
  name: PLUGIN_NAME,
  prefix: PLUGIN_PREFIX,
  reducer: closeableElement,
});

export default closeable;

export const getCloseable = (state: CloseableState, id: number) => state[id];
export const getConfiguration = (peState: CLOSEABLE_ELEMENT_STATE) => peState.configuration;
export const getIsOpen = (peState: CLOSEABLE_ELEMENT_STATE) => peState.isOpen;
export const getGroups = (peState: CLOSEABLE_ELEMENT_STATE) => peState.groups;
export const getMembership = (
  peState: CLOSEABLE_ELEMENT_STATE,
) => (group: string) => getGroups(peState).includes(group);
