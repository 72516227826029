// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupContent.module.scss';


type PopupContentPropTypes = {
  children: Node,
  size?: string,
  isAlert?: boolean,
  clearPaddings?: boolean,
  isContrasted?: boolean,
  isRound?: boolean,
  className?: string,
};

const PopupContent = ({
  children,
  size = 'medium',
  isAlert = false,
  clearPaddings = false,
  isContrasted = false,
  isRound = false,
  className = '',
} : PopupContentPropTypes) => (
  <div
    className={
      `
        ${styles.popupContent}
        ${styles[size]}
        ${isAlert ? styles.alert : ''}
        ${clearPaddings ? styles.clearPaddings : ''}
        ${isContrasted ? styles.contrasted : ''}
        ${isRound ? styles.round : ''}
        ${className}
      `
    }
  >
    { children }
  </div>
);

export default PopupContent;
