// @flow
import type { ERROR_TYPE, ID_TYPE } from '../types/common';
import type {
  RELEVANT_DATES_FETCH_STARTED_TYPE,
  RELEVANT_DATES_FETCH_COMPLETED_TYPE,
  RELEVANT_DATES_FETCH_FAILED_TYPE,
} from '../types/dates';
import * as types from '../types/dates';

export const startFetchingRelevantDates = (
  id: ID_TYPE,
): RELEVANT_DATES_FETCH_STARTED_TYPE => ({
  type: types.RELEVANT_DATES_FETCH_STARTED,
  payload: id,
});

export const completeFetchingRelevantDates = (
  relevantDates: Array<String>,
): RELEVANT_DATES_FETCH_COMPLETED_TYPE => ({
  type: types.RELEVANT_DATES_FETCH_COMPLETED,
  payload: relevantDates,
});

export const failFetchingRelevantDates = (
  error: ERROR_TYPE,
): RELEVANT_DATES_FETCH_FAILED_TYPE => ({
  type: types.RELEVANT_DATES_FETCH_FAILED,
  payload: error,
});
