// @flow
import React from 'react';

import styles from './user.module.scss';


type UserPropTypes = {
  pictureURL?: string,
  firstName: string,
  lastName: string,
  userRole?: string,
  size?: string,
  className?: string,
  theme?: string,
  onNameClick?: Function,
};

const User = ({
  pictureURL,
  firstName = '',
  lastName = '',
  userRole,
  size = 'medium',
  className,
  theme,
  onNameClick,
} : UserPropTypes) => (
  <div className={
    `
      ${styles.user}
      ${typeof className === 'string' ? className : ''}
      ${styles[size]}
      ${typeof userRole !== 'string' ? styles.noUserRole : ''}
      ${typeof styles[theme] !== 'undefined' ? styles[theme] : ''}
    `
  }
  >
    {
      typeof pictureURL !== 'undefined' && (
        <div className={styles.pictureContainer}>
          <img src={pictureURL} alt="" />
        </div>
      )
    }
    <div className={styles.userData}>
      <div
        className={
          `
            ${styles.name}
            ${onNameClick ? styles.onHover : ''}
          `
        }
        onClick={onNameClick ? onNameClick : null}
      >
        { `${firstName} ${lastName}` }
      </div>
      {
      typeof userRole !== 'undefined'
        && (
          <div className={styles.userRole}>
            { userRole }
          </div>
        )
    }
    </div>
  </div>
);

export default User;
