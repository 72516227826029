// @flow
import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
  ConnectedRouter,
  routerActions,
} from 'connected-react-router';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';

import * as selectors from './reducers';
import LoginView from './components/views/Login';
import DashboardView from './components/views/Dashboard';
import CalendarView from './components/views/Calendar';
import ReportsView from './components/views/Reports';
import ExternalView from './components/views/External';
import RequestRecoverPassword from './components/views/RequestRecoverPassword';
import RecoverPassword from './components/views/RecoverPassword';


const UserIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: selectors.isAuthenticated,
  // authenticatingSelector: selectors.isLoginLoading,
  // AuthenticatingComponent: Loading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const localizeRoutes = routes => routes.map((route) => {
  if (route.localize !== false) {
    return {
      ...route,
      path: `/:locale${route.path}`,
    };
  }
  return { ...route };
});

const defaultLocale = 'es';

const routes = [
  /* {
    path: '/',
    exact: true,
    localize: false,
    component: () => <Redirect to={`/${defaultLocale}`} />,
  }, */
  {
    path: '/external/:boardUUID',
    exact: true,
    localize: false,
    component: ({ match: { params } }: any) => <Redirect to={`/${defaultLocale}/external/${params.boardUUID}`} />,
  },
  {
    path: '/',
    exact: true,
    localize: false,
    component: LoginView,
  },
  {
    path: '/dashboard/:boardUUID',
    localize: false,
    component: UserIsAuthenticated(DashboardView),
  },
  {
    path: '/calendar/:boardUUID',
    localize: false,
    component: UserIsAuthenticated(CalendarView),
  },
  {
    path: '/reports/:boardUUID',
    localize: false,
    component: UserIsAuthenticated(ReportsView),
  },
  {
    path: '/external/:boardUUID',
    component: ExternalView,
  },
  {
    path: '/forgot-password',
    exact: true,
    localize: false,
    component: RequestRecoverPassword,
  },
  {
    path: '/recover-password',
    exact: true,
    localize: false,
    component: RecoverPassword,
  }
];

const localizedRoutes = localizeRoutes(routes);

const configRouter = (history: Object) => () => (
  <ConnectedRouter history={history}>
    <Switch>
      {
        localizedRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))
      }
    </Switch>
  </ConnectedRouter>
);

// <Route path="/forbidden" component={ ForbiddenView } />
// <Route path="/not-found" component={ NotFoundView } />
// <Route path="/" component={ LoginView } />
// <Route path="*" component={ NotFoundView } />

export default configRouter;
