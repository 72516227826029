import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import i18n from 'i18n-js';
import {
  Icon,
  Typeahead,
  OPTION_TYPE
} from '@nahualventure/paper-ui';

import { Search } from '../../api';
import { omnisearchMock } from '../../api/mocks';
import { OMNISEARCH_DISPLAY_AS_OPTIONS } from '../../settings';

import OmnisearchResultItem from '../OmnisearchResultItem';
import { OMNISEARCH_RESULT_TYPE } from '../../types/omnisearchResults';
import * as selectors from '../../reducers';

import styles from './omnisearch.module.scss';

type OmnisearchPropType = {
  onSelect?: Function;
  onLoad?: Function;
  fetchItems?: Promise<OPTION_TYPE[]>;
}


export const asyncOmnisearch = async (
  token: string,
  text: string,
) => {
  return await Search.custom.omnisearch({
    token,
    filters: { text },
    mock: omnisearchMock,
  });
}

const initAsyncOmnisearch = async (
  token: string,
  text: string
): Promise<OPTION_TYPE[]> => {
  try {
    const response = await asyncOmnisearch(token, text);
    return response.map(
      ({
        main_pk,
        text,
        first_name,
        last_name,
        display_as,
        profile_picture_90,
        board,
        phase,
      }: OMNISEARCH_RESULT_TYPE) => ({
        value: main_pk,
        label: text.toLocaleLowerCase(),
        subLabel:
          display_as === OMNISEARCH_DISPLAY_AS_OPTIONS.CLIENT
            ? `${board || ''} | ${phase || ''}`
            : 'Contacto',
        imageUrl: profile_picture_90,
        displayAs: display_as,
        displayText: `${first_name} ${last_name}`
      })
    );
  } catch (responseError) {
    console.log(responseError);
    return [];
  }
};

const Omnisearch = ({
  onSelect,
  onLoad,
  fetchItems,
}: OmnisearchPropType) => {

  useEffect(() => {
    onLoad && onLoad();
  }, []) 

  const renderItem = (option: OPTION_TYPE, idx: number, itemProps: any) => (
    <OmnisearchResultItem
      key={idx}
      value={option.value}
      title={option.label}
      subtitle={option.subLabel}
      imageURL={option.imageUrl}
      { ...option}
      {...itemProps}
    />
  )

  const renderSelectedItem = (option: OPTION_TYPE, onReset: Function) => (
    <div className={styles.selectedItem}>
      <label>{ option.label }</label>
      <p>{ option.subLabel }</p>
      <div className={styles.clearSection}>
        <Icon
          iconSize="sm"
          iconName="times"
          onClick={() => onReset()}
        />
      </div>
    </div>
  )

  return (
    <Typeahead
      autoFocus
      fullWidth
      placeholder={'Buscar...'}
      icon={'search'}
      size={'large'}
      inputDebounce={500}
      fetchItems={fetchItems}
      onSelect={onSelect}
      renderItem={renderItem}
      renderSelectedItem={renderSelectedItem}
    />
  )
}

export default connect(
  (state) => ({
    token: selectors.getToken(state),
  }),
  undefined,
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    async fetchItems(text) {
      return initAsyncOmnisearch(stateProps.token, text);
    },
  }),
)(Omnisearch);
