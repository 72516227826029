// @flow
import type { Node, ComponentType } from 'react';
import debounce from 'lodash/debounce';
import isEmptyObject from 'is-empty-object';
import React from 'react';
import { findDOMNode } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import hoClickOutside from 'react-onclickoutside';

import { close, open } from '../../../actions/closeable';
import { getIsOpen } from '../../../reducers/closeable';
import {
  transformVerticalDirection,
  transformHorizontalDirection,
} from './transform';
import TipRail from '../TipRail';
import styles from './popover.module.scss';


const getOffsetClass = ({ offset, tver, thor }) => {
  if (typeof offset !== 'undefined') {
    if (
      (
        ['above', 'below'].includes(tver)
        && ['left', 'right'].includes(thor)
      ) || (
        ['before', 'after'].includes(thor)
        && ['top', 'bottom'].includes(tver)
      )
    ) {
      if (offset > 0) {
        return `offset${offset}`;
      }
    }
  }

  return '';
};

type PopoverPropTypes = {
  at?: Object,
  transition?: string,
  isOpen?: boolean,
  hasTip?: boolean,
  className?: string,
  vertical?: string,
  horizontal?: string,
  tipPosition?: string,
  offset?: number,
  Content: ComponentType<any>,
  onClick?: Function,
  onClickOutside?: Function,
  onMouseEnter?: Function,
  onMouseLeave?: Function,
  children: Node,
  color?: string,
  closeOnOut?: boolean,
  afterOpen?: Function,
  afterClose?: Function,
  elevation?: number,
  isRound?: boolean,
  isInline?: boolean
};

type PopoverStateTypes = {
  repositioning: boolean,
  boxTop: number,
  boxLeft: number,
  boxWidth: number,
  boxHeight: number
};

class PopoverDummy extends React.Component<PopoverPropTypes, PopoverStateTypes> {
  componentWillMount() {
    this.setState({
      repositioning: true,
      boxTop: 0,
      boxLeft: 0,
      boxWidth: 0,
      boxHeight: 0,
    });
  }

  componentWillReceiveProps(nextProps: PopoverPropTypes) {
    this.updateBox();
    const { isOpen } = nextProps;
    this.handleUpdateBox = debounce(() => {
      this.updateBox();
    }, 100);

    if (isOpen) {
      this.handleScroll = () => {
        this.setState({ repositioning: true });
        this.handleUpdateBox();
      };

      window.addEventListener(
        'scroll',
        this.handleScroll,
      );
      window.addEventListener(
        'resize',
        this.handleScroll,
      );
    } else {
      window.removeEventListener(
        'scroll',
        this.handleScroll,
      );
      window.removeEventListener(
        'resize',
        this.handleScroll,
      );
    }
  }

  handleUpdateBox: Function;

  handleScroll: Function;

  updateBox() {
    try {
      const node = findDOMNode(this);
      if (node instanceof Element) {
        let rect;
        if (node.firstElementChild instanceof Element) {
          rect = node.firstElementChild.getBoundingClientRect();
        } else {
          rect = node.getBoundingClientRect();
        }

        this.setState({
          repositioning: false,
          boxTop: rect.top,
          boxLeft: rect.left,
          boxWidth: rect.width,
          boxHeight: rect.height,
        });
      }
    } catch (_e) { /* noop */ }
  }

  handleClickOutside() {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside();
    }
  }

  render() {
    const {
      at,
      transition = 'fadeIn',
      isOpen = false,
      hasTip = true,
      className = '',
      vertical = 'above',
      horizontal = 'center',
      tipPosition = 'center',
      offset = 0,
      Content = () => null,
      onClick = e => e,
      children,
      color = 'white',
      closeOnOut = true,
      onMouseEnter = e => e,
      onMouseLeave = e => e,
      elevation = 2,
      isRound = false,
      isInline = false,
    } = this.props;

    if (typeof at === 'undefined' || isEmptyObject(at)) {
      const tver = transformVerticalDirection(vertical);
      const thor = transformHorizontalDirection(horizontal);

      const {
        repositioning,  
        boxLeft,
        boxTop,
        boxWidth,
        boxHeight,
      } = this.state;

      return (
        <div
          className={
            `
              ${styles.bounds}
              ${isInline ? styles.inline : ''}
            `
          }
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          onKeyPress={onClick}
          role="presentation"
        >
          { children }
          <CSSTransition
            in={isOpen}
            timeout={100}
            classNames={{
              enter: styles[`${transition}Enter`],
              enterActive: styles[`${transition}EnterActive`],
              exit: styles[`${transition}Exit`],
              exitActive: styles[`${transition}ExitActive`],
            }}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={
                `
                  ${styles.floatingBox}
                  ${repositioning ? styles.repositioning : ''}
                `
              }
              style={{
                left: boxLeft,
                top: boxTop,
                width: boxWidth,
                height: boxHeight,
              }}
            >
              <div
                className={
                  `
                    ${styles.popover}
                    ${isRound ? styles.round : ''}
                    ${hasTip ? styles.hasTip : ''}
                    ${styles[`elevation${elevation}`] || ''}
                    ${styles[thor]}
                    ${styles[tver]}
                    ${styles[getOffsetClass({ offset, tver, thor })] || ''}
                    ${className}
                  `
                }
                style={{ backgroundColor: color }}
                onClick={(e: MouseEvent) => closeOnOut && e.stopPropagation()}
                onKeyPress={(e: MouseEvent) => closeOnOut && e.stopPropagation()}
                role="presentation"
              >
                <Content {...this.props} />
              </div>
              {
                hasTip && (
                  ['above', 'below'].includes(tver) && (
                    <TipRail
                      vertical={tver}
                      horizontal={tipPosition}
                      tipColor={color}
                    />
                  )
                )
              }
              {
                hasTip && (
                  ['above', 'below'].includes(tver) && (
                    <TipRail
                      vertical={tver}
                      horizontal={tipPosition}
                      tipColor={color}
                    />
                  )
                )
              }
              {
                hasTip && (
                  !['above', 'below'].includes(tver) && ['before', 'after'].includes(thor) && (
                    <TipRail
                      isHorizontal={false}
                      vertical={tipPosition}
                      horizontal={thor}
                      tipColor={color}
                    />
                  )
                )
              }
            </div>
          </CSSTransition>
        </div>
      );
    }

    // TODO: handle global positioned popovers
    return null;
  }
}

const Popover = hoClickOutside(PopoverDummy);

export default Popover;

export const ClickPopover = connect(
  undefined,
  (
    dispatch,
    {
      id,
      peState,
      closeOnOut = true,
      afterOpen,
      afterClose,
    },
  ) => ({
    onClick() {
      if (getIsOpen(peState)) {
        dispatch(close(id));

        if (typeof afterClose !== 'undefined') {
          afterClose(id);
        }
      } else {
        dispatch(open(id));

        if (typeof afterOpen !== 'undefined') {
          afterOpen(id);
        }
      }
    },
    onClickOutside: () => (closeOnOut && getIsOpen(peState) ? dispatch(close(id)) : null),
  }),
)(Popover);

export const HoverPopover = connect(
  undefined,
  (dispatch, { id }) => ({
    onMouseEnter: () => dispatch(open(id)),
    onMouseLeave: () => dispatch(close(id)),
  }),
)(Popover);
