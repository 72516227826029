// @flow
import React from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import {
  MdFormatBold,
  MdPerson,
  MdToday,
  MdAccessTimeFilled,
  MdSort,
  MdArrowDownward,
  MdArrowUpward,
} from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import * as actions from '../../actions/phases';
import PopMenu from '../PopMenu';


import styles from './phaseSortingOptions.module.scss';

const compareOptions = {
  alphabetic: {
    title: 'Alfabético',
    Icon: MdFormatBold,
    compareFunction: (a, b) => {
      if ((a.first_name + a.last_name) > (b.first_name + b.last_name)) { return 1; }
      if ((a.first_name + a.last_name) < (b.first_name + b.last_name)) { return -1; }
      return 0;
    },
  },
  age: {
    title: 'Edad',
    Icon: MdPerson,
    compareFunction: (a, b) => b.birth_date - a.birth_date,
  },
  date: {
    title: 'Fecha de Seguimiento',
    Icon: MdToday,
    compareFunction: (a, b) => b.next_relevant_date - a.next_relevant_date,
  },
  createdDate: {
    title: 'Fecha de Ingreso',
    Icon: MdAccessTimeFilled,
    compareFunction: (a, b) => b.created_date - a.created_date,
  },
};

type SortOptionType = {
  className?: string,
  text?: string,
  Icon: ComponentType<*>,
}

const SortOption = ({
  className = '',
  text,
  Icon,
  ...props
}: SortOptionType) => (
  <div
    {...props}
    className={
      `
        ${styles.sortOption}
        ${className}
      `
    }
  >
    <Icon size={25} />
    {
      text && (
        <span>
          {text}
        </span>
      )
    }
  </div>
);

type PhaseSortingOptionsPropTypes = {
  id: ID_TYPE,
  sortingKey: ID_TYPE,
  sortingDirection: string,
  onOptionSelected: Function,
  onDirectionSelected: Function,
};

const PhaseSortingOptions = ({
  id,
  sortingKey,
  sortingDirection,
  onOptionSelected,
  onDirectionSelected,
}: PhaseSortingOptionsPropTypes) => (
  <div className={styles.phaseSortingOptions}>
    <div className={styles.leftGroup}>
      <PopMenu
        id={`phaseSortMenu-${id}`}
        vertical="below"
        horizontal="center"
        tipPosition="center"
        offset={0}
        isRound
        options={Object.keys(compareOptions).map(key => ({
          title: compareOptions[key].title,
          Icon: compareOptions[key].Icon,
          onClick: () => onOptionSelected({
            ...compareOptions[key],
            id: key,
          }),
        }))}
      >
        <SortOption
          className={styles.marked}
          text={compareOptions[sortingKey].title}
          Icon={MdSort}
        />
      </PopMenu>
    </div>
    <div className={styles.rightGroup}>
      <SortOption
        className={
          `
            ${sortingDirection === 'down' ? styles.marked : null}
          `
        }
        onClick={() => onDirectionSelected('down')}
        Icon={MdArrowDownward}
      />
      <SortOption
        className={
          `
            ${sortingDirection === 'up' ? styles.marked : null}
          `
        }
        onClick={() => onDirectionSelected('up')}
        Icon={MdArrowUpward}
      />
    </div>
  </div>
);

export default connect(
  (state, { id }) => {
    const sorting = selectors.getPhase(state, id).sortingMethod || {};
    return ({
      id,
      sortingKey: sorting.key || 'date',
      sortingDirection: sorting.direction,
      clients: selectors.getUnfilteredPhaseClients(state, id),
    });
  },
  (dispatch, { id }) => ({
    onSortingSelected(selected, clients) {
      let sorted = clients.sort(compareOptions[selected.key].compareFunction);
      if (selected.direction === 'up') {
        sorted = sorted.reverse();
      }

      dispatch(actions.completeFetchingPhaseClients(
        id,
        sorted.map(client => client.id),
      ));
      dispatch(actions.updateSortingMethod(
        id,
        selected,
      ));
    },
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onOptionSelected(selected) {
      dispatchProps.onSortingSelected(
        {
          key: selected.id,
          direction: stateProps.sortingDirection,
        },
        stateProps.clients,
      );
    },
    onDirectionSelected(selected) {
      dispatchProps.onSortingSelected(
        {
          key: stateProps.sortingKey,
          direction: selected,
        },
        stateProps.clients,
      );
    },
  }),
)(PhaseSortingOptions);
