// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';

import LoginLogo from '../../LoginLogo';
import LoginFormContainer from '../../LoginFormContainer';
import LoginPoweredByEdoo from '../../LoginPoweredByEdoo';
import RecoverPasswordForm from '../../RecoverPasswordForm';
import RecoverPasswordError from '../../RecoverPasswordError';

import styles from './recoverPassword.module.scss';


type RecoverPasswordViewPropTypes = { };


const RecoverPasswordView = ({
}: RecoverPasswordViewPropTypes) => (
  <div className={styles.login}>
    <LoginFormContainer>
      <LoginLogo />
      <div className={styles.title}>
        {"Escoge una nueva contraseña"}
      </div>
      <RecoverPasswordError />
      <RecoverPasswordForm />
      <NavLink to="/">
        {"Ya la recordé, acceder"}
      </NavLink>
    </LoginFormContainer>
    <div className={styles.loginRightPanel}>
      <LoginPoweredByEdoo />
    </div>
  </div>
);

export default RecoverPasswordView;
