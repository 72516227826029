// @flow
import React from 'react';
import { connect } from 'react-redux';

import { BOARD_SETTINGS_MENU_ITEMS_ID } from '../../MenuItems/MenuItemsBoardSettings';
import BoardAppearanceSettings from '../../BoardAppearanceSettings';

import * as selectors from '../../../reducers';

type SettingsPopupContentPropTypes = {
  settingsMenuItem: string,
};


const SettingsPopupContent = ({
  settingsMenuItem,
}: SettingsPopupContentPropTypes) => {
  let Content = null;

  switch (settingsMenuItem) {
    case 'appearanceSettings':
      Content = <BoardAppearanceSettings />
      break;
    default:
      Content = <BoardAppearanceSettings  />;
      break;
  }

  return Content;
};


export default connect(
  state => ({
    settingsMenuItem: selectors.getMenuItemsStatus(state, BOARD_SETTINGS_MENU_ITEMS_ID),
  })
)(SettingsPopupContent);
