// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { reset } from 'redux-form';
import i18n from 'i18n-js';

import { i18nSetup } from '../../../../i18n';
import * as selectors from '../../../reducers';
import * as actions from '../../../actions/boards';
import ExternalClientCreationForm from '../../ClientForm/ExternalClientCreationForm';
import ExternalHeader from '../../ExternalHeader';
import ExternalIntro from '../../ExternalIntro';
import ClientCreationSuccessPopup from '../../ClientCreationSuccessPopup';
import ExternalFooter from '../../ExternalFooter';
import LanguageSelector from '../../LanguageSelector';
import HiddenPDFSignature from '../../HiddenPDFSignature';
import { FORM_ID } from '../../ClientForm';
import Loader from '../../Loader';

import styles from './external.module.scss';


type ExternalViewPropTypes = {
  onLoad: Function,
  resetFormAfterPrint: Function,
  baseColor?: string,
  allowFormSubmission?: boolean,
  usePrintSignature: boolean,
};

class ExternalView extends React.Component<ExternalViewPropTypes> {
  constructor(props) {
    super(props);

    this.state = {
      isPrint: false,
    }
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();

    // Add extended class
    document.getElementsByTagName(
      'html',
    )[0].classList.add('extended');
  }

  componentWillUnmount() {
    // Remove extended class
    document.getElementsByTagName(
      'html',
    )[0].classList.remove('extended');
  }

  render() {
    const { baseColor, allowFormSubmission, usePrintSignature, resetFormAfterPrint } = this.props;
    const { isPrint } = this.state;

    return (
      <div className={styles.external}>
        <ExternalHeader />
        <div className={styles.formContainer}>
          <LanguageSelector />

          {
            allowFormSubmission !== undefined ? (
              <>
                {
                  allowFormSubmission ? (
                    <Fragment>
                      <ExternalIntro />
                      <ExternalClientCreationForm isPrint={isPrint} />
                    </Fragment>
                  ) : (
                    <span className={styles.formSubmissionMessage} style={{ color: baseColor }}>
                      {i18n.t('allowFormSubmissionMessage')}
                    </span>
                  )
                }
              </>
            ) : (
              <div className={styles.loaderContainer}>
                <Loader size="x-large" tint="main" />
              </div>
            )
          }
        </div>
        <ExternalFooter />
        {
          usePrintSignature && (
            <div className={styles.hiddenPDFSignatureFooter}>
              <HiddenPDFSignature text="Nombre del Encargado: " />
              <HiddenPDFSignature text="Firma del Encargado: " />
            </div>
          )
        }
        <ClientCreationSuccessPopup
          onPrint={() => {
            this.setState(state => ({
              ...state,
              isPrint: true,
            }), () => {
              // Open print dialog
              setTimeout(() => {
                window.print();
              }, 1000);

              setTimeout(() => {
                // Reset print state
                this.setState(state => ({
                  ...state,
                  isPrint: false,
                }));
                resetFormAfterPrint();
              }, 4000);
            });
          }}
        />
      </div>
    );
  }
}


export default withRouter(
  connect(
    (state) => {
      const board = selectors.getCurrentBoard(state) || {};
      // This is the only value that depends on board school...
      const { use_print_signature } = board.school || {};

      return ({
        isAuthenticated: selectors.isAuthenticated(state),
        baseColor: (board.colors || {}).base,
        allowFormSubmission: board.allow_form_submission,
        usePrintSignature: use_print_signature,
      });
    },
    (dispatch, { match: { params: { locale, boardUUID } } }) => ({
      onLoad(isAuthenticated) {
        if (isAuthenticated) {
          return dispatch(push('dashboard'));
        }
        i18nSetup(locale);
        // Start fetching board (inside this saga, client form fetching should start)
        return dispatch(actions.startFetchingBoard(boardUUID));
      },
      resetFormAfterPrint() {
        dispatch(reset(FORM_ID));
      },
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      onLoad() {
        dispatchProps.onLoad(stateProps.isAuthenticated);
      },
    }),
  )(ExternalView),
);
