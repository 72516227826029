// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeaderLeftGroup.module.scss';


type PopupHeaderLeftGroupPropTypes = {
  children?: Node
};

const PopupHeaderLeftGroup = ({
  children,
} : PopupHeaderLeftGroupPropTypes) => (
  <div className={styles.popupHeaderLeftGroup}>
    { children }
  </div>
);

export default PopupHeaderLeftGroup;
