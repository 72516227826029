// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdArrowBack, MdClose } from 'react-icons/md';

import type { ID_TYPE } from '../../types/common';
import * as closeableActions from '../../actions/closeable';
import { ReduxClickPopover } from '../Closeable';
import Button from '../Button';
import CardHeader from '../CardHeader';
import CardHeaderLeftGroup from '../CardHeaderLeftGroup';
import CardHeaderRightGroup from '../CardHeaderRightGroup';
import CardHeaderTitleContainer from '../CardHeaderTitleContainer';
import CardHeaderTitle from '../CardHeaderTitle';
import PhaseEditionForm from '../PhaseEditionForm';

import styles from './phaseEditionPixel.module.scss';


type PhaseEditionPixelPropTypes = {
  id: ID_TYPE,
  onBack: Function
};

const PhaseEditionPixel = ({
  id,
  onBack
}: PhaseEditionPixelPropTypes) =>
  <ReduxClickPopover
    id={ `phaseEditionPopover-${ id }` }
    Content={ () =>
      <Fragment>
        <CardHeader size="small">
          <CardHeaderLeftGroup>
            <Button
              size="small"
              Icon={ MdArrowBack }
              tint="mediumGray"
              clearMargins
              isTransparent
              isOutlined
              onClick={ onBack }
            />
          </CardHeaderLeftGroup>
          <CardHeaderTitleContainer>
            <CardHeaderTitle size="small" isCentered>
              { 'Edición de fase' }
            </CardHeaderTitle>
          </CardHeaderTitleContainer>
          <CardHeaderRightGroup>
            <Button
              size="small"
              Icon={ MdClose }
              tint="mediumGray"
              clearMargins
              isTransparent
              isOutlined
              onClick={ onBack }
            />
          </CardHeaderRightGroup>
        </CardHeader>
        <div className={ styles.formContainer }>
          <PhaseEditionForm id={ id } />
        </div>
      </Fragment>
    }
    vertical="below"
    horizontal="center"
    tipPosition="center"
    isRound>
    <div className={ styles.editionPixel } />
  </ReduxClickPopover>


export default connect(
  undefined,
  (dispatch, { id }) => ({
    onBack() {
      dispatch(closeableActions.close(`phaseEditionPopover-${ id }`));
      dispatch(closeableActions.open(`phaseMenu-${ id }`));
    }
  })
)(PhaseEditionPixel);
