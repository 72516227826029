// @flow
import { combineReducers } from '@reduxjs/toolkit';

import * as types from '../types/notifications';
import * as common from './common';
import type { MAYBE_ERROR_TYPE, ERROR_TYPE } from '../types/common';
import type {
  NOTIFICATIONS_ACTION_TYPE,
  NOTIFICATION_TYPE,
} from '../types/notifications';
import * as closeableTypes from '../types/closeable';


export type NotificationsState = {
  byId: { [number]: NOTIFICATION_TYPE },
  order: Array<number>,
  isFetching: boolean,
  fetching: Array<number>,
  nextPage: number,
  errors: { [number]: ERROR_TYPE },
  error: MAYBE_ERROR_TYPE,
  count: number,
  screen: number
};

const byId = common.byId({
  fetched: [types.NOTIFICATIONS_FETCH_SUCCEED],
  add: [
    types.NOTIFICATION_ADDED,
    types.NOTIFICATION_FETCH_SUCCEED,
  ],
  updated: [types.NOTIFICATION_READ],
});

const order = common.order({
  fetched: [types.NOTIFICATIONS_FETCH_SUCCEED],
  add: [
    types.NOTIFICATION_ADDED,
    types.NOTIFICATION_FETCH_SUCCEED,
  ],
});

const isFetching = common.isFetching({
  started: [types.NOTIFICATIONS_FETCH_STARTED],
  succeed: [types.NOTIFICATIONS_FETCH_SUCCEED],
  failed: [types.NOTIFICATIONS_FETCH_FAILED],
});

const fetching = common.fetching({
  started: [types.NOTIFICATION_FETCH_STARTED],
  succeed: [types.NOTIFICATION_FETCH_SUCCEED],
  failed: [types.NOTIFICATION_FETCH_FAILED],
});

const nextPage = (
  state: ?number = 1,
  action: NOTIFICATIONS_ACTION_TYPE,
): ?number => {
  switch (action.type) {
    case types.NOTIFICATIONS_FETCH_SUCCEED: {
      const { next } = action.payload;
      return next != null ? Math.max(state || -1, next) : null;
    }

    default: {
      return state;
    }
  }
};

const error = (
  state: MAYBE_ERROR_TYPE = {},
  action: NOTIFICATIONS_ACTION_TYPE,
): MAYBE_ERROR_TYPE => {
  switch (action.type) {
    case types.NOTIFICATIONS_FETCH_STARTED: {
      return {};
    }

    case types.NOTIFICATIONS_FETCH_SUCCEED: {
      return {};
    }

    case types.NOTIFICATIONS_FETCH_FAILED: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

const errors = common.errors({
  populate: [types.NOTIFICATION_FETCH_FAILED],
  clear: [types.NOTIFICATION_FETCH_SUCCEED],
});

const count = (state: number = 0, action: NOTIFICATIONS_ACTION_TYPE) => {
  switch (action.type) {
    case types.NOTIFICATIONS_COUNTED: {
      return action.payload;
    }
    case types.NOTIFICATIONS_WATCHED: {
      return 0;
    }
    default:
      return state;
  }
};

// TODO: Return to the initial screen when the popup open
const screen = common.mux({
  selected: [types.NOTIFICATION_POPUP_CHANGED],
  allDeselected: [closeableTypes.CLOSEABLE_OPENED],
  default: 1,
});

const notifications = combineReducers({
  byId,
  order,
  isFetching,
  fetching,
  nextPage,
  error,
  errors,
  count,
  screen,
});

export default notifications;

export const getNotification = (
  state: NotificationsState, id: number,
): NOTIFICATION_TYPE => state.byId[id];
export const getNotificationOrder = (
  state: NotificationsState,
): Array<number> => state.order;
export const getNotifications = (
  state: NotificationsState,
): Array<NOTIFICATION_TYPE> => state.order.map(id => getNotification(state, id));
export const areNotificationsFetching = (
  state: NotificationsState,
): boolean => state.isFetching;
export const getNotificationsCount = (
  state: NotificationsState,
): number => state.count;
export const isNotificationFetching = (
  state: NotificationsState, id: number,
): boolean => state.fetching.includes(id);
export const getNotificationsNextPage = (
  state: NotificationsState,
): number => state.nextPage;
export const getNotificationsFetchError = (
  state: NotificationsState,
): MAYBE_ERROR_TYPE => state.error;
export const getNotificationError = (
  state: NotificationsState, id: number,
): ERROR_TYPE => state.errors[id];

export const getCurrentScreen = (
  state: NotificationsState,
): number => state.screen;
