// @flow
import React, { FC, useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from '../../actions/reports';
import * as selectors from '../../reducers';

import ChartContainer from '../ChartContainer';

/* import styles from './clientCountPerMonthChart.module.scss'; */

type ClientCountByMonthChartProps = {
  height?: number | string,
  width?: number | string,
  dataSet: Array<any>,
  onLoad?: Function,
  isLoading: boolean,
};

const chartState = {
  type: 'line',
  options: {
    title: { text: 'Por meses' },
    stroke: { curve: 'smooth', width: 3 },
    markers: { size: 6 },
    xaxis: {
      categories: [],
      tickPlacement: 'between',
    },
    chart: {
      toolbar: { show: false },
    },
  },
  series: [],
};

const ClientCountByMonthChart: FC = (props: ClientCountByMonthChartProps) => {
  const [data, setData] = useState(chartState);
  const {
    onLoad,
    width,
    height,
    dataSet,
    isLoading,
  } = props;

  useEffect(() => {
    if (onLoad) onLoad();
  }, []);

  // Build month data grouped by phase
  const normalized = {};
  const months = {};

  /* const uniqMonths = new Set(dataSet.map(({ year, month }) => `${month}-${year}`));
  const months = [...uniqMonths]; */

  dataSet.forEach((set) => {
    const {
      phase,
      count,
      year,
      month,
    } = set;
    if (phase in normalized) normalized[phase].push(count);
    else normalized[phase] = [count];

    months[`${month}-${year}`] = undefined;
  });

  // Build series data for chart
  const tempSeries = [];
  Object.keys(normalized).forEach((key) => {
    tempSeries.push({
      name: key,
      data: normalized[key],
    });
  });

  if (!isEqual(data.options.xaxis.categories, Object.keys(months))) {
    setData({
      ...data,
      options: {
        ...data.options,
        xaxis: {
          ...data.options.xaxis,
          categories: [...Object.keys(months)],
        },
      },
    });
  }

  if (!isEqual(data.series, tempSeries)) {
    setData({
      ...data,
      series: [
        ...tempSeries,
      ],
    });
  }

  return (
    <ChartContainer isLoading={isLoading}>
      <Chart
        type={data.type}
        options={data.options}
        series={data.series}
        height={height}
        width={width}
      />
    </ChartContainer>
  );
};

export default connect(
  state => ({
    boardId: (selectors.getCurrentBoard(state) || {}).id,
    isLoading: selectors.getIsFetchingClientCountByMonth(state),
    dataSet: selectors.getReporstByType(state, 'countPerMonth'),
  }),
  dispatch => ({
    onLoad(boardId) {
      dispatch(actions.startFetchingClientsCountPerMonth(boardId));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onLoad() {
      dispatchProps.onLoad(stateProps.boardId);
    },
  }),
)(ClientCountByMonthChart);
