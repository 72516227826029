// @flow
import React, { useState, useEffect } from 'react';
import {
  MdAttachFile,
  MdCloudUpload,
  MdDelete,
  MdMoreVert,
  MdEdit,
} from 'react-icons/md';
import { Line } from 'rc-progress';

import Button from '../Button';
import PopMenu from '../PopMenu';

import styles from './filesUploader.module.scss';

type FileItemProps = {
  name: string,
  fileId: string,
  extension: string,
  progress: string,
  onDelete: (fileId: string) => any,
  onSetAsProfilePicture: Function,
  error: string,
  fileLink: string,
  isPreview: boolean,
}

const pictureExtensions = ['png', 'jpg', 'jpeg', 'raw', 'bmp', 'eps'];

const FileItem = ({
  name,
  fileId,
  extension = '',
  progress = 100,
  onDelete,
  fileLink,
  isPreview,
  onSetAsProfilePicture,
  // error,
}: FileItemProps) => {
  const [isHover, setIsHover] = useState(false);
  const [hideProgressBar, setHideProgressBar] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setHideProgressBar(true);
      }, 1000);
    }

    if (fileLink) {
      setHideProgressBar(true);
    }
  }, [progress]);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={styles.fileInfo}>
        <div className={styles.leftIcon}>
          {
            progress === 100
            || isPreview
            || fileLink
              ? (
                <Button
                  size="small"
                  tint="primary"
                  Icon={MdAttachFile}
                  onClick={() => {
                    if (fileLink) {
                      window.open(fileLink, '_blank');
                    }
                  }}
                  isTransparent
                  clearMargins
                />
              ) : (
                <Button
                  tint="primary"
                  size="small"
                  Icon={MdCloudUpload}
                  isTransparent
                  clearMargins
                />
              )
          }
        </div>
        <div className={styles.fileName}>
          {`${name}`}
        </div>
        <div className={styles.removeIcon}>
          {
            isHover
            && progress === 100
            && onDelete
            && (
              <Button
                size="small"
                tint="primary"
                Icon={MdDelete}
                isTransparent
                onClick={() => onDelete(fileId)}
                clearMargins
              />
            )
          }
        </div>
        <div className={styles.moreIcon}>
          {
            isPreview
            && pictureExtensions.includes(extension.toLowerCase())
            && (
              <PopMenu
                hasTip={false}
                id={`${fileId}_fileItemMenu`}
                horizontal="left"
                vertical="below"
                isRound
                options={[
                  {
                    Icon: MdEdit,
                    title: 'Usar como foto de perfil',
                    onClick: onSetAsProfilePicture,
                  },
                ]}
              >
                <Button
                  size="small"
                  Icon={MdMoreVert}
                  isTransparent
                  clearMargins
                />
              </PopMenu>
            )
          }
        </div>
      </div>
      {
        !hideProgressBar
        && !isPreview
        && (
          <div className={styles.progressBar}>
            <Line percent={progress} strokeColor="#003634" />
          </div>
        )
      }
    </div>
  );
};

export default FileItem;
