// @flow
import type { ID_TYPE } from '../types/common';
import type { GLOBALFILTER_TYPE, GLOBALFILTERS_ACTION_TYPE } from '../types/globalFilters';
import * as types from '../types/globalFilters';

export type GlobalfiltersState = GLOBALFILTER_TYPE;

const initialState = {
  baseDate: new Date(),
  startBirthDate: null,
  endBirthDate: null,
  cycle: null,
  level: null,
  isAdmitted: null,
  isArchived: null,
  noStatus: null,
};

const globalFilters = (
  state: GlobalfiltersState = initialState,
  action: GLOBALFILTERS_ACTION_TYPE,
): GlobalfiltersState => {
  switch (action.type) {
    case types.GLOBAL_FILTER_UPDATED: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.GLOBAL_FILTER_CLEARED: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};


export default globalFilters;


// Selectors
export const getGlobalFilters = (state: GlobalfiltersState): GLOBALFILTER_TYPE => state;
export const getGlobalBaseDateFilter = (state: GlobalfiltersState): ?Date => state.baseDate;
export const getGlobalStartBirthDateFilter = (state: GlobalfiltersState): ?Date => state.startBirthDate;
export const getGlobalEndBirthDateFilter = (state: GlobalfiltersState): ?Date => state.endBirthDate;
export const getGlobalCycleIdFilter = (state: GlobalfiltersState): ?ID_TYPE | ?ID_TYPE[]  => state.cycle;
export const getGlobalLevelIdFilter = (state: GlobalfiltersState): ?ID_TYPE => state.level;
export const getGlobalIsAdmittedFilter = (state: GlobalfiltersState): ?boolean => state.isAdmitted;
export const getGlobalIsArchivedFilter = (state: GlobalfiltersState): ?boolean => state.isArchived;
export const getGlobalNoStatusFilter = (state: GlobalfiltersState): ?boolean => state.noStatus;
export const hasGlobalFilters = (state: GlobalfiltersState) => Object.keys(state)
  .map(key => state[key])
  .filter(filter => filter != null)
  .length > 0;
