// @flow
import React, { useState, useEffect } from 'react';
import {
  MdDelete,
} from 'react-icons/md';
import {
  FaFilePdf,
  FaFileImage,
} from 'react-icons/fa';
import { Line } from 'rc-progress';

import Button from '../Button';

import styles from './filesUploader.module.scss';

type FileItemCompactProps = {
  name: string,
  fileId: string,
  extension: string,
  progress: string,
  onDelete: (fileId: string) => any,
  error: string,
  fileLink: string,
}

const FileItemCompact = ({
  name,
  fileId,
  extension = '',
  progress = 100,
  onDelete,
  fileLink,
  // error,
}: FileItemCompactProps) => {
  const [hideProgressBar, setHideProgressBar] = useState(false);

  const openUrl = () => {
    if (fileLink) {
      window.open(fileLink, '_blank');
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setHideProgressBar(true);
      }, 1000);
    }

    if (fileLink) {
      setHideProgressBar(true);
    }
  }, [progress, fileLink]);

  return (
    <div className={styles.compactContainer}>
      <div className={styles.fileIcon}>
        {
          ['jpg', 'png', 'jpeg'].includes(extension) ? <FaFileImage /> : <FaFilePdf />
        }
      </div>
      <div className={styles.compactFileInfo}>
        <div
          className={`
            ${styles.fileName}
            ${fileLink ? styles.click : ''}
          `}
          onClick={openUrl}
        >
          {`${name}`}
        </div>
        {
          !hideProgressBar
          && (
            <div className={styles.compactProgressBar}>
              <Line percent={progress} strokeColor="#003634" strokeWidth={2} />
            </div>
          )
        }
        {
          hideProgressBar && (
            <div className={styles.fileExtension}>
              {`${extension.toUpperCase()}`}
            </div>
          )
        }
      </div>
      <div className={styles.removeIcon}>
        {
          onDelete
          && hideProgressBar
          && (
            <Button
              size="small"
              tint="primary"
              Icon={MdDelete}
              isTransparent
              onClick={() => onDelete(fileId)}
              clearMargins
            />
          )
        }
      </div>
    </div>
  );
};

export default FileItemCompact;
