// @flow
import React from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { connect } from 'react-redux';

// import { ReduxHoverPopover } from '../../Closeable';
import SidebarButton from '../SidebarButton';
import { UserExtraData } from '../../../settings';

import * as selectors from '../../../reducers';
import * as userActions from '../../../actions/users';

import styles from './sidebarOpen.module.scss';


type SidebarSettingsPropTypes = { 
  isOpen: boolean,
  onClose: boolean,
  onOpen: Function,
};


const SidebarSettings = ({ isOpen, onOpen, onClose }: SidebarSettingsPropTypes) => (
  <div className={styles.sidebarOpen}>
    {
      isOpen ? (
        <SidebarButton
          Icon={MdKeyboardArrowLeft}
          title="Cerrar Panel"
          onClick={onClose ? () => onClose() : null}
        />
      ) : (
        <SidebarButton
          Icon={MdKeyboardArrowRight}
          onClick={onOpen ? () => onOpen() : null}
        />
      )
    }
  </div>
);

export default connect(
  state => ({
    userExtraData: selectors.getUserExtraData(state),
    isOpen: selectors.isMainSidebarOpen(state),
  }),
  dispatch => ({
    onToggle(extraData, value) {
      const extraDataPayload = {
        ...extraData,
        [UserExtraData.OPENED_MAIN_SIDEBAR]: value,
      };
      dispatch(userActions.userChangeExtraDataStarted(extraDataPayload));
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    onOpen() {
      dispatchProps.onToggle(stateProps.userExtraData, true);
    },
    onClose() {
      dispatchProps.onToggle(stateProps.userExtraData, false);
    },
  }),
)(SidebarSettings);
