// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { PHASE_TYPE, PHASES_ACTION_TYPE } from '../types/phases';
import * as common from './common';
import * as types from '../types/phases';


type FiltersType = { [ID_TYPE]: string };
type ConfigurationType = {
  sendEmail: boolean,
  emailConfiguration: {
    subject?: string,
    title?: string,
    content?: string,
  },
};

export type PhasesState = {
  byId: { [ID_TYPE]: PHASE_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  fetchingDetail: Array<ID_TYPE>,
  fetching: Array<ID_TYPE>,
  updating: Array<ID_TYPE>,
  error: ERROR_TYPE,
  errors: { [ID_TYPE]: ERROR_TYPE },
  selected: ID_TYPE,
  filters: FiltersType,
  byIdEmailTemplates: { [ID_TYPE]: ConfigurationType },
};

const byId = common.byId({
  added: [
    types.PHASE_ADD_STARTED,
  ],
  updated: [
    types.PHASE_UPDATE_STARTED,
    types.PHASE_CLIENTS_FETCH_COMPLETED,
    types.SORTING_METHOD_UPDATED,
    types.FETCH_PHASE_COMPLETED,
  ],
  fetched: [types.PHASES_FETCH_COMPLETED],
  removed: [types.PHASE_REMOVED],
  confirmed: [
    types.PHASE_ADD_COMPLETED,
    // types.PHASE_UPDATE_COMPLETED
    // BIG FUCKING TODO: review why this action deletes byid entry
    // when old and new id is the same
  ],
  addedToArrayAttribute: [types.PHASE_CLIENTS_ADDED],
  removedFromArrayAttribute: [types.PHASE_CLIENTS_REMOVED],
  replacedInArrayAttribute: [types.PHASE_CLIENTS_UPDATED],
  defaultAttributes: {
    isConfirmed: false,
    filter: '',
  },
});

const order = common.order({
  added: [types.PHASE_ADD_STARTED],
  fetched: [types.PHASES_FETCH_COMPLETED],
  removed: [types.PHASE_REMOVED],
  confirmed: [
    types.PHASE_ADD_COMPLETED,
    // types.PHASE_UPDATE_COMPLETED
  ],
  sorted: [types.SORT_PHASE_STARTED],
});

const isFetching = common.isFetching({
  started: [types.PHASES_FETCH_STARTED],
  succeed: [types.PHASES_FETCH_COMPLETED],
  failed: [types.PHASES_FETCH_FAILED],
});

const fetching = common.fetching({
  started: [types.PHASE_CLIENTS_FETCH_STARTED],
  succeed: [types.PHASE_CLIENTS_FETCH_COMPLETED],
  failed: [types.PHASE_CLIENTS_FETCH_FAILED],
});

const fetchingDetail = common.fetching({
  started: [types.FETCH_PHASE_STARTED],
  succeed: [types.FETCH_PHASE_COMPLETED],
  failed: [types.FETCH_PHASE_FAILED],
});

const updating = common.fetching({
  started: [types.PHASE_UPDATE_STARTED],
  succeed: [types.PHASE_UPDATE_COMPLETED],
  failed: [types.PHASE_UPDATE_FAILED],
});

const error = common.error({
  clear: [types.PHASES_FETCH_STARTED],
  populate: [types.PHASES_FETCH_FAILED],
});

const errors = common.errors({
  clear: [
    types.FETCH_PHASE_STARTED,
    types.FETCH_PHASE_COMPLETED,
    types.PHASE_ADD_STARTED,
  ],
  populate: [
    types.FETCH_PHASE_FAILED,
    types.PHASE_ADD_FAILED
  ],
});

const selected = common.mux({
  selected: [types.PHASE_SELECTED],
  allDeselected: [types.PHASE_UNSELECTED],
  default: -1,
});

const byIdEmailTemplates = common.keyExtractorById({
  idKey: 'id',
  extractionKey: 'email_template',
  set: [
    types.PHASE_CLIENTS_FETCH_COMPLETED,
    types.PHASES_FETCH_COMPLETED,
    types.PHASE_ADD_STARTED,
    types.FETCH_PHASE_COMPLETED,
  ],
  default: {},
})

const filters = (state: FiltersType = {}, action: PHASES_ACTION_TYPE): FiltersType => {
  switch (action.type) {
    case types.PHASE_FILTER_UPDATED: {
      const { id, filter } = action.payload;
      return {
        ...state,
        [id]: filter,
      };
    }
    default: {
      return state;
    }
  }
};

const phases = combineReducers({
  byId,
  order,
  isFetching,
  fetching,
  fetchingDetail,
  updating,
  error,
  errors,
  selected,
  filters,
  byIdEmailTemplates,
});


export default phases;


// Selectors
export const getPhase = (state: PhasesState, id: ID_TYPE): ?PHASE_TYPE => state.byId[id];
export const getPhasesIds = (state: PhasesState): Array<ID_TYPE> => state.order;
export const getPhases = (
  state: PhasesState,
): Array<?PHASE_TYPE> => state.order.map(i => getPhase(state, i));
export const getNextRelevantPhase = (state: PhasesState, date: Date): ?PHASE_TYPE => {
  const allPhases: Array<?Object> = getPhases(state);
  let nextPhase = {};

  for (let i = 0; i < allPhases.length; i += 1) {
    const phase: Object = allPhases[i] || {};

    if (phase.next_relevant_date != null) {
      if (phase.next_relevant_date >= date) {
        nextPhase = phase;
        break;
      }
    }
  }

  return nextPhase;
};
export const isPhaseFetching = (
  state: PhasesState,
  id: ID_TYPE,
): boolean => state.fetching.includes(id);
export const isPhaseUpdating = (
  state: PhasesState,
  id: ID_TYPE,
): boolean => state.updating.includes(id);
export const isFetchingPhases = (state: PhasesState): boolean => state.isFetching;
export const getPhasesError = (state: PhasesState): ERROR_TYPE => state.error;
export const getPhaseError = (state: PhasesState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
export const getPhaseClientsIds = (
  state: PhasesState,
  id: ID_TYPE,
): Array<ID_TYPE> => (getPhase(state, id) || {}).clients || [];
export const getPhaseFilter = (state: PhasesState, id: ID_TYPE) => (
  state.filters[id] ? state.filters[id].toLowerCase() : ''
);
export const getPhaseSelected = (state: PhasesState): ?PHASE_TYPE => getPhase(state, state.selected);
export const getPhaseEmailTemplate = (state: PhasesState, id: ID_TYPE): ?Object => state.byIdEmailTemplates[id];
export const isFetchingPhase = (state: PhasesState, id: ID_TYPE): boolean => state.fetchingDetail.includes(id);
