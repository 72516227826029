// @flow
import type { ERROR_TYPE } from './common';

export type SCHOOL_TYPE = {
  id: number,
  name: string,
  tier: string,
  active_users_limit?: number,
  address: string,
  phone: string,
  logo: {
    url: string
  },
  use_print_signature: boolean,
}

export type SCHOOL_FETCH_STARTED_TYPE = {
  type: 'SCHOOL_FETCH_STARTED',
  payload: number
};
export const SCHOOL_FETCH_STARTED = 'SCHOOL_FETCH_STARTED';

export type SCHOOL_FETCH_SUCCEED_TYPE = {
  type: 'SCHOOL_FETCH_SUCCEED',
  payload: SCHOOL_TYPE
};
export const SCHOOL_FETCH_SUCCEED = 'SCHOOL_FETCH_SUCCEED';

export type SCHOOL_FETCH_FAILED_TYPE = {
  type: 'SCHOOL_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const SCHOOL_FETCH_FAILED = 'SCHOOL_FETCH_FAILED';


export type SCHOOL_ACTION_TYPE =
  | SCHOOL_FETCH_STARTED_TYPE
  | SCHOOL_FETCH_SUCCEED_TYPE
  | SCHOOL_FETCH_FAILED_TYPE;
