// @flow
import { combineReducers } from '@reduxjs/toolkit';

import * as types from '../types/schools';
import * as common from './common';
import type { ERROR_TYPE } from '../types/common';
import type { SCHOOL_TYPE } from '../types/schools';


export type SchoolsState = {
  school: SCHOOL_TYPE,
  isFetching: boolean,
  error: ERROR_TYPE
};

const school = common.singleton({
  populate: [types.SCHOOL_FETCH_SUCCEED],
  clear: [
    types.SCHOOL_FETCH_STARTED,
    types.SCHOOL_FETCH_FAILED,
  ],
});

const isFetching = common.isFetching({
  started: [types.SCHOOL_FETCH_STARTED],
  failed: [types.SCHOOL_FETCH_FAILED],
  succeed: [types.SCHOOL_FETCH_SUCCEED],
});

const error = common.error({
  populate: [types.SCHOOL_FETCH_FAILED],
  clear: [
    types.SCHOOL_FETCH_STARTED,
    types.SCHOOL_FETCH_SUCCEED,
  ],
});

const schools = combineReducers({
  school,
  isFetching,
  error,
});


export default schools;


export const getSchool = (state: SchoolsState): SCHOOL_TYPE => state.school;
export const isFetchingSchool = (state: SchoolsState): boolean => state.isFetching;
export const getSchoolError = (state: SchoolsState): ERROR_TYPE => state.error;
