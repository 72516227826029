// @flow

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import * as types from '../types/uploadingClients';
import type {
  UPLOADING_CLIENTS_ADDED_TYPE,
  UPLOADING_CLIENTS_CLEARED_TYPE,
  UPLOADING_CLIENTS_STEP_UPDATED_TYPE,
} from '../types/uploadingClients';

export const addUploadingClients = (
  entities: { [ID_TYPE]: UPLOADING_CLIENT_TYPE },
  order: Array<ID_TYPE>,
): UPLOADING_CLIENTS_ADDED_TYPE => ({
  type: types.UPLOADING_CLIENTS_ADDED,
  payload: {
    entities,
    order,
  },
});

export const updateUploadingClientsStep = (
  step: number,
): UPLOADING_CLIENTS_STEP_UPDATED_TYPE => ({
  type: types.UPLOADING_CLIENTS_STEP_UPDATED,
  payload: step,
});

export const updateUploadingClientsPhase = (
  phase: number,
): UPLOADING_CLIENT_PHASE_UPDATED_TYPE => ({
  type: types.UPLOADING_CLIENT_PHASE_UPDATED,
  payload: phase,
});

export const failAddingClients = (error: ERROR_TYPE): UPLOADING_CLIENTS_ADD_FAILED_TYPE => ({
  type: types.UPLOADING_CLIENTS_ADD_FAILED,
  payload: error,
});

export const clearUploadingClients = (): UPLOADING_CLIENTS_CLEARED_TYPE => ({
  type: types.UPLOADING_CLIENTS_CLEARED,
  payload: {
    byId: {},
    order: [],
  },
});

export const startUploadingClients = (
  uploadingClients: Array<UPLOADING_CLIENT_TYPE>,
): UPLOADING_CLIENTS_UPLOAD_STARTED_TYPE => ({
  type: types.UPLOADING_CLIENTS_UPLOAD_STARTED,
  payload: {
    uploadingClients,
  },
});

export const completeUploadingClients = (): UPLOADING_CLIENTS_UPLOAD_COMPLETED_TYPE => ({
  type: types.UPLOADING_CLIENTS_UPLOAD_COMPLETED,
});

export const failUploadingClients = (error: ERROR_TYPE): UPLOADING_CLIENTS_UPLOAD_FAILED_TYPE => ({
  type: types.UPLOADING_CLIENTS_UPLOAD_FAILED,
  payload: error,
});
