// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './cardHeaderRightGroup.module.scss';


type CardHeaderRightGroupPropTypes = {
  children?: Node
};

const CardHeaderRightGroup = ({
  children,
} : CardHeaderRightGroupPropTypes) => (
  <div className={styles.headerRightGroup}>
    { children }
  </div>
);

export default CardHeaderRightGroup;
