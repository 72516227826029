// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './card.module.scss';


type CardPropTypes = {
  className?: string,
  children?: Node,
  elevation?: number,
  isRound?: boolean,
  isClickeable?: boolean,
};

const Card = ({
  className,
  children,
  elevation = 2,
  isRound = false,
  isClickeable = false,
} : CardPropTypes) => (
  <div
    className={
      `
        ${styles.card}
        ${typeof className !== 'undefined' ? className : ''}
        ${typeof styles[`elevation${elevation}`] !== 'undefined' ? styles[`elevation${elevation}`] : ''}
        ${isRound ? styles.round : ''}
        ${isClickeable ? styles.click : ''}
      `
    }
  >
    { children }
  </div>
);

export default Card;
