// @flow
import type { Node } from 'react';
import React from 'react';
import styles from './loginformcontainer.module.scss';

type LoginFormContainerPropTypes = {
  elevation?: number,
  tint?: string,
  children?: Node
};

const LoginFormContainer = ({
  elevation = 3,
  tint = 'white',
  children,
}: LoginFormContainerPropTypes) => (
  <div className={
    `
      ${styles.loginformcontainer}
      ${styles[`elevation${elevation}`]}
      ${styles[tint]}
    `
  }
  >
    { children }
  </div>
);

export default LoginFormContainer;
