// @flow
import React from 'react';
import { MdCameraAlt } from 'react-icons/md';

import Button from '../Button';
import PopMenu from '../PopMenu';
import styles from './imagePicker.module.scss';


type FloatButtonPropTypes = {
  getRootProps: any,
  options: Array<Object>,
};

export const IMAGE_MENU_ID = 'imageMenu';

const FloatButton = ({
  getRootProps,
  options = [],
}: FloatButtonPropTypes) => {
  const nOptions = options.map(el => ({ ...getRootProps(), ...el }));

  return (
    <PopMenu
      hasTip={false}
      id={IMAGE_MENU_ID}
      horizontal="right"
      vertical="below"
      options={nOptions}
      className={styles.topFix}
      isRound
      offset={80}
      width={155}
    >
      <div className={styles.floatButton}>
        <Button
          color="gray"
          clearMargins
          Icon={MdCameraAlt}
          isRound
          tint="paleyGray"
          size='small'
        />
      </div>
    </PopMenu>
  )
}


export default FloatButton;
