// @flow
import type { Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ReactMarkdown from 'react-markdown';

import type { FOBI_FORM_TYPE } from '../FobiRenderer/types';
import * as fobiTypes from '../FobiRenderer/types';
import * as selectors from '../../reducers';
import { FobiReduxFormRenderer } from '../FobiRenderer';

import styles from './clientSchoolSpecificFormRenderer.module.scss';


type ClientSchoolSpecificFormRendererPropTypes = {
  formDescription: FOBI_FORM_TYPE,
  showPreviews?: boolean,
  baseColor?: string,
};

type ElementWrapperPropTypes = {
  plugin_uid: string,
  children?: Node,
};

type ContentTextWrapperPropTypes = {
  input?: string,
}

const ElementWrapper = ({ plugin_uid, children }: ElementWrapperPropTypes) => (
  <Col xs={12} sm={plugin_uid === fobiTypes.TEXTAREA_ELEMENT || plugin_uid === fobiTypes.FILE_ELEMENT ? 12 : 6}>
    { children }
  </Col>
);

const ContentTextWrapper = ({ input }: ContentTextWrapperPropTypes) => (
  <Col xs={12}>
    <ReactMarkdown source={input} className={styles.ContentTextWrapper} />
  </Col>
);

const ClientSchoolSpecificFormRendererDummy = ({
  formDescription,
  showPreviews = false,
  baseColor,
  ...props
}: ClientSchoolSpecificFormRendererPropTypes) => (
  <Grid>
    <Row>
      {/* <Col xs={12}>
        <h2 style={{ color: baseColor }}>
          {i18n.t('additionalApplicantInformation')}
        </h2>
      </Col> */}
      <FobiReduxFormRenderer
        formDescription={formDescription}
        ElementWrapper={ElementWrapper}
        ContentTextWrapper={ContentTextWrapper}
        showPreviews={showPreviews}
        baseColor={baseColor}
        {...props}
      />
    </Row>
  </Grid>
);


export default connect(
  state => ({
    formDescription: selectors.getClientFormDescription(state),
  }),
)(ClientSchoolSpecificFormRendererDummy);
