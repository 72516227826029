import React, { useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { isEmptyStringOrArray } from '../../utils';

import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Range,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdCode,
  MdLooksOne,
  MdLooksTwo,
  MdFormatQuote,
  MdFormatListNumbered,
  MdFormatListBulleted,
  MdLink,
  MdLinkOff,
  MdImage,
} from 'react-icons/md';

import {
  Button,
  Toolbar,
  TagBar,
  TagsDirections,
  Tag,
  TagsList,
} from './components'
import styles from './textEditor.module.scss';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const TAGS = [
  ['{client_formal_name}', 'Utiliza la combinación del primer nombre y el primer apellido del lead.'],
  ['{client_full_name}', 'Utiliza todos nombres y apellidos del lead.'],
  ['{client_level}', 'Utiliza el grado al que aplica el lead.'],
  ['{client_cycle}', 'Utiliza el ciclo al que aplica el lead.'],
];

const LIST_TYPES = ['numbered-list', 'bulleted-list']


type TextEditorPropTypes = {
  value?: any,
  onChange?: Function,
};


const TextEditor = ({
  value = [],
  onChange,
}: TextEditorPropTypes) => {
  const validatedValue = !isEmptyStringOrArray(value) ? value : PLACE_HOLDER_VALUE;
  const [localValue, setLocalValue] = useState(validatedValue);

  const renderElement = props => <Element {...props} />
  const renderLeaf = props =><Leaf {...props} />
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const handleOnChange = (nValue) => {
    setLocalValue(nValue);

    if (onChange) onChange(nValue);
  }

  return (
    <Slate
      editor={editor}
      value={localValue}
      onChange={val => handleOnChange(val)}>
      <Toolbar>
        <MarkButton format="bold" Icon={MdFormatBold} />
        <MarkButton format="italic" Icon={MdFormatItalic} />
        <MarkButton format="underline" Icon={MdFormatUnderlined} />
        <MarkButton format="code" Icon={MdCode} />
        <BlockButton format="heading-one" Icon={MdLooksOne} />
        <BlockButton format="heading-two" Icon={MdLooksTwo} />
        <BlockButton format="block-quote" Icon={MdFormatQuote} />
        <BlockButton format="numbered-list" Icon={MdFormatListNumbered} />
        <BlockButton format="bulleted-list" Icon={MdFormatListBulleted} />
        <AddLinkButton Icon={MdLink} />
        <RemoveLinkButton Icon={MdLinkOff} />
        <AddImageButton Icon={MdImage} />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Escribe acá el contenido del correo…"
        autoFocus
        className={styles.editor}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
      />
      <TagBar>
        <TagsDirections />
        <TagsList>
          {
            TAGS.map((tag, idx) => <TagButton key={idx} value={tag[0]} tooltip={tag[1]} />)
          }
        </TagsList>
      </TagBar>
    </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const insertTag = (editor, value) => {
  Editor.insertNode(editor, { text: value, tag: true })
  Editor.insertNode(editor, { text: ' ' })
}


const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    case 'image':
      return (
        <div>
          {/* children is not required but if is not added, Slate crash :p */}
          {children}
          <img src={element.url} alt="img" />
        </div>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.tag) {
    children =
      <span className={styles.tagValue}>
        {children}
      </span>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, Icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

const MarkButton = ({ format, Icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

const TagButton = ({ value, tooltip }) => {
  const editor = useSlate()

  return (
    <Tag
      value={value}
      tooltip={tooltip}
      onMouseDown={e => {
        e.preventDefault()
        insertTag(editor, value)
      }}
    />
  )
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const insertImage = (editor, url) => {
  Transforms.insertNodes(editor, { type: 'image', url, children: [{ text: '' }] })
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  })
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const AddLinkButton = ({ Icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Icon />
    </Button>
  )
}

const AddImageButton = ({ Icon }) => {
  const editor = useSlate()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the image:')
        if (!url) return
        insertImage(editor, url)
      }}
    >
      <Icon />
    </Button>
  )
}

const RemoveLinkButton = ({ Icon }) => {
  const editor = useSlate()

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <Icon />
    </Button>
  )
}

const PLACE_HOLDER_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: ' ' }],
  },
];

export default TextEditor
