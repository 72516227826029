// @flow
import { combineReducers } from '@reduxjs/toolkit';

import type { ID_TYPE } from '../types/common';
import type { MENU_ITEMS_TYPE } from '../types/menuItems';
import * as common from './common';
import * as types from '../types/menuItems';


export type MenusItemsState = {
  byId: { [ID_TYPE]: MENU_ITEMS_TYPE },
};

const byId = common.byId({
  added: [types.MENU_ITEMS_REGISTERED],
  updated: [types.MENU_ITEMS_UPDATED]
});

export const menusItems = combineReducers({
  byId,
});

export default menusItems;


export const getMenuItemsStatus = (
  state: MenusItemsState,
  id: ID_TYPE,
): ?ID_TYPE => (state.byId[id] || {}).item;
