// @flow
import type { FieldArrayProps } from 'redux-form';
import React, { useEffect } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Row, Col, Grid } from 'react-flexbox-grid';
import i18n from 'i18n-js';

import type { ID_TYPE } from '../../types/common';
import * as selectors from '../../reducers';
import { FullLoader } from '../Loader';
import Button from '../Button';
import {
  RenderInput,
  RenderTextarea,
  RenderSelect,
} from '../FieldRenderer';
import {
  FORM_ID,
  // DEFAULT_RELATIONSHIPS
} from '../ClientForm';
import ContactSchoolSpecificFormRender from '../ContactSchoolSpecificFormRender';

import styles from './clientContactsForm.module.scss';


type ClientsFormPropTypes = FieldArrayProps & {
  relationshipsOptions: Array<{ value: ID_TYPE, name: string }>,
  isLoading?: boolean,
  showPreviews?: boolean,
  isExternalForm?: boolean,
  baseColor?: string,
  fieldsToHide?: Array<string>,
  minContactsSize: number,
  maxContactsSize: number,
};

const localFormValueSelector = formValueSelector(FORM_ID);

const ClientsFormDummy = ({
  fields,
  relationshipsOptions,
  isLoading = false,
  showPreviews = false,
  isExternalForm = false,
  baseColor,
  fieldsToHide = [],
  maxContactsSize = 0,
  minContactsSize = 0,
}: ClientsFormPropTypes) => {

  useEffect(() => {
    if (fields.length === 1 && minContactsSize <= 0) fields.remove(0);
  }, [])

  useEffect(() => {
    if (fields.length > 0 && maxContactsSize <= 0) fields = new Array(maxContactsSize).fill(null);
  }, [fields])

  return (isLoading
    ? <FullLoader title="Cargando contactos" tint="gray" />
    : !fieldsToHide.includes('contacts_section') && (
      <Grid>
        {
          !isExternalForm && fields.length === 0 && (
            <div className={`
              ${styles.contactsPlaceholder}
              ${styles.noContacts}
            `}>
              { i18n.t('noContactsAdded') }
            </div>
          )
        }
        {
          fields.map(
            (member, index) => (
              <Row
                key={index}
                className={
                  `${styles.contactRow}
                   ${styles.cardContact}
                   ${isExternalForm ? styles.externalCardContact : ''}`
                }
              >
                {/* Add a division when it's not the first contact */}
                {
                  index !== 0 && (
                    <Col xs={12}>
                      <hr />
                    </Col>
                  )
                }
                {/* -- */}
                <Col xs={12}>
                  <div className={styles.title}>
                    <ContactTitle
                      member={member}
                      relationshipsOptions={relationshipsOptions}
                      baseColor={baseColor}
                    />
                    {
                      !showPreviews
                        && <Button
                          Icon={MdClose}
                          onClick={() => fields.remove(index)}
                          size="small"
                          tint="gray"
                          clearMargins
                          isTransparent
                          isOutlined
                        />
                    }
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    component={props => <input {...props} />}
                    name={`${member}.id`}
                    type="hidden"
                  />
                  <Field
                    label={i18n.t('firstName')}
                    component={RenderInput}
                    name={`${member}.first_name`}
                    type="text"
                    placeholder={i18n.t('egFirstName')}
                    showPreview={showPreviews}
                    baseColor={baseColor}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    label={i18n.t('lastName')}
                    component={RenderInput}
                    name={`${member}.last_name`}
                    type="text"
                    placeholder={i18n.t('egLastName')}
                    showPreview={showPreviews}
                    baseColor={baseColor}
                    required
                  />
                </Col>
                {
                  !fieldsToHide.includes('homeAddress') && (
                    <Col xs={12}>
                      <Field
                        label={i18n.t('homeAddress')}
                        component={RenderTextarea}
                        name={`${member}.address`}
                        placeholder={i18n.t('egHomeAddress')}
                        showPreview={showPreviews}
                        baseColor={baseColor}
                        required
                      />
                    </Col>
                  )
                }
                {
                  !fieldsToHide.includes('id_document') && (
                    <Col xs={12} sm={6}>
                      <Field
                        label={i18n.t('identificationDocument')}
                        component={RenderInput}
                        name={`${member}.id_document`}
                        placeholder={i18n.t('egIdentificationDocument')}
                        showPreview={showPreviews}
                        baseColor={baseColor}
                      />
                    </Col>
                  )
                }
                {
                  !fieldsToHide.includes('email') && (
                    <Col xs={12} sm={6}>
                      <Field
                        label={i18n.t('email')}
                        component={RenderInput}
                        name={`${member}.email`}
                        type="email"
                        placeholder={i18n.t('egEmail')}
                        showPreview={showPreviews}
                        baseColor={baseColor}
                        required
                      />
                    </Col>
                  )
                }
                {
                  !fieldsToHide.includes('phone') && (
                    <Col xs={12} sm={6}>
                      <Field
                        label={i18n.t('phone')}
                        component={RenderInput}
                        name={`${member}.phone`}
                        type="text"
                        placeholder={i18n.t('egPhone')}
                        showPreview={showPreviews}
                        baseColor={baseColor}
                      />
                    </Col>
                  )
                }
                {
                  !fieldsToHide.includes('relationship') && (
                    <Col xs={12} sm={6}>
                      <Field
                        label={i18n.t('relationship')}
                        component={RenderSelect}
                        name={`${member}.relationship`}
                        options={relationshipsOptions}
                        id={`clientContactForm_${member}.relationship`}
                        showPreview={showPreviews}
                        baseColor={baseColor}
                      />
                    </Col>
                  )
                }
                <ContactSchoolSpecificFormRender
                  member={member}
                  showPreviews={showPreviews}
                  baseColor={baseColor}
                />
              </Row>
            ),
          )
        }
        {
          maxContactsSize > 0 && !showPreviews && !fieldsToHide.includes('contact_add_button') && (
            <Row className={
              `${isExternalForm && fields.length === 0 && styles.contactRow}
               ${isExternalForm && fields.length === 0 && styles.cardContact}
               ${isExternalForm && fields.length === 0 && styles.buttonGroup}
               ${isExternalForm && fields.length === 0 ? styles.externalCardContact : ''}`
            }>
              <Col xs={12}>
                {
                  isExternalForm && fields.length === 0 && (
                    <div className={styles.contactsPlaceholder}>
                      <span className={styles.info}>
                        { i18n.t('addContactsPlaceholder') }&nbsp;
                      </span>
                      { i18n.t('addContactsPlaceholderInfo') }
                    </div>
                  )
                }
                <Button
                  className={`
                    ${styles.dottedButton}
                    ${fields.length === maxContactsSize ? styles.disabled : ''}
                  `}
                  type="button"
                  onClick={() => {
                    if (fields.length < maxContactsSize) {
                      fields.push({ relationship: relationshipsOptions[0].value });
                    }
                  }}
                  title={i18n.t('addAnotherContact')}
                  tint="secondary"
                  Icon={MdAdd}
                  style={{
                    color: baseColor,
                    border: `2px dashed ${baseColor}`,
                  }}
                  isFullWidth
                />
              </Col>
            </Row>
          )
        }
      </Grid>
    )
)};

const ContactTitle = connect(
  (state, { member, relationshipsOptions }) => {
    const relationship = localFormValueSelector(state, `${member}.relationship`);
    
    let relationshipGender = relationshipsOptions.find(
      option => option.value === relationship,
    );
    relationshipGender = relationshipGender ? relationshipGender.gender : 'OTHER'

    return { relationship, relationshipGender };
  },
  undefined,
)(
  ({ relationship, relationshipGender, baseColor }) => (
    <h2 style={{ color: baseColor }}>
      {
        relationship
          ? i18n.t('informationOf', {
            relationship: i18n.t(relationship, { defaultValue: relationship }),
            genderRelationship: i18n.t(
              `${relationshipGender}genderInformation`,
              { defaultValue: `${relationshipGender}genderInformation` }
            ),
          })
          : i18n.t('contactInformation')
      }
    </h2>
  ),
);

export default connect(
  (state, { clientId, fields }) => {

    // Hidden & Required client fields list
    const { contact_hidden_fields, min_contacts_size, max_contacts_size } = selectors.getCurrentBoard(state) || {};
    const fieldsToHide = contact_hidden_fields ? [...contact_hidden_fields] : [];
    const maxContactsSize = max_contacts_size || 0;

    let minContactsSize = min_contacts_size ?? 0;
    minContactsSize = max_contacts_size <= 0 ? 0 : minContactsSize;

    return ({
      fields,
      relationshipsOptions: [
        ...selectors.getRelationships(state).map(
          rel => ({ value: i18n.t(rel.name), label: i18n.t(rel.name), gender: rel.gender }),
        ),
        /*
        DEFAULT_RELATIONSHIPS not been used anymore...
        ...DEFAULT_RELATIONSHIPS.map(
          rel => ({ value: i18n.t(rel.value), label: i18n.t(rel.label) }),
        ), */
      ],
      isLoading: selectors.isClientFetchingContacts(state, clientId),
      fieldsToHide,
      minContactsSize,
      maxContactsSize,
    });
  },
)(ClientsFormDummy);
