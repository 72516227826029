// @flow
import type { ID_TYPE } from '../../types/common';


export type FORM_ELEMENT_COMMON_TYPE = {
  position: number
};

export type PLUGIN_DATA_TYPE = {
  label: ?string,
  name: string,
  help_text: ?string,
  required: boolean,
  placeholder: ?string
};

export type OPTION_TYPE = {
  name: string,
  value: ID_TYPE
};

export type BOOLEAN_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'boolean',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?boolean
  }
};
export const BOOLEAN_ELEMENT = 'boolean';

// export type CHECKBOX_SELECT_MULTIPLE_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'checkbox_select_multiple',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     choices: Array<OPTION_TYPE>
//   }
// };
// export const CHECKBOX_SELECT_MULTIPLE_ELEMENT = 'checkbox_select_multiple';

export type DATE_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'date',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?string,
    input_formats: ?string
  }
};
export const DATE_ELEMENT = 'date';

// export type DATE_DROPDOWN_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'date_drop_down',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     input_formats: ?string,
//     year_min: number,
//     year_max: number
//   }
// };
// export const DATE_DROPDOWN_ELEMENT = 'date_drop_down';

// export type DATETIME_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'datetime',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     input_formats: ?string
//   }
// };
// export const DATETIME_ELEMENT = 'datetime';

export type FILE_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'file',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    allowed_extensions: string,
    help_text: string,
    initial: string,
    label: string,
    max_length: number,
    name: string,
    required: boolean,
  }
};
export const FILE_ELEMENT = 'file';

export type DECIMAL_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'decimal',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?number,
    max_digits: ?number,
    decimal_places: ?number,
    min_value: ?number,
    max_value: ?number
  }
};
export const DECIMAL_ELEMENT = 'decimal';

export type EMAIL_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'email',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?string,
    max_length: ?number
  }
};
export const EMAIL_ELEMENT = 'email';

// export type FILE_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'file',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     max_length: ?number,
//     allowed_extensions: ?string
//   }
// };
// export const FILE_ELEMENT = 'file';

export type FLOAT_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'float',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?number,
    min_value: ?number,
    max_value: ?number
  }
};
export const FLOAT_ELEMENT = 'float';

// export type HIDDEN_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'hidden',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     max_length: ?number
//   }
// };
// export const HIDDEN_ELEMENT = 'hidden';

export type INTEGER_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'integer',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?number,
    min_value: ?number,
    max_value: ?number
  }
};
export const INTEGER_ELEMENT = 'integer';

// export type IP_ADDRESS_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'ip_address',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     protocol: ?string,
//     unpack_ipv4: boolean,
//     max_length: ?number,
//   }
// };
// export const IP_ADDRESS_ELEMENT = 'ip_address';

export type NULL_BOOLEAN_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'null_boolean',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?boolean
  }
};
export const NULL_BOOLEAN_ELEMENT = 'null_boolean';

// export type PASSWORD_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'password',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     max_length: ?number
//   }
// };
// export const PASSWORD_ELEMENT = 'password';

// export type RADIO_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'radio',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     choices: Array<OPTION_TYPE>,
//     initial: ?string
//   }
// };
// export const RADIO_ELEMENT = 'radio';

// export type REGEX_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'regex',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     regex: ?string,
//     max_length: ?number,
//   }
// };
// export const REGEX_ELEMENT = 'regex';

export type SELECT_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'select',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    choices: Array<OPTION_TYPE>,
    initial: ?string
  }
};
export const SELECT_ELEMENT = 'select';

// export type SELECT_MULTIPLE_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'select_multiple',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     choices: Array<OPTION_TYPE>,
//     initial: ?string
//   }
// };
// export const SELECT_MULTIPLE_ELEMENT = 'select_multiple';

// export type SLUG_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'slug',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     max_length: ?number
//   }
// };
// export const SLUG_ELEMENT = 'slug';

export type TEXT_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'text',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?string,
    max_length: ?number
  }
};
export const TEXT_ELEMENT = 'text';

export type TEXTAREA_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'textarea',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    initial: ?string,
    max_length: ?number
  }
};
export const TEXTAREA_ELEMENT = 'textarea';

export type CHECKBOX_SELECT_MULTIPLE_ELEMENT_TYPE = {
  ...FORM_ELEMENT_COMMON_TYPE,
  plugin_uid: 'checkbox_select_multiple',
  position: number,
  plugin_data: PLUGIN_DATA_TYPE & {
    choices: Array<OPTION_TYPE>,
    initial: ?string
  }
};
export const CHECKBOX_SELECT_MULTIPLE_ELEMENT = 'checkbox_select_multiple';

// export type TIME_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'time',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     input_formats: string
//   }
// };
// export const TIME_ELEMENT = 'time';

// export type URL_ELEMENT_TYPE = {
//   ...FORM_ELEMENT_COMMON_TYPE,
//   plugin_uid: 'url',
//   plugin_data: PLUGIN_DATA_TYPE & {
//     initial: ?string,
//     max_length: ?number
//   }
// };
// export const URL_ELEMENT = 'url';

export type FOBI_ELEMENT_TYPE =
  | BOOLEAN_ELEMENT_TYPE
  // | CHECKBOX_SELECT_MULTIPLE_ELEMENT_TYPE
  | DATE_ELEMENT_TYPE
  // | DATE_DROPDOWN_ELEMENT_TYPE
  // | DATETIME_ELEMENT_TYPE
  | DECIMAL_ELEMENT_TYPE
  | EMAIL_ELEMENT_TYPE
  // | FILE_ELEMENT_TYPE
  | FLOAT_ELEMENT_TYPE
  // | HIDDEN_ELEMENT_TYPE
  | INTEGER_ELEMENT_TYPE
  // | IP_ADDRESS_ELEMENT_TYPE
  | NULL_BOOLEAN_ELEMENT_TYPE
  // | PASSWORD_ELEMENT_TYPE
  // | RADIO_ELEMENT_TYPE
  // | REGEX_ELEMENT_TYPE
  | SELECT_ELEMENT_TYPE
  // | SELECT_MULTIPLE_ELEMENT_TYPE
  // | SLUG_ELEMENT_TYPE
  | TEXT_ELEMENT_TYPE
  | TEXTAREA_ELEMENT_TYPE
  // | TIME_ELEMENT_TYPE
  // | URL_ELEMENT_TYPE;

export type FOBI_FORM_TYPE = {
  name?: string,
  slug?: string,
  is_public?: boolean,
  active_date_from?: string,
  active_date_to?: string,
  inactive_page_title?: string,
  inactive_page_message?: string,
  is_cloneable?: boolean,
  success_page_title?: string,
  success_page_message?: string,
  action?: Object,
  form_elements: Array<FOBI_ELEMENT_TYPE>
};
