// @flow
import type { ComponentType } from 'react';
import React from 'react';
import {
  MdAttachFile,
  MdFormatBold,
  MdFormatUnderlined,
  MdFormatItalic,
} from 'react-icons/md';

import Button from '../Button';

import styles from './commentEditorToolbar.module.scss';


type CommentEditorToolbarPropTypes = {
  onBoldClicked: Function,
  onUnderlineClicked: Function,
  onItalicClicked: Function,
  onFileSelectClick: Function,
  actionProps: Object,
  overBold?: boolean,
  overItalic?: boolean,
  overUnderline?: boolean,
};

type CommentToolPropTypes = {
  onClick: Function,
  Icon: ComponentType<*>,
  isActive?: boolean
};

const CommentTool = ({
  onClick,
  Icon,
  isActive = false,
}: CommentToolPropTypes) => (
  <button
    type="button"
    className={
      `
        ${styles.tool}
        ${isActive ? styles.active : ''}
      `
    }
    onClick={onClick}
  >
    <Icon size={26} />
  </button>
);

const CommentEditorToolbar = ({
  onBoldClicked,
  onUnderlineClicked,
  onItalicClicked,
  onFileSelectClick,
  actionProps,
  overBold = false,
  overItalic = false,
  overUnderline = false,
}: CommentEditorToolbarPropTypes) => (
  <div className={styles.toolbar}>
    <div className={styles.tools}>
      <CommentTool
        onClick={onBoldClicked}
        Icon={MdFormatBold}
        isActive={overBold}
      />
      <CommentTool
        onClick={onUnderlineClicked}
        Icon={MdFormatUnderlined}
        isActive={overUnderline}
      />
      <CommentTool
        onClick={onItalicClicked}
        Icon={MdFormatItalic}
        isActive={overItalic}
      />
      <div className={styles.divider} />
      { onFileSelectClick && (
        <CommentTool
          onClick={onFileSelectClick}
          Icon={MdAttachFile}
        />
      )}
    </div>
    <div className={styles.action}>
      <Button {...actionProps} clearMargins />
    </div>
  </div>
);


export default CommentEditorToolbar;
