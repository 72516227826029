// @flow
import React from 'react';
import styled from 'styled-components';

import styles from './textarea.module.scss';


type TextareaPropTypes = {
  size?: string,
  className?: string,
  tint?: string,
  isSquare?: boolean,
  isStacked?: boolean,
  hasError?: boolean,
  elevation?: number,
  clearMargins?: boolean,
};

const StyledTextArea = styled.textarea`
  &:focus, &:hover {
    border-color: ${props => props.tint} !important;
  }

  &:focus {
  }
`;

const Textarea = ({
  size = 'medium',
  className = '',
  isSquare = false,
  isStacked = false,
  hasError = false,
  elevation = 1,
  clearMargins = false,
  tint,
  ...props
}: TextareaPropTypes) => (
  <StyledTextArea
    {...props}
    tint={tint}
    className={
      `
        ${styles.textarea}
        ${styles[size]}
        ${className}
        ${isSquare ? styles.squareBorderRadius : ''}
        ${styles[`elevation${elevation}`]}
        ${clearMargins ? styles.noMargins : ''}
        ${isStacked ? styles.stacked : ''}
        ${hasError ? styles.error : ''}
      `
    }
  />
);

export default Textarea;
