// @flow
import React from 'react';

import hoListCheck from '../hoListCheck';
import styles from './tip.module.scss';


const TIP_DIRECTIONS = [
  'up',
  'right',
  'down',
  'left',
];

// Transform tip direction
const transformTipDirection = hoListCheck({
  array: TIP_DIRECTIONS,
  defaultElement: 'down',
});

const transformTipPosition = (axis: string, position: string) => {
  if (axis === 'horizontal') {
    if (position === 'left') {
      return 'hleft';
    }

    if (position === 'center') {
      return 'hcenter';
    }

    return 'hright';
  }

  if (position === 'top') {
    return 'vtop';
  }

  if (position === 'middle') {
    return 'vmiddle';
  }

  return 'vbottom';
};

const getTipBorderStyles = (direction: string, color: string): Object => {
  switch (direction) {
    case 'up': return { borderBottomColor: color };
    case 'down': return { borderTopColor: color };
    case 'left': return { borderRightColor: color };
    default: return { borderLeftColor: color };
  }
};

const Tip = ({
  color = 'white',
  shadowColor = 'rgba(0, 0, 0, 0.03)',
  direction = 'down',
  axis = 'horizontal',
  position = 'center',
}: {
  color?: string,
  shadowColor?: string,
  direction?: string,
  axis?: string,
  position?: string
}) => (
  <div
    className={
      `
        ${styles.shadow}
        ${styles[transformTipDirection(direction)]}
        ${styles[transformTipPosition(axis, position)]}
      `
    }
    style={getTipBorderStyles(direction, shadowColor)}
  >
    <div
      className={
        `
          ${styles.tip}
          ${styles[transformTipDirection(direction)]}
        `
      }
      style={getTipBorderStyles(direction, color)}
    />
  </div>
);

export default Tip;
