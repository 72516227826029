// @flow
import { combineReducers } from '@reduxjs/toolkit';

import * as types from '../types/time';
import type { TIME_ACTION_TYPE } from '../types/time';

export type TimeState = {
  timestamp: number
};

const timestamp = (state: number = 0, action: TIME_ACTION_TYPE): number => {
  switch (action.type) {
    case types.TIME_UPDATED: {
      return action.payload.timestamp;
    }
    default: {
      return state;
    }
  }
};

const time = combineReducers({
  timestamp,
});

export default time;

export const getCurrentTimestamp = (state: TimeState): number => state.timestamp;
