// @flow
import uuid from 'uuid';
import React from 'react';
import { connect } from 'react-redux';
import { Form, FieldArray } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { MdArrowForward } from 'react-icons/md';
import i18n from 'i18n-js';

import * as selectors from '../../reducers';
import * as actions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';

import Button from '../Button';
import ClientGenericDataForm from '../ClientGenericDataForm';
import ClientSchoolSpecificFormRenderer from '../ClientSchoolSpecificFormRenderer';
import ClientContactsForm from '../ClientContactsForm';
import ExternalEpilogue from '../ExternalEpilogue';
import HiddenPDFParagraph from '../HiddenPDFParagraph';


import GenericPopup from '../GenericPopup';
import { FullLoader } from '../Loader';

import type { ClientFormPropTypes } from './index';

import {
  clientReduxFormDecorator,
  clientFormDecorator,
  // DEFAULT_RELATIONSHIPS,
} from './index';

import styles from './clientForm.module.scss';


type ExternalClientCreationFormPropTypes = {
  isLoading?: boolean,
  loadingTitle: string,
}

type ExternalClientCreationFormHandlerPropTypes = {
  baseColor?: string,
};

const ExternalClientCreationFormDummyHandler = clientReduxFormDecorator(({
  canCreateClientWithoutContacts,
  handleSubmit,
  baseColor,
  isSaving = false,
  isPrint,
  closeContactsWarningPopup,
  boardId,
}: ClientFormPropTypes & ExternalClientCreationFormHandlerPropTypes) => (
  <div className={styles.externalClientForm}>
    <Form onSubmit={handleSubmit}>
      <ClientGenericDataForm hideMain baseColor={baseColor} showPreviews={isPrint} />
      <hr />
      <ClientSchoolSpecificFormRenderer baseColor={baseColor} showPreviews={isPrint} />
      <hr />
      <FieldArray
        name="contacts"
        isExternalForm
        component={ClientContactsForm}
        baseColor={baseColor}
        showPreviews={isPrint}
        required={!canCreateClientWithoutContacts}
      />
      <hr />
      <ExternalEpilogue />
      <HiddenPDFEpilogue boardId={boardId} className={styles.pdfEpilogue} />
      <Grid className={styles.hideOnPrint}>
        <Row center="sm">
          {/* <Col xs={12} sm={6}>
            <Button
              tint="mediumGray"
              title={i18n.t('cancel')}
              isFullWidth
            />
          </Col> */}
          <Col xs={12} sm={6}>
            <Button
              type="submit"
              tint="secondary"
              title={i18n.t('startProcess')}
              iconPosition="right"
              Icon={MdArrowForward}
              style={{ backgroundColor: baseColor }}
              isLoading={isSaving}
              disabled={isSaving}
              isFullWidth
            />
          </Col>
        </Row>
      </Grid>
    </Form>
    <GenericPopup
      id="VALIDATE_CONTACTS"
      title="Contactos"
      bodyText={i18n.t('canCreateWithoutContactsWarning')}
      onConfirm={closeContactsWarningPopup}
      hideCancel
      hideCloseButton
    />
  </div>
));


const ExternalClientCreationFormHandler = connect(
  (state) => {
    const cycles = selectors.getCycles(state);
    const levels = selectors.getLevels(state);
    const fieldsToHide = (selectors.getCurrentBoard(state) || {}).contact_hidden_fields;
    const priorityRels = selectors.getRelationships(state)
      .filter(rel => rel.priority > 0)
      .map(rel => ({
        relationship: rel.name,
      }));

    const contacts = !(fieldsToHide || []).includes('contacts_section')
      ? priorityRels
      : [];
    /*
    DEFAULT_RELATIONSHIPS not been used anymore...
      const contacts = !(fieldsToHide || []).includes('contacts_section') || undefined;
        ? DEFAULT_RELATIONSHIPS.map(rel => ({ // Default values
          relationship: rel.value,
        }))
      : [];
    */

    const canCreateClientWithoutContacts =
      selectors.getCurrentBoard(state).min_contacts_size === 0 ||
      selectors.getCurrentBoard(state).max_contacts_size === 0;

    return ({
      baseColor: ((selectors.getCurrentBoard(state) || {}).colors || {}).base,
      isSaving: selectors.isCreatingClient(state),
      canCreateClientWithoutContacts,
      initialValues: {
        cycle: cycles && cycles.length > 0 ? cycles[0].id : undefined,
        level: levels && levels.length > 0 ? levels[0].id : undefined,
        contacts,
      },
    });
  },
  dispatch => ({
    onSubmit(values: Object) {
      dispatch(actions.startAddingClient({
        id: uuid(),
        ...values,
      }, true));
    },
    open: id => dispatch(closeableActions.open(id)),
    closeContactsWarningPopup() {
      dispatch(closeableActions.close('VALIDATE_CONTACTS'));
    },
  }),
)(clientFormDecorator(ExternalClientCreationFormDummyHandler));

const ExternalClientCreationForm = ({
  isLoading = false,
  loadingTitle,
  isPrint,
}: ExternalClientCreationFormPropTypes) => (
  isLoading
    ? (
      <FullLoader tint="gray" title={loadingTitle} />
    ) : (
      <ExternalClientCreationFormHandler isPrint={isPrint} />
    )
);


export default connect(
  (state) => {
    const fBoard = selectors.isFetchingCurrentBoard(state);
    const fCycles = selectors.isFetchingCycles(state);
    const fLevels = selectors.isFetchingLevels(state);
    const fPhases = selectors.isFetchingPhases(state);
    const fRelationships = selectors.isFetchingRelationships(state);
    const fForm = selectors.getClientFormDescription(state) == null;

    let loadingTitle = '';
    if (fBoard) loadingTitle = i18n.t('loadingSchool');
    else if (fCycles) loadingTitle = i18n.t('loadingCycles');
    else if (fLevels) loadingTitle = i18n.t('loadingLevels');
    else if (fPhases) loadingTitle = i18n.t('loadingPhases');
    else if (fRelationships) loadingTitle = i18n.t('loadingRelationships');
    else if (fForm) loadingTitle = i18n.t('loadingForm');
    return {
      isLoading: fBoard || fLevels || fPhases || fCycles || fRelationships || fForm,
      loadingTitle,
    };
  },
)(ExternalClientCreationForm);

export const HiddenPDFEpilogue = connect(
  (state, { boardId }) => {
    const { print_epilogue } = selectors.getCurrentBoard(state, boardId);
    const i18nLocale = selectors.getLocaleParam(state, true);

    const defaultLocaleKey = Object.keys(print_epilogue).length
      ? Object.keys(print_epilogue)[0]
      : undefined;
    
    const printEpilogueHTMLText = (i18nLocale in print_epilogue || defaultLocaleKey)
      ? print_epilogue[i18nLocale] || print_epilogue[defaultLocaleKey]
      : ''
    
    return { text: print_epilogue ? { __html: `${printEpilogueHTMLText}` } : null };
  },
  undefined,
)(HiddenPDFParagraph);
