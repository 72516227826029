// @flow
import React from 'react';
import type { Node } from 'react';

import Loader from '../Loader';
import Card from '../Card';

import styles from './chartContainer.module.scss';

type ChartContainerProps = {
  children: Node,
  isLoading?: boolean
}

const ChartContainer = ({
  children,
  isLoading,
}: ChartContainerProps) => (
  <div className={styles.chartContainer}>
    <Card className={styles.card}>
      <div className={styles.content}>
        {
          isLoading ? (
            <Loader
              tint="mediumgray"
              size="large"
              className={styles.loader}
            />
          ) : (
            <div className={styles.chart}>
              {children}
            </div>
          )
        }
      </div>
    </Card>
  </div>
);

export default ChartContainer;
