// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Col, Grid } from 'react-flexbox-grid';
import { MdClose } from 'react-icons/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import Label from '../Label';
import Omnisearch from '../Omnisearch';

import { CLIENT_EDITION_POPUP_ID } from '../ClientEditionPopup';
import * as actions from '../../actions/clients';
import * as closeableActions from '../../actions/closeable';
import { open } from '../../actions/closeable';

import styles from './popup.module.scss';

export const OMNISEARCH_TYPEAHEAD_POPUP_ID = 'omnisearchTypeaheadPopup';

type OmnisearchTypeaheadPopupPropTypes = {
  onSelectedSearchResult: Function;
};

const OmnisearchTypeaheadPopup = ({
  onSelectedSearchResult,
}: OmnisearchTypeaheadPopupPropTypes) => {
  const onSelect = item => {
    const { value } = item;
    onSelectedSearchResult && onSelectedSearchResult(value);
  };

  return (
    <Popup id={OMNISEARCH_TYPEAHEAD_POPUP_ID}>
      <PopupContainer
        size={'small'}
        isFullscreen={false}
        isRound
      >
        <div className={styles.closeButtonContainer}>
          <PopupCloseButton
            tint={"mediumGray"}
            Icon={MdClose}
            clearMargins
            isTransparent
            isOutlined
            className={styles.closeButton}
          />
        </div>
        <PopupContent
          className={styles.popupContent}
          size={'large'}
        >
          <Grid>
            <Col>
              <Label>
                {'Búsca a un estudiante o contacto'}
              </Label>
              <div className={styles.omnisearchContainer}>
                <Omnisearch 
                  onSelect={onSelect}
                />
              </div>
            </Col>
          </Grid>
        </PopupContent>
      </PopupContainer>
    </Popup>
  );
} 

export default connect(
  undefined,
  (dispatch) => ({
    onSelectedSearchResult(id) {
      dispatch(actions.startFetchingClient(id));
      // This will be executed when client belongs to the current board (no redirect needed)
      dispatch(closeableActions.close(OMNISEARCH_TYPEAHEAD_POPUP_ID));  
      dispatch(actions.selectClientForEdition(id));
      dispatch(open(CLIENT_EDITION_POPUP_ID));
    },
  }),
)(OmnisearchTypeaheadPopup);
