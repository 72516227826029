// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type { CLIENT_TYPE } from '../types/clients';
import type {
  CALENDAR_VIEW_FILTER_UPDATED_TYPE,
  CALENDAR_VIEW_FILTER_FETCH_STARTED_TYPE,
  CALENDAR_VIEW_FILTER_FETCH_COMPLETED_TYPE,
  CALENDAR_VIEW_FILTER_FETCH_FAILED_TYPE,
} from '../types/calendarViewFilter';
import * as types from '../types/calendarViewFilter';


export const updateCalendarViewFilter = (filters: {
  startDate?: Date,
  endDate?: Date,
  phaseId?: number
}): CALENDAR_VIEW_FILTER_UPDATED_TYPE => ({
  type: types.CALENDAR_VIEW_FILTER_UPDATED,
  payload: filters,
});

export const startFetchCalendarViewFilter = (): CALENDAR_VIEW_FILTER_FETCH_STARTED_TYPE => ({
  type: types.CALENDAR_VIEW_FILTER_FETCH_STARTED,
});

export const completeFetchCalendarViewFilter = (
  clients: { [ID_TYPE]: CLIENT_TYPE },
  clientIds: Array<ID_TYPE>,
): CALENDAR_VIEW_FILTER_FETCH_COMPLETED_TYPE => ({
  type: types.CALENDAR_VIEW_FILTER_FETCH_COMPLETED,
  payload: {
    entities: clients,
    order: clientIds,
  },
});

export const failFetchCalendarViewFilter = (
  error: ERROR_TYPE,
): CALENDAR_VIEW_FILTER_FETCH_FAILED_TYPE => ({
  type: types.CALENDAR_VIEW_FILTER_FETCH_FAILED,
  payload: error,
});
