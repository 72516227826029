// @flow
import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';

import styles from './clientSchoolLogo.module.scss';


type ClientSchoolLogoPropTypes = {
  pictureURL: string,
};

const ClientSchoolLogo = ({ pictureURL }: ClientSchoolLogoPropTypes) => (
  <img
    className={styles.clientSchoolLogo}
    src={pictureURL}
    alt="logo"
  />
);

export default connect(
  (state, { clientId }) => {
    const client = selectors.getClient(state, clientId);
    const board = selectors.getCurrentBoard(state, client.board);
    const { logo } = board.school;
    return {
      pictureURL: logo ? logo.url : '',
    };
  },
  undefined,
)(ClientSchoolLogo);
